import React from 'react';

import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import appTheme from "../../../../../assets/appTheme";

import DLText from "../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../core/components/DLButton/DLButton";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import DiscardTransaction from "./../components/discardTransaction/DiscardTransaction";
import SendToManagement from "./../components/sendToManagement/SendToManagement";
import EditPaymentDetails from "./../components/editPaymentDetails/EditPaymentDetails";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu/Menu";
import {renderStatusUI} from "../../../FTHelper";
import MoveToDraft from "../components/moveToDraft/MoveToDraft";
import {numberWithCommas} from "../../../../../utils/helper";

const FTDetailsForCRMView = (props) => {
    const {
        isMobileView, accountHolders, category, IINNumber, accountType, isShowLoader
        , UIState, paymentDetails, investmentDetails, ticketNumber, txnStatus, txnStage, txnData, handleOpenDiscardTransactionModal,
        handleOpenSendToManagementModal, handleOpenEditPaymentModal, handleAddOrEditTransactionDetails, anchorEl, handleItemClose,
        handleItemOpen, paymentId, FTStatuses, handleOpenMoveToDraftModal, rejectComment, getDepositMode
    } = props;

    const handleAmount = (amount) =>{
        if(isEmpty(amount)){
            return "-";
        }
        return numberWithCommas(amount)
    }

    const renderMoveToDraftModal = () => {
        const {isMobileView, handleCloseMoveToDraftModal, isMoveToDraftModalOpen} = props;
        if (isMoveToDraftModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}}
                            onClick={handleCloseMoveToDraftModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <MoveToDraft {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <MoveToDraft {...props}/>
                )
            }
        }

    };

    const renderDiscardTransactionModal = () => {
        const {isMobileView, handleCloseDiscardTransactionModal, isDiscardTransactionModalOpen} = props;
        if (isDiscardTransactionModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}}
                            onClick={handleCloseDiscardTransactionModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <DiscardTransaction {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <DiscardTransaction {...props}/>
                )
            }
        }

    };

    const renderEditPaymentModal = () => {
        const {isMobileView, handleCloseEditPaymentModal, isEditPaymentModalOpen} = props;
        if (isEditPaymentModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseEditPaymentModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <EditPaymentDetails {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <EditPaymentDetails {...props}/>
                )
            }
        }

    };

    const renderSendToManagementTransactionModal = () => {
        const {isMobileView, handleCloseSendToManagementModal, isSendToManagementModalOpen} = props;

        if (isSendToManagementModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseSendToManagementModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <SendToManagement {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <SendToManagement {...props}/>
                )
            }
        }

    };

    const renderAccountHoldersDetails = (accountDetails) => {
        return accountDetails.map((accountHolder, index) => (
            <div key={'menuItem-' + accountHolder.id}>
                <MenuItem
                    className={'py-0 text-center pb-2 pt-2'}
                    style={{minWidth: 200, height: "max-content", cursor: "auto"}}
                    disableRipple
                >
                    <div className='text-start'>
                        <DLText
                            id={'account-holder-name'}
                            text={'Account Holder ' + (index + 1) + ': ' + accountHolder.fullName}
                            marginBottom={"none"}
                            fontSize={"xs"}
                        />
                        <div>
                            {isEmpty(accountHolder.email) ? '-' : <a href={'mailto:' + accountHolder.email}
                               style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                                {accountHolder.email}
                            </a>}
                        </div>
                        <div>
                            {isEmpty(accountHolder.phoneNumber) ? '-' : <a href={'tel:' + accountHolder.phoneNumber}
                               style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                                {accountHolder.phoneNumber}
                            </a>}
                        </div>
                    </div>
                </MenuItem>
                <div className={'px-3'}>
                    {index < accountDetails.length - 1 && (
                        <div className='border-bottom'/>
                    )}
                </div>
            </div>
        ));
    };

    const renderAccountDetails = () => {
        const {accountDetails} = props;

        let allAccountHoldersNames = "";
        accountDetails.map((applicant) => {
            if (isEmpty(allAccountHoldersNames)) {
                allAccountHoldersNames = applicant?.fullName;
            } else {
                allAccountHoldersNames = allAccountHoldersNames + ', ' + applicant?.fullName;
            }
        });

        return (
            <div key={'accountDetails'}
                 className='d-flex align-items-center '>
                <div>
                    {(!isMobileView) &&
                    <div onClick={(event) => {
                        handleItemOpen(event)
                    }}>
                        <DLText id={'account-holder-name'}
                                text={allAccountHoldersNames}
                                marginBottom={"none"}
                                isClickable={true}
                                fontColor={"darkBlue"}
                        />
                    </div>
                    }
                    <Menu id="items-menu"
                          anchorEl={anchorEl}
                          getcontentanchorel={null}
                          anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                          transformOrigin={{vertical: "top", horizontal: "left"}}
                          open={Boolean(anchorEl)}
                          onClose={() => handleItemClose()}
                          className="p-2 w-100"
                          disableAutoFocusItem
                          elevation={0}
                          PaperProps={{
                              style: {
                                  marginTop: '0px',
                                  boxShadow: '0 4px 4px rgba(147, 147, 147, 0.25)',
                                  borderRadius: '8px'
                              }
                          }}
                          MenuListProps={{
                              style: {
                                  color: appTheme.appColor.black,
                                  maxHeight: '400px',
                                  textAlign: 'center',
                                  borderRadius: '8px'
                              },
                          }}>
                        {accountDetails && accountDetails.length > 0 ? (
                            renderAccountHoldersDetails(accountDetails)
                        ) : (
                            <MenuItem disabled>No applicants available</MenuItem>
                        )}
                    </Menu>
                </div>
            </div>
        );
    };

    const renderDesktopHeader = () => {
        return (
            <div className='w-100'>
                <div className='page-header d-flex justify-content-between'>
                    <div className="d-flex align-items-center">
                        <i className='fa-solid fa-chevron-left cursor fa-2xs'
                           style={{width: 10}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className="ps-2">
                            Financial Transaction: Lumpsum Transaction
                        </div>
                    </div>

                </div>
                <div className='d-flex align-items-center mb-3 page-container '>
                    <div className='pe-2 border-end-black d-flex '>
                        <DLText id={'ac-details-name'}
                                fontWeight={'semi-bold'}
                                text={accountType + '-' + IINNumber}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='px-2 border-end-black cursor'>
                        <DLText id={'category'}
                                text={category}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='px-2 border-end-black'>
                        {renderAccountDetails()}
                    </div>
                    <div className='px-2 border-end-black'>
                        {ticketNumber}
                    </div>
                    <div className='px-2'>
                        {renderStatusUI(txnStage, txnStatus, FTStatuses)}
                    </div>
                </div>
            </div>
        )
    };

    const renderChequePaymentDetails = () => {
        return (
            <div className="h-100 bg-white border-rounded p-3">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'payment-details-title'}
                        type={'normal'}
                        text={'Payment Details'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                    <DLButton
                        id={'edit-transaction'}
                        label={'Edit'}
                        buttonSize={"sm"}
                        fontSize={"sm"}
                        styleType={"outlined"}
                        hidden={txnStage !== "DRAFT"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => {
                            handleOpenEditPaymentModal()
                        }}/>
                </div>
                <div className={'pe-3 overflow-scroll overflow-x-hidden mt-3'}
                     style={{maxHeight: 'calc(100dvh - 480px)'}}>
                    <div className='row mb-3'>
                        <div className='col-3'>
                            <DLText id={'payment-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Payment Mode'}/>
                            <DLText id={'payment-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.paymentMechanism?.description}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'bank-name-&-account-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Bank Name & Account Number'}/>
                            {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3'>
                            <DLText id={'cheque-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Cheque Number'}/>
                            <DLText id={'cheque-number-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.chequePayment?.chequeNo}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'micr-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'MICR Number'}/>
                            <DLText id={'micr-number-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.chequePayment?.micrNo}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'date'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Date (date on cheque)'}/>
                            <DLText id={'date-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.chequePayment?.chequeDate}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'cheque-deposite-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Cheque Deposite Mode'}/>
                            <DLText id={'cheque-deposite-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={getDepositMode(paymentDetails?.chequePayment?.chequeDepositMode)}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderPaymentDetails = () => {
        if(paymentDetails?.paymentMechanism?.description === "CHEQUE"){
            return renderChequePaymentDetails()
        }
        return (
            <div className="h-100 bg-white border-rounded p-3">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'payment-details-title'}
                        type={'normal'}
                        text={'Payment Details'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                    <DLButton
                        id={'edit-transaction'}
                        label={'Edit'}
                        buttonSize={"sm"}
                        fontSize={"sm"}
                        styleType={"outlined"}
                        hidden={txnStage !== "DRAFT"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => {
                            handleOpenEditPaymentModal()
                        }}/>
                </div>
                <div className={'pe-3 overflow-scroll overflow-x-hidden mt-3'}
                     style={{maxHeight: 'calc(100dvh - 480px)'}}>
                    <div className='row mb-3'>
                        <div className='col-3'>
                            <DLText id={'payment-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Payment Mode'}/>
                            <DLText id={'payment-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.paymentMechanism?.description}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'bank-name-&-account-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Bank Name & Account Number'}/>
                            {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3'>
                            <DLText id={'RTGS/IFSC-code'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'RTGS/IFSC Code'}/>
                            <DLText id={'RTGS/IFSC-code-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.bankAccount?.IFSCCode}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderBankNameAndAccountNumberUi = (bankAccountDetails) =>{
        if(isEmpty(bankAccountDetails?.bankName) && isEmpty(bankAccountDetails?.accountNumber)){
           return "-"
        }
        return (
            <div>
                <DLText id={'bank-name-&-account-number-value'}
                        fontSize={'sm'}
                        marginBottom={"none"}
                        fontWeight={"normal"}
                        isInline={true}
                        text={bankAccountDetails?.bankName}/>
                <DLText id={'bank-name-&-account-number-value'}
                        fontSize={'sm'}
                        marginBottom={"none"}
                        fontWeight={"normal"}
                        isInline={true}
                        text={bankAccountDetails?.accountNumber}/>
            </div>
        )
    }

    const renderInvestmentDetails = () => {
        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded p-3 h-100">
                    <div className='d-flex justify-content-between'>
                        <div>
                            <DLText
                                id={'investment-amount-title'}
                                type={'normal'}
                                text={'Investment Amount : ' + handleAmount(txnData?.investmentAmount)}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                fontColor={'black'}
                                marginBottom={"none"}
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-center'>
                            <DLText id={''}
                                    text={'No schemes have been added for the funds'}
                                    fontWeight={'normal'}
                                    fontSize={"sm"}
                            />
                            <DLButton id={'add--button'}
                                      onClick={() => handleAddOrEditTransactionDetails("ADD")}
                                      hidden={txnStage !== "DRAFT"}
                                      label={'Add'}/>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded p-3 ">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'investment-amount-title'}
                        type={'normal'}
                        text={'Investment Amount : ' + handleAmount(txnData?.investmentAmount)}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                    <DLButton
                        id={'edit-transaction'}
                        label={'Edit'}
                        hidden={txnStage !== "DRAFT"}
                        buttonSize={"sm"}
                        fontSize={"sm"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => handleAddOrEditTransactionDetails("EDIT")}/>
                </div>
                <div className='pt-3'>
                    <div className='p-0 w-100 border-rounded panel h-100'>
                        <div className="p-2 h-100 c">
                            {renderHeadings()}
                            {renderData()}
                            {renderTotalCount()}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderHeadings = () => {
        return (
            <div className={"py-2 border-bottom row px-0 m-0"}>
                <div className='data-card-label col-4' style={{}}>{"Funds"}</div>
                <div className='data-card-label col-4' style={{}}>{"Folio No"}</div>
                <div className='data-card-label col-2 text-end' style={{maxWidth: "150px"}}>{"Allocation"}</div>
                <div className='data-card-label col-2 text-end' style={{maxWidth: "150px"}}>{"Amount"}</div>
            </div>
        )
    };

    const renderTotalCount = () => {
        return (
            <div className={"py-2 border-top row px-0 m-0"}>
                <div className='data-card-label col-4' style={{}}></div>
                <div className='data-card-label col-4' style={{}}></div>
                <div className='data-card-label col-2 text-end' style={{maxWidth: "150px"}}>{isEmpty(txnData?.totalAllocationPercentage)? "-" : txnData?.totalAllocationPercentage + "%"}</div>
                <div className='data-card-label col-2 text-end' style={{maxWidth: "150px"}}>{isEmpty(txnData?.totalAllocationAmount)? "-" : handleAmount(txnData?.totalAllocationAmount)}</div>
            </div>
        )
    };

    const renderData = () => {
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "" : "border-bottom"}>
                            <div className={"py-2 row px-0 m-0"}
                                 key={index}>
                                <div className='data-card-label col-4'>
                                    <DLText id={''}
                                            text={item?.productName}
                                            fontWeight={"semi-bold"}
                                            fontSize={"xs"}
                                    />
                                </div>
                                <div className='data-card-label col-4'>
                                    <DLText id={''}
                                            text={item?.folioNumber}
                                            fontWeight={"normal"}
                                            fontSize={"xs"}
                                    />
                                </div>
                                <div className='data-card-label col-2 text-end' style={{maxWidth: "150px"}}>
                                    <DLText id={''}
                                            text={item?.allocation + "%"}
                                            fontWeight={"normal"}
                                            fontSize={"xs"}
                                    />
                                </div>
                                <div className='data-card-label col-2 text-end' style={{maxWidth: "150px"}}>
                                    <DLText id={''}
                                            text={handleAmount(item?.amount)}
                                            fontWeight={"normal"}
                                            fontSize={"xs"}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderDraftButtons = () => {
        return (
            <div className="sticky-bottom-buttons gap-0">
                <DLButton
                    id={'move-to-draft-Btn'}
                    history={props.history}
                    label={'Move To Draft'}
                    buttonSize={"sm"}
                    fontSize={"sm"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleOpenMoveToDraftModal()
                    }}
                />
            </div>
        )
    };

    const renderButtons = () => {
        return (
            <div className="sticky-bottom-buttons gap-0">
                {!isEmpty(txnData?.totalAllocationAmount)
                &&
                <div className='d-flex justify-content-end align-items-center me-2 gap-1'>
                    <DLText id={''}
                            text={'Current payment amount'}
                            fontSize={"sm"}
                            fontWeight={"normal"}
                            fontColor={"grayDark"}
                            marginBottom={"none"}
                    />
                    <DLText id={''}
                            text={'Rs.' + handleAmount(txnData?.totalAllocationAmount)}
                            fontSize={"lg"}
                            fontWeight={"bold"}
                            marginBottom={"none"}
                    />
                </div>}
                <DLButton
                    id={'discardBtn'}
                    history={history}
                    label={'Discard'}
                    buttonSize={"sm"}
                    fontSize={"sm"}
                    type={'danger'}
                    styleType={"contained"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleOpenDiscardTransactionModal()
                    }}/>
                <DLButton
                    id={'send-to-management-Btn'}
                    history={props.history}
                    label={'Send to Management'}
                    isDisabled={isEmpty(txnData?.totalAllocationAmount) || isEmpty(paymentId)}
                    buttonSize={"sm"}
                    fontSize={"sm"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleOpenSendToManagementModal()
                    }}
                />
            </div>
        )
    };

    const renderDetails = () => {
        return (
            <div className='h-100'>
                <div className='d-flex flex-column h-100'>
                    <div className='mb-2 flex-grow-1'>
                        {renderInvestmentDetails()}
                    </div>
                    <div className='mb-5'>
                        {renderPaymentDetails()}
                    </div>
                </div>
            </div>
        )
    };

    const renderError = () => {
        if (txnStatus === "REJECTED" && !isEmpty(rejectComment)) {
            return (
                <div className='mb-3 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {rejectComment}
                    </div>
                </div>
            )
        }
    };

    const renderDesktopUI = () => {
        return (
            <div className='d-flex flex-column bg-light h-100'>
                {renderDesktopHeader()}
                <div className='page-container flex-grow-1 bg-light'>
                    {renderError()}
                    {renderDetails()}
                </div>
                {txnStage === "DRAFT" && renderButtons()}
                {txnStatus === "REJECTED" && renderDraftButtons()}
            </div>
        );
    };

    const renderMobileUI = () => {
        return (
            <div>
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader || isEmpty(UIState)}/>);
    };

    if (isEmpty(UIState)) {
        return renderLoader()
    }

    return (
        <div className='h-100'>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}
            {renderLoader()}
            {renderDiscardTransactionModal()}
            {renderSendToManagementTransactionModal()}
            {renderEditPaymentModal()}
            {renderMoveToDraftModal()}
        </div>
    );
};

export default FTDetailsForCRMView;