import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../AppComponents/AppConstants";

import DLLoader from "../../../core/components/DLLoader/DLLoader";
import {
    getClientRequestsData,
    resetClientRequestsFilters,
    setClientRequestsRowPerPageCount,
    setLimitCount,
    setPageCount,
    setSearchKeyword,
    setSkipCount,
    setStatus
} from "../../ClientRequestsActions"
import CRMListView from "./CRMListView";

const CRMList = (props) => {
    const [CRMListState, setCRMListState] = useState({
        clientRequests: [],
        listingCount: 0,
        skipCount: 0,
        limitCount: 50,
        isShowFilterMobile: false,
        isCreateModalOpen: false,

        UIState: '',
        errorInApi: '',
        isShowLoader: false,
        filters: {
            keyword: '',
            status: ['OPEN','IN_PROGRESS'],
            CRMId: 'ALL'
        },
    });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        getClientRequests()
    }, [props.clientRequestsFilter]);

    const generateFilter = (filter) => {
        let filterObject = {};
        if (!isEmpty(filter.keyword)) {
            filterObject.keyword = filter.keyword;
        }
        if (!isEmpty(filter.status)) {
            if (filter.status.length === 0) {
                delete filterObject.status
            } else {
                filterObject.status = filter.status;
            }
        }
        return filterObject;
    };

    const getClientRequests = () => {
        const {clientRequestsFilter} = props;

        setCRMListState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            skipCount: clientRequestsFilter.skipCount,
            limitCount: clientRequestsFilter.limitCount,
            filters: generateFilter(clientRequestsFilter),
        };

        getClientRequestsData(payload)
            .then(res => {
                if (res?.success) {
                    setCRMListState(prevState => ({
                        ...prevState,
                        clientRequests: res.data?.clientRequests,
                        listingCount: res.data?.count,
                        skipCount: clientRequestsFilter.skipCount,
                        limitCount: clientRequestsFilter.limitCount,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                    setPage(isEmpty(clientRequestsFilter.page) ? 0 : clientRequestsFilter.page);
                    setRowsPerPage(isEmpty(clientRequestsFilter.rowsPerPage) ? 50 : clientRequestsFilter.rowsPerPage);
                } else {
                    setCRMListState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    }

    // Function to determine whether to enable/disable the pagination control buttons
    const getPaginationNextButtonStatus = () => {
        const {listingCount, skipCount, limitCount} = CRMListState;
        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setCRMListState((prevState) => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    keyword: value,
                }
            }));
            setSearchKeyword(value);
            return;
        }

        if (name === 'status') {
            setCRMListState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    status: [...value]
                }
            }));
            setStatus([...value]);
        }
    };

    const resetPagination = () => {
        setClientRequestsRowPerPageCount(50);
        setLimitCount(50);
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangeRowsPerPage = (event) => {
        setCRMListState(prevState => ({
            ...prevState,
            isSearchFilterUpdated: true
        }));

        setClientRequestsRowPerPageCount(parseInt(event.target.value, 10));
        setLimitCount(parseInt(event.target.value, 10));
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangePage = (event, newPage) => {
        let {limitCount, skipCount} = CRMListState;
        const isForward = newPage > page;
        if (isForward) {
            skipCount = skipCount + rowsPerPage;
            limitCount = rowsPerPage
        } else {
            skipCount = skipCount - rowsPerPage;
            limitCount = rowsPerPage
        }

        setSkipCount(skipCount);
        setLimitCount(limitCount);
        setPageCount(newPage);
    };

    const handleToggleMobileFilter = (value) => {
        setCRMListState((prevStep) => ({
            ...prevStep,
            isShowFilterMobile: value
        }));
    };

    const resetStatusFilters = () => {
        resetClientRequestsFilters();
    };

    const handleRefreshButton=()=>{
        getClientRequests();
    };

    const redirectToDetailsScreen = (clientRequestsId) => {
        props.history.push('/client-requests/' + clientRequestsId)
    };

    if (isEmpty(CRMListState.UIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    return (
        <CRMListView
            {...props}
            {...CRMListState}
            page={page}
            rowsPerPage={rowsPerPage}
            handleRefreshButton={handleRefreshButton}
            handleToggleMobileFilter={handleToggleMobileFilter}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getPaginationNextButtonStatus={getPaginationNextButtonStatus}
            onChangeFilter={onChangeFilter}
            redirectToDetailsScreen={redirectToDetailsScreen}
            resetStatusFilters={resetStatusFilters}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    clientRequestsFilter: state.clientRequests.clientRequestsFilter,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(CRMList);
