import React, {useState} from 'react';
import {connect} from "react-redux";

import DeleteOnboardingView from "./DeleteOnboardingView";
import {removeOnboarding} from "../../OnboardingActions";
import {isEmpty} from "../../../utils/validations";


const DeleteOnboarding = (props) => {

    const [deleteOnboardingState, setDeleteOnboardingState] = useState({
        isApiInProgress: false,
        handleErrorInComponent:true,
        modalErrorMessage:'',
    });

    const deleteOnboarding = () => {
        const {getApplicationId, handleCloseDeleteOnboardingModal} = props;

        let onboardingId = getApplicationId();

        setDeleteOnboardingState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));
        removeOnboarding(onboardingId)
            .then((res) => {
                if (res.success) {
                    handleCloseDeleteOnboardingModal();
                    props.history.push('/onboardings');
                    setDeleteOnboardingState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false
                    }));
                } else {
                    setDeleteOnboardingState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false,
                        modalErrorMessage:res.__error
                    }));
                }
            }
        );
    };

    const getName = () =>{
        const {applicantDetails} = props;
        let name = '';
        if(isEmpty(applicantDetails)){
            return;
        }
        applicantDetails.map((item)=>{
           if(isEmpty(name)){
               name = item.name;
           }else{
               name = name +', '+item.name
           }
        })
        return name;
    }

    return (
        <DeleteOnboardingView
            {...props}
            {...deleteOnboardingState}
            deleteOnboarding={deleteOnboarding}
            getName={getName}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(DeleteOnboarding);
