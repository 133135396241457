import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {isEmpty} from "../../../../../../utils/validations";

import {
    submitIINData,
    FATKASendToClient,
    FATKARetrigger,
    IINSendToClient,
    IINRetrigger,
    getIINActivity
} from "../../../FormsAndReviewActions";
import {getOnboardingDetails} from "../../../../../OnboardingActions";
import {getOnboardingStatus} from "../../../../../OnboardingHelper";
import {onboardingStatuses} from "../../../../../OnboardingConsts";

import IINActivityView from "./IINActivityView";
import store from "../../../../../../store";
import {showToastMessage} from "../../../../../../AppComponents/AppActions";
import {refreshIINStatus, setIINFormStatus, setIINSubmitStatus} from "../../../../../onboardingFlowReducer";

const IINActivity = (props) => {

    const [IINActivityState, setIINActivityState] = useState({
        NSEActivities: [],
        isShowLoader: false,
        NSEStatus: '',
        NSEButtonStatus: '',
        responseErrors: '',
        IINNumber: ''
    });

    useEffect(() => {
        getStatus();
    }, []);

    useEffect(() => {
        const {isRefershIINForm} = props;
        if(isRefershIINForm){
            getStatus();
            store.dispatch(setIINFormStatus(false));
        }
    }, [props.isRefershIINForm]);

    useEffect(() => {
        const {isIINSubmitted, resetIINSubmissionStatus} = props;
        if(isIINSubmitted){
            getStatus();
            setTimeout(()=>{
                resetIINSubmissionStatus()
            },1000)
        }

    }, [props.isIINSubmitted]);

    useEffect(() => {
        const {refreshIIN,} = props;
        if(refreshIIN){
            getStatus(true);
            setTimeout(()=>{
                store.dispatch(refreshIINStatus(false));
            },1000)
        }

    }, [props.refreshIIN]);

    const getStatus = (isRefreshActivity) => {
        const {onboardingApplicationId, setIINForm} = props;
        setIINActivityState(prevState => ({
            ...prevState,
            isShowLoader: true,
        }));

        getOnboardingDetails(onboardingApplicationId)
            .then((res) => {
                if (res?.success) {
                    if(!isRefreshActivity){
                        getSubmitButtonStatus(res?.data);
                        setIINForm(res.data?.account?.accountIINDetails?.IIN);
                    }else{
                        setIINActivityState(prevState => ({
                            ...prevState,
                            isShowLoader: false,
                        }));
                    }
                    store.dispatch(setIINSubmitStatus(res?.data?.iinStatus));
                } else {
                    if(!isRefreshActivity){
                        getIINActivities();
                    }
                    setIINActivityState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                    }));
                }
            })
    };

    const getSubmitButtonStatus = (data) => {
        let status = data?.iinStatus;
        let onboardingApplicationStatus = data?.status;
        if (isEmpty(status)) {
            getIINActivities();
            return ''
        }
        let NSEStatus = getOnboardingStatus(status, onboardingStatuses);

        if (status === 'DRAFT' || status === 'NOT_READY_FOR_SUBMISSION') {
            setIINActivityState(prevState => ({
                ...prevState,
                isShowLoader: false,
                NSEStatus: NSEStatus,
                NSEButtonStatus: 'HIDE',
            }));
        }
        if (status === 'SUBMISSION_IN_PROGRESS' || status === 'AWAITING_CONFIRMATION' || status === 'CONFIRMED') {
            setIINActivityState(prevState => ({
                ...prevState,
                isShowLoader: false,
                NSEStatus: NSEStatus,
                NSEButtonStatus: 'DISABLE',
            }));
        }
        if (status === 'SUBMISSION_FAILED' || status === 'ERRORED' || status === 'READY_FOR_SUBMISSION') {
            setIINActivityState(prevState => ({
                ...prevState,
                isShowLoader: false,
                NSEStatus: NSEStatus,
                NSEButtonStatus: 'SHOW',
            }));
        }
        setTimeout(() => {
            getIINActivities();
            props.handleChangeOnboardingStatus(status, onboardingApplicationStatus)
        }, 500);
    };

    const getIINNumbers = (data) => {
        if (isEmpty(data)) {
            return ''
        }
        const createCustomerObj = data.find(item => item.api === "CREATECUSTOMER");

        // Return the response part of the found object
        return !isEmpty(createCustomerObj) ? createCustomerObj.response?.IINNumber : '';
    };

    const getIINActivities = () => {
        const {onboardingApplicationId, setActivityStatus} = props;
        setIINActivityState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));
        getIINActivity(onboardingApplicationId)
            .then(res => {
                if (res?.success) {
                    setIINActivityState(prevState => ({
                        ...prevState,
                        NSEActivities: res.data,
                        IINNumber: isEmpty(getIINNumbers(res.data)) ? '' : getIINNumbers(res.data),
                        isShowLoader: false,
                    }));
                    setActivityStatus('');
                } else {
                    setIINActivityState(prevState => ({
                        ...prevState,
                        NSEActivities: [],
                        isShowLoader: false,
                    }));
                    setActivityStatus(res?.__error)
                }
            })
    };

    const retriggerFATKA = (activityId, PANNumber) => {
        const {onboardingApplicationId, setActivityStatus} = props;
        setIINActivityState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));
        let payloadData = {
            onboardingApplicationId: onboardingApplicationId,
            activityId: activityId,
            PANNumber: PANNumber
        };
        FATKARetrigger(payloadData)
            .then(res => {
                if (res?.success) {
                    store.dispatch(showToastMessage('success', 'FATCA link is sent to client'));
                    setIINActivityState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        responseErrors: ''
                    }));
                    setActivityStatus('');
                    getIINActivities()
                } else {
                    setIINActivityState(prevState => ({
                        ...prevState,
                        isShowLoader: false
                    }));
                    setActivityStatus(res?.__error)
                }
            })
    };

    const sendToClient = (activityId) => {
        const {onboardingApplicationId, setActivityStatus} = props;
        setIINActivityState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));
        let payloadData = {
            onboardingApplicationId: onboardingApplicationId,
            activityId: activityId
        };
        FATKASendToClient(payloadData)
            .then(res => {
                store.dispatch(showToastMessage('success', 'FATCA confirmation link sent to client'));
                if (res?.success) {
                    setIINActivityState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                    }));
                    setActivityStatus('');
                } else {
                    setIINActivityState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                    }));
                    setActivityStatus(res?.__error);
                }
            })
    };

    const sendToIINClient = (activityId) => {
        const {onboardingApplicationId, profile, setActivityStatus} = props;
        setIINActivityState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));
        let payloadData = {
            onboardingApplicationId: onboardingApplicationId,
            activityId: activityId
        };
        IINSendToClient(payloadData)
            .then(res => {
                if (res?.success) {
                    setIINActivityState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                    }));
                    setActivityStatus('');
                } else {
                    setIINActivityState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                    }));
                    setActivityStatus(res?.__error);
                }
            })
    };

    const retriggerIIN = () => {
        const {onboardingApplicationId, profile, setActivityStatus} = props;
        setIINActivityState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));
        let payloadData = {
            onboardingApplicationId: onboardingApplicationId
        };
        IINRetrigger(payloadData, onboardingApplicationId)
            .then(res => {
                if (res?.success) {
                    store.dispatch(showToastMessage('success', 'IIN confirmation link sent to client'));
                    setIINActivityState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        responseErrors: ''
                    }));
                    setActivityStatus('');
                    getIINActivities()
                } else {
                    setIINActivityState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        // responseErrors: res?.__error
                    }));
                    setActivityStatus(res?.__error)
                }
            })
    };

    const isFATKAApiStatus = (API) =>{
        if((API === 'FATCAENQUIERY_APPLICANT_1')
            || (API === 'FATCAENQUIERY_APPLICANT_2')
            || (API === 'FATCAENQUIERY_APPLICANT_3')){
            return true
        }
        return false
    };

    const isFATKARegisterApiStatus = (API) =>{
        if((API === 'FATCAIINRETRIGGEREMAIL_FL_APPLICANT_1')
            || (API === 'FATCAIINRETRIGGEREMAIL_FL_APPLICANT_2')
            || (API === 'FATCAIINRETRIGGEREMAIL_FL_APPLICANT_3')){
            return true
        }
        return false
    };

    return (
        <IINActivityView
            {...props}
            {...IINActivityState}
            retriggerFATKA={retriggerFATKA}
            sendToClient={sendToClient}
            sendToIINClient={sendToIINClient}
            retriggerIIN={retriggerIIN}
            getStatus={getStatus}
            isFATKAApiStatus = {isFATKAApiStatus}
            isFATKARegisterApiStatus={isFATKARegisterApiStatus}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXLView: state.appState.deviceInfo.isXLView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXSView: state.appState.deviceInfo.isXSView,
    accountId: state.onboardingFlowState.accountId,
    accountType: state.onboardingFlowState.accountType,
    profile: state.userState.profile,
    isRefershIINForm: state.onboardingFlowState.isRefershIINForm,
    IINSubmitStatus: state.onboardingFlowState.IINSubmitStatus,
    refreshIIN: state.onboardingFlowState.refreshIIN
});

export default connect(mapStateToProps, {})(IINActivity);
