import React from 'react';

import {isEmpty} from "../../../../../utils/validations";

import DLText from "../../../../../core/components/DLText/DLText";

const NFTDashboardWidgetView = (props) => {

    const {data, count, getHeading, getRedirectTo, requestType, isXSView} = props;

    const renderData = (data) => {
        return data.map((item, index) => {
            return (
                <div className={"d-flex justify-content-between py-1"}
                     key={'data' + index}>
                    <div className='data-card-label pe-2'>
                        <DLText id={''}
                                text={item?.description}
                                fontSize={"sm"}
                                fontWeight={"normal"}
                        />
                    </div>
                    {item?.count && <div className='data-card-label'>
                        <DLText id={''}
                                text={item?.count}
                                fontSize={"md"}
                                isClickable={true}
                                onClick={()=>{getRedirectTo(requestType, item)}}
                                fontWeight={"semi-bold"}
                        />
                    </div>}
                </div>
            )
        })
    };

    const renderHeadingWidget = (title) => {

        return (
            <div className={"py-2 px-3 border-bottom d-flex justify-content-between"}>
                <div className='d-flex align-items-center'>
                    <DLText id={'title-name'}
                            isInline={true}
                            fontSize={'md'}
                            marginBottom={'none'}
                            fontWeight={'semi-bold'}
                            text={title}/>
                </div>
                <div>
                        <DLText id={'count'}
                            isInline={true}
                            fontSize={'md'}
                            marginBottom={'none'}
                            fontWeight={'semi-bold'}
                            isClickable={count !== 0}
                            onClick={() => {
                                if(count !== 0){
                                    (title === 'Status') ? getRedirectTo('Status') : getRedirectTo()
                                }
                            }}
                            text={count}/>
                </div>
            </div>
        )
    };

    const renderWidgetListing = (data) => {

        if (isEmpty(data)) {
            return (
                <div className='d-flex h-75 align-items-center justify-content-center'
                     style={{minHeight: 217}}>
                    <div>Data not found</div>
                </div>
            )
        } else {
            return (
                <div className={isXSView ? 'p-3' : data?.length > 5 ? 'p-3 overflow-scroll overflow-x-hidden ' : 'p-3'}
                     style={{height: isXSView ? '' :217}}>
                    {renderData(data)}
                </div>
            )
        }
    };

    const renderWidget = (data) => {
        return (
            <div className={'p-0 w-100 border-rounded panel h-100'}>
                <div className="px-0 h-100 c">
                    {renderHeadingWidget(getHeading())}
                    <div>
                        {renderWidgetListing(data)}
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div>
            {renderWidget(data)}
        </div>
    );
};

export default NFTDashboardWidgetView;