import React, {Component, useState} from 'react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {isEmpty} from "../../../utils/validations";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Download from "yet-another-react-lightbox/plugins/download";

const DLImageView = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const {id, src, resizeMode, height, width, shape, style, downloadFileName, isCustomUI, customUI} = props;

    const closeImageModal = () => {
        setIsModalOpen(false)
    };

    const openImageModal = () => {
        setIsModalOpen(true)
    };

    const getDownloadfileName = () => {
            if(!isEmpty(downloadFileName)){
            let newFileName = downloadFileName.substring(0, downloadFileName.lastIndexOf('.')) || downloadFileName;
            newFileName += '.jpg';
            return newFileName;
        }
        return downloadFileName;
    };

    return (
        <>
            {isCustomUI ?
                <div
                    onClick={() => openImageModal()}>
                    {customUI}
                </div>
                :<img
                id={id + '-image'}
                onClick={() => openImageModal()}
                style={{
                    width: isEmpty(width) ? '100%' : width,
                    height: isEmpty(height) ? 'auto' : height,
                    borderRadius: (shape === 'circle' ? '50%' : 0),
                    objectFit: resizeMode,
                    ...style
                }}
                src={src}
                alt={'thumbnail'}
                className={'cursor '}
            />}
            <Lightbox
                open={isModalOpen}
                close={() => closeImageModal()}
                slides={[{src: src, downloadFilename: getDownloadfileName()}]}
                plugins={[Zoom, Download]}
            />
        </>
    )
};

export default DLImageView;
