import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLModalTitleView from "./DLModalTitleView";

class DLModalTitle extends Component {
  render() {
    return (
      <DLModalTitleView
          {...this.props}
      />
    );
  }
}

DLModalTitle.propTypes = {
  id: PropTypes.string.isRequired,  // for testing purpose
  title: PropTypes.string.isRequired,
  actionsUI: PropTypes.element,
  fontColor: PropTypes.string,
  crossIconColor: PropTypes.string,
  crossIconFontSize: PropTypes.string,
  showIcon: PropTypes.bool
};

DLModalTitle.defaultProps = {
  fontColor: 'black',
  showIcon: true,
  crossIconColor: 'darkGray',
  crossIconFontSize: 'md'
};

export default DLModalTitle;
