import {connect} from "react-redux";
import HomePageView from "./HomePageView";
import React, {useEffect, useState} from "react";
import DLLoader from "../core/components/DLLoader/DLLoader";
import {isEmpty} from "../utils/validations"

const HomePage = (props) => {
    const [homePageState, sethomePageState] = useState({
        isManagement: false,
        isShowLoader: true,
        isRefreshButtonLoader:0
    });

    useEffect(() => {
        if(!isEmpty(props.profile)){
            getUserRole()
        }
    }, [props.profile,homePageState.isRefreshButtonLoader]);

    const getUserRole=()=>{
        const {profile} =props;
        sethomePageState((prevState) => ({
            ...prevState,
            isManagement: profile?.isManagement ,
            isShowLoader: false,
        }));
    };


    if(homePageState.isShowLoader ){
        return (<DLLoader type={"screen"} isVisible={homePageState.isShowLoader}/>)
    }

    const refreshButton = () => {
        sethomePageState((prevState) => ({
            ...prevState,
            isRefreshButtonLoader: homePageState.isRefreshButtonLoader + 1,
        }));
    };

    return (
        <HomePageView
            {...props}
            {...homePageState}
            refreshButton={refreshButton}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(HomePage);
