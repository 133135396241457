import React, {Component} from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import DLDropDownEditView from "./DLDropdownEdit/DLDropDownEditView";
import Box from '@mui/material/Box';
import '../../../assets/css/dlComponent.css'
import BottomDropDownView from "./BottomDropDownView";
import {getFontColor, getFontFamily, getFontSize, getThemeColor, isEmpty} from "../DLComponentHelper";

const dropDownMarginBottoms = {
    'none': '',
    'xxs': 'mb-1',
    'xs': 'mb-2',
    'sm': 'mb-3',
    'md': 'mb-4'
};

class DLDropDownView extends Component {

    getStyle = (fontColor, fontSize)  =>{
        return {
            fontFamily: getFontFamily(),
            ...getFontSize(fontSize),
            color: getFontColor(fontColor)
        }
    };

    getLabelStyle = (fontColor, fontSize)  =>{
        const {error, isRequired} = this.props;

        return {
            fontFamily: getFontFamily(),
            ...getFontSize(fontSize),
            color: (error && isRequired ) ? getThemeColor('danger') : getFontColor(fontColor)
        }
    };

    getClasses = () => {
        const {inputHeight} = this.props;
        let size = '';

        switch (inputHeight) {
            case 'xs':
                size = 'dl-dropdown-textfield-xs';
                break;
            case 'sm':
                size = 'dl-dropdown-textfield-sm';
                break;
            case 'md':
                size = 'dl-dropdown-textfield-md';
                break;
            case 'lg':
                size = 'dl-dropdown-textfield-lg';
                break;
            case 'xl':
                size = 'dl-dropdown-textfield-xl';
                break;
            default:
                size = 'dl-dropdown-textfield-xs';
        }
        return size + ' dl-dropdown-textfield-container'
    };

    render() {

        const {value, options, onSelect, label, enableTranslation, fontColor, type, t, id ,marginBottom, minWidth, showBottomPopUp,
            fontSize, labelFontColor, labelFontSize, error, labelPlacement, isDisabled, isRequired, style, helperMessage,
            disableHelperMessageHeight,maxCharLength, hideCharLength, placeholder
        } = this.props;

        if(showBottomPopUp){
            return (
                <BottomDropDownView {...this.props}/>
            )
        }

        if (type === 'inline-edit') {
            return <DLDropDownEditView id={id+'-DLDropDownView'} {...this.props}/>
        }
        if (type === 'sort-options') {
            return (
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m:0, minWidth:minWidth},
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div className={dropDownMarginBottoms[marginBottom]}>
                        <TextField
                            id={id+'-text-field'}
                            className={this.getClasses()}
                            select
                            value={value}
                            placeholder={placeholder}
                            // variant={'standard'}
                            onChange={(e) => {
                                onSelect(e.target.value);
                            }}
                        >
                            {
                                options.map((option) => {
                                    return (<MenuItem key={option.value}
                                                      id={id+'menu-item-'+option.value}
                                                      value={option.value}>
                                        {
                                            enableTranslation ? <span style={{...this.getStyle(fontColor, fontSize)}}>{t(option.label)}</span>
                                                :  <span style={{...this.getStyle(fontColor, fontSize, true)}}>{option.label}</span>
                                        }
                                    </MenuItem>);
                                })
                            }
                        </TextField>
                        {/*</div>*/}
                    </div>
                </Box>
            )
        }


        return (
            <div className={dropDownMarginBottoms[marginBottom]}>

                {(labelPlacement === 'top') &&
                <div style={{
                    lineHeight: '21px',
                    marginBottom: isEmpty(label) ? '0px' : '4px',
                    ...style
                }}
                >
                    {
                        <span  style={{...this.getLabelStyle(labelFontColor, labelFontSize)}}>{label}</span>
                    }
                    {/*{*/}
                    {/*    isRequired ? (<span style={{color: getThemeColor('danger')}}>*</span>) : null*/}
                    {/*}*/}
                </div>
                }
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': {
                            m:0,
                            minWidth: minWidth,
                            borderRadius:'4px',
                        },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        id={id + '-text-field'}
                        className={this.getClasses()}
                        select
                        disabled={isDisabled}
                        label={(labelPlacement === 'top') ? '' :enableTranslation ? t(label) : label}
                        value={value}
                        onChange={(e) => {
                            onSelect(e.target.value);
                        }}
                        error={Boolean(error)}
                        helperText={
                            (<span className='d-flex justify-content-between' style={{minHeight: disableHelperMessageHeight ? '0' : '19px'}}>
                                <span style={{ fontFamily: getFontFamily()}}>{helperMessage}</span>
                                {
                                    ((!isEmpty(maxCharLength) && !hideCharLength) &&
                                        <span hidden={hideCharLength}
                                              className="d-flex justify-content-end" style={{
                                                  color:theme.appColor.black,
                                                    fontFamily: getFontFamily(),
                                              }}>({value?.length}/{maxCharLength})</span>)
                                }
                              </span>)
                        }
                    >
                        {
                            options.map((option) => {
                                return (<MenuItem key={option.value} id={id+'-menu-item-'+option.value} value={option.value}>
                                    {
                                        enableTranslation ? <span style={{...this.getStyle(fontColor, fontSize)}}>{t(option.label)}</span> :  <span style={{...this.getStyle(fontColor, fontSize)}}>{option.label}</span>
                                    }
                                </MenuItem>);
                            })
                        }
                    </TextField>
                </Box>
            </div>
        );
    }
}



export default DLDropDownView;
