import React, {useState} from 'react';
import {connect} from "react-redux";

import {copyManagementPortfolio} from "../../portfolioAction";

import CopyPortfolioView from "./CopyPortfolioView";
import store from "../../../store";
import {showToastMessage} from "../../../AppComponents/AppActions";


const CopyPortfolio = (props) => {

    const {portfolioName, portfolioId, handleCloseCopyPortfolioModal, getPortfolios} = props;
    const [copyPortfolioState, setCopyPortfolioState] = useState({
        isApiInProgress: false,
        handleErrorInComponent:true,
        modalErrorMessage:''
    });

    const clearData = () => {
        getPortfolios();
        handleCloseCopyPortfolioModal();
    }

    const copyPortfolio = () => {

        setCopyPortfolioState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));

        let payload = {
            id: portfolioId
        }

        copyManagementPortfolio(payload)
            .then((res) => {
                    if (res.success) {
                        clearData();
                        store.dispatch(showToastMessage('success', 'Portfolio Copied - Copy of ' + portfolioName + ' portfolio is created.'))
                        setCopyPortfolioState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false
                        }));
                    } else {
                        setCopyPortfolioState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false,
                            modalErrorMessage:res.__error
                        }));
                    }
                }
            );
    };

    return (
        <CopyPortfolioView
            {...props}
            {...copyPortfolioState}
            copyPortfolio={copyPortfolio}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(CopyPortfolio);
