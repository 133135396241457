import React from 'react';
import DLText from "../../../../../../core/components/DLText/DLText";
import {isEmpty} from "../../../../../../utils/validations";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import appTheme from "../../../../../../assets/appTheme";
import {getTimeFromNowWithDate} from "../../../../../../utils/helper";

const IINActivityView = (props) => {

    const {
        NSEActivities, isShowLoader, isFATKARegisterApiStatus, retriggerFATKA, isFATKAApiStatus, retriggerIIN,isLGView,
        NSEButtonStatus, getStatus, generatedFormDetails, submitIIN, onboardingStatus,isMobileView, isManagementUser, IINSubmitStatus
    } = props;

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderTitleUI = () => {
        return (
            <div>
                <div className={(isLGView && !(IINSubmitStatus === 'IN_PROGRESS' || onboardingStatus === 'SUBMISSION_IN_PROGRESS')) ? '' : 'd-flex justify-content-between align-items-center '}>
                    <div className='d-flex align-items-center'>
                        <DLText id={''}
                                text={'NSE Activities'}
                                isInline={true}
                                fontWeight={"semi-bold"}
                                fontSize={"md"}
                        />
                    </div>
                    <div className={'d-flex pe-1 align-items-center'}>
                        {(IINSubmitStatus === 'IN_PROGRESS' || onboardingStatus === 'SUBMISSION_IN_PROGRESS') ? <div className='d-flex' style={{gap: 4}}>
                                <div className="lds-spinner">
                                    <div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/>
                                </div>
                            <DLText id={''}
                                    text={'Processing..'}
                                    fontColor={"darkBlue"}
                                    fontSize={"xs"}
                                    marginBottom={"none"}
                                    fontWeight={"semi-bold"}
                                    />
                                <div onClick={() => {
                                    getStatus()
                                }}>
                                    <i className="fa-solid fa-rotate-right cursor"/>
                                </div>
                        </div>
                            :
                            <DLButton id={'delete-button'}
                                      buttonSize={'sm'}
                                      fontSize={'sm'}
                                      sideMargin={'none'}
                                      isDisabled={isEmpty(generatedFormDetails) || onboardingStatus === 'ACTIVATED' || isManagementUser}
                                      onClick={() => {
                                          submitIIN()
                                      }}
                                      label={isEmpty(NSEActivities) ? "Submit" : "Submit again / Refresh"}/>
                        }
                    </div>
                </div>
            </div>
        )
    };

    const renderErrors = (nseErrors) => {
        if (isEmpty(nseErrors)) {
            return ''
        }
        return nseErrors.map((error, key) => {
            return (
                <div key={'error' + key} className={'error' + key}>
                    <DLText id={''}
                            text={error}
                            fontSize={"xs"}
                            fontColor={"danger"}
                    />
                </div>
            )
        })
    };

    const renderStatusIcon = (status) => {
        if (isEmpty(status)) {
            return ''
        }
        switch (status) {
            case 'FAILED':
                return <i className="fa-regular fa-circle-xmark NSE-status-icon"
                          style={{color: appTheme.appColor.danger}}/>;
            case 'IN_PROGRESS':
                return <div className='NSE-status-progress-icon'
                            style={{
                                border: '2px solid '+ appTheme.appColor.secondary
                            }}>
                    <i className="fa-solid fa-exclamation"
                       style={{
                           fontSize: 10,
                           color:appTheme.appColor.secondary,
                           paddingTop: 2
                       }}/>
                </div>;
            case 'SUCCESS':
                return <i className="fa-regular fa-circle-check NSE-status-icon"
                          style={{color: appTheme.appColor.success}}/>;
            case 'INITIATED':
                return <div className='NSE-status-icon' style={{border: '2px dashed #282828'}}/>;

            default:
                return '';
        }
    };

    const renderVerificationUI = (isVerified) => {
        if (isVerified) {
            return (
                <div className='d-flex align-items-center'>
                    <DLText id={''}
                            text={'Verified'}
                            fontSize={"xs"}
                            fontColor={"success"}
                            isInline={true}
                            marginBottom={"none"}
                    />
                    <i className="fa-solid fa-check ps-1"
                       style={{color: appTheme.appColor.success}}
                    />
                </div>
            )
        } else {
            return (
                <div className='d-flex align-items-center pb-1 mb-2'>
                    <DLText id={''}
                            text={'Verified'}
                            fontSize={"xs"}
                            fontColor={"danger"}
                            isInline={true}
                            marginBottom={"none"}
                    />
                    <i className="fa-solid fa-xmark ps-1"
                       style={{color: appTheme.appColor.danger}}
                    />
                </div>
            )
        }
    };

    const renderLinkGenerateUI = (isLinkGenerated, link, activityId, isVerified, PANNumber) => {
        if (isVerified) {
            return ''
        }
        return (
            <div>
                <DLButton
                    id={'search-button'}
                    styleType={"outlined"}
                    borderType={'square'}
                    buttonSize={'sm'}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    isDisabled={isManagementUser}
                    onClick={() => {
                        retriggerFATKA(activityId, PANNumber)
                    }}
                    label={"Retrigger FATCA Email"}
                />
            </div>
        )
    };

    const renderRegisterStatusUI = (NSEDetails) => {
        let response = NSEDetails?.response;
        let isLinkGenerated = !isEmpty(response?.link);
        let generatedLink = isLinkGenerated ? response?.link : '';
        let isRegistered = ((response?.kycRegistered && response?.fatcaRegistered) || (isLinkGenerated));
        let activityId = NSEDetails?.id;

        if (isRegistered) {
            return (<div>
                <div className='d-flex align-items-center'>
                    <DLText id={''}
                            text={'Registered'}
                            fontSize={"xs"}
                            fontColor={"success"}
                            isInline={true}
                            marginBottom={"none"}
                    />
                    <i className="fa-solid fa-check ps-1"
                       style={{color: appTheme.appColor.success}}
                    />
                </div>
                {renderVerificationUI(response?.verifyStatus)}
                {renderLinkGenerateUI(isLinkGenerated, generatedLink, activityId, response?.verifyStatus, response?.PANNumber)}
            </div>);

        } else {
            return (
                <div className='d-flex align-items-center'>
                    <DLText id={''}
                            text={'Registered'}
                            fontSize={"xs"}
                            fontColor={"danger"}
                            isInline={true}
                            marginBottom={"none"}
                    />
                    <i className="fa-solid fa-xmark ps-1"
                       style={{color: appTheme.appColor.danger}}
                    />
                </div>
            )
        }

    };

    const renderIINRegisterStatusUI = (details) => {

        if(details?.consolidateIinConfirmationStatus){
            return ''
        }
        return (
            <DLButton
                id={'search-button'}
                styleType={"outlined"}
                borderType={'square'}
                buttonSize={'sm'}
                fontSize={'sm'}
                sideMargin={'none'}
                isDisabled={isManagementUser}
                onClick={() => {
                    retriggerIIN()
                }}
                label={"Retrigger IIN Email"}
            />
        )
    };

    const renderIINStatus = (consolidateStatus, activationStatus) => {
        return (
            <div className='pb-2'>
                <div className='d-flex align-items-center'>
                    <DLText id={''}
                            text={'Confirmed'}
                            fontSize={"xs"}
                            fontColor={consolidateStatus ? "success" : "danger"}
                            isInline={true}
                            marginBottom={"none"}
                    />
                    {consolidateStatus ?
                        <i className="fa-solid fa-check ps-1"
                           style={{color: appTheme.appColor.success}}
                        /> :
                        <i className="fa-solid fa-xmark ps-1"
                           style={{color: appTheme.appColor.danger}}
                        />}
                </div>
                {(consolidateStatus) && <div className='d-flex align-items-center'>
                    <DLText id={''}
                            text={'Activated'}
                            fontSize={"xs"}
                            fontColor={activationStatus ? "success" : "danger"}
                            isInline={true}
                            marginBottom={"none"}
                    />
                    {activationStatus ?
                        <i className="fa-solid fa-check ps-1"
                           style={{color: appTheme.appColor.success}}
                        /> :
                        <i className="fa-solid fa-xmark ps-1"
                           style={{color: appTheme.appColor.danger}}
                        />}
                </div>}

            </div>
        )

    };

    const renderRetriggerIIN = (response) => {
        if (isEmpty(response)) {
            return ''
        }
        return (<div>
            {renderIINStatus(response?.consolidateIinConfirmationStatus, response?.activationStatus)}
            {renderIINRegisterStatusUI(response, response?.id)}
        </div>)
    };

    const getStatusBySteps = (item) =>{
        if(item?.status === 'FAILED' || item?.status === 'INITIATED'){
            return renderStatusIcon(item?.status)
        }
      if((item?.api === 'FATCAENQUIERY')
          || isFATKAApiStatus(item?.api)) {

          let response = item?.response;
          let isLinkGenerated = !isEmpty(response?.link);
          let isRegistered = ((response?.kycRegistered && response?.fatcaRegistered) || (isLinkGenerated));
          if(item?.status === 'SUCCESS'){
              if(response?.verifyStatus && isRegistered){
                  return renderStatusIcon('SUCCESS')
              }else{
                  return renderStatusIcon('IN_PROGRESS')
              }
          }
      }else if(isFATKARegisterApiStatus(item?.api)){
          let response = item?.response;
          let isLinkGenerated = !isEmpty(response?.link);

          if(isLinkGenerated){
              return renderStatusIcon('IN_PROGRESS')
          }else{
              return renderStatusIcon(item?.status)
          }
      }
      else if(item?.apiLabel === 'IIN Status'){
          if(item?.response?.consolidateIinConfirmationStatus && item?.response?.activationStatus){
              return renderStatusIcon('SUCCESS')
          }else{
              return renderStatusIcon('IN_PROGRESS')
          }
      }
      return renderStatusIcon(item?.status)
    };

    const renderNSEActivityUI = () => {

        if (isEmpty(NSEActivities)) {
            return ''
        }
        return NSEActivities.map((item, index) => {
            return (
                <div key={'NSE-status' + index}>
                    <div className='d-flex mb-3'>
                        <div>
                            {((item?.api === 'FATCAENQUIERY')
                                || isFATKAApiStatus(item?.api)
                                || isFATKARegisterApiStatus(item?.api)
                                || (item?.apiLabel === 'IIN Status'))
                                ? getStatusBySteps(item)
                                : renderStatusIcon(item?.status) }

                            <div className={index === NSEActivities.length - 1  ? 'd-flex justify-content-center h-50' : 'd-flex justify-content-center h-100'}>
                                <div className='step-connector'/>
                            </div>
                        </div>

                        <div className='pb-2'>
                            <div>
                                <DLText id={''}
                                        text={
                                            <div style={{lineHeight: '15px'}}>
                                                {item?.apiLabel}
                                                <span className='ps-1'
                                                      style={{
                                                          fontSize: 'var(--font-size-xxs)',
                                                          color: 'var(--gray-dark)'
                                                      }}>
                                                    {getTimeFromNowWithDate(item?.updatedAt)}
                                                </span>
                                            </div>
                                        }
                                        fontWeight={"semi-bold"}
                                        isInline={true}
                                />
                            </div>
                            {(!isEmpty(item?.response)
                                && (
                                    (item?.api === 'FATCAENQUIERY')
                                    || isFATKAApiStatus(item?.api)
                                    || isFATKARegisterApiStatus(item?.api)
                                )
                            ) && renderRegisterStatusUI(item)}
                            {(item?.apiLabel === 'IIN Status') && renderRetriggerIIN(item?.response)}
                            {(item?.status === "FAILED") && renderErrors(item?.nseErrors)}
                            {(!isEmpty(item?.response?.IINNumber)) &&
                            <DLText id={''}
                                    text={'IIN: '+item?.response?.IINNumber}
                                    fontColor={"grayDark"}
                                    isInline={true}
                                    fontSize={"xs"}
                            />
                            }
                        </div>
                    </div>
                </div>
            );
        })
    };

    const renderContentUI = () => {
        if (isEmpty(NSEActivities)) {
            return (<div className={'pt-3'}>
                {(onboardingStatus === 'ACTIVATED') ? '' : 'Ready for Submission'}
            </div>)
        }

        return (
            <div className='pt-3 pb-1'>
                {renderNSEActivityUI()}
            </div>
        )
    };

    const renderErrorUI = () => {
        const {responseErrors, isMobileView} = props;

        if (isEmpty(responseErrors)) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={isMobileView ? '' : 'mb-2'}>
                <div className='pb-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseErrors}
                    </div>
                </div>
            </div>
        );
    };

    const renderUI = () => {
        return (
            <div>
                <div className={'border-top pt-3 ' + (isMobileView ? '' : 'pb-4')}>
                    {renderErrorUI()}
                    {renderTitleUI()}
                    {renderContentUI()}
                </div>
            </div>
        )
    };

    return (
        <div>
            {renderUI()}
            {renderLoader()}
        </div>
    )
};

export default IINActivityView;