import {getFontFamily, getTheme, isEmpty} from "./DLComponentHelper";
import {componentDefaults} from "./componentDefaults";

let marginBottoms = {
  'none': '',
  'xxs': 'mb-1',
  'xs': 'mb-2',
  'sm': 'mb-3',
  'md': 'mb-4'
};

export const generateWrapperClassName = (props) => {
  const {isInline, marginBottom} = props;
  return ((isInline) ? ' d-inline ' : ' d-flex ' + marginBottoms[marginBottom])
    + ' text-break ';
};


export const generateTextStyleClassName = (props, theme) => {
  const {styleType, maxNumberOfLines, alignText, fixedNumberOfLines} = props;
  theme = getTheme();
  return (styleType === 'bold' ? ' fw-bolder ' : ' ')
    + (styleType === 'semi-bold' ? ' fw-bold ' : ' ')
    + (styleType === 'semi-bold-muted' ? ' fw-bold text-muted ' : ' ')
    + (maxNumberOfLines ? ' max-lines-' + maxNumberOfLines : ' ')
    + (fixedNumberOfLines ? (' max-lines-' + fixedNumberOfLines + '-fixed ') : ' ')
    + (styleType === 'muted' ? ' text-muted ' : ' ')
    + (alignText === 'center' ? ' text-center ' : ' ')
    + (styleType === 'bold-muted' ? ' fw-bolder text-muted ' : ' ');

};

export const generateArrayStyleClassName = (props) => {
  const {styleType, alignText} = props;
  return (styleType === 'bold' ? ' fw-bolder ' : ' ')
    // + (maxNumberOfLines ? ' max-lines-' + maxNumberOfLines : ' ')
    + (styleType === 'muted' ? ' text-muted ' : ' ')
    + (alignText === 'center' ? ' text-center ' : ' ')
    + (styleType === 'bold-muted' ? ' fw-bolder text-muted ' : ' ')
    + (styleType === 'chip' ? ' badge  bg-light py-md-1 px-2 me-1 mb-1' : ' ')
    + (styleType === 'chip-warning' ? ' badge bg-warning dl-badge-font-size py-md-1 px-2 mr-1 mb-1' : ' ')
    + (styleType === 'chip-danger' ? ' badge badge-pill dl-badge-danger py-md-1 px-2 mr-1 mb-1' : ' ')
    + (styleType === 'chip-muted' ? ' badge badge-pill dl-badge-secondary py-md-1 px-2 mr-1 mb-1' : ' ')
    + (styleType === 'chip-square' ? ' badge feed-label py-md-1 px-2 mr-1 mb-1' : ' ')
    + (styleType === 'chip-success' ? ' badge bg-success dl-badge-font-size text-wrap  py-md-1 px-2 mr-1 mb-1' : ' ');
};

export const getFontSize = (theme, fontSize) => {
  let fontSizes = {
    'xxs': '10px',
    'xs': '12px',
    'sm': '14px',
    'md': '16px',
    'lg': '20px',
    'xl': '24px',
    'xxl': '28px',
    'xxxl': '32px'
  };

  return fontSizes[fontSize]
};

export const getFontColor = (theme, fontColor) => {

  theme = getTheme();
  let fontColors = {
    'grey': '#a1a5b7',
    'gray': '#a1a5b7',
    'primary': '#0073ff',
    'warning': '#f18372',
    'danger': '#d9534f',
    'success': 'rgb(93, 183, 122)',
    'secondary': '#f18386',
    'white': '#fff',
    'black': '#000',
  };

  if (isEmpty(theme?.appColor) || isEmpty(fontColor)) {
    //do nothing
  } else {
    fontColors = {
      'grey': 'rgba(0, 0, 0, 0.54)',
      'gray': 'rgba(0, 0, 0, 0.54)',
      'primary': theme?.appColor?.primary?.color,
      'warning': theme?.appColor?.warning?.color,
      'danger':  theme?.appColor?.danger?.color,
      'success': theme?.appColor?.success?.color,
      'secondary': theme?.appColor?.secondary?.color,
      'white': '#fff',
      'black': '#000',
    };
  }


  return fontColors[fontColor]
};

export const generateTextStyleObject = (props, theme) => {
  const {fontSize, fontColor} = props;
  theme = getTheme();
  return {
    flex: 10,
    ...getFontSize(fontSize),
    fontFamily: getFontFamily(),
    color: getFontColor(theme, fontColor)
  };
};

export const generateIconStyleObject = (props, theme) => {
  const {fontSize} = props;
  theme = getTheme();
  return {
    flex: 1,
    ...getFontSize(theme, fontSize),
    minWidth: '30px'
  };
};



export const httpGet = async (params, extraData) => {

  if(extraData?.isStatusCodeImplementation){

    let statusCode;
    let headers={};

    if(isEmpty(extraData?.accountId)){
      headers={
        'Accept': 'application/json'
      }
    }else{
      headers={
        'Accept': 'application/json',
        'x-account-id': extraData?.accountId,
      }
    }


    if(extraData?.authToken){
      headers={
        ...headers,
        'Authorization': 'Bearer ' + extraData?.authToken,
      }
    }

    return fetch(extraData?.API_URL + params, {
      method: 'GET',
      headers: headers,
    })
      .then(response =>{
        // capture the status code if the response to json conversion call breaks
        statusCode = response.status;
        if (response.status === 204) {
          return response
        } else {
          return response.json()
        }
      })
      .then(data => {
        if (statusCode === 204) {
          return {statusCode: statusCode}
        } else {
          if (Array.isArray(data)) {
            return {data: data, statusCode: statusCode}
          }
          if (typeof data === 'object') {
            return {...data, statusCode: statusCode}
          }
          return {data: data, statusCode: statusCode}
        }
      })
      .catch((error) => {
        return {statusCode: statusCode, error: error}
      });
  }



  try {
    let rawResponse = await fetch(params.API_URL + params.url,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + params.authToken,
        },
      });
    if (rawResponse.status === 200) {
      let jsonResponse = await rawResponse.json();
      if (jsonResponse?.status) {
        return jsonResponse;
      } else {
        // check for any common error
        return jsonResponse
      }
    } else {
      throw new Error('URL: ' + rawResponse.url + ' , statusCode: ' + rawResponse.status);
    }

  } catch (err) {
    if (params?.ignoreCatch) {
      throw err;
    } else {
      return null;
    }
  }
};

export const httpDelete = async (params) => {
  try {
    let rawResponse = await fetch(params.API_URL + params.url,
      {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + params.authToken,
        },
      })

    if (rawResponse.status === 200) {
      let jsonResponse = await rawResponse.json();
      if (jsonResponse?.status) {
        return jsonResponse;
      } else {
        // check for any common error
        return jsonResponse
      }
    } else {
      throw new Error('URL: ' + rawResponse.url + ' , statusCode: ' + rawResponse.status);
    }

  } catch (err) {
    if (params?.ignoreCatch) {
      throw err;
    } else {
      return null;
    }
  }

};

export const muiThemeObj = (theme) => {
  theme = getTheme();
  return {
    typography: {
      fontFamily: getFontFamily(),
      fontSize: theme?.default?.fontSize ? theme?.default?.fontSize : componentDefaults?.fontSizes.xs,
    },
    palette: {
      primary: {
        main: theme?.appColor?.primary ? theme?.appColor?.primary : componentDefaults?.colors.primary,
      },
      secondary: {
        main: theme?.appColor?.secondary ? theme?.appColor?.secondary : componentDefaults?.colors.secondary,
      },
      warning: {
        main: theme?.appColor?.warning ? theme?.appColor?.warning : componentDefaults?.colors.warning,
      },
      danger: {
        main: theme?.appColor?.danger ? theme?.appColor?.danger : componentDefaults?.colors.danger,
      },
      error: {
        main: theme?.appColor?.danger ?  theme?.appColor?.danger : componentDefaults?.colors.danger,
      },
      grey: {
        main: theme?.appColor?.gray ? theme?.appColor?.gray : componentDefaults?.colors.gray,
      },
      info: {
        main: theme?.appColor?.info ? theme?.appColor?.info : componentDefaults?.colors.info,
      },
      success: {
        main: theme?.appColor?.success ? theme?.appColor?.success : componentDefaults?.colors.success,
      },
    },
  }
};

export const getHeight = (buttonSize) =>{
  let size;
  switch (buttonSize) {
    case 'xs':
      size = 32;
      break;
    case 'sm':
      size = 36;
      break;
    case 'md':
      size = 40;
      break;
    case 'lg':
      size = 44;
      break;
    case 'xl':
      size = 48;
      break;
    default:
      size = 32;
  }
  return size
};