import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {isEmpty} from "../../../core/components/DLComponentHelper";
import {updateLeadStatus, updateOnHoldLeadStatus} from "../../LeadsActions";
import LeadsOnHoldView from "./LeadsOnHoldView";
import {APP_CONST} from "../../../AppComponents/AppConstants";

const LeadsOnHold = (props) => {

    const [leadOnHoldState, setLeadOnHoldState] = useState({
        followUpDateError: '',
        followUpDate: '',
        onHoldComment: '',
        onHoldCommentError: '',
        modalErrorMessage: ''
    });

    const checkIfValidateObj = (validateObj) => {
        const {followUpDateError, onHoldCommentError} = validateObj;
        if (followUpDateError || onHoldCommentError) {
            // If any mandatory error is present (non-empty), return false
            return false;
        }
        // If no mandatory errors are present, return true
        return true;
    };


    const validateChanges = (name) => {
        const {onHoldComment, followUpDate} = leadOnHoldState;
        let errorObj = {};

        if (name === "onHoldComment" || name === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(onHoldComment)) {
                errorObj.onHoldCommentError = "Please enter comments";
            }
        }

        if (name === "followUpDate" || name === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(followUpDate)) {
                errorObj.followUpDateError = "Please select date";
            }
        }

        return errorObj;
    };

    const updateLeadStatusToOnHold = () => {
        const {onHoldComment, followUpDate} = leadOnHoldState;
        const {getLeadId, getDetails,handleHistoryBlockRefresh, leadStatus} = props;
        let leadId = getLeadId();

        const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION)

        if (isEmpty(leadId)) {
            return
        }

        if (!checkIfValidateObj(validateObj)) {
            setLeadOnHoldState(prevStep => ({
                ...prevStep,
                ...validateObj
            }));
            return;
        }

        if(leadStatus === "ON_HOLD"){
            let data = {
                id: leadId,
                details: {
                    comment: onHoldComment,
                    followUpDate: followUpDate

                }
            };

            updateOnHoldLeadStatus(data).then((res) => {
                    if (res.success) {
                        getDetails();
                        handleHistoryBlockRefresh(true);
                        clearData()
                    } else {
                        setLeadOnHoldState((prevStep) => ({
                            ...prevStep,
                            modalErrorMessage: res?.__error
                        }));
                    }
                }
            );

        }else{
            let data = {
                "id": leadId,
                "type": "ON_HOLD",
                "details": {
                    "comment": onHoldComment,
                    "followUpDate": followUpDate
                }
            };

            updateLeadStatus(data).then((res) => {
                    if (res.success) {
                        getDetails();
                        handleHistoryBlockRefresh(true);
                        clearData()
                    } else {
                        setLeadOnHoldState((prevStep) => ({
                            ...prevStep,
                            modalErrorMessage: res?.__error
                        }));
                    }
                }
            );
        }

    };

    const clearData = () => {
        const {closeOnHoldModal} = props;
        closeOnHoldModal();
        setLeadOnHoldState((prevStep) => ({
            ...prevStep,
            onHoldComment: '',
            followUpDate: '',
            modalErrorMessage : ''
        }));
    };

    const onChangeData = (name, value) => {
        setLeadOnHoldState(prevStep => ({
            ...prevStep,
            [name]: value,
            [name + "Error"]: ''
        }));
    };

    const onBlurInputText = (name) => {
        const validateObject = validateChanges(name, leadOnHoldState);

        setLeadOnHoldState(prevStep => ({
            ...prevStep,
            ...validateObject
        }));
    };

    return (
        <LeadsOnHoldView
            {...props}
            {...leadOnHoldState}
            updateLeadStatusToOnHold={updateLeadStatusToOnHold}
            clearData={clearData}
            onChangeData={onChangeData}
            onBlurInputText={onBlurInputText}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(LeadsOnHold);
