import React, {useEffect, useState} from 'react';
import StepperView from "./StepperView";
import {connect} from "react-redux";
import {isEmpty} from "../../../utils/validations";

const Stepper = (props) => {

    const[stepperState, setStepperState] = useState({
        completedStepsPercent: 0,
        remainingStepsPercent : 0,
        stepperText : ''
    });

    useEffect(()=>{
        getStepDetails()
    },[props.stepperSteps]);

    const getStepDetails = () => {
        const {stepperSteps} = props;
        if (isEmpty(stepperSteps)) {
            return ''
        }
        let stepInProgressIndex = '';
        let stepInProgressName = '';
        for (let i = 0; i < stepperSteps.length; i++) {
            if (stepperSteps[i]?.status === "IN_PROGRESS") {
                stepInProgressIndex = i;
                stepInProgressName = stepperSteps[i]?.stepName;
                break;
            }
        }
        let totalSteps = stepperSteps?.length;
        if(isEmpty(totalSteps)){
            return
        }

        let currentStep = stepInProgressIndex + 1;

        let completedStepsPercent = parseInt((100/totalSteps)*currentStep);
        let remainingStepsPercent = parseInt(100 - completedStepsPercent) + '%';
        completedStepsPercent += '%';
       
        setStepperState(prevState => ({
            ...prevState,
            completedStepsPercent: completedStepsPercent,
            remainingStepsPercent : remainingStepsPercent,
            stepperText:  `Step ${currentStep} of ${totalSteps}: ${stepInProgressName}`
        }));

    };
    return (<StepperView
                {...props}
                {...stepperState}
                getStepDetails={getStepDetails}
            />);
};

const mapStateToProps = (state) => ({
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(Stepper);