import React, {useEffect, useState} from 'react';
import {isEmpty} from "../../../utils/validations";
import {APP_CONST} from "../../../AppComponents/AppConstants";

import {connect} from "react-redux";

import ClientRequestDetailsView from "./ClientRequestDetailsView";
import {getAdvisoryUsers} from "../../../clients/ClientsActions";
import {getClientRequestById, getEarlierRequest, getTransactionRequestById} from "../../ClientRequestsActions";

const ClientRequestDetails = (props) => {
    const [clientRequestDetailsState, setClientRequestDetailsState] = useState({

        ticketNumber: '',
        accountCategory: '',
        accountDetails: [],
        status: "",
        accountType: '',
        IINNumber: '',
        transactionsHistory: [],
        accountId: '',
        isAddNewTransactionDialogOpen: false,
        isShowLoader: false,
        category: '',
        advisoryUsers: [],
        createdBy: '',
        comments: '',
        createdAt: '',
        responseError: '',
        UIState: '',
        selectedId: '',
        isMarkAsCompletedAvailable: false,
        isMarkAsCompletedModalOpen : false
    });

    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        getDetails();
    }, []);

    useEffect(() => {
        if(!isEmpty(clientRequestDetailsState?.accountId)){
            let clientRequestId = getId();
            getEarlierRequestData(clientRequestDetailsState?.accountId, clientRequestId);
        }
    }, [clientRequestDetailsState?.accountId]);


    const getId = () => {
        return props.match?.params?.id
    };

    const getAdvisoryUserName = (value) => {
        const{advisoryUsers} = clientRequestDetailsState;
        if (isEmpty(value)) {
            return '-'
        }
        const user = advisoryUsers.find(item=>item.id === value)
        return user? user.firstName + " "+ user.lastName : "-";
    };

    const getDetails = () => {
        const id = getId();
        if (isEmpty(id)) {
            return
        }
        setClientRequestDetailsState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));


        getAdvisoryUsers()
            .then((advisoryRes) => {
                if (advisoryRes?.success) {

                    getClientRequestById(id).then(res => {
                        if (res?.success) {
                            let requestData = res.data;

                            getTransactionRequestById(id).then(transactionsRes => {
                                if (transactionsRes?.success) {
                                    let transactionsData = transactionsRes.data;

                                    setClientRequestDetailsState(prevState => ({
                                        ...prevState,
                                        id: id,
                                        accountType: getFormattedData('accountType', requestData?.accountDetails),
                                        IINNumber: getFormattedData('IINNumber', requestData?.accountDetails),
                                        category: getFormattedData('category', requestData?.accountDetails),
                                        accountDetails: requestData?.accountDetails[0]?.accountHolders,
                                        ticketNumber: requestData?.ticketNumber,
                                        createdBy: requestData?.clientRequestComment?.createdBy?.id,
                                        comments: requestData?.clientRequestComment?.comments,
                                        createdAt: requestData?.clientRequestComment?.createdAt,
                                        advisoryUsers: advisoryRes?.data?.advisoryUsers,
                                        status: requestData?.status,
                                        accountId: requestData?.accountId,
                                        transactionsHistory: transactionsData?.txnRequests,
                                        isMarkAsCompletedAvailable: transactionsData?.markCompleted,
                                        isShowLoader: false,
                                        UIState: APP_CONST.CONTENT_AVAILABLE,
                                        responseError: ''
                                    }));

                                }else {
                                    setClientRequestDetailsState(prevState => ({
                                        ...prevState,
                                        isShowLoader: false,
                                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                                        responseError: res.__error
                                    }));
                                }
                            })
                        }
                    })
                }
            });
    };

    const getEarlierRequestData = (id, clientRequestId) => {
        if(isEmpty(clientRequestId)){
           return;
        }
        setClientRequestDetailsState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getEarlierRequest(id, clientRequestId).then(earlierReqRes => {
            if (earlierReqRes?.success) {
                let earlierReqData = earlierReqRes.data;
                setClientRequestDetailsState(prevState => ({
                    ...prevState,
                    earlierReqHistory: earlierReqData?.clientRequests,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                    responseError: ''
                }));
            }else {
                setClientRequestDetailsState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    responseError: res.__error
                }));
            }
        })
    };

    function capitalizeFirstLetters(name) {
        return name
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const getFormattedData = (name, accountDetails) => {
        if (name === 'accountType') {
            const accountType = accountDetails.map(account => {
                    return account.accountType
                }
            );
            if (accountType[0] === 'INDIVIDUAL') {
                return 'Individual Account'
            } else {
                return 'Joint Account'
            }
        }
        if (name === 'IINNumber') {
            return accountDetails.flatMap(account => {
                    return account.IINNumber
                }
            );
        }
        if (name === 'category') {
            return accountDetails.flatMap(account => {
                    if (account.category === 'HNI') {
                        return account.category;
                    } else if (account.category === 'UNASSIGNED') {
                        return capitalizeFirstLetters(account.category);
                    } else if (account.category === 'RETAIL') {
                        return capitalizeFirstLetters(account.category);
                    } else if (account.category === 'PREMIUM_RETAIL') {
                        return 'Premium Retail';
                    } else if (account.category === 'ULTRA_HNI') {
                        return 'Ultra HNI';
                    } else {
                        return '-';
                    }
                }
            );
        }

    };

    const handleHistoryBlockRefresh = (value) => {
        setClientRequestDetailsState((prevState) => ({
            ...prevState,
            isHistoryBlockRefresh: value
        }))
    };

    const handleOpenAddNewTransaction = () => {
        setClientRequestDetailsState((prevState) => ({
            ...prevState,
            isAddNewTransactionDialogOpen: true
        }))
    };

    const handleCloseAddNewTransaction = () => {
        setClientRequestDetailsState((prevState) => ({
            ...prevState,
            isAddNewTransactionDialogOpen: false
        }))
    };

    const handleOpenMarkAsCompletedModal = () => {
        setClientRequestDetailsState((prevState) => ({
            ...prevState,
            isMarkAsCompletedModalOpen : true
        }));
    };

    const handleCloseMarkAsCompletedModal = () => {
        setClientRequestDetailsState((prevState) => ({
            ...prevState,
            isMarkAsCompletedModalOpen : false
        }));
    };

    const handleItemOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleItemClose = () => {
        setAnchorEl(null);
    };

    const onSaveSuccess = () => {
        getDetails();
    };

    const handleRedirect = (txnId) => {
        props.history.push("/client-requests/"+getId()+"/lumpsum-transaction/"+txnId)
    };

    return (
        <ClientRequestDetailsView
            {...props}
            {...clientRequestDetailsState}
            anchorEl={anchorEl}
            getId={getId}
            handleHistoryBlockRefresh={handleHistoryBlockRefresh}
            getDetails={getDetails}
            onSaveSuccess={onSaveSuccess}
            getAdvisoryUserName={getAdvisoryUserName}
            handleOpenAddNewTransaction={handleOpenAddNewTransaction}
            handleCloseAddNewTransaction={handleCloseAddNewTransaction}
            handleOpenMarkAsCompletedModal={handleOpenMarkAsCompletedModal}
            handleCloseMarkAsCompletedModal={handleCloseMarkAsCompletedModal}
            handleItemOpen={handleItemOpen}
            handleItemClose={handleItemClose}
            handleRedirect={handleRedirect}
        />);
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(ClientRequestDetails);
