import React from 'react';
import './Stepper.css';
import {isEmpty} from "../../../utils/helperCommon";
import DLText from "../DLText/DLText";
import appTheme from "../../../assets/appTheme";

const StepperView = (props) => {
    const {stepperSteps, stepperText, isMobileView} = props;

    const getBackgroundColor = (blockState) => {
        switch (blockState) {
            case 'SUCCESS':
                return appTheme.appColor.successLight;
            case 'ERROR':
                return appTheme.appColor.danger;
            case 'IN_PROGRESS':
                return appTheme.appColor.darkBlue;
            case 'WARNING':
                return appTheme.appColor.secondary;
            default:
                return '';
        }
    };

    const getBlockTextColor = (blockState) => {
        switch (blockState) {
            case 'SUCCESS':
                return 'success';
            case 'ERROR':
                return 'danger';
            case 'IN_PROGRESS':
                return 'white';
            case 'WARNING':
                return 'orange';
            default:
                return 'black';
        }
    };

    const getBlockFontColor = (blockState) => {
        if (isEmpty(blockState)) {
            return 'black';
        }
        switch (blockState) {
            case 'SUCCESS':
                return 'var(--success)';
            case 'ERROR':
                return 'var(--danger)';
            case 'IN_PROGRESS':
                return 'var(--blue-dark)';
            case 'WARNING':
                return 'var(--secondary)';
            default:
                return 'var(--black)'
        }
    };

    const handleShowIcon = (blockState) => {
        switch (blockState) {
            case 'SUCCESS':
                return (<i className="fa-solid fa-check ps-2"
                           style={{color: getBlockFontColor(blockState)}}/>);
            case 'ERROR':
                return <></>;
            case 'WARNING':
                return (<i className="fa-solid fa-exclamation ms-2"
                           style={{color: getBlockFontColor('WARNING')}}/>);
            case 'IN_PROGRESS':
                return <></>;
            default:
                return <></>;
        }
    };

    const renderMobileProgressUI = () => {
        const {completedStepsPercent, remainingStepsPercent} = props;
        return (
            <div className='d-flex align-items-center'
                 style={{height: '10px'}}>
                <div style={{
                    width: completedStepsPercent,
                    backgroundColor: 'var(--blue-dark)',
                    borderRadius: '3px',
                    height: '2px'
                }}
                />
                <div className='border-bottom-gray'
                     style={{
                         width: remainingStepsPercent,
                         backgroundColor: 'var(--gray-light)',
                         borderRadius: '3px',
                         height: '2px'
                     }}
                />
            </div>
        )
    };


    const renderMobileUI = () => {
        const {completedStepsPercent} = props;
        if(isEmpty(completedStepsPercent)){
            return ''
        }
        return (
            <div>
                {renderMobileProgressUI()}
                <div className='page-container box-shadow'
                     style={{
                         backgroundColor: 'white',
                         zIndex: 100,
                         position:"relative"
                     }}>
                    <div className="py-2 mobile-step-text bg-white">
                        <DLText id={'steps'}
                                text={stepperText}
                                customWrapperStyle={{
                                    backgroundColor: 'white',
                                    position: "relative",
                                    zIndex: 100
                                }}
                                fontSize={"xs"}
                                fontWeight={"semi-bold"}
                                fontColor={"grayDark"}
                        />
                    </div>
                </div>

            </div>
        );
    };

    const renderDesktopModeProgressUI = () => {
        return stepperSteps.map((step, index) => {
            const blockText = step.stepName;
            const blockState = step.status;

            return (<div key={'step_'+index}
                className={(blockState === 'ERROR') ? 'd-flex align-items-center step current step-error' :
                (blockState === 'IN_PROGRESS') ? 'd-flex align-items-center step current step-progress' :
                    (blockState === 'SUCCESS') ? 'd-flex align-items-center step current step-success' :
                        'd-flex align-items-center step current'
            }
                         style={{backgroundColor: getBackgroundColor(blockState)}}>
                <DLText id={''}
                        text={blockText}
                        fontWeight={"semi-bold"}
                        marginBottom={'none'}
                        fontColor={getBlockTextColor(blockState)}/>
                {handleShowIcon(blockState)}

            </div>)
        });
    };

    const renderDesktopUI = () => {
        return (
            <div className={isMobileView ? "ps-3" : ""}>
                <div className="wrapper">
                    <div className="arrow-steps clearfix page-container pe-0 py-0">
                        {renderDesktopModeProgressUI()}
                    </div>
                </div>
            </div>
        );
    };


    return (
        <div>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}
        </div>
    );
};

export default StepperView;