import React from 'react';

import Dropzone from 'react-dropzone-uploader';
import DLTooltip from "../DLTooltip/DLTooltip";
import DLButton from "../DLButton/DLButton";
import DLLoader from "../DLLoader/DLLoader";

import 'react-dropzone-uploader/dist/styles.css';
import "./DLDocumentUploaderStyle.css";

import "../../../assets/css/designToken.css"
import pdfLogo from '../../../assets/img/iconPdf.png';

import {getThemeColor} from "./../DLComponentHelper";
import {getFontFamily} from "../DLComponentHelper";
import DLImage from "../DLImage/DLImage"

const documentUploaderMarginBottoms = {
    'none': '',
    'xxs': 'mb-1',
    'xs': 'mb-2',
    'sm': 'mb-3',
    'md': 'mb-4'
};

const DLDocUploaderView = (props) => {

    const {
        maxFileUploadLimit, uploadFileSize, uploadFileLimit, fontSize, label, isRequired, error, allowedFileTypes, isShowLabel,
        marginBottom, id, disableHelperMessageHeight, helperMessage, style, theme, hideDeleteIcon, onChangeFile,
        handleChangeStatus, DLDocUploaderState, isEmpty,handleRemoveAttachment, isXSView, isDeletable, isDisableDelete,
        imageHeight, downloadFileName
    } = props;


    const {fileName, isShowLoader, isShowDownloadLoader, previewImage} = DLDocUploaderState;

    const getIcon = (iconType) => {
        if (iconType === 'DOC') {
            return <i style={{fontSize: 30}} className="fa-regular fa-file me-2"/>;
        }

        if (iconType === 'IMAGE') {
            return <i style={{fontSize: 30}} className="fa-sharp fa-regular fa-image me-2"/>;
        }

        if (iconType === 'PDF') {
            return <img src={pdfLogo} alt="" style={{height: 26, width: 26}} className='me-2'/>;
        }

        return <i className="fa-regular fa-file me-2 default-icon mt-1"/>;
    };

    const getInputContentUI = () => {
        const {boxLabel, placeHolder} = props;
        if (isEmpty(fileName)) {
            return (
                <div className="d-flex align-items-start justify-content-start" key={0}>
                    {getIcon()}
                    <div style={{
                        textAlign: 'start',
                        color: 'var(--black)',
                        fontWeight: 400,
                        fontSize: '16px',
                        fontFamily: getFontFamily()
                    }}>
                        {boxLabel}
                        <div className='mt-1' style={{fontSize: 'var(--font-size-xs)'}}>
                            {placeHolder}
                        </div>
                    </div>
                </div>
            );
        }

        if (!isEmpty(fileName)) {
            return (<div key={'err'} className='p-4'
                         style={{color: 'grey', fontSize: fontSize, fontFamily: getFontFamily()}}>Uploading...</div>);
        }

        return (
            <div style={{textAlign: 'center', width: '40%'}} key={0}>
                <div style={{
                    backgroundColor: '#003f61',
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: fontSize,
                    fontFamily: getFontFamily(),
                    borderRadius: 4,
                    padding: 5
                }} className='mb-2 text-capitalize'> {'Add Files'}</div>
            </div>
        );
    };

    const generatePreviewContent = (file) => {
        if (file.files.length >= uploadFileLimit) {
            return (<div key={0} className='p-4'
                         style={{color: 'grey', fontSize: fontSize, fontFamily: getFontFamily()}}>Uploading...</div>);
        } else {
            return null;
        }
    };

    const getExternalStyle = (isError) => {
        const {boxStyle} = props;
        let defaultStyle = {
            height: '75px',
            minWidth: 200,
            width: '100px',
            border: '1px solid ' + (isError ? 'var(--danger)' : 'var(--gray)') + ' !important',
            borderRadius: '8px',
            backgroundColor: 'white'
        };

        if (boxStyle) {
            return boxStyle;
        } else {
            return defaultStyle;
        }
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader || isShowDownloadLoader}/>);
    };

    const getFormatedFileName = (fileName) =>{
        if(isEmpty(fileName)){
            return '-'
        }
        if(isXSView){
            if(fileName.length < 10){
                return fileName
            }
            return (fileName).substring(0, 10)+ '...'
        }
        if(fileName.length < 30){
            return  fileName
        }
        return (fileName).substring(0, 30)+ '...';
    };

    return (
        <div>
            <div className={'w-100 ' + documentUploaderMarginBottoms[marginBottom]}>
                {isShowLabel && (
                    <div
                        style={{
                            fontWeight: '400 !important',
                            lineHeight: '1 !important',
                            fontFamily: getFontFamily(),
                            marginBottom: isEmpty(label) ? '0px !important' : '8px !important',
                            ...style
                        }}
                    >
                        <span style={{color: error ? getThemeColor('danger') : ''}}>{label}</span>

                        <span className='mx-2'>
                        <DLTooltip id={id + '-tool-tip'} tooltipContent={'tooltip'}/>
                    </span>

                        {isRequired ? <span style={{color: getThemeColor('danger')}}> *</span> : null}
                    </div>
                )}
                <div className='w-100'>
                    {isEmpty(fileName) ? (
                        <div style={getExternalStyle(!isEmpty(helperMessage))}
                             className='dl-document-uploader mb-1 w-100'>
                            <Dropzone
                                id={id + '-dropzone'}
                                inputContent={getInputContentUI()}
                                onChangeStatus={handleChangeStatus}
                                accept={allowedFileTypes}
                                disabled={false}
                                maxSizeBytes={uploadFileSize}
                                maxFiles={maxFileUploadLimit === 0 ? 1 : maxFileUploadLimit}
                                multiple={false}
                                canCancel={true}
                                submitButtonDisabled={true}
                                autoUpload={false}
                                PreviewComponent={(e) => {
                                    return generatePreviewContent(e);
                                }}
                            />
                        </div>
                    ) : (
                        <div className='border border-rounded bg-white'>
                            <div id={id + 'attachment'} className='d-flex align-items-center p-2'
                                 style={{borderRadius: 8}}>
                                <div
                                    className={'uploader-image flex-shrink-0'}
                                    style={{
                                        marginLeft: 10,
                                        maxHeight: '50px',
                                        overflow: 'hidden',
                                        alignItems: 'center'
                                    }}>
                                    <div className='me-2' style={{fontSize: 'var(--font-size-md)'}}>
                                        <i className='fa-regular fa-file'/>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between py-2 align-items-center flex-grow-1'>
                                    <div style={{fontSize: '16px', fontFamily: getFontFamily()}}
                                         id={'fileName'}>
                                        {getFormatedFileName(fileName)}
                                    </div>
                                    {isDeletable && <div>
                                        <DLButton
                                            id={id + '-button-'}
                                            theme={theme}
                                            type={'icon'}
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: 'black',
                                                cursor: isDisableDelete ? 'auto' : 'pointer'
                                            }}
                                            hidden={hideDeleteIcon}
                                            startIcon={<i className={isDisableDelete ? 'fa-regular fa-trash-can disable-icon ' : 'fa-regular fa-trash-can'}/>}
                                            onClick={() => handleRemoveAttachment()}
                                            isDisabled={isDisableDelete}
                                        />
                                    </div>}
                                </div>
                            </div>
                            {!isEmpty(previewImage) && <div>
                                <div className={"d-flex justify-content-center align-items-center"}>
                                    <DLImage id={''}
                                             onClick={()=>{}}
                                             src={previewImage}
                                             downloadFileName={isEmpty(downloadFileName) ? fileName : downloadFileName}
                                             resizeMode={"cover"}
                                             style={{
                                                 minWidth: '100%',
                                                 maxHeight: imageHeight,
                                                 objectPosition: "top",
                                                 borderBottomRightRadius: 8,
                                                 borderBottomLeftRadius: 8,
                                             }}
                                             allowFullScreen={true}
                                    />
                                </div>
                            </div>}
                        </div>
                    )}
                </div>
                <div className='mt-1'
                     style={{
                         color: 'var(--danger)',
                         minHeight: disableHelperMessageHeight ? '0' : '19px',
                         fontSize: 'var(--font-size-xs)',
                         paddingLeft: 14
                     }}>
                    {helperMessage}
                </div>

            </div>
            {renderLoader()}
        </div>
    );
};

export default DLDocUploaderView;