export const headingFTData = ['Account', 'Ticket Number', 'Type of Request', 'Status', 'Created Date'];

export const headingFTDataForManagement = ['Account', 'Ticket Number', 'Type of Transaction', 'Status', 'Created Date', 'CRM & Location'];

export const purchaseTransactionHeadingData = ['Schemes', 'Folio No.', 'Allocation', 'Amount', 'Action'];

export const purchaseTransactionCellConfig = [
    {
        textAlign: 'left',
        width: '35%',
        minWidth: '35%'
    },
    {
        textAlign: 'left',
        width: '15%',
        minWidth: '15%'
    },
    {
        textAlign: 'right',
        width: '100px',
        minWidth: '100px',
        paddingRight: 100
    },
    {
        textAlign: 'left',
        width: '15%',
        minWidth: '15%'
    },
    {
        textAlign: 'center',
        width: '15%',
        minWidth: '15%'
    }
];
