import React, {useEffect, useState} from 'react';
import SignatureView from "./SignatureView";
import {connect} from "react-redux";
import {getDocAssociateData} from "../../../OnboardingActions";
import {isEmpty} from "../../../../core/components/DLComponentHelper";

const Signature = (props) => {
    const [signatureState, setSignatureState] = useState({
        responseErrors: [],
        isLoaderVisible: false,
        totalErrors: 0,
        document: {
            documentType: 'SIGNATURE',
            documentCategory: 'SIGNATURE',
            originalFileName: '',
            uploadedFileName: '',
            filePath: '',
            signatureError: ''
        }
    });

    useEffect(() => {
        const {selectedScreenObj} = props;
        props.getUplodedDocuments(selectedScreenObj?.screenKey, selectedScreenObj?.personId);
    }, [props.selectedScreenObj]);

    useEffect(() => {
        let docData = mapDocumentData(props.selectedScreenObj);

        if (!isEmpty(docData)) {
            setSignatureState(prevState => ({
                ...prevState,
                document: docData,
            }));
        }
    }, [props.selectedScreenObj.documentTypes]);

    const mapDocumentData = (selectedScreenObj) => {
        let selectedDocumentTypes = selectedScreenObj.documentTypes;

        const {document} = signatureState;
        let docData = {...document};

        if (!isEmpty(selectedDocumentTypes)) {
            //get PAN document using id from documentTypes
            let signatureDocument = selectedDocumentTypes.find((document) => document.id === 'SIGNATURE');
            if (!isEmpty(signatureDocument.documents)) {
                docData.filePath = signatureDocument.documents[0].filePath;
                docData.fileName = signatureDocument.documents[0].fileName;
                docData.id = signatureDocument.documents[0].id;
            }
        }
        return docData
    };

    const associateDocument = (type, response) => {
        const {selectedScreenObj} = props;
        const {document} = signatureState;

        if(type === 'ERROR'){
            setSignatureState(prevStep => ({
                ...prevStep,
                responseErrors: response,
                totalErrors: 1,
            }));
            return;
        }

        if (type === 'DELETE') {
            setSignatureState(prevStep => ({
                ...prevStep,
                document: {
                    ...document,
                    filePath: '',
                    signatureError: ''
                },
                responseErrors: [],
                totalErrors: 0,
            }));
            return;
        }

        setSignatureState(prevStep => ({
            ...prevStep,
            isLoaderVisible: true,
        }));

        const associatePayload = {
            type: document.documentType,
            personId: selectedScreenObj?.personId,
            documentRequirementId: selectedScreenObj?.documentRequirementId,
            originalFileName: response?.originalFileName,
            uploadedFileName: response?.uploadedFileName
        };

        getDocAssociateData(associatePayload)
            .then((res) => {
                if (res.success) {

                    setSignatureState(prevStep => ({
                        ...prevStep,
                        document: {
                            ...document,
                            id: res.data.id,
                            filePath: res.data?.filePath,
                            signatureError: ''
                        },
                        isLoaderVisible: false,
                        responseErrors: [],
                        totalErrors: 0,
                    }));
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                    }
                    setSignatureState(prevStep => ({
                        ...prevStep,
                        document: {
                            documentType: 'SIGNATURE',
                            documentCategory: 'SIGNATURE',
                            originalFileName: '',
                            uploadedFileName: '',
                            filePath: '',
                            signatureError: ''
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                    }));
                }
            })
    };

    const validatePhoto = () => {
        const {document} = signatureState;
        if (isEmpty(document.filePath)) {
            setSignatureState(prevStep => ({
                ...prevStep,
                document: {
                    ...document,
                    signatureError: 'Please upload signature'
                },
                isLoaderVisible: false,
                responseErrors: [],
                totalErrors: 0,
            }));
            return false;
        }
        return true
    };

    const onNextButtonClick = () => {
        const {handleClickNext, isProceedWithErrors} = props;
        setSignatureState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));

        const validateObject = validatePhoto();
        if (validateObject || isProceedWithErrors) {
            handleClickNext();
            setSignatureState(prevState => ({
                ...prevState,
                isLoaderVisible: false
            }));
        }
    };


    return (
        <SignatureView {...props}
                       {...signatureState}
                       onNextButtonClick={onNextButtonClick}
                       associateDocument={associateDocument}
        />

    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    accountId: state.onboardingFlowState.accountId
});

export default connect(mapStateToProps, {})(Signature);