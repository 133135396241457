import React, {useEffect, useState} from 'react';
import PurchaseTransactionView from "./PurchaseTransactionView";
import {isEmpty} from "../../../../../../utils/validations";
import {submitFundRequest, serachPurchaseProducts, selectProduct, addPurchaseScheme} from "../../../../FTAction";

const PurchaseTransaction = (props) => {

    const [purchaseTransactionState, setPurchaseTransactionState] = useState({
        funds: [],
        investmentAmount: "",
        totalAllocationAmount: "",
        totalAllocationPercentage: "",
        warningMessage: '',
        totalSelectedAmount: '',
        responseErrors: '',
        isOpenAddSchemeModal: false,
        isShowLoader: false,
        searchFundError: '',
        searchKeyword: '',
        addedFund: '',
        productModalError: '',
        selectedProductId: '',
        isReinvested: false,
        addedProductAmount: '',
        folioNumbers: [
            {
                label: 'Create New Folio',
                value: 'null'
            }
        ],
        productMinValue: '',
        productMaxValue: '',
        productMultipleValue: '',
        addedProductAmountError: ''
    });

    useEffect(() => {
        setTransactionData();
    }, []);

    const getTransactionRequestId = () => {
        return props.match?.params?.transactionId
    };

    const getClientRequestId = () => {
        return props.match?.params?.clientRequest
    };

    const setTransactionData = () => {
        let selectedPorfolio = props?.location?.state?.porfolio;
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        if (isEmpty(selectedPorfolio)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                isShowLoader: false
            }));
            return
        }
        let allFunds = [];
        let response = selectedPorfolio?.funds;

        response.map((selectedFund) => {
            let allocationAmount = getAllocation(selectedFund?.amount, selectedPorfolio?.investmentAmount);
            allFunds.push({
                productId: selectedFund?.productId,
                productName: selectedFund?.productName,
                amount: selectedFund?.amount,
                allocationAmount: selectedFund?.allocation,
                allocation: isEmpty(allocationAmount) ? '-' : (allocationAmount + '%'),
                errors: getErrors(selectedFund?.amount, selectedFund),
                details: selectedFund?.details,
                folioNumbers: [
                    {
                        label: 'Create New Folio',
                        value: 'null'
                    }
                ]
            })
        });

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: allFunds,
            investmentAmount: selectedPorfolio?.investmentAmount,
            totalAllocationAmount: selectedPorfolio?.totalAllocationAmount,
            totalAllocationPercentage: selectedPorfolio?.totalAllocationPercentage,
            isShowLoader: false
        }))
    };

    const isMultiple = (num, multiple) => {

        if (multiple === 0) return false;

        let selectedMultiple = multiple;

        if(selectedMultiple < 100){
            //as per disscusion with both sir to solve 0.01 multiplier issue
            selectedMultiple = 100
        }

        // Count decimal places in multiple to determine the scaling factor
        const decimalPlaces = selectedMultiple?.toString().split('.')[1]?.length || 0;
        const scaleFactor = Math.pow(10, decimalPlaces);

        // Scale num and selectedMultiple to integers
        const scaledNum = Math.round(num * scaleFactor);
        const scaledMultiple = Math.round(selectedMultiple * scaleFactor);
        // Check if scaled num is a selectedMultiple of scaled selectedMultiple
        return scaledNum % scaledMultiple === 0;
    };

    const getErrors = (amount, selectedFund) => {
        let errors = selectedFund?.errors;
        let allLimits = selectedFund?.details?.limits;

        let uniqueError = errors;

        if (isEmpty(allLimits)) {
            return errors
        }

        let selectedLimit = allLimits.find(product => product.TRXNType === selectedFund?.details?.txnType);
        //get limit details from array of all limits using txnType

        let minimumAmountMessage = 'Please enter a valid value, minimum amount invested in this scheme is ' + selectedLimit?.minAmount;
        let maximumAmountMessage = 'Please enter a valid value, maximum amount invested in this scheme is ' + selectedLimit?.maxAmount;
        let multiplierMessage = 'Please enter a valid value, multiplier for this scheme is ' + selectedLimit?.multiples;

        if (!isEmpty(selectedLimit)) {
            if (amount > selectedLimit?.maxAmount) {
                errors.push(maximumAmountMessage)
            } else {
                errors = errors.filter(value => value !== maximumAmountMessage);
            }

            if (amount < selectedLimit?.minAmount) {
                errors.push(minimumAmountMessage)
            } else {
                errors = errors.filter(value => value !== minimumAmountMessage);
            }

            if (isMultiple(amount, selectedLimit?.multiples)) {
                errors = errors.filter(value => value !== multiplierMessage);

            } else {
                errors.push(multiplierMessage)
            }

            uniqueError = [...new Set(errors)];
        }
        return uniqueError
    };

    const onchangeValue = (name, value) => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            [name]: value,
            [name + "Error"]: ''
        }))
    };

    const getAllocation = (amountSelected, investmentAmount) => {

        if (isEmpty(amountSelected) || isEmpty(investmentAmount)) {
            return ''
        }

        let totalPercentage = (amountSelected / investmentAmount) * 100;
        return isEmpty(totalPercentage) ? '' : totalPercentage.toFixed(2)
    };

    const changeAmount = (newAmount, productId) => {
        const {funds, investmentAmount} = purchaseTransactionState;
        let totalAllocationAmount = 0;
        let allFunds = funds.map(fund => {
            if (fund.productId === productId) {
                let allocationAmount = getAllocation(newAmount, investmentAmount);
                totalAllocationAmount += parseInt(newAmount);
                return {
                    ...fund,
                    amount: newAmount,
                    errors: getErrors(newAmount, fund),
                    allocationAmount: allocationAmount,
                    allocation: isEmpty(allocationAmount) ? '-' : allocationAmount + '%'
                };
            } else {
                totalAllocationAmount += parseInt(fund?.amount)
            }
            return fund;
        });

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            warningMessage: (totalAllocationAmount !== parseInt(investmentAmount)) ? 'Investment Amount is not matching with the total amount of all the Schemes.' : '',
            funds: allFunds
        }))
    };

    const clearSelectedPortfolio = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: [],
            totalAllocationAmount: "",
            totalAllocationPercentage: "",
            warningMessage: '',
            totalSelectedAmount: ''
        }))
    };

    const deleteFund = (productId) => {
        const {funds} = purchaseTransactionState;
        let allFunds = funds.filter(product => product.productId !== productId);
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: allFunds
        }))
    };

    const closeAddSchemeModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenAddSchemeModal: false,
            productModalError:'',
            searchFundError: '',
            searchProduct: '',
            searchKeyword:'',
            products:[],
            addedFund: '',
            isReinvested: false,
            addedProductAmountError: '',
            productMinValue:'',
            productMaxValue: '',
            productMultipleValue : '',
            addedProductAmount: ''
        }))
    };

    const openAddSchemeModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenAddSchemeModal: true
        }))
    };

    const redirectScreen = (action) => {
        let transactionRequestId = getTransactionRequestId();
        let clientRequestId = getClientRequestId();

        if (action === 'SELECT-FOLIO') {
            props.history.replace(
                '/client-requests/' + clientRequestId + '/lumpsum-transaction/' + transactionRequestId + '/select-portfolio');
        }
        if (action === 'DISCARD') {
            props.history.replace(
                '/client-requests/' + clientRequestId + '/lumpsum-transaction/' + transactionRequestId);
        }
    };

    const validateTransaction = () => {
        const {funds} = purchaseTransactionState;
        let allErrors = [];
        funds.map((selectedFund) => {
            let errors = isEmpty(selectedFund?.errors) ? [] : selectedFund?.errors;
            allErrors = [...allErrors, ...errors]
        });
        return (isEmpty(allErrors));
    };

    let searchProductAPI = (keyword) => {
        let payload = {keyword: keyword};
        serachPurchaseProducts(payload).then(res => {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                searchFundError: '',
                products: res.data?.products,
                productModalError: '',
                isShowLoader: false
            }))
        });
    };

    const searchProduct = (keyword) => {

        if (isEmpty(keyword)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                searchKeyword: '',
                products:[]
            }));
            return
        }

            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                isShowLoader: true,
                searchKeyword: keyword,
            }));

        setTimeout(() => {
            searchProductAPI(keyword)
        }, 500)

    };

    const submitTransactionDetails = () => {
        const {funds} = purchaseTransactionState;
        let transactionRequestId = getTransactionRequestId();
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        let products = [];
        funds.map((selectedFund) => {
            products.push({
                productId: selectedFund?.productId,
                allocation: selectedFund?.allocationAmount,
                amount: selectedFund?.amount
            })
        });

        if (validateTransaction()) {
            let payload = {
                transactionRequestId: transactionRequestId,
                products: products
            };

            submitFundRequest(payload, true)
                .then(res => {
                    if (res.success) {
                        setPurchaseTransactionState((prevState) => ({
                            ...prevState,
                            isShowLoader: false
                        }));
                        redirectScreen('DISCARD')
                    } else {
                        setPurchaseTransactionState((prevState) => ({
                            ...prevState,
                            responseErrors: res?.__error,
                            isShowLoader: false
                        }))

                    }
                })
        }
    };

    const addProduct = (selectedProduct) => {
        let transactionRequestId = getTransactionRequestId();
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));
        if (isEmpty(selectedProduct)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedFund: '',
                selectedProductId: '',
                searchKeyword: '',
                products: '',
                productModalError: '',
                isShowLoader: false
            }));
            return
        }

        let productId = selectedProduct?.id;

        selectProduct(transactionRequestId, productId).then(res => {
            if (res?.success) {
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    addedFund: selectedProduct?.description,
                    productMinValue: res?.data?.limit?.minAmount,
                    productMaxValue: res?.data?.limit?.maxAmount,
                    productMultipleValue: (res?.data?.limit?.multiples < 100) ? 100 : res?.data?.limit?.multiples,
                    selectedProductId: productId,
                    productModalError: '',
                    searchKeyword: '',
                    isShowLoader: false
                }))
            } else {
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    productModalError: res?.__error,
                    isShowLoader: false
                }))
            }
        });
    };

    const reinvestProduct = (value) => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isReinvested: value,
        }))
    };

    const validateAmount = () => {
        const {addedProductAmount, productMinValue, productMaxValue, productMultipleValue, selectedProductId} = purchaseTransactionState;

        if (isEmpty(selectedProductId)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                searchFundError: 'Please select Product'
            }));
            return false
        }

        if (isEmpty(addedProductAmount)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Please enter amount'
            }));
            return false
        }

        if (addedProductAmount > productMaxValue) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Please enter a valid value, maximum amount invested in this scheme is ' + productMaxValue
            }));
            return false
        }

        if (addedProductAmount < productMinValue) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Please enter a valid value, minimum amount invested in this scheme is ' + productMinValue
            }));
            return false
        }

        if (!isMultiple(addedProductAmount, productMultipleValue)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Please enter a valid value, multiplier for this scheme is ' + productMultipleValue
            }));
            return false
        }

        return true
    };

    const addScheme = () => {
        const {addedProductAmount, addedFolioNumber, selectedProductId, funds, investmentAmount} = purchaseTransactionState;
        let transactionRequestId = getTransactionRequestId();

        let isValidAmount = validateAmount();
        if (!isValidAmount) {
            return
        }

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            txnRequestId: transactionRequestId,
            productId: selectedProductId,
            amount: addedProductAmount,
            folioNumber: addedFolioNumber
        };

        addPurchaseScheme(payload).then(res => {
            if (res?.success) {
                let resp = res?.data[0];

                let allocationAmount = getAllocation(resp?.amount, investmentAmount);
                let respFunds = [{
                    productId: resp?.productId,
                    productName: resp?.productName,
                    amount: resp?.amount,
                    allocationAmount: resp?.allocation,
                    allocation: isEmpty(allocationAmount) ? '-' : (allocationAmount + '%'),
                    errors: [],
                    details: resp?.details,
                    folioNumbers: [
                        {
                            label: 'Create New Folio',
                            value: 'null'
                        }
                    ]
                }];

                const mappedProducts = funds.concat(respFunds);
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    funds: mappedProducts,
                    isShowLoader: false,
                    addedFolioNumber: '',
                    addedProductAmount: '',
                    addedFund: '',
                    selectedProductId: '',
                    searchKeyword: '',
                    isOpenAddSchemeModal: false
                }))
            } else {
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    productModalError: res?.__error,
                    isShowLoader: false
                }))
            }
        });
    };

    const closeAddDiscardModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: false
        }))
    };

    const openDiscardModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: true
        }))
    };


    return (
        <PurchaseTransactionView
            {...props}
            {...purchaseTransactionState}
            onchangeValue={onchangeValue}
            changeAmount={changeAmount}
            clearSelectedPortfolio={clearSelectedPortfolio}
            deleteFund={deleteFund}
            closeAddSchemeModal={closeAddSchemeModal}
            openAddSchemeModal={openAddSchemeModal}
            redirectScreen={redirectScreen}
            validateTransaction={validateTransaction}
            submitTransactionDetails={submitTransactionDetails}
            searchProduct={searchProduct}
            addProduct={addProduct}
            reinvestProduct={reinvestProduct}
            addScheme={addScheme}
            closeAddDiscardModal={closeAddDiscardModal}
            openDiscardModal={openDiscardModal}
        />
    );
};

export default PurchaseTransaction;