import React, {useState} from 'react';
import LoginView from "./LoginView";
import {isValidEmail} from "../../../utils/helper";
import {APP_CONST} from "../../../AppComponents/AppConstants"
import {isEmpty} from "../../../utils/helperCommon";
import {userLogin} from "../../userActions";
import {useMutation} from "react-query";
import {connect} from "react-redux";
import {setNotAccessible, setUserAuthStatus, userLoggedOut} from "../../userReducer";
import {userConst} from "../../userConsts";
import {httpPost, setAuthorizationToken} from "../../../utils/httpHelper";
import {loginFormData} from "../loginModal";
import {showSystemErrorBlock, showToastMessage} from "../../../AppComponents/AppActions";
import store from "../../../store";

let API_URL = process.env.REACT_APP_API_URL;

const Login = (props) => {
    const {setUserAuthStatus} = props;
    const [formData, setFormData] = useState({
        ...loginFormData,
        ...Object.keys(loginFormData).reduce((acc, item) => {
            return {
                ...acc,
                [item + 'Error']: '',
            };
        }, {})
    });

    const {mutate, isLoading: isLoginInProgress} = useMutation('userLogin', userLogin, {
        onSuccess: (data) => {
            setAuthorizationToken(data.token, formData.rememberMe);
            setUserAuthStatus(userConst.AUTH_STATUS_AUTHORIZED);
        }
    });

    const processGoogleLogin = (data) => {
        let obj = {
            ...data,
            payload:data.credential
        };
        httpPost(API_URL+ '/service/advisory/signIn/advisoryUser', obj, '', true)
            .then(res => {
                if (res.success) {
                   const tokenSet = setAuthorizationToken(res.data?.token, true);
                    if(tokenSet.status){
                        setUserAuthStatus(userConst.AUTH_STATUS_AUTHORIZED);
                    }
                }else{
                    store.dispatch(showToastMessage('warning', res.__error))
                }
            })
    };

    const onChangeValue = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
            [name + "Error"]: '',
        });
    };

    const validateChanges = (name) => {
        const {email, password} = formData;

        if (name === "email" || name === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(email)) {
                setFormData({
                    ...formData,
                    emailError: 'Enter Email Address'
                });
                return false;
            }

            if (email.length > 50) {
                setFormData({
                    ...formData,
                    emailError: 'Please enter upto 50 characters'
                });
                return false;
            }

            if (!isValidEmail(email, true)) {
                setFormData({
                    ...formData,
                    emailError: 'Enter valid email or remove special characters other than @, -, _ or period'
                });
                return false;
            }

        }

        if (name === 'password' || name === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(password)) {
                setFormData({
                    ...formData,
                    passwordError: 'Enter Password'
                });
                return false;
            }
        }

        return true;
    };

    const onBlurInputText = (name) => {
        validateChanges(name);
    };

    const submitForm = () => {

        if (!validateChanges(APP_CONST.FORM_SUBMISSION)) {
            return;
        }
        const {email, password, rememberMe} = formData;

        let userData = {
            email: email.trim().toLowerCase(),
            password: password,
            rememberMe: rememberMe,
        };
        mutate(userData);
    };

    return (
        <LoginView {...props}
                   processGoogleLogin={processGoogleLogin}
                   formData={formData}
                   isLoginInProgress={isLoginInProgress}
                   onChangeValue={onChangeValue}
                   onBlurInputText={onBlurInputText}
                   submitForm={submitForm}
        />
    )
};

Login.propTypes = {};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {
    setUserAuthStatus
})(Login);


