import {httpGet, httpPost, httpPut} from "../utils/httpHelper";
import {downloadS3BucketFile, uploadData} from "../utils/FileHelper";


export const getCategoryOptions = () => {
    return httpGet('/service/advisory/categoryType/all').then(res => {
        return res;
    });
};

export const getAccountTypeOptions = () => {
    return httpGet('/service/advisory/accountType/all').then(res => {
        return res;
    });
};

export const getCRMDetails = () => {
    return httpGet('/service/advisory/advisoryUser/all').then(res => {
        return res;
    })
};

export const getAdvisoryUsers = () => {
    return httpGet('/service/advisory/advisoryUser/all').then(res => {
        return res;
    })
};

export const getCRMs = () => {
    return httpGet('/service/advisory/advisoryUser/all?role=CRM').then(res => {
        return res;
    })
};

export const getLocations = () => {
    return httpGet('/service/advisory/location/all').then(res => {
        return res;
    })
};

export const getAccountDetails = (accountId) => {
    return httpGet('/service/advisory/client/account?accountId='+accountId, true).then(res => {
        return res;
    })
};

export const getPersonDetails = (accountId, accountHolderId) => {
    return httpGet('/service/advisory/client/profile?accountId='+accountId+'&accountHolderId='+accountHolderId).then(res => {
        return res;
    })
};

export const getClientFinancialProfile = (accountId) => {
    return httpGet('/service/advisory/client/financialProfile?accountId='+accountId).then(res => {
        return res;
    })
};

export const getClientBankAccount = (accountId) => {
    return httpGet('/service/advisory/client/bank?accountId='+accountId).then(res => {
        return res;
    })
};

export const getClientNominee = (accountId) => {
    return httpGet('/service/advisory/client/nominee/all?accountId='+accountId).then(res => {
        return res;
    })
};

export const getClientNomineeDetails = (accountId, nomineeId) => {
    return httpGet('/service/advisory/client/nominee?accountId='+accountId+'&nomineeId='+nomineeId).then(res => {
        return res;
    })
};

export const getClientPersonDetails = (accountHolderId, accountId) => {
    return httpGet('/service/advisory/client/personForm?accountId='+accountId+'&accountHolderId='+accountHolderId).then(res => {
        return res;
    })
};

export const getClientRequiredDocuments = (accountId) => {
    return httpGet('/service/advisory/client/documentRequirement?accountId='+accountId).then(res => {
        return res;
    })
};

export const getClientGenerateForms = (data) => {
    return httpPost('/service/advisory/client/generateForms', data, true)
        .then(res => {
            return res;
        })
};

export const getClientAccountTypes = () => {
    return httpGet('/service/advisory/accountType/all?isClient=' + true).then(res => {
        return res;
    })
};

export const getAllTaxStatus = () => {
    return httpGet('/service/advisory/taxStatus/all').then(res => {
        return res;
    })
};

export const updateClientFinancialProfileData = (data) => {
    return httpPut('/service/advisory/client/financialProfile', data, true)
        .then((res) => {
            return res;
        });
};

