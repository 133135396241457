import React from 'react';

import {APP_CONST} from "../../../../../../AppComponents/AppConstants";
import {isEmpty} from "../../../../../../core/components/DLComponentHelper";

import DLText from "../../../../../../core/components/DLText/DLText";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLInputTextField from "../../../../../../core/components/DLInputTextField/DLInputTextField";
import DLRadioGroup from "../../../../../../core/components/DLRadioGroup/DLRadioGroup";

const ModelPortfolioView = (props) => {
    const {
        isXSView, isShowLoader, onChangeFilter, UIState, isMobileView, portfolioListing,
        responseError, selectedPortfolioId, filters, isSMView, isMDView
    } = props;

    const {keyword} = filters;

    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between'>
                <div className='page-header'>
                    Select Model Portfolio
                </div>
            </div>
        )
    };

    const renderFilter = () => {
        return (
            <div>
                <div className='d-flex'>
                    <div className='search-input' style={{flex: 1.8}}>
                        <DLInputTextField
                            id={'NFT-list-search-filter'}
                            label={'Search'}
                            labelFontSize={'sm'}
                            labelFontColor={'grayDark'}
                            marginBottom={"none"}
                            rows={1}
                            styleType={"search"}
                            onChangeValue={(e) => {
                                onChangeFilter('keyword', e)
                            }}
                            value={keyword}
                            placeholder={'Search by portfolio name/scheme'}
                            isSearchable={true}
                            size="small"
                            inputHeight={'sm'}
                            inputProps={{style: {padding: '7px 10px', maxWidth: "350px"}}}
                            inputType={"text"}
                            disableHelperMessageHeight={true}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const emptyList = () => {
        return (
            <div className='d-flex align-items-center justify-content-center empty-records'
                 style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 370px)'}}>
                No data found.
            </div>)
    };

    const renderWidget = (item) => {
        const {portfolioSelected, selectFolio} = props;

        return (
            <div className="py-2 px-3 border-bottom d-flex justify-content-between bg-light">
                <div className='d-flex align-items-center'>
                    <DLRadioGroup
                        id={'hours-radioBtn'}
                        options={[
                            {
                                label: item?.name,
                                value: item?.name,
                            }]}

                        label={''}
                        value={portfolioSelected}
                        fontWeight={"semi-bold"}
                        type={'inline'}
                        labelFontSize={'md'}
                        removePadding={true}
                        labelFontColor={'black'}
                        onChangeValue={(e) => {
                            selectFolio(e, item?.id)
                        }}
                        minHeight={"30"}
                        disableHelperMessageHeight={true}
                        disableDescriptionMessageHeight={true}
                    />
                </div>
            </div>
        )
    };

    const renderListing = () => {
        let isMobileUI = (isXSView || isSMView);
        if (isEmpty(portfolioListing)) {
            return (
                <div className='d-flex align-items-center justify-content-center empty-records'
                     style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 370px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className='row'>
                {
                    portfolioListing?.map((item, index) => {
                        return (
                            <div className={isMobileUI ? 'col-12 mb-3' : isMDView ? 'col-6 mb-3' : 'col-4 mb-3'}
                                 key={index}>
                                <div className={'p-0 w-100 border-rounded panel h-100'}>
                                    <div className="px-0 h-100 c">
                                        {renderWidget(item)}
                                        {renderTableUi(item)}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    };

    const renderTableUi = (item) => {
        if (isEmpty(item?.portfolioFunds)) {
            return (
                <div className='d-flex h-75 align-items-center justify-content-center'
                     style={{minHeight: 217}}>
                    <div> No funds added</div>
                </div>
            )
        } else {
            return (
                <div className={item?.portfolioFunds?.length > 6 ? 'p-3 overflow-scroll overflow-x-hidden ' : 'p-3 '}
                     style={{minHeight: 217, height: isXSView ? 'auto' : 217, overflow: 'auto'}}>
                    {
                        isShowLoader ?
                            <div className='d-flex align-items-center justify-content-center h-100'>
                                {renderLoader()}
                            </div>
                            :
                            renderData(item?.portfolioFunds)
                    }
                </div>
            )
        }
    };

    const renderData = (funds) => {
        return funds.map((item, index) => {
            return (
                <div className={"d-flex justify-content-between py-1"}
                     key={'data' + index}>
                    <div className='data-card-label pe-2'>
                        <DLText id={''}
                                text={item?.productMaster?.description}
                                fontSize={"xs"}
                                fontWeight={"normal"}
                        />
                    </div>
                    {item?.allocationPercentage && <div className='data-card-label'>
                        <DLText id={''}
                                text={item?.allocationPercentage + '%'}
                                fontSize={"xs"}
                                fontWeight={"semi-bold"}
                        />
                    </div>}
                </div>
            )
        })
    };

    const renderUi = () => {
        return (
            <div className={'w-100 mt-3' + ((isMobileView && !isXSView) ? '  page-container' : '')}>
                <div>
                    {renderListing()}
                </div>
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderActionButtons = () => {
        const {onNextClick, redirectScreen} = props;

        return (
            <div className='sticky-bottom-buttons pb-3'>
                <DLButton
                    id={'save-and-close-button'}
                    styleType={"outlined"}
                    borderType={'square'}
                    buttonSize={'sm'}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => {
                        redirectScreen('', 'DISCARD')
                    }}
                    label={"Discard"}
                />
                <DLButton
                    id={'save-and-close-button'}
                    styleType={"outlined"}
                    borderType={'square'}
                    buttonSize={'sm'}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => {
                        redirectScreen([], 'NEXT')
                    }}
                    label={"Skip"}
                />
                <DLButton id={'next-button'}
                          buttonSize={'sm'}
                          fontSize={'sm'}
                          sideMargin={'none'}
                          isDisabled={isEmpty(selectedPortfolioId)}
                          onClick={() => {
                              onNextClick()
                          }}
                          label={"Next"}/>

            </div>
        );
    };

    const renderErrorUI = () => {
        const {responseError} = props;

        if (isEmpty(responseError)) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className={'mb-3'}>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            </div>
        );
    };

    if (UIState === APP_CONST.CONTENT_UNAVAILABLE) {
        return (
            <div className='page-container'>
                {emptyList()}
            </div>
        );
    }

    return (
        <div className='d-flex justify-content-center bg-light p-3'>
            <div className='w-100 bg-white border-rounded border m-1 mb-5'>
                {renderHeader()}

                <div className='page-container pt-3'>
                    {renderErrorUI()}
                    {renderFilter()}
                    <div style={{
                        minHeight: isEmpty(responseError) ? 'calc(100dvh - 290px)' : 'calc(100dvh - 335px)',
                        maxHeight: 'auto'
                    }}>
                        {renderUi()}
                    </div>
                </div>
                {renderActionButtons()}
                {renderLoader()}
            </div>
        </div>
    );
};

export default ModelPortfolioView;