import React, {useState} from 'react';
import {connect} from "react-redux";

import {removePortfolio} from "../../portfolioAction";
import store from "../../../store";
import {showToastMessage} from "../../../AppComponents/AppActions";

import DeletePortfolioView from "./DeletePortfolioView";


const DeletePortfolio = (props) => {

    const {portfolioName, portfolioId, handleCloseDeletePortfolioModal, getPortfolios} = props;
    const [deletePortfolioState, setDeletePortfolioState] = useState({
        isApiInProgress: false,
        handleErrorInComponent:true,
        modalErrorMessage:''
    });

    const clearData = () => {
        getPortfolios();
        handleCloseDeletePortfolioModal();
    }

    const deletePortfolio = () => {

        setDeletePortfolioState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));

        removePortfolio(portfolioId)
            .then((res) => {
                if (res.success) {
                    clearData();
                    store.dispatch(showToastMessage('danger', portfolioName + ' portfolio is removed.'))
                    setDeletePortfolioState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false
                    }));
                } else {
                    setDeletePortfolioState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false,
                        modalErrorMessage:res.__error
                    }));
                }
            }
        );
    };

    return (
        <DeletePortfolioView
            {...props}
            {...deletePortfolioState}
            deletePortfolio={deletePortfolio}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(DeletePortfolio);
