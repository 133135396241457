//noinspection DuplicatedCode,JSUnresolvedVariable,SpellCheckingInspection,JSUnresolvedFunction

import React, {Component} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {makeStyles} from '@mui/styles';
import DLTooltip from "../DLTooltip/DLTooltip";
import {getFontColor, getFontFamily, getFontSize, getTheme, getThemeColor, isEmpty} from "../DLComponentHelper";
import "../../../assets/css/designToken.css"

const radioGroupMarginBottoms = {
    'none': '',
    'xxs': 'mb-1',
    'xs': 'mb-2',
    'sm': 'mb-3',
    'md': 'mb-4'
};

const useStyles = makeStyles({
    root: {
        // '&:hover': {
        //     backgroundColor: '#0B407D',
        // },
    }
});

function getRadioBtnStyle(type, removePadding, options, style) {

    if (type === 'multiple' && options.length > 1) {
        return style;
    }

    if (removePadding) {
        return {paddingLeft: '0px', paddingTop: '0px', paddingBottom: '0px'}
    }
}

function RadioInput(props) {

    return (
        <Radio
            className={'radio-button-css'}
            disableRipple={true}
            {...props}
            style={getRadioBtnStyle(props.radioType, props.removePadding, props.options, props?.style)}
        />
    );
}

function getRadioBorderColor(option, value, isDisabled) {
    let theme = getTheme();
    if (isDisabled) {
        return {color: theme.appColor.gray}

    } else if (option.value === value) {
        return {color: theme.appColor.primary}

    } else {
        return {color: theme.appColor.grayDark}
    }
}

function getSingleRadioBorderColor(option, value, isDisabled) {
    let theme = getTheme();
    if (isDisabled) {
        return {color: theme.appColor.gray}

    } else if (!isEmpty(value)) {
        return {color: theme.appColor.primary}

    } else {
        return {color: theme.appColor.grayDark}
    }
}

function getFontWeight(fontWeight) {
    if (fontWeight === 'bold') {
        return {
            fontWeight: '600'
        }
    }
    if (fontWeight === 'semi-bold') {
        return {
            fontWeight: '500'
        }
    }
    return {}
};

class DLRadioGroupView extends Component {
    render() {
        let {
            type, enableTranslation, t, onChangeValue, isDisabled, radioType, isRequired, error, helperMessage, fontWeight, removePadding,
            label, value, options, checked, id, marginBottom, tooltipContent, disableHelperMessageHeight, style, labelFontSize, labelFontColor,
            disableDescriptionMessageHeight, description, helperMessageColor, minHeight
        } = this.props;

        let theme = getTheme();

        if (radioType === 'multiple') {
            return (<div className={radioGroupMarginBottoms[marginBottom]}>
                    <div style={{minHeight: minHeight, alignItems: 'center', display: 'flex'}}>
                        <FormControl component="fieldset">
                            {
                                !isEmpty(label) &&
                                <FormLabel
                                    component="legend"
                                    style={{
                                        fontColor: (error && isRequired) ? getThemeColor('danger') : getFontColor(labelFontColor),
                                        ...getFontSize(labelFontSize),
                                        ...getFontWeight(fontWeight),
                                        fontWeight: '400 !important',
                                        lineHeight: '1 !important',
                                        fontFamily: getFontFamily(),
                                        marginBottom: isEmpty(label) ? '0px !important' : '8px !important',
                                        ...style
                                    }}
                                >
                                    <span
                                        style={{color: error && isRequired ? getThemeColor('danger') : ''}}>{(enableTranslation ? t(label) : label)}</span>

                                    {/*{(isRequired ? <span style={{ color: getThemeColor('danger') }}> *</span> : '')}*/}
                                    <span className='ml-3'><DLTooltip id={id + 'radio-tool-tip'}
                                                                      tooltipContent={tooltipContent}/></span>
                                </FormLabel>
                            }
                            <RadioGroup
                                id={id}
                                className={'d-block'}
                                value={value}
                                onChange={(e) => onChangeValue(e.target.value)}
                            >
                                {options.map((option, index) => {
                                    const borderColor = getRadioBorderColor(option, value, isDisabled);
                                    return (
                                        <div className={type === 'block' ? 'd-block' : 'd-inline-block'}
                                             key={option.value}>
                                            <FormControlLabel
                                                id={id + '-radio-' + index}
                                                disabled={isDisabled}
                                                value={option.value}
                                                control={<RadioInput style={borderColor}
                                                                     radioType={radioType}
                                                                     options={options}
                                                />}
                                                className={type === 'block' ? "mb-3 ps-2" : "mb-0 pe-4"}
                                                label={(<span style={{
                                                    fontFamily: getFontFamily(),
                                                    ...getFontSize(labelFontSize),
                                                    ...getFontWeight(fontWeight),
                                                    color: getFontColor(labelFontColor),
                                                }}> {enableTranslation ? t(option.label) : option.label}</span>)}
                                            />
                                        </div>
                                    );
                                })}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    {!isEmpty(description) &&
                    <div className='mb-1' style={{
                        minHeight: disableDescriptionMessageHeight ? '0' : '19px',
                        color: 'var(--gray-dark)',
                        fontSize: 'var(--font-size-xs)',
                        paddingLeft: 17
                    }}>
                        <span>{description}</span>
                    </div>
                    }
                    {!isEmpty(helperMessage) &&
                    <div style={{
                        minHeight: disableHelperMessageHeight ? '0' : '19px',
                        color: getFontColor(helperMessageColor),
                        fontSize: 'var(--font-size-xs)',
                        margin: '0px 14px 0 18px'
                    }}>
                        <span className="d-flex justify-content-between"
                              style={{minHeight: '19px'}}>{helperMessage}</span>
                    </div>
                    }
                </div>
            );
        }

        const borderColor = getSingleRadioBorderColor(value, isDisabled);

        return (
            <div
                className={(type === 'block' ? 'd-block ' : 'd-inline-block ') + radioGroupMarginBottoms[marginBottom]}>
                <FormControlLabel
                    id={id}
                    checked={checked}
                    disabled={isDisabled}
                    onChange={(e) => onChangeValue(e.target.checked)}
                    control={<RadioInput style={borderColor} radioType={radioType} removePadding={removePadding}/>}
                    className={type === 'block' ? "mb-2" : "mb-0"}
                    label={(<>
                      <span style={{
                          fontFamily: getFontFamily(),
                          ...getFontSize(labelFontSize),
                          ...getFontWeight(fontWeight),
                          color: getFontColor(labelFontColor)
                      }}
                            className=''>
                      {enableTranslation ? t(label) : label}
                      </span>
                        <span className='ml-3'><DLTooltip id={id + 'radio-tool-tip'}
                                                          tooltipContent={tooltipContent}/></span>
                    </>)}
                />
                {!isEmpty(helperMessage) &&
                <div style={{minHeight: disableHelperMessageHeight ? '0' : '19px'}}>
                    <span>{helperMessage}</span>
                </div>
                }
            </div>
        );
    }
}

export default DLRadioGroupView;
