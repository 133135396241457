//noinspection DuplicatedCode,JSUnresolvedVariable,SpellCheckingInspection,JSUnresolvedFunction

import React, {Component} from 'react';
import Dialog from '@mui/material/Dialog';

class DLModalView extends Component {


  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    }
  }

  componentDidMount() {
    if (this.props.open) {
      this.openModal();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open && !this.state.isOpen) {
      this.openModal();
    }

    if (prevProps.open && !this.props.open && this.state.isOpen) {
      if (this.props.ignoreWebBackClick) {
        this.setState({
          isOpen: false
        })
        return;
      }
      this.closeModal(false);
    }
  }


  openModal = () => {
    if (!this.state.isOpen) {
      this.setState({
        isOpen: true
      }, () => {
        if (this.props.ignoreWebBackClick) {
          return;
        }
      })
    }
  };

  closeModal = (byBackButton) => {


    if (this.state.isOpen) {
      this.setState({
        isOpen: false
      }, () => {
        if (this.props.ignoreWebBackClick) {
          this.props.onClose();
          return;
        }

        this.props.onClose();
      })
    }
  };


  render() {
    const {isFullScreen, fullWidth, maxWidth, renderChildrenElements} = this.props;
    const {isOpen} = this.state;
    return (
      <Dialog
        open={isOpen}
        onClose={() => this.closeModal(false)}
        maxWidth={maxWidth}
        fullScreen={isFullScreen}
        fullWidth={fullWidth}
      >
        {renderChildrenElements('Header')}
        {renderChildrenElements('Content')}
        {renderChildrenElements('Action')}
      </Dialog>
    );
  }
}

export default DLModalView;
