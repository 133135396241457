import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";
import {
    getNFTWidgetListing, setStatus, setCategory, setAgeing, setNFTStatusTitle, resetNFTFilters, setFromDate, setToDate
} from "../../../NFTAction";

import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import NFTDashboardWidgetView from "../components/NFTDashboardWidgetView";

const NFTDashboardCRMWidget = (props) => {

        const {requestType} = props;
        const [NFTDashboardState, setNFTDashboardState] = useState({
                data: [],
                count: 0,
                isApiInProgress: false,
                UIState: '',
            }
        );

        useEffect(() => {
            getOpenRequests();
        }, []);

        const getOpenRequests = () => {
            const {requestType} = props;
            let url = getUrl(requestType)

            setNFTDashboardState(prevState => ({
                ...prevState,
                isShowLoader: true
            }));

            getNFTWidgetListing(url)
                .then(res => {
                    if (res?.success) {
                        setNFTDashboardState(prevState => ({
                            ...prevState,
                            data: res.data?.items,
                            count: isEmpty(res.data?.totalCount)? 0 : res.data?.totalCount,
                            isShowLoader: false,
                            UIState: APP_CONST.CONTENT_AVAILABLE
                        }));
                    } else {
                        setNFTDashboardState(prevState => ({
                            ...prevState,
                            isShowLoader: false,
                            UIState: APP_CONST.CONTENT_UNAVAILABLE
                        }));
                    }
                })
        };

        const getUrl = (type) => {
            if (type === 'STATUS') {
                return '/service/advisory/nft/crm/summary/requests/statusSummary'
            }
            if (type === 'AGEING') {
                return '/service/advisory/nft/crm/summary/requests/ageing'
            }
            if (type === 'CATEGORY') {
                return '/service/advisory/nft/crm/summary/requests/category'
            }
        }

        const getHeading = () => {
            let type = requestType;
            if (type === 'STATUS') {
                return 'Status'
            }
            if (type === 'AGEING') {
                return 'Ageing'
            }
            if (type === 'CATEGORY') {
                return 'Category'
            }
        }

        const getRedirectTo = (type, filter) => {
            if (isEmpty(type)) {
                resetNFTFilters()
                props.history.push("/non-financial-transactions")
            }
            if (type === 'Status'){
                resetNFTFilters();
                setStatus(["OPEN","IN_PROGRESS"]);
                props.history.push("/non-financial-transactions")
            }
            if (type === 'STATUS') {
                resetNFTFilters()
                setStatus([filter?.key])
                props.history.push("/non-financial-transactions")
            }
            if (type === 'AGEING') {
                resetNFTFilters()
                setNFTStatusTitle('Ageing')
                if(!isEmpty(filter?.fromDate)){
                    setFromDate(filter?.fromDate)
                }
                if(!isEmpty(filter?.toDate)){
                    setToDate(filter?.toDate)
                }
                setAgeing([filter?.key])
                props.history.push("/non-financial-transactions")
            }
            if (type === 'CATEGORY') {
                resetNFTFilters()
                setNFTStatusTitle('Category')
                setCategory([filter?.key])
                props.history.push("/non-financial-transactions")
            }

        }

        if (isEmpty(NFTDashboardState.UIState)) {
            return (<DLLoader type={"screen"} isVisible={true}/>);
        }

        return (
            <NFTDashboardWidgetView
                {...props}
                {...NFTDashboardState}
                getHeading={getHeading}
                getRedirectTo={getRedirectTo}
            />
        );
    }
;

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(NFTDashboardCRMWidget);
