import React from 'react';

import {isEmpty} from "../../../utils/validations";

const FilterBadgeView = (props) => {
    const {badgeData, onClose} = props;
    const {title,data} = badgeData;
    return (
        !isEmpty(title) && <div className='badge-light px-3' style={{lineHeight:'26px'}}>
            {title}{data} <i className="fa-solid fa-xmark ps-2 cursor" onClick={onClose}/>
        </div>
    );
};

export default FilterBadgeView;