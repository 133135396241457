import React, {useEffect, useState} from 'react';
import DocumentUploadView from "./DocumentUploadView";
import {connect} from "react-redux";

const DocumentUpload = (props) => {

    const[documentUploadState, setDocumentUploadState] = useState({
        documents : [],
        selectedApplicantName: '',
        isMultipleApplicants: false
    });

    useEffect(()=>{
       setAllDocumentWithStatus()
    },[props.selectedScreenObj]);

    const setAllDocumentWithStatus = () =>{
        const {screenDetails, selectedScreenObj, totalApplicants} = props;
        const documents =  screenDetails
            .filter(screen => screen.parentScreenKey === "UPLOAD_DOCUMENTS")
            .map(screen => ({
                name: screen.screenName,
                key: screen.screenKey,
                personId: screen.personId,
                applicantName: screen.applicantName,
                status: screen.processCompleted ? 'SUCCESS' : screen.processInitiated? 'IN_PROGRESS' : 'WARNING'
            }));

        setDocumentUploadState((prevState) => ({
            ...prevState,
            documents: documents,
            selectedApplicantName: selectedScreenObj?.applicantName,
            isMultipleApplicants : (totalApplicants?.length > 2)
        }));
    };

    const validateUploadDocuments = () =>{
        const {handleClickNext} = props;
        //    validate documents and submit form
        handleClickNext()
    };

    return (
        <DocumentUploadView
            {...props}
            {...documentUploadState}
            validateUploadDocuments={validateUploadDocuments}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    leads: state.leads,
    leadsFilter: state.leadsFilter,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(DocumentUpload);
