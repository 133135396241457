import React from 'react';

const InvestmentView = (props) => {

    const {isMobileView} = props;

    return (
        <div className='p-4'>
            <div className='d-flex align-items-center justify-content-center border border-rounded bg-white'
                 style={{
                     maxHeight: isMobileView ? 'calc(100dvh - 218px)' : 'calc(100dvh - 200px)',
                     minHeight: isMobileView ? 'calc(100dvh - 218px)' : 'calc(100dvh - 200px)'
                 }}>
                No data found.
            </div>
        </div>
    );
};

export default InvestmentView;