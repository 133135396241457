import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {APP_CONST} from "../../../../../AppComponents/AppConstants";
import {isEmpty} from "../../../../../core/components/DLComponentHelper";

import {
    resetFTFilters,
    setFTLimitCount,
    setFTPageCount,
    setFTRowPerPageCount,
    setFTSkipCount,
    getFTStatuses,
    getFinancialTransactionRequest,
    getFTTypes,
    setFTSearchKeyword,
    setFTStatus
} from "../../../FTAction";

import {
    getAccountTypeOptions,
    getCategoryOptions,
    getCRMs
} from "../../../../NFT/NFTAction";

import FTRequestListForCRMView from "./FTRequestListForCRMView";

const FTRequestListForCRM = (props) => {

    const [FTRequestListState, setFTRequestListState] = useState({
        FTList: [],
        listingCount: 0,
        skipCount: 0,
        limitCount: 50,
        isShowFilterMobile: false,
        isCreateModalOpen: false,
        isShowLoaderMaster: false,
        UIState: '',
        errorInApi: '',
        isShowLoader: false,
        filters: {
            keyword: '',
            status: []
        },
        FTStatusDropdown: [],
        CRMDropdownValues: [],
        allAccountTypes: [],
        categoryTypes: [],
        typesOfTransaction: [],
        allFTStatus: []
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    useEffect(() => {
        getMasterData()
    }, []);

    useEffect(() => {
        getFTList();
    }, [props.FTFilter]);

    const getMasterData = () => {

        setFTRequestListState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getCRMs()
            .then(CRMRes => {
                let allCRMs = [];
                let allStatus = [];
                let allAccountTypes = [];
                let categoryTypes = [];
                let typesOfTransaction = [];

                if (CRMRes?.success) {
                    allCRMs = CRMRes.data?.advisoryUsers;
                }
                getFTStatuses()
                    .then(allStatusRes => {
                        if (allStatusRes?.success) {
                            allStatus = allStatusRes?.data
                        }
                        getAccountTypeOptions()
                            .then(accountTypeRes => {
                                if (accountTypeRes?.success) {
                                    allAccountTypes = accountTypeRes.data?.accountType
                                }
                                getCategoryOptions()
                                    .then(res => {
                                        if (res?.success) {
                                            categoryTypes = res.data?.categoryType;
                                        }
                                        getFTTypes()
                                            .then(txnRequestResp => {
                                                if (txnRequestResp?.success) {
                                                    typesOfTransaction = txnRequestResp.data?.txnRequestTypes;
                                                }
                                                setFTRequestListState(prevState => ({
                                                    ...prevState,
                                                    CRMDropdownValues: setValuesForDropdown(allCRMs, "firstName", "id"),
                                                    FTStatusDropdown: getFormattedStatus(allStatus),
                                                    allFTStatus: allStatus,
                                                    allAccountTypes: allAccountTypes,
                                                    categoryTypes: categoryTypes,
                                                    typesOfTransaction: typesOfTransaction,
                                                    isShowLoader: false,
                                                    UIState: APP_CONST.CONTENT_AVAILABLE
                                                }))
                                            })
                                    })
                            });

                    })
            })
    };

    const getFTList = () => {
        const {FTFilter} = props;

        setFTRequestListState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            skipCount: FTFilter.FTskipCount,
            limitCount: FTFilter.FTlimitCount,
            filters: generateFilter(FTFilter)
        };

        getFinancialTransactionRequest(payload).then((FTResp) => {
            if (FTResp?.success) {
                setFTRequestListState(prevState => ({
                    ...prevState,
                    FTList: FTResp?.data?.txnRequests,
                    listingCount: FTResp?.data?.count,
                    skipCount: FTFilter.FTskipCount,
                    limitCount: FTFilter.FTlimitCount,
                    errorInApi: '',
                    isShowLoader: false,
                }));
                setPage(isEmpty(FTFilter.FTpage) ? 0 : FTFilter.FTpage);
                setRowsPerPage(isEmpty(FTFilter.FTrowsPerPage) ? 50 : FTFilter.FTrowsPerPage);
            } else {
                setFTRequestListState(prevState => ({
                    ...prevState,
                    errorInApi: res?.__error,
                    isShowLoader: false
                }))
            }
        })
    };

    const generateFilter = (filter) => {

        let filterObject = {};

        if (!isEmpty(filter.FTkeyword)) {
            filterObject.keyword = filter.FTkeyword;
        }

        if (!isEmpty(filter.FTstatus)) {
            if (filter.FTstatus[0] === "ALL") {
                delete filterObject.FTstatus
            } else {
                filterObject.status = filter.FTstatus;
            }
        }

        return filterObject;
    };

    const handleChangePage = (event, newPage) => {
        let {limitCount, skipCount} = FTRequestListState;

        const isForward = newPage > page;
        if (isForward) {
            skipCount = skipCount + rowsPerPage;
            limitCount = rowsPerPage
        } else {
            skipCount = skipCount - rowsPerPage;
            limitCount = rowsPerPage
        }
        setFTSkipCount(skipCount);
        setFTLimitCount(limitCount);
        setFTPageCount(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setFTRequestListState(prevState => ({
            ...prevState,
            isSearchFilterUpdated: true
        }));

        setFTRowPerPageCount(parseInt(event.target.value, 10));
        setFTLimitCount(parseInt(event.target.value, 10));
        setFTPageCount(0);
        setFTSkipCount(0);
    };

    const handleToggleMobileFilter = (value) => {
        setFTRequestListState((prevStep) => ({
            ...prevStep,
            isShowFilterMobile: value
        }));
    };

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setFTRequestListState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    keyword: value,
                }
            }));
            setFTSearchKeyword(value);
        }

        if (name === 'status') {
            setFTRequestListState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    status: [value]
                }
            }));
            setFTStatus([value]);
        }
    };

    const resetPagination = () => {

        setFTRowPerPageCount(10);
        setFTLimitCount(10);
        setFTPageCount(0);
        setFTSkipCount(0);
    };

    const resetFTListing = () => {
        resetFTFilters();
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        let list = [{label: "All", value: "ALL"}];
        if (isEmpty(data)) {
            return;
        }
        data.map((item) => {
            list.push({label: item?.[labelToken] + ' ' + item?.lastName, value: item?.[valueToken]})
        });
        return list;
    };

    const getFormattedStatus = (statuses) => {

        let formattedStatus = [
            {label: 'All', value: 'ALL'}
        ];

        if (isEmpty(statuses)) {
            return []
        }
        statuses.map(item => (
            formattedStatus.push({
                value: item.key,
                label: item?.label
            })
        ));

        return formattedStatus
    };

    const getAccountType = (value) => {
        const {allAccountTypes} = FTRequestListState;
        if (isEmpty(allAccountTypes)) {
            return ''
        }

        let accountType = allAccountTypes.find((item) => {
            return item?.id === value;
        });
        return accountType?.name + " Account";
    };

    const getValueFromList = (value, allRecords, isShowLabel) => {

        if (isEmpty(allRecords) || isEmpty(value)) {
            return ''
        }

        let categoryType = allRecords.find((item) => {
            if (isShowLabel) {
                return item?.value === value;
            } else {
                return item?.id === value;
            }

        });
        return isShowLabel ? categoryType?.label : categoryType?.name;
    };

    const getPaginationNextButtonStatus = () => {
        const {listingCount, skipCount, limitCount} = FTRequestListState;
        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    const handleRedirect = (clientRequestId, txnId) => {
        props.history.push("/client-requests/"+clientRequestId+"/lumpsum-transaction/"+txnId)
    };

    return (
        <FTRequestListForCRMView
            {...props}
            {...FTRequestListState}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleToggleMobileFilter={handleToggleMobileFilter}
            onChangeFilter={onChangeFilter}
            resetFTListing={resetFTListing}
            getAccountType={getAccountType}
            getValueFromList={getValueFromList}
            getPaginationNextButtonStatus={getPaginationNextButtonStatus}
            handleRedirect={handleRedirect}
        />
    );

};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    FTFilter: state.FTState.FTFilter,
});

export default connect(mapStateToProps, {})(FTRequestListForCRM);
