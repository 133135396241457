import React from 'react';

import {numberWithCommas} from "../../utils/helper";
import {isEmpty} from "../../core/components/DLComponentHelper";

import DLText from "../../core/components/DLText/DLText";
import DLButton from "../../core/components/DLButton/DLButton";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import ApproveTransaction from "../components/approveTransaction/ApproveTransaction";
import RejectTransaction from "../components/rejectTransaction/RejectTransaction";
import ApprovedOrRejected from "../approvedOrRejectedTransaction/ApprovedOrRejected";
import appTheme from "../../assets/appTheme";

const ClientsApprovalView = (props) => {
    const {
        isMobileView, IINNumber, accountType, isShowLoader
        , UIState, paymentDetails, investmentDetails, ticketNumber, txnStatus, txnStage, txnData, handleOpenApproveTransactionModal,
        handleOpenRejectTransactionModal, transactionStatus, approvalTransactionStatus
    } = props;

    const handleAmount = (amount) => {
        if (isEmpty(amount)) {
            return "-";
        }
        return numberWithCommas(amount)
    }

    const renderApproveTransactionModal = () => {
        const {isMobileView, handleCloseApproveTransactionModal, isApproveTransactionModalOpen} = props;
        if (isApproveTransactionModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}}
                            onClick={handleCloseApproveTransactionModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <ApproveTransaction {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <ApproveTransaction {...props}/>
                )
            }
        }

    };

    const renderRejectTransactionModal = () => {
        const {isMobileView, handleCloseRejectTransactionModal, isRejectTransactionModalOpen} = props;

        if (isRejectTransactionModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}}
                            onClick={handleCloseRejectTransactionModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <RejectTransaction {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <RejectTransaction {...props}/>
                )
            }
        }

    };

    const renderAccountDetails = () => {
        const {accountDetails} = props;

        let allAccountHoldersNames = "";
        if (isEmpty(accountDetails)) {
            return allAccountHoldersNames;
        }
        accountDetails.map((applicant) => {
            if (isEmpty(allAccountHoldersNames)) {
                allAccountHoldersNames = applicant?.fullName;
            } else {
                allAccountHoldersNames = allAccountHoldersNames + ', ' + applicant?.fullName;
            }
        });

        return (
            <div key={'accountDetails'}
                 className='d-flex align-items-center '>
                <div>
                    {(!isMobileView) &&
                    <DLText id={'account-holder-name'}
                            text={allAccountHoldersNames}
                            marginBottom={"none"}
                    />
                    }
                </div>
            </div>
        );
    };

    const renderDesktopHeader = () => {
        return (
            <div className='w-100'>
                <div className='page-header d-flex justify-content-between'>
                    <div className="d-flex align-items-center">
                        <div>
                            Financial Transaction: Lumpsum Transaction
                        </div>
                    </div>

                </div>
                <div className='d-flex align-items-center mb-3 page-container '>
                    <div className='pe-2 border-end-black d-flex '>
                        <DLText id={'ac-details-name'}
                                fontWeight={'semi-bold'}
                                text={accountType + '-' + IINNumber}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='px-2 border-end-black'>
                        {renderAccountDetails()}
                    </div>
                    <div className='px-2 border-end-black'>
                        {ticketNumber}
                    </div>
                    <div className='px-2'>
                        {renderHeaderStatus(approvalTransactionStatus)}
                    </div>
                </div>
            </div>
        )
    };

    const renderHeaderStatus = (statusType) =>{
        if (statusType === 'REJECTED') {
            return (<div className="d-flex align-items-center badge-red ms-0">
                <span style={{color: appTheme.appColor.white}}> {statusType} </span>
            </div>);
        } else if (statusType === "APPROVED") {
            return (<div className="d-flex align-items-center badge-green ms-0">
                <span style={{color: appTheme.appColor.white}}> {statusType} </span>
            </div>);
        } else {
            return (<div className="d-flex align-items-center badge-amber ms-0">
                <span style={{color: appTheme.appColor.white}}> {statusType} </span>
            </div>);
        }
    }

    const renderChequePaymentDetails = () => {
        return (
            <div className="h-100 bg-white border-rounded p-3">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'payment-details-title'}
                        type={'normal'}
                        text={'Payment Details'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>
                <div className={'pe-3 overflow-scroll overflow-x-hidden mt-3'}
                     style={{maxHeight: 'calc(100dvh - 480px)'}}>
                    <div className='row mb-3'>
                        <div className='col-3'>
                            <DLText id={'payment-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Payment Mode'}/>
                            <DLText id={'payment-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.paymentMechanism?.description}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'bank-name-&-account-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Bank Name & Account Number'}/>
                            {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3'>
                            <DLText id={'cheque-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Cheque Number'}/>
                            <DLText id={'cheque-number-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.chequePayment?.chequeNo}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'micr-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'MICR Number'}/>
                            <DLText id={'micr-number-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.chequePayment?.micrNo}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'date'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Date (date on cheque)'}/>
                            <DLText id={'date-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.chequePayment?.chequeDate}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'cheque-deposite-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Cheque Deposite Mode'}/>
                            <DLText id={'cheque-deposite-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.chequePayment?.checkDepositModeDescription}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderPaymentDetails = () => {
        if (paymentDetails?.paymentMechanism?.description === "CHEQUE") {
            return renderChequePaymentDetails()
        }
        return (
            <div className="h-100 bg-white border-rounded p-3">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'payment-details-title'}
                        type={'normal'}
                        text={'Payment Details'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>
                <div className={'pe-3 overflow-scroll overflow-x-hidden mt-3'}
                     style={{maxHeight: 'calc(100dvh - 480px)'}}>
                    <div className='row mb-3'>
                        <div className='col-3'>
                            <DLText id={'payment-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Payment Mode'}/>
                            <DLText id={'payment-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.paymentMechanism?.description}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'bank-name-&-account-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Bank Name & Account Number'}/>
                            {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3'>
                            <DLText id={'RTGS/IFSC-code'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'RTGS/IFSC Code'}/>
                            <DLText id={'RTGS/IFSC-code-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.bankAccount?.IFSCCode}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderBankNameAndAccountNumberUi = (bankAccountDetails) => {
        if (isEmpty(bankAccountDetails?.bankName) && isEmpty(bankAccountDetails?.accountNumber)) {
            return "-"
        }
        return (
            <div>
                <DLText id={'bank-name-&-account-number-value'}
                        fontSize={'sm'}
                        marginBottom={"none"}
                        fontWeight={"normal"}
                        isInline={true}
                        text={bankAccountDetails?.bankName}/>
                <DLText id={'bank-name-&-account-number-value'}
                        fontSize={'sm'}
                        marginBottom={"none"}
                        fontWeight={"normal"}
                        isInline={true}
                        text={bankAccountDetails?.accountNumber}/>
            </div>
        )
    }

    const renderInvestmentDetails = () => {
        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded p-3 h-100">
                    <div className='d-flex justify-content-between'>
                        <DLText
                            id={'investment-amount-title'}
                            type={'normal'}
                            text={'Investment Amount : ' + handleAmount(txnData?.investmentAmount)}
                            fontSize={'lg'}
                            fontWeight={'semi-bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                        />
                    </div>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-center'>
                            <DLText id={''}
                                    text={'No schemes have been added for the funds'}
                                    fontWeight={'normal'}
                                    fontSize={"sm"}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded p-3 ">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'investment-amount-title'}
                        type={'normal'}
                        text={'Investment Amount : ' + handleAmount(txnData?.investmentAmount)}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>
                <div className='pt-3'>
                    <div className='p-0 w-100 border-rounded panel h-100'>
                        <div className="p-2 h-100 c">
                            {renderHeadings()}
                            {renderData()}
                            {renderTotalCount()}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderHeadings = () => {
        return (
            <div className={"py-2 border-bottom row  px-0 m-0"}>
                <div className='data-card-label col-4' style={{}}>{"Funds"}</div>
                <div className='data-card-label col-4' style={{}}>{"Folio No"}</div>
                <div className='data-card-label col-2 text-end' style={{maxWidth: "150px"}}>{"Allocation"}</div>
                <div className='data-card-label col-2 text-end' style={{maxWidth: "150px"}}>{"Amount"}</div>
            </div>
        )
    };

    const renderTotalCount = () => {
        return (
            <div className={"py-2 border-top row px-0 m-0"}>
                <div className='data-card-label col-4'/>
                <div className='data-card-label col-4'/>
                <div className='data-card-label col-2 text-end'
                     style={{maxWidth: "150px"}}>{isEmpty(txnData?.totalAllocationPercentage) ? "-" : txnData?.totalAllocationPercentage + "%"}</div>
                <div className='data-card-label col-2 text-end'
                     style={{maxWidth: "150px"}}>{isEmpty(txnData?.totalAllocationAmount) ? "-" : handleAmount(txnData?.totalAllocationAmount)}</div>
            </div>
        )
    };

    const renderData = () => {
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "" : "border-bottom"}>
                            <div className={"py-2 row px-0 m-0"}
                                 key={index}>
                                <div className='data-card-label col-4'>
                                    <DLText id={''}
                                            text={item?.productName}
                                            fontWeight={"semi-bold"}
                                            fontSize={"xs"}
                                    />
                                </div>
                                <div className='data-card-label col-4'>
                                    <DLText id={''}
                                            text={item?.folioNumber}
                                            fontWeight={"normal"}
                                            fontSize={"xs"}
                                    />
                                </div>
                                <div className='data-card-label col-2 text-end' style={{maxWidth: "150px"}}>
                                    <DLText id={''}
                                            text={item?.allocation + "%"}
                                            fontWeight={"normal"}
                                            fontSize={"xs"}
                                    />
                                </div>
                                <div className='data-card-label col-2 text-end' style={{maxWidth: "150px"}}>
                                    <DLText id={''}
                                            text={handleAmount(item?.amount)}
                                            fontWeight={"normal"}
                                            fontSize={"xs"}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderButtons = () => {
        return (
            <div className="sticky-bottom-buttons gap-0">
                {!isEmpty(txnData?.totalAllocationAmount)
                &&
                <div className='d-flex justify-content-end align-items-center me-2 gap-1'>
                    <DLText id={''}
                            text={'Current payment amount'}
                            fontSize={"sm"}
                            fontWeight={"normal"}
                            fontColor={"grayDark"}
                            marginBottom={"none"}
                    />
                    <DLText id={''}
                            text={'Rs.' + handleAmount(txnData?.totalAllocationAmount)}
                            fontSize={"lg"}
                            fontWeight={"bold"}
                            marginBottom={"none"}
                    />
                </div>}
                <DLButton
                    id={'reject-Btn'}
                    history={props.history}
                    label={'Reject'}
                    buttonSize={"sm"}
                    type={"danger"}
                    fontSize={"sm"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleOpenRejectTransactionModal()
                    }}
                />
                <DLButton
                    id={'approve-Btn'}
                    history={history}
                    label={'Approve'}
                    buttonSize={"sm"}
                    fontSize={"sm"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleOpenApproveTransactionModal()
                    }}/>
            </div>
        )
    };

    const renderDetails = () => {
        return (
            <div className='h-100'>
                <div className='d-flex flex-column h-100'>
                    <div className='mb-2 flex-grow-1'>
                        {renderInvestmentDetails()}
                    </div>
                    <div className='mb-5'>
                        {renderPaymentDetails()}
                    </div>
                </div>
            </div>
        )
    };

    const renderDesktopUI = () => {
        return (
            <div className='d-flex flex-column bg-light h-100' style={{minHeight: 'calc(100dvh)'}}>
                {renderDesktopHeader()}
                <div className='page-container flex-grow-1 bg-light'>
                    {renderDetails()}
                </div>
                {renderButtons()}
            </div>
        );
    };

    const renderMobileUI = () => {
        return (
            <div>
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader || isEmpty(UIState)}/>);
    };

    if (isEmpty(UIState)) {
        return renderLoader()
    }

    if (!isEmpty(transactionStatus)) {
        return (
            <div className='h-100'>
                <ApprovedOrRejected {...props} type={transactionStatus}/>
            </div>
        )
    }

    return (
        <div>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}
            {renderLoader()}
            {renderApproveTransactionModal()}
            {renderRejectTransactionModal()}
        </div>
    );
};

export default ClientsApprovalView;