import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'FTReducer',
    initialState: {
        FTFilter: {
            FTkeyword: '',
            FTstatus: ['ALL'],
            FTCRMId: ['ALL'],
            FTtitle: '',
            FTskipCount: 0,
            FTlimitCount: 50,
            FTpage:0,
            FTrowsPerPage: 50
        },
        CRMDetails: []
    },
    reducers: {
        setFTSearchKeywordValue (state,action) {
            state.FTFilter.FTkeyword = action.payload;
        },

        setFTStatusValue (state,action) {
            state.FTFilter.FTstatus = action.payload;
        },
        
        setFTCRMIdValue(state, action) {
            state.FTFilter.FTCRMId = action.payload;
        },

        setFTSkipCountValue(state,action){
            state.FTFilter.FTskipCount = action.payload;
        },

        setFTLimitCountValue(state,action){
            state.FTFilter.FTlimitCount = action.payload;
        },

        setFTPageValue(state,action){
            state.FTFilter.FTpage = action.payload;
        },

        setFTRowsPerPageValue(state,action){
            state.FTFilter.FTrowsPerPage = action.payload;
        },

        resetFTAllFilters (state) {
            state.FTFilter = {
                ...state.FTFilter,
                FTkeyword: '',
                FTstatus: ['ALL'],
                FTCRMId: ['ALL'],
                FTtitle: '',
                FTskipCount: 0,
                FTlimitCount: 50,
                FTpage:0,
                FTrowsPerPage: 50
            };
        },
        setCRMDetails(state,action) {
            state.CRMDetails = action.payload;
        },
    }
});

export const {setFTSearchKeywordValue, resetFTAllFilters, setFTRowsPerPageValue, setFTPageValue,
    setFTLimitCountValue,setFTSkipCountValue, setFTStatusValue, setFTCRMIdValue} = appSlice.actions;

export default appSlice.reducer

