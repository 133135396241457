import React from 'react';
import "./LeadDetailsCss.css"
import "../../../assets/css/listing.css"
import {isEmpty} from "../../../core/components/DLComponentHelper";
import {convertUTCToLocalTime} from "../../../utils/helper";
import {renderLeadDetailsStatusBadge} from "../../LeadsHelper";

import DLText from "../../../core/components/DLText/DLText";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import LeadsLost from "../leadsLost/LeadsLost";
import LeadsOnHold from "../leadsOnHold/LeadsOnHold";
import AddRemark from "../LeadsAddRemark/AddRemark";
import HistoryBlock from "../../../core/components/historyBlock/HistoryBlock";
import AssignCRM from "../assignCRM/AssignCRM";
import ConfirmationModel from "./component/ConfirmationModel";
import DeleteLead from "../deleteLead/DeleteLead";
import DLMenuOptions from "../../../core/components/DLMenuOptions/DLMenuOptions";
import appTheme from "../../../assets/appTheme";

const LeadsDetailsView = (props) => {
    const {
        isMobileView, leadName, leadEmail, leadPhone, leadStatus, leadAssignedDate, getLeadSource, leadReferredBy,
        openLostModal, isShowLoader, getDetails, isOpenLostModal, isOpenOnHoldModal, closeLostModal,
        openOnHoldModal, closeOnHoldModal, getLeadId, handleOpenAddRemarkModal, handleHistoryBlockRefresh, handleOpenAssignCRMModal,
        handleConfirmationModal, isContacted, UIState, onboardingApplicationId
    } = props;

    const renderLostLeadModal = () => {
        if (isOpenLostModal) {
            return (
                <LeadsLost
                    handleHistoryBlockRefresh={handleHistoryBlockRefresh}
                    history={props.history}
                    getLeadId={getLeadId}
                    isOpenLostModal={isOpenLostModal}
                    openLostModal={openLostModal}
                    closeLostModal={closeLostModal}
                    getDetails={getDetails}/>
            );
        }
    };

    const renderOnHoldLeadModal = () => {
        if (isOpenOnHoldModal) {
            return (
                <LeadsOnHold
                    handleHistoryBlockRefresh={handleHistoryBlockRefresh}
                    history={props.history}
                    getLeadId={getLeadId}
                    isOpenOnHoldModal={isOpenOnHoldModal}
                    openOnHoldModal={openOnHoldModal}
                    closeOnHoldModal={closeOnHoldModal}
                    getDetails={getDetails}
                    leadStatus={leadStatus}
                />
            );
        }
    };

    const renderAddRemarkModal = () => {
        const {isAddRemarkModalOpen} = props;

        if (isAddRemarkModalOpen) {
            return (
                <AddRemark {...props}
                />
            )
        }

    };

    const renderAssignCRMModal = () => {
        const {isAssignCRMModalOpen, onSaveSuccess} = props;
        const leadId = getLeadId();

        if (isAssignCRMModalOpen) {
            return (
                <AssignCRM {...props}
                           selectedIds={[leadId]}
                           onSaveSuccess={onSaveSuccess}
                />
            )
        }

    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader || isEmpty(UIState)}/>);
    };

    const renderDesktopHeader = () => {
        return (
            <div className='w-100'>
                <div className='page-header d-flex justify-content-between'>
                    <div className="d-flex align-items-center">
                        <i className='fa-solid fa-chevron-left cursor fa-2xs'
                           style={{width: 10,
                               paddingTop:isMobileView ? '0px' : "3px"}}                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className="ps-2">
                            Lead
                        </div>
                    </div>

                </div>
                <div className='d-flex align-items-center mb-3 page-container '>
                    <div className='pe-2 border-end-black'>
                        <DLText id={'lead-name'}
                                text={leadName}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='px-2 border-end-black cursor'>
                        <DLText id={'lead-email'}
                                fontWeight={"semi-bold"}
                                text={isEmpty(leadEmail) ? '-' :
                                    <a href={"mailto:" + leadEmail}
                                       className='link-primary'
                                       style={{textDecoration: 'underline', fontSize: '14px'}}>{leadEmail}</a>
                                }
                                marginBottom={"none"}
                                isClickable={true}
                        />
                    </div>
                    <div className='px-2 border-end-black cursor'>
                        <DLText id={'lead-phone'}
                                fontWeight={"semi-bold"}
                                text={isEmpty(leadPhone) ? '-' :
                                    <a href={"tel:" + leadPhone}
                                       className='link-primary '
                                       style={{fontSize: '14px'}}>{leadPhone}</a>
                                }
                                marginBottom={"none"}
                                isClickable={true}
                        />
                    </div>
                    <div className='px-2'>
                        {renderLeadDetailsStatusBadge(leadStatus)}
                    </div>
                </div>
            </div>
        )
    };

    const renderDesktopDetails = () => {
        const {leadId, leadName, handleOpenDeleteLeadModal, leadStatus, getLeadId} = props;
        return (
            <div className="lead-details lead-details-panel h-100">
                <div>
                    <DLText
                        id={'lead-details-title'}
                        type={'normal'}
                        text={'Lead Details'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>

                <div className='h-100 d-flex'>
                    <div className='d-flex flex-column flex-grow-1'>
                        <div className='pt-3'>
                            <div className='d-flex gap-4 py-2'>
                                <div style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={'Assigned Date'}
                                        fontColor={'grayDark'}
                                    />
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        text={(leadAssignedDate === '-') ? '-' : convertUTCToLocalTime(leadAssignedDate, 'DD-MM-YYYY')}
                                    />
                                </div>
                            </div>

                            <div className='d-flex gap-4 py-2'>
                                <div style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={'Source'}
                                        fontColor={'grayDark'}
                                    />
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        text={getLeadSource()}
                                    />
                                </div>
                            </div>

                            <div className='d-flex gap-4 py-2'>
                                <div style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={'Referred By'}
                                        fontColor={'grayDark'}
                                    />

                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        text={leadReferredBy}
                                    />

                                </div>
                            </div>
                        </div>
                        {
                            (leadStatus === 'ONBOARDING_INITIATED' && !isEmpty(onboardingApplicationId))
                                &&
                        <div className='flex-grow-1 d-flex align-items-end w-100 mb-5'>
                            <div className='w-100'>
                                <div className='bg-light'  style={{border: "1px solid #CFCFCF", borderRadius : '8px', backgroundColor: appTheme.appColor.lightBlue}}>
                                    <div className="d-flex justify-content-between p-3">
                                        <div>
                                            <DLText id={''}
                                                    text={'Onboarding is initiated for this lead'}
                                                    fontSize={'sm'}
                                                    fontWeight={"normal"}
                                            />
                                        </div>
                                        <div>
                                            <u style={{textDecorationColor: appTheme.linkStyle.color}}>
                                                <DLText id={''}
                                                        text={'View Onboarding'}
                                                        fontSize={"sm"}
                                                        isClickable={true}
                                                        fontWeight={"semi-bold"}
                                                        onClick={()=>{
                                                            props.history.push('/onboardings/'+onboardingApplicationId)
                                                        }}
                                                />
                                            </u>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>

                {props.profile?.isManagement &&
                <div>
                    <div className="sticky-bottom-buttons gap-0">
                        <DLButton
                            id={"Remove-button"}
                            sideMargin={'md'}
                            styleType={"outlined"}
                            type={"danger"}
                            onClick={() => {
                                handleOpenDeleteLeadModal(leadId, leadName);
                            }}
                            label={'Remove'}
                        />
                        <DLButton
                            id={"assign-to-CRM-button"}
                            sideMargin={'md'}
                            styleType={"contained"}
                            onClick={() => {
                                handleOpenAssignCRMModal();
                            }}
                            label={'Assign to CRM'}
                        />
                    </div>
                </div>
                }

                {props.profile.isCRM &&
                <div className="sticky-bottom-buttons gap-0">
                    {(leadStatus !== 'LOST') && <DLButton id={"lost-button"}
                                                          sideMargin={'md'}
                                                          styleType={"outlined"}
                                                          type={"danger"}
                                                          isDisabled={leadStatus === 'ONBOARDING_INITIATED'}
                                                          onClick={() => {
                                                              openLostModal()
                                                          }}
                                                          label={'Lost'}
                    />}
                    <DLButton
                        id={"on-hold-button"}
                        sideMargin={'md'}
                        styleType={"outlined"}
                        type={"black"}
                        isDisabled={leadStatus === 'ONBOARDING_INITIATED'}
                        onClick={() => {
                            openOnHoldModal()
                        }}
                        label={'On Hold'}
                    />
                    <DLButton id={'add-a_remark-button'}
                              styleType={"outlined"}
                              type={"black"}
                              sideMargin={'md'}
                              padding={'none'}
                              onClick={() => {
                                  handleOpenAddRemarkModal();
                              }}
                              label={'Add a Remark'}
                    />
                    {!isContacted && <DLButton id={'add-a_remark-button'}
                                               styleType={"outlined"}
                                               type={"black"}
                                               sideMargin={'md'}
                                               padding={'none'}
                                               isDisabled={leadStatus === 'ONBOARDING_INITIATED'}
                                               onClick={() => {
                                                   handleConfirmationModal(true);
                                               }}
                                               label={'Mark as Contacted'}
                    />}
                    <DLButton
                        id={"initiate-onboarding-button"}
                        sideMargin={'md'}
                        styleType={"contained"}
                        isDisabled={leadStatus === 'ONBOARDING_INITIATED'}
                        onClick={() => {
                            props.history.replace('/onboardings/new?leadId=' + props.leadId, {leadId: props.leadId})
                        }}
                        label={'Initiate Onboarding'}
                    />

                </div>}
            </div>
        )
    };

    const renderDesktopLeadData = () => {
        return (<div className="pb-4">
            <div className=" row">
                <div className='col-6'>
                    {renderDesktopDetails()}
                </div>
                <div className=' col-6'>
                    <HistoryBlock {...props}/>
                </div>
            </div>
        </div>)
    };

    const renderDesktopUI = () => {
        return (
            <div className='lead-details-container '>
                {renderDesktopHeader()}
                <div className='lead-page-container page-container bg-light mx-3'>
                    {renderDesktopLeadData()}
                </div>
            </div>
        );
    };

    const renderMobileDetails = () => {
        return (
            <div className='lead-details-mobile'>
                <div className='lead-details-mobile-container'>
                    <DLText
                        id={'lead-details-assigned'}
                        type={'normal'}
                        text={'Assigned Date'}
                        fontColor={'grayDark'}
                        marginBottom={'sm'}
                    />
                    <DLText
                        id={'lead-details-assigned'}
                        type={'normal'}
                        text={(leadAssignedDate === '-') ? '-' : convertUTCToLocalTime(leadAssignedDate, 'DD-MM-YYYY')}
                    />
                </div>

                <div className='d-flex align-items-center justify-content-between lead-details-mobile-container my-3 '>
                    <div>
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            text={'Source'}
                            fontColor={'grayDark'}
                            marginBottom={'sm'}
                        />
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            text={getLeadSource()}
                        />
                    </div>
                    <div className='text-start'>
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            text={'Referred By'}
                            fontColor={'grayDark'}
                            marginBottom={'sm'}
                        />
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            text={leadReferredBy}
                        />
                    </div>
                </div>

                {
                    (leadStatus === 'ONBOARDING_INITIATED' && !isEmpty(onboardingApplicationId))
                    &&
                    <div className='flex-grow-1 d-flex align-items-end w-100 mt-5 mb-4'>
                        <div className='w-100'>
                            <div className='bg-light'  style={{border: "1px solid #CFCFCF", borderRadius : '8px', backgroundColor: appTheme.appColor.lightBlue}}>
                                <div className="p-3">
                                    <div>
                                        <DLText id={''}
                                                text={'Onboarding is initiated for this lead'}
                                                fontSize={'sm'}
                                                fontWeight={"normal"}
                                        />
                                    </div>
                                    <div>
                                        <u style={{textDecorationColor: appTheme.linkStyle.color}}>
                                            <DLText id={''}
                                                    text={'View Onboarding'}
                                                    fontSize={"sm"}
                                                    isClickable={true}
                                                    marginBottom={"none"}
                                                    fontWeight={"semi-bold"}
                                                    onClick={()=>{
                                                        props.history.push('/onboardings/'+onboardingApplicationId)
                                                    }}
                                            />
                                        </u>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                <div className='border-top'>
                    <HistoryBlock {...props}/>
                </div>

            </div>
        )
    };

    const renderMobileHeader = () => {
        return (
            <div className='page-header'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <i className='fa-solid fa-chevron-left cursor'
                           style={{width: 10}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className='ps-3'>
                            Lead
                        </div>
                    </div>
                    <div>
                        {renderLeadDetailsStatusBadge(leadStatus)}
                    </div>
                </div>
                <div style={{paddingLeft: '28px'}} className='pt-1'>
                    <DLText id={'lead-email'}
                            fontWeight={"semi-bold"}
                            text={leadName}
                            customWrapperStyle={{lineHeight:'25px'}}
                            marginBottom={"none"}
                    />
                    <DLText id={'lead-email'}
                            fontWeight={"semi-bold"}
                            customWrapperStyle={{lineHeight:'25px'}}
                            text={isEmpty(leadEmail) ? '-' :
                                <a href={"mailto:" + leadEmail}
                                   className='link-primary cursor'
                                   style={{fontSize: 14}}>{leadEmail}</a>
                            }
                            marginBottom={"none"}
                            isClickable={true}
                    />
                    <DLText id={'lead-phone'}
                            fontWeight={"semi-bold"}
                            customWrapperStyle={{lineHeight:'25px'}}
                            text={isEmpty(leadPhone) ? '-' :
                                <a href={"tel:" + leadPhone}
                                   className='link-primary cursor'
                                   style={{fontSize: 14}}>{leadPhone}</a>
                            }

                            marginBottom={"none"}
                            isClickable={true}
                    />
                </div>
            </div>
        )
    };

    const getOptionArray = () => {
        let isDisable = leadStatus === 'ONBOARDING_INITIATED';
        let array = [];
        if (leadStatus !== 'LOST') {
            array.push({
            label:
                <span
                    className='d-flex justify-content-between align-items-center gap-2 py-0 w-100'
                    style={{color: isDisable ? appTheme.appColor.disable: ''}}
                    onClick={() => {
                        if (!isDisable) {
                            openLostModal()
                        }
                    }}>
                Lost
                </span>,
            value: 'Lost'
        });}
        array.push({
            label:
                <span
                    className='d-flex justify-content-between align-items-center gap-2 py-0 w-100'
                    style={{color: isDisable ? appTheme.appColor.disable: ''}}
                    onClick={() => {
                        if (!isDisable) {
                            openOnHoldModal()
                        }
                    }}>
                    On Hold
                    </span>,
            value: 'OnHold'
        });
        array.push({
            label:
                <span
                    className='d-flex justify-content-between align-items-center gap-2 py-0 w-100'
                    onClick={() => {
                        handleOpenAddRemarkModal()
                    }}>
                    Add a Remark
                    </span>,
            value: 'AddARemark'
        });

        if (!isContacted) { array.push({
            label:
                <span
                    className='d-flex justify-content-between align-items-center gap-2 py-0 w-100'
                    style={{color: isDisable ? appTheme.appColor.disable: ''}}
                    onClick={() => {
                        if (!isDisable) {
                            handleConfirmationModal(true);
                        }
                    }}>
                    Mark as Contacted
                    </span>,
            value: 'MarkAsContacted'
        });}
        return array;
    };

    const renderMobileLeadData = () => {
        const {leadId, leadName, handleOpenDeleteLeadModal} = props;
        return (
            <div className="pb-4">
                <div className="pt-1 page-container">
                    {renderMobileDetails()}
                </div>
                {props.profile?.isManagement &&
                <div>
                    <div className="sticky-bottom-buttons gap-0">
                        <DLButton
                            id={"Remove-button"}
                            sideMargin={'md'}
                            styleType={"outlined"}
                            type={"danger"}
                            onClick={() => {
                                handleOpenDeleteLeadModal(leadId, leadName);
                            }}
                            label={'Remove'}
                        />
                        <DLButton
                            id={"assign-to-CRM-button"}
                            sideMargin={'md'}
                            styleType={"contained"}
                            onClick={() => {
                                handleOpenAssignCRMModal();
                            }}
                            label={'Assign to CRM'}
                        />
                    </div>
                </div>
                }
                {props.profile.isCRM &&
                <div className="sticky-bottom-buttons" style={{gap: 0}}>
                    <div className='d-flex align-items-center m-0 p-0'>
                        <DLMenuOptions id={'menu-opt'}
                                       anchorUi={
                                           <DLButton
                                           id={"on-hold-button"}
                                           sideMargin={'none'}
                                           styleType={"outlined"}
                                           type={"black"}
                                           isDisabled={leadStatus === 'ONBOARDING_INITIATED'}
                                           onClick={() => {
                                           }}
                                           label={'More'}
                                           padding={"none"}
                                       />}
                                       onSelect={() => {
                                       }}
                                       options={getOptionArray()}
                        />
                    </div>

                    <DLButton id={'on-hold-button'}
                              styleType={"contained"}
                              type={"black"}
                              sideMargin={'none'}
                              isDisabled={leadStatus === 'ONBOARDING_INITIATED'}
                              onClick={() => {
                                  props.history.replace('/onboardings/new?leadId=' + props.leadId, {leadId: props.leadId})
                              }}
                              padding={'none'}
                              label={'Initiate Onboarding'}
                    />
                </div>
                }
            </div>
        );
    };

    const renderDeleteLeadModal = () => {
        const {isMobileView, handleCloseAssignCRMModal, isDeleteLeadModalOpen} = props;

        if (isDeleteLeadModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseAssignCRMModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <DeleteLead {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <DeleteLead {...props}/>
                )
            }
        }

    };

    const renderConfirmationModel = () => {
        return (
            <ConfirmationModel
                {...props}
            />
        );
    };

    const renderMobileUI = () => {
        return (
            <div>
                {renderMobileHeader()}
                {renderMobileLeadData()}
            </div>
        );
    };

    if (isEmpty(UIState)) {
        return renderLoader()
    }
    ;

    return (
        <div>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}
            {renderLostLeadModal()}
            {renderOnHoldLeadModal()}
            {renderLoader()}
            {renderAddRemarkModal()}
            {renderAssignCRMModal()}
            {renderConfirmationModel()}
            {renderDeleteLeadModal()}
        </div>
    );
};

export default LeadsDetailsView;