import React from 'react';
import OnboardingWizard from "./components/onboardingWizard/OnboardingWizard";
import IINVerification from "./components/INNVerification/IINVerification";
import UploadDocuments from "./components/uploadDocuments/uploadDocuments";
import ReferenceDocuments from "./components/referenceDocuments/referenceDocuments";
import DLLoader from "../core/components/DLLoader/DLLoader";

const OnboardingView = (props) => {
    const {isLoaderVisible} = props;

    const renderUI = () => {
        const {uiType, handleClickContinue} = props;

        switch (uiType) {
            case 'IIN_Verification':
                return <IINVerification
                    {...props}
                    handleClickContinue={handleClickContinue}
                />;
            case 'Reference_Documents':
                return <ReferenceDocuments
                    {...props}
                    handleClickContinue={handleClickContinue}
                />;
            case 'Upload_Documents':
                return <UploadDocuments
                    {...props}
                    handleClickContinue={handleClickContinue}/>;
        }
    };

    return (
        <div>
            {renderUI()}
            <DLLoader type={'screen'} isVisible={isLoaderVisible}/>
        </div>
    );
};

export default OnboardingView;