import React from 'react';
import DLButton from "../../../core/components/DLButton/DLButton";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import DLText from "../../../core/components/DLText/DLText";

const UploadDocumentsView = (props) => {
    const {documents, isXSView, onboardingApplicationId} = props;

    const renderLoader = () => {
        const {isShowLoader} = props;
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderHeader = () => {
        return (
            <div className='page-header'>
                Client Onboarding
            </div>
        )
    };

    return (
        <div className={isXSView ? 'bg-white ' : 'bg-light h-100'}>
            {renderHeader()}
            <div className={'page-container'}>
                    <div className={isXSView ? '' : 'bg-white p-3 border-rounded'}
                         style={{
                             minHeight: isXSView ? '' : 'calc(100dvh - 180px)',
                             maxHeight: isXSView ? '' : 'calc(100dvh - 280px)',
                             overflowY: "auto"
                         }}>
                        <div className="mb-3 pb-1">
                            <DLText id={''}
                                    fontColor={'black'}
                                    fontSize={isXSView ? 'sm' : 'lg'}
                                    fontWeight={'semi-bold'}
                                    text={'Before you begin please have following documents available'}
                                    marginBottom={'none'}/>
                            <div className='mt-3 pt-1'>
                                    <ul>
                                        {documents.map((item, index) => (
                                            <li key={'document-'+ index} className={'mb-3'} style={{fontSize:isXSView ? '12px' : '14px'}}>{item}</li>
                                        ))}
                                    </ul>
                            </div>
                        </div>
                    </div>
            </div>
            <div className='sticky-bottom-buttons'>
                <DLButton id={'begin-onboarding-button'}
                          buttonSize={'sm'}
                          fontSize={'sm'}
                          sideMargin={'none'}
                          style={{
                              minWidth: '150px'
                          }}
                          onClick={() => {
                              props.history.replace('/onboardings/new/' + onboardingApplicationId)
                          }}
                          label={'Continue'}/>
            </div>
            {renderLoader()}
        </div>
    );
};

export default UploadDocumentsView;