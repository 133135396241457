import React, {useEffect, useState} from 'react';

import {isEmpty} from "../core/components/DLComponentHelper";
import {APP_CONST} from "../AppComponents/AppConstants";

import {verifyOTPUrl} from "./clientsApprovalAction";
import {connect} from "react-redux";

import ClientsTransactionView from "./ClientsTransactionView";
import DLLoader from "../core/components/DLLoader/DLLoader";

const ClientsTransaction = (props) => {
    const [clientsTransactionState, setClientsTransactionState] = useState({
        isUserVerified: false,
        approvalData: [],
        UIState: "",
        isShowLoader: false,
        responseError: ''
    });

    useEffect(() => {
        let id = getUUID();
        if (!isEmpty(id)) {
            verifyURL(id)
        }
    }, []);

    const getUUID = () => {
        return props?.match?.params?.id
    }

    const verifyURL = (UUID) => {
        setClientsTransactionState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));

        let payload = {
            uuid: UUID
        };

        verifyOTPUrl(payload, true).then(res => {
            if (res?.success) {
                setClientsTransactionState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false,
                    uuid: UUID,
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                }));
            } else {
                setClientsTransactionState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    responseError: res.__error
                }));
            }
        })
    };


    const otpVerified = (userData) => {
        setClientsTransactionState((prevStep) => ({
            ...prevStep,
            isUserVerified: true,
            approvalData: userData
        }));
    };

    const {UIState, isShowLoader} = clientsTransactionState;

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader || isEmpty(UIState)}/>);
    };

    if (isEmpty(clientsTransactionState.UIState)) {
        return renderLoader()
    }

    return (
        <div>
            <ClientsTransactionView {...props}
                                    {...clientsTransactionState}
                                    otpVerified={otpVerified}
            />
        </div>
    );
};


const mapStateToProps = (state) => ({
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(ClientsTransaction);
