import React from 'react';
import DLButton from "../../../../core/components/DLButton/DLButton";
import DLDocUploader from "../../../../core/components/DLDocUploader/DLDocUploader";
import {handleFileError} from "../../../../utils/FileHelper";
import DLLoader from "../../../../core/components/DLLoader/DLLoader";
import {isEmpty} from "../../../../utils/validations";

const SignatureView = (props) => {

    const {isMobileView, isLoaderVisible, onNextButtonClick} = props;

    const renderDocumentUploader = () => {
        const {associateDocument, document, profile} = props;
        return (
            <DLDocUploader
                id={'resource-doc-uploader'}
                history={props.history}
                key={'doc-uploader'}
                label={''}
                boxLabel={'Upload Signature'}
                onChangeValue={(type, value) => {
                    associateDocument(type, value);
                }}
                isShowLabel={false}
                disableHelperMessageHeight={false}
                helperMessage={document.signatureError}
                isRequired={true}
                handleError={(error) => handleFileError(error, 62914560, 1)}
                filePath={document?.filePath}
                uploadedFileName={document?.fileName}
                documentId={document?.id}
            />
        )
    };

    const renderErrorUI = () => {
        const {responseErrors, totalErrors} = props;
        if (isEmpty(responseErrors) || totalErrors === 0) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className='mb-3'>
                {responseErrors.slice(0, 3).map((errorMessage, index) => (
                    <div key={index} className='py-2 align-items-center d-flex message-block'>
                        <div className='error-vertical-line'/>
                        <div className='error-panel'>
                            {errorMessage}
                        </div>
                    </div>
                ))}
                {totalErrors > 3 && (
                    <div className='py-2 align-items-center d-flex'>
                        {`...${totalErrors - 3} More`}
                    </div>
                )}
            </div>
        );
    };

    const renderUi = () => {
        if (isMobileView) {
            return (
                <div className={"mx-3"}>
                    {renderErrorUI()}
                    {renderDocumentUploader()}
                </div>
            )
        }

        return (
            <div>
                {renderErrorUI()}
                <div className={"d-flex mx-3"} style={{gap: "5%"}}>
                    <div style={{flex: 3}}>
                        {renderDocumentUploader()}
                    </div>
                    <div style={{flex: 4}}>
                    </div>
                </div>
            </div>
        )
    };

    const renderButtonUi = () => {
        const{isFromOnboardingFlow, handleClickNext} = props;
        return(
            <div className='sticky-bottom-buttons'>

                {isFromOnboardingFlow ?
                    <>
                        <DLButton
                            id={'cancel-button'}
                            styleType={"outlined"}
                            borderType={'square'}
                            buttonSize={'sm'}
                            fontSize={'sm'}
                            sideMargin={'none'}
                            onClick={() => {
                            }}
                            label={"Save & Close"}
                        />
                        <DLButton id={'delete-button'}
                                  buttonSize={'sm'}
                                  fontSize={'sm'}
                                  sideMargin={'none'}
                                  onClick={() => {
                                      handleClickNext()
                                  }}
                                  label={"Next"}/>
                    </>
                    :
                    <DLButton id={'delete-button'}
                              buttonSize={'sm'}
                              fontSize={'sm'}
                              sideMargin={'none'}
                              onClick={() => {
                                  handleClickNext()
                              }}
                              label={"Save"}/>
                }

            </div>

        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isLoaderVisible}/>);
    };


    return (
        <div style={{overflowY: 'auto', minHeight: 'calc(100dvh - 268px)', maxHeight: 'calc(100dvh - 336px)'}}>
            {renderUi()}
            <div className='bg-light p-0 m-0 w-100'>
                {renderButtonUi()}
            </div>
            {renderLoader()}
        </div>
    );
};

export default SignatureView;