import React, {useEffect, useState} from 'react';
import OnboardingView from "./OnboardingView";
import {isEmpty} from "../utils/validations";

const Onboarding = (props) => {

    const [onboardingState, setOnboardingState] = useState({
        uiType: 'IIN_Verification',
        isLoaderVisible: false,
        onboardingApplicationId: ''
    });


    const handleClickContinue = (uiType, resData) => {
        setOnboardingState((prevState) => ({
            ...prevState,
            isLoaderVisible: true
        }));

        let newUIType = '';
        if (uiType === 'IIN_Verification') {
            // setOnboardingState((prevState) => ({
            //     ...prevState,
            //     onboardingApplicationId: resData?.onboardingApplicationId
            // }));
            if(!isEmpty(resData?.onboardingApplicationId)){
                props.history.replace('/onboardings/new/' + resData?.onboardingApplicationId)
            }
        } else if (uiType === 'Reference_Documents') {
            newUIType = 'Upload_Documents';
        } else if (uiType === 'Upload_Documents') {
            newUIType = 'OnboardingWizard';
        }

        setOnboardingState((prevState) => ({
            ...prevState,
            uiType: newUIType,
            isLoaderVisible: false
        }));
    };

    return (
        <div className={'h-100'}>
            <OnboardingView
                {...props}
                {...onboardingState}
                handleClickContinue={handleClickContinue}/>
        </div>
    );
};

export default Onboarding;