import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLModalView from "./DLModalView";
import DLModalTitle from "./DLModalTitle/DLModalTitle";
import DLModalContainer from "./DLModalContainer/DLModalContainer";
import DLModalActions from "./DLModalActions/DLModalActions";

class DLModal extends Component {

  renderChildrenElements = (elementCategory) => {
    const { children } = this.props;
    const isArray = Array.isArray(children);
    switch (elementCategory) {
      case 'Header':
        if (isArray){
          return children.find(child => child.type === DLModalTitle);
        }
        if(children?.type === DLModalTitle){
          return children;
        }
        break;
      case 'Content':
        if (isArray){
          return children.find(child => child.type === DLModalContainer);
        }
        if(children?.type === DLModalContainer){
          return children;
        }
        break;
      case 'Action':
        if (isArray){
          return children.find((child) => child?.type === DLModalActions);
        }
        if(children?.type === DLModalActions){
          return children;
        }
        break;
      default:
        break;
    }
  };

  render() {

    if(this.props.hidden){
      return null;
    }

    return (
      <>
        <DLModalView
            {...this.props}
            renderChildrenElements={this.renderChildrenElements}
        />
      </>
    );
  }
}

DLModal.propTypes = {
  id: PropTypes.string.isRequired,  // for testing purpose
  open : PropTypes.bool.isRequired,
  onClose : PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOf([
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
  ]),
  ignoreWebBackClick: PropTypes.bool,   // will disable the navigation insertion which closes the popup on back click (android or back browser navigation)
  hidden: PropTypes.bool
};

DLModal.defaultProps = {
  maxWidth:'xs',
  fullWidth:true,
  ignoreWebBackClick: false,
  hidden: false
};


export default DLModal;
