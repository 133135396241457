//noinspection DuplicatedCode,JSUnresolvedVariable,SpellCheckingInspection,JSUnresolvedFunction

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLDocumentUploaderView from './DLDocumentUploaderView';

class DLDocumentUploader extends Component {
  render() {
    if(this.props.hidden){
      return null;
    }
    return (<DLDocumentUploaderView{...this.props}/>);
  }
}

DLDocumentUploader.propTypes = {
  id: PropTypes.string.isRequired,  // for testing purpose
  label: PropTypes.string.isRequired,
  allowedFileTypes: PropTypes.string,
  attachments: PropTypes.array,  // existing attachments OR attachments passed by this component in onChangeValue
  fileNames: PropTypes.array,    // existing filenames
  onChangeValue: PropTypes.func.isRequired, // should be function will pass object as { attachments:[],filenames:[],previewUrl:[] }
  error: PropTypes.bool,
  s3BucketType: PropTypes.string.isRequired,
  rootObjectType: PropTypes.string.isRequired,
  uploadFileLimit: PropTypes.number,   // number of max upload file
  uploadFileSize: PropTypes.number,
  isRequired: PropTypes.bool,
  handleModalOpen:PropTypes.func,
  handleError:PropTypes.func,
  tooltipValue: PropTypes.string, // should be a trans key
  boxLabel: PropTypes.string, // label on box
  isShowLabel: PropTypes.bool,
  hideDeleteIcon: PropTypes.bool, //to hide delete icon
  marginBottom: PropTypes.oneOf([
    'none',
    'xxs',
    'xs',
    'sm',
    'md',
  ]),
  hidden: PropTypes.bool,
  helperMessage: PropTypes.string,
  iconType: PropTypes.string,
  fontSize: PropTypes.string,
  fontColor: PropTypes.string,
  disableHelperMessageHeight: PropTypes.bool,
  boxStyle: PropTypes.object,
  startIcon: PropTypes.element
};

DLDocumentUploader.defaultProps = {
  allowedFileTypes :'image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.xlsx,.xls,.ppt,.pptx',
  attachments: [],
  fileNames:[],
  uploadFileLimit: 20,
  tooltipValue: "ALLOW-FILE-TYPES",
  uploadFileSize: 5500000,
  isShowLabel: true,
  isRequired: false,
  hidden: false,
  marginBottom: 'sm',
  error: false,
  helperMessage:'',
  boxLabel:'Upload File',
  disableHelperMessageHeight: false,
  hideDeleteIcon:false,
  fontSize:'14px',
  fontColor: '#000000',
  startIcon:<i style={{fontSize: '16px', padding: '10px'}} className='fas fa-trash'/>,
  labelFontSize:'xs',
};

export default DLDocumentUploader
