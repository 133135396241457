import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'clientRequestsReducer',
    initialState: {
        clientRequestsFilter: {
            keyword: '',
            CRMId: 'ALL',
            status: ['OPEN','IN_PROGRESS'],
            skipCount: 0,
            limitCount: 50,
            page:0,
            rowsPerPage: 50
        },
        CRMDetails: []
    },
    reducers: {
        setSearchKeywordValue (state,action) {
            state.clientRequestsFilter.keyword = action.payload;
        },

        setStatusValue (state,action) {
            state.clientRequestsFilter.status = action.payload;
        },

        setCRMValue(state, action) {
            state.clientRequestsFilter.CRMId = action.payload;
        },

        setSkipCountValue(state,action){
            state.clientRequestsFilter.skipCount = action.payload;
        },

        setLimitCountValue(state,action){
            state.clientRequestsFilter.limitCount = action.payload;
        },

        setPageValue(state,action){
            state.clientRequestsFilter.page = action.payload;
        },

        setRowsPerPageValue(state,action){
            state.clientRequestsFilter.rowsPerPage = action.payload;
        },

        resetAllFilters (state,action) {
            state.clientRequestsFilter = {
                ...state.clientRequestsFilter,
               keyword:'',
                CRMId: 'ALL',
               status:['OPEN','IN_PROGRESS'],
               skipCount: 0,
               page:0
            };
        },

        setCRMDetails(state, action) {
            state.CRMDetails = action.payload;
        },

    }
});

export const {setStatusValue, setSearchKeywordValue, resetAllFilters, setCRMValue,
    setRowsPerPageValue, setPageValue,setLimitCountValue,setSkipCountValue,setCRMDetails} = appSlice.actions;
export default appSlice.reducer

