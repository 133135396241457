//noinspection DuplicatedCode,JSUnresolvedVariable,SpellCheckingInspection,JSUnresolvedFunction

import React, {Component, createRef} from 'react';
import Dropzone from 'react-dropzone-uploader';
import DLTooltip from "../DLTooltip/DLTooltip";
import DLModal from "../DLModal/DLModal";
import DLModalTitle from "../DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../DLModal/DLModalActions/DLModalActions";
import DLButton from "../DLButton/DLButton";
import DLText from "../DLText/DLText";
import 'react-dropzone-uploader/dist/styles.css';
import {getFontFamily, getRandomId, getTheme, getThemeColor, isEmpty} from "./../DLComponentHelper";
import "./DLDocumentUploaderStyle.css";
import pdfLogo from '../../../assets/img/iconPdf.png';
import xlxLogo from '../../../assets/img/iconXlsx.png';
import pptLogo from '../../../assets/img/iconPpt.jpg';
import docsLogo from '../../../assets/img/iconDocx.png';
import xmlLogo from '../../../assets/img/iconXml.jpg';
import csvLogo from '../../../assets/img/icon-document.png';
import defaultImg from '../../../assets/img/fileIcon.png';

let documentExtensions = ['ppt', 'pptx', 'doc', 'docx', 'pdf', 'xlx', 'xlsx','xls', 'csv'];

const documentUploaderMarginBottoms = {
    'none': '',
    'xxs': 'mb-1',
    'xs': 'mb-2',
    'sm': 'mb-3',
    'md': 'mb-4'
};


class DLDocumentUploaderView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDeleteDialogOpen: false,
            deleteFileIndex: null,
            dropZoneRef: createRef(),
            dropzoneKey: getRandomId(8),
            maxFileUploadLimit: props.attachments.length > 0 ? (props.uploadFileLimit - props.attachments.length) : props.uploadFileLimit,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.attachments.length !== this.props.attachments.length) {
            this.setState({
                dropzoneKey: getRandomId(8),
                maxFileUploadLimit: this.props.attachments.length > 0 ? (this.props.uploadFileLimit - this.props.attachments.length) : this.props.uploadFileLimit,
            })
        }
    }


    handleChangeStatus = ({meta, remove, xhr, file}, status) => {

        const {onChangeValue, attachments, fileNames, handleError} = this.props;

        if (status === 'done') {
            file.previewUrl = meta.previewUrl;
            let object = {
                attachments: attachments.concat([file]),
                fileNames: fileNames.concat([meta.name]),
            };
            onChangeValue(object);

        } else if (status === 'error_file_size') {
            handleError(status);
            remove();
            // showToastMessage('danger', t("TOAST-ERROR-MAX-FILE-SIZE"));
        } else if (status === 'rejected_max_files') {
            handleError(status);
            remove();
            // showToastMessage('danger', t("TOAST-ERROR-MAX-FILES-LIMIT"));
        } else if (status === 'rejected_file_type') {
            handleError(status);
            remove();
            // showToastMessage('danger', t('RESOURCE-TOAST-ERROR-REJECTED-FILE-TYPE'));
        }

    };

    openDeleteDialog = (deleteFileIndex) => {
        const {handleModalOpen} = this.props;

        if (!isEmpty(handleModalOpen)){
            handleModalOpen(true)
        }

        this.setState({
            isDeleteDialogOpen: true,
            deleteFileIndex: deleteFileIndex,
        })
    };

    closeDeleteDialog = (status) => {
        const {handleModalOpen} = this.props;

        if (!isEmpty(handleModalOpen)){
            handleModalOpen(false)
        }
        const {deleteFileIndex, dropZoneRef} = this.state;

        if (status && deleteFileIndex !== null) {


            if (isEmpty(dropZoneRef?.current?.files)) {

            } else {
                let dropzoneFile = dropZoneRef?.current?.files.find((file, index) => index === this.state.deleteFileIndex);
                if (dropzoneFile) {
                    dropzoneFile.remove();
                }
            }


            let newAttachments = this.props.attachments.filter((file, index) => index !== this.state.deleteFileIndex);
            let newFileNames = this.props.fileNames.filter((name, index) => index !== this.state.deleteFileIndex);

            let object = {
                attachments: newAttachments,
                fileNames: newFileNames,
            };

            this.props.onChangeValue(object);

        }

        this.setState({
            isDeleteDialogOpen: false,
            deleteFileIndex: null,

        })
    };


    getFontColor = () => {
        const {fontColor} = this.props;

        let theme = getTheme();
        let color = "";
        switch (fontColor) {
            case 'primary':
                color = theme?.appColor?.primary;
                break;
            case 'secondary':
                color = theme?.appColor?.secondary;
                break;
            case 'success':
                color = theme?.appColor?.success;
                break;
            case 'warning':
                color = theme?.appColor?.warning;
                break;
            case 'danger':
                color = theme?.appColor?.danger;
                break;
            case 'gray':
                color = theme?.appColor?.gray;
                break;
            case 'info':
                color = theme?.appColor?.info;
                break;
            case 'grayLight':
                color = theme?.appColor?.grayLight;
                break;
            case 'white':
                color = theme?.appColor?.white;
                break;
            default:
                color = fontColor;
        }
        return color;
    };

    getIcon = () =>{
        const {iconType} = this.props;

        if (iconType === 'DOC'){
            return  <i style={{fontSize:30}}  className="fa-regular fa-file me-2"/>
        }

        if (iconType === 'IMAGE'){
            return <i style={{fontSize:30}} className="fa-sharp fa-regular fa-image me-2"/>
        }

        if (iconType === 'PDF'){
            return <img src={pdfLogo} alt={''} style={{height:26,width:26}} className='me-2'/>
        }

        return  <i style={{color: '#558A28', fontSize:30}} className="fa-solid fa-file-excel me-2"/>
    };


    getInputContentUI = () => {

        const {attachments, uploadFileLimit,  boxLabel, fontSize} = this.props;

        if (isEmpty(attachments)) {

            return (
                <div className="d-flex align-items-center " style={{textAlign: 'center'}} key={0}>
                     {this.getIcon()}
                    <div style={{color: this.getFontColor(), fontSize:fontSize, fontFamily: getFontFamily()}} className=''> {boxLabel}</div>
                </div>
            )
        }

        if (attachments.length >= uploadFileLimit) {
            return (<div key={'err'} style={{color: 'grey', fontSize:fontSize, fontFamily: getFontFamily()}}> Uploading... </div>)
        }

        return (
            <div style={{textAlign: 'center', width: '40%'}} key={0}>
                {/*<i style={{color: 'grey'}} className="fa fa-image fa-3x rounded-circle  mb-2"/>*/}
                <div style={{
                    backgroundColor: '#003f61',
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize:fontSize,
                    fontFamily: getFontFamily(),
                    borderRadius: 4,
                    padding: 5
                }} className='mb-2 text-capitalize'> {'Add Files'}</div>
            </div>
        )


    };

    generatePreviewContent = (file) => {
        const {uploadFileLimit, fontSize} = this.props;

        if (file.files.length >= uploadFileLimit) {
            return (<div key={0} style={{color: 'grey', fontSize:fontSize, fontFamily: getFontFamily()}}>Uploading...</div>);
        } else {
            return null;
        }
    };


    getFileImage = (extension) => {
        switch (extension) {
            case 'pdf':
                return pdfLogo;
            case 'xlx':
            case 'xls':
            case 'xlsx':
                return xlxLogo;
            case 'ppt':
            case 'pptx':
                return pptLogo;
            case 'doc':
            case 'docx':
                return docsLogo;
            case 'xml':
                return xmlLogo;
            case 'csv':
                return csvLogo;
            default:
                return pdfLogo;
        }

    };

    getImageUrl = (file) => {
        let extension = file?.name.split('.').pop().toLowerCase();
        if (documentExtensions.includes(extension)) {
            return this.getFileImage(extension);
        } else {

            if(isEmpty(file?.previewUrl)){
                return defaultImg;
            } else {
                return file.previewUrl;
            }
        }
    };

    getExternalStyle = () =>{
        const {boxStyle} = this.props;
        let defaultStyle = {
            height: '100px',
            width: '200px',
            // border: 4px dashed #caccce!important;
            borderWidth: '2px',
            // borderStyle: 'dashed',
            borderColor: 'transparent',
            borderRadius: '8px',
            backgroundColor: 'white',

        };

        if(boxStyle){
            return boxStyle
        }else {
            return defaultStyle
        }
    };

    render() {

        const {isDeleteDialogOpen, dropZoneRef, maxFileUploadLimit, dropzoneKey} = this.state;
        const {
            attachments, uploadFileSize, uploadFileLimit, tooltipValue, fontSize, label, isRequired, fileNames, s3BucketType,
            rootObjectType, error, allowedFileTypes, isShowLabel, marginBottom, id, disableHelperMessageHeight,
            helperMessage, remoteAttachmentMessage, style, theme, history, hideDeleteIcon, startIcon, onChangeValue
        } = this.props;
        return (
            <div className={'w-100 ' + documentUploaderMarginBottoms[marginBottom]}>

                {
                    isShowLabel &&
                    <div
                        style={{
                            fontWeight: '400 !important',
                            lineHeight: '1 !important',
                            fontFamily: getFontFamily(),
                            marginBottom: isEmpty(label) ? '0px !important' : '8px !important',
                            ...style
                        }}
                    >
            <span style={{ color: error ? getThemeColor('danger') : ''}}>
              {
                  label
              }
            </span>

                        <span className='mx-2'><DLTooltip id={id + "-tool-tip"}
                                                          tooltipContent={'tooltip'}/></span>

                        {
                            isRequired ?
                                (<span style={{ color: getThemeColor('danger')}}> *</span>) :
                                null
                        }

                    </div>
                }

                <div className='w-100'>

                    {(attachments.length === uploadFileLimit && uploadFileLimit === 1)

                        ? <></>
                        :

                        <div style={this.getExternalStyle()} className='dl-document-uploader mb-1 w-100'>
                            <Dropzone
                                id={id + '-dropzone'}
                                key={'dropzone-' + dropzoneKey}
                                ref={dropZoneRef}
                                inputContent={this.getInputContentUI()}
                                onChangeStatus={this.handleChangeStatus}
                                accept={allowedFileTypes}
                                disabled={attachments.length === uploadFileLimit}
                                maxSizeBytes={uploadFileSize}
                                maxFiles={maxFileUploadLimit === 0 ? 1 : maxFileUploadLimit}
                                multiple={true}
                                canCancel={true}
                                submitButtonDisabled={true}
                                autoUpload={false}
                                PreviewComponent={(e) => {
                                    return this.generatePreviewContent(e)
                                }}
                            />
                        </div>
                    }

                    {
                        attachments.map((file, index) => {
                            return (
                                <div key={'attached-' + index} id={id + '-attached-' + index}
                                     className='d-flex align-items-center p-2' style={{ borderRadius:8}}>
                                    <div className={'uploader-image flex-shrink-0'}
                                         style={{marginLeft: 10, maxHeight: '50px', overflow: 'hidden', alignItems: 'center'}}>
                                        <div className="me-3"
                                             style={{fontSize: 'var(--font-size-md)'}}>
                                            <i className="fa-regular fa-file"/>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between p-2 align-items-center flex-grow-1'>
                                        <div className={'text-break'}
                                             style={{fontSize:fontSize, fontFamily: getFontFamily()}}
                                             id={'fileName-' + index}>
                                            {fileNames[index]}
                                        </div>
                                        <div>
                                            <DLButton
                                                id={id + '-button-' + index}
                                                theme={theme}
                                                type={'icon'}
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    color: 'black'
                                                }}
                                                hidden={hideDeleteIcon}
                                                startIcon={startIcon}
                                                onClick={() =>onChangeValue([])}
                                            />
                                        </div>
                                    </div>
                                </div>)
                        })
                    }
                </div>
                <div style={{minHeight: disableHelperMessageHeight ? '0' : '19px'}}>{helperMessage}</div>
                <DLModal id={id + '-del-dialog'}
                         open={isDeleteDialogOpen}
                         history={history}
                         theme={theme}
                         onClose={this.closeDeleteDialog}>
                    <DLModalTitle id={id + '-DLModal-title'}
                                  history={history}
                                  theme={theme}
                                  title={'Confirmation'}
                                  onClose={() => this.closeDeleteDialog(false)}/>
                    <DLModalContainer id={id + '-DLModal-container'} history={history}
                                      theme={theme}>

                        <div className="mt-4">
                        <DLText
                            id={'document-upload-delete-message'}
                            type={'normal'}
                            theme={theme}
                            customWrapperStyle={{
                                textAlign:'center'
                            }}
                            text={remoteAttachmentMessage ? remoteAttachmentMessage : 'Remove'}
                            alignText={'center'}
                        />
                        </div>

                    </DLModalContainer>
                    <DLModalActions id={id + '-DLModalActions'} history={history}
                                    theme={theme}>
                        <div className="mb-4">
                            <DLButton id={id + '-DLModal-button'} history={history}
                                      style={{minWidth: "90px", minHeight: "30px", padding: "0 16px"}}
                                      theme={theme} sideMargin={'sm'} styleType={'outlined'}
                                      onClick={() => this.closeDeleteDialog(false)}
                                      label={'No'}/>

                            <DLButton history={history}
                                      style={{minWidth: "90px", minHeight: "30px", padding: "0 16px"}}
                                      theme={theme} id={id + '-DLModal-button'} sideMargin={'sm'} type={'primary'}
                                      onClick={() => this.closeDeleteDialog(true)}
                                      label={'Yes'}/>
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    }

}

export default DLDocumentUploaderView;

