import {
    httpDelete,
    httpGet,
    httpPost,
    httpPostV2, removeAuthorizationToken,
    setAuthorizationToken
} from "../utils/httpHelper";
import {userTypes} from "./userTypes";
import store from "../store";
import {userLogout} from "../AppComponents/AppReducer";
import {GetMyProfileData, profileCallFired, resetLoggedOutState} from "./userReducer";
import {showSystemErrorBlock} from "../AppComponents/AppActions";
import {acceptNotification, joinRoom, connect, leaveRoom, disconnect} from "../utils/socket";

// let base_api = 'http://localhost:10217'
let base_api = ''

export const registerUser = (obj) => {
    return httpPost('/service/provisioning/signup/local/request', obj)
};

export const getRegisteredUserEmail = (id) => {
    return httpGet('/service/provisioning/signup/request?id=' + id);
};

export const sendSignUpRequest = (code, signup_id) => {
    return httpPost('/service/provisioning/signup/local/confirm', {
        code: code,
        requestId: signup_id
    });
};

export const resendCodeRequest = (requestId) => {
    return httpPost('/service/provisioning/signup/local/resendVerificationCode', {
        requestId: requestId
    });
};

export const userLogin = (data) => {
    return httpPost('/service/provisioning/signIn/local/operation', data);
};

export const validateEmail = (email) => {
    return httpPost('/service/provisioning/user/local/password/sendResetLink', email)
};

export const resetPassword = (password, userId, code) => {
    return httpPost('/service/provisioning/user/local/password', {
        password: password,
        userId: userId,
        code: code
    });
};

export const validateResetLink = (id) => {
    return httpPost('/service/provisioning/user/local/password/validateResetLink', {code: id})
};

export const setTokenAfterLogin = async (res, rememberMe) => {
    let authResponse = await setAuthorizationToken(res.token, rememberMe);
    return !!authResponse.status;
};

export const getMyProfile = () => {
    return httpGet(base_api+'/service/advisory/advisoryUser/profile')
        .then(res => {
                store.dispatch(profileCallFired());
                if (res.success){
                    store.dispatch(GetMyProfileData(res.data));
                    store.dispatch(afterLoginActions())
                }
                return res;
            }
        ).catch(()=>{
            store.dispatch(showSystemErrorBlock())
        })
};

export const setDrawerWidth = (data) => {
    store.dispatch({
        type: userTypes.SET_DRAWER_WIDTH,
        payload: {
            isDrawerWidth: data
        }
    })
};

export const logoutUser = () => (dispatch) => {
    removeAuthorizationToken();
    leaveRoom();
    disconnect();
    dispatch(userLogout());
    dispatch(resetLoggedOutState());
    return true;
};


export const afterLoginActions = () => () => {
    // STARTS connect the socket
    connect();
    acceptNotification();
    // ENDS connect the socket
};

export const joinApplicationRoom = () => () => {
    // STARTS join the room
    joinRoom();
    // ENDS join the room
};

export const leaveApplicationRoom = () => () => {
    // STARTS leave the room
    leaveRoom();
    // ENDS leave the room
};
