import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import AadhaarView from "./AadhaarView";
import {isEmpty} from "../../../../utils/validations";
import {
    getDocAssociateData,
    getGenderList, getAadhaarData,
    getStateList,
    updateAadharData, disAssociateDocument
} from "../../../OnboardingActions";
import {isAlphabetic, isIncludeSpecialCharacters, validateAge} from "../../../../utils/helper";
import store from "../../../../store";
import {showToastMessage} from "../../../../AppComponents/AppActions";

const Aadhaar = (props) => {
    const initialDocumentsState = [
        {
            documentType: 'AADHAR_CARD',
            documentCategory: 'FRONT',
            originalFileName: '',
            uploadedFileName: '',
            previewImage: '',
            filePath: '',
            id: ''
        },
        {
            documentType: 'AADHAR_CARD',
            documentCategory: 'BACK',
            originalFileName: '',
            uploadedFileName: '',
            previewImage: '',
            filePath: '',
            id: ''
        },
        {
            documentType: 'AADHAR_CARD',
            documentCategory: 'COMPLETE',
            originalFileName: '',
            uploadedFileName: '',
            previewImage: '',
            filePath: '',
            id: ''
        }
    ];
    const initialErrorState = {
        aadhaarInformation: {
            nameAsPerAadhaar: "",
            firstName: "",
            middleName: "",
            lastName: "",
            aadhaarNumber: "",
            dateOfBirth: "",
            gender: ""
        },
        aadhaarAddress: {
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            district: "",
            stateCode: "",
            zipCode: ""
        },
        document: {
            type: '',
            error: ''
        },
    };

    const [aadhaarCardState, setAadhaarCardState] = useState({
        aadhaarInformation: {
            nameAsPerAadhaar: "",
            firstName: "",
            middleName: "",
            lastName: "",
            aadhaarNumber: "",
            dateOfBirth: "",
            gender: ""
        },
        aadhaarAddress: {
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            district: "",
            stateCode: "",
            zipCode: "",
            countryCode: "IND"
        },
        document: [...initialDocumentsState],
        errors: {...initialErrorState},
        dropdownValues: {
            states: [],
            genders: []
        },
        images: [],
        isLoaderVisible: false,
        aadhaarCardType: '',
        selectedScreen: ''
    });

    useEffect(() => {
        getMasterData();
    }, [props.selectedScreenObj?.personId]);

    useEffect(() => {

        const {selectedScreenObj} = props;
        const {errors} = aadhaarCardState;

        if (isEmpty(props.selectedScreenObj)) {
            return;
        }
        let updatedDocuments = mapDocumentData(selectedScreenObj);
        if (!isEmpty(updatedDocuments)) {
            setAadhaarCardState(prevState => ({
                ...prevState,
                document: updatedDocuments,
                errors: {
                    ...errors,
                    ...validateAddhaarCard(selectedScreenObj?.documentTypes[0].documents)
                },
                aadhaarCardType: isEmpty(props.selectedScreenObj?.documentTypes[0].documents) ? 'SINGLE' : props.selectedScreenObj?.documentTypes[0].documents[0].additionalDetails?.aadhaarAttachmentType
            }));
        }
    }, [props.selectedScreenObj.documentTypes]);


    const validateAddhaarCard = (documents) => {
        const {isFromOnboardingFlow} = props;

        if (isFromOnboardingFlow) {
            return {
                document: {
                    type: 'SINGLE',
                    error: ''
                }
            };
        }
        let result = {
            document: {
                type: 'SINGLE',
                error: 'Please upload Aadhaar card '
            }
        };

        if (isEmpty(documents)) {
            return result
        }

        result = {
            document: {
                type: isEmpty(documents) ? 'SINGLE' : documents[0].additionalDetails?.aadhaarAttachmentType,
                error: isEmpty(documents[0].filePath) ? 'Please upload Aadhaar card' : ''
            }
        };

        if (documents[0].additionalDetails?.aadhaarAttachmentType === 'DOUBLE') {

            let typesInDocuments = documents.map(doc => doc.additionalDetails.type);
            const requiredTypes = ["BACK", "FRONT"];

            for (let type of requiredTypes) {
                if (!typesInDocuments.includes(type)) {
                    result.document.type = type;
                    result.document.error = 'Please upload Aadhaar card';
                    return result;
                }
            }
        }

        return result;
    };
    const mapDocumentData = (selectedScreenObj) => {
        if (isEmpty(selectedScreenObj?.documentTypes[0]?.documents)) {
            return [...initialDocumentsState];
        }
        let updatedDocument = [...aadhaarCardState.document];
        selectedScreenObj?.documentTypes[0].documents.forEach(doc => {
            const frontObjIndex = aadhaarCardState.document.findIndex(doc => doc.documentCategory === 'FRONT');
            const backObjIndex = aadhaarCardState.document.findIndex(doc => doc.documentCategory === 'BACK');
            const completeIndex = aadhaarCardState.document.findIndex(doc => doc.documentCategory === 'COMPLETE');
            let imageFilePaths = doc?.imageFilePaths;
            if (doc.additionalDetails?.type === 'FRONT') {
                //update FRONT Obj
                updatedDocument[frontObjIndex].filePath = isEmpty(imageFilePaths) ? '' : imageFilePaths[0];
                updatedDocument[frontObjIndex].fileName = doc.fileName;
                updatedDocument[frontObjIndex]['id'] = doc.id;
            }
            if (doc.additionalDetails?.type === 'BACK') {
                //update BACK Obj
                updatedDocument[backObjIndex].filePath = isEmpty(imageFilePaths) ? '' : imageFilePaths[0];
                updatedDocument[backObjIndex].fileName = doc.fileName;
                updatedDocument[backObjIndex]['id'] = doc.id;
            }
            if (doc.additionalDetails?.type === 'COMPLETE') {
                updatedDocument[completeIndex]['filePath'] = isEmpty(imageFilePaths) ? '' : imageFilePaths[0];
                updatedDocument[completeIndex]['fileName'] = doc.fileName;
                updatedDocument[completeIndex]['id'] = doc.id;
            }

        });

        return updatedDocument
    };

    const getMasterData = () => {
        const {selectedScreenObj, getUplodedDocuments, onboardingApplicationId} = props;

        setAadhaarCardState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));

        getStateList()
            .then(stateRes => {
                if (stateRes?.success) {
                    const stateData = stateRes?.data?.state;
                    getGenderList()
                        .then(genderRes => {
                            if (genderRes?.success) {
                                const genderData = genderRes?.data?.gender;

                                const dropDowns = {
                                    ...aadhaarCardState.dropdownValues,
                                    states: setValuesForDropdown(stateData, "description", "code"),
                                    genders: setValuesForDropdown(genderData, "description", "code")
                                };

                                getAadhaarData(selectedScreenObj?.personId, onboardingApplicationId)
                                    .then(aadharResp => {
                                        if (aadharResp?.success) {
                                            const updatedFPData = getMappedData(aadharResp.data, dropDowns);
                                            getUplodedDocuments(selectedScreenObj?.screenKey, selectedScreenObj?.personId);
                                            setAadhaarCardState(prevState => ({
                                                ...prevState,
                                                ...updatedFPData,
                                                dropdownValues: dropDowns,
                                                isLoaderVisible: false
                                            }));

                                        }
                                    });
                            }
                        })
                }
            })
    };

    const getDownloadImageFileName = (aadhaarInformation, type, aadhaarCardType) => {
        let firstName = aadhaarInformation?.firstName;
        let lastName = aadhaarInformation?.lastName;
        let downloadFileName = '';
        if (!isEmpty(firstName)) {
            downloadFileName = firstName + '_'
        }
        if (!isEmpty(lastName)) {
            downloadFileName = downloadFileName + lastName + '_'
        }
        if (!isEmpty(downloadFileName)) {
            if (aadhaarCardType === 'SINGLE') {
                downloadFileName = downloadFileName + 'AADHAR'
            } else {
                if (type === 'FRONT') {
                    downloadFileName = downloadFileName + 'AADHAR-FRONT'
                }
                if (type === 'BACK') {
                    downloadFileName = downloadFileName + 'AADHAR-BACK'
                }
            }
        }
        return downloadFileName
    };

    //GET CALL MAPPING
    const getMappedData = (response, dropdownVals) => {
        const {aadhaarInformation, errors, aadhaarAddress} = aadhaarCardState;
        const {isFromOnboardingFlow} = props;
        if (isEmpty(response)) {
            let errorsObj = {
                ...errors,
                aadhaarInformation: (isFromOnboardingFlow) ? errors.aadhaarInformation :
                    validateAadhaarInformation({
                        aadhaarInformation: aadhaarInformation,
                        errors: errors.aadhaarInformation
                    }, 'SUBMIT'),
                aadhaarAddress: (isFromOnboardingFlow) ? errors.aadhaarAddress :
                    validateaadhaarAddress({aadhaarAddress: aadhaarAddress, errors: errors.aadhaarAddress}, 'SUBMIT'),
            };

            return {
                errors: {
                    ...errorsObj
                },
                scrollToFieldId: getFirstErrorFieldId(errorsObj)
            };
        }
        let aadhaarInformationObj = mapAadhaarInformation(response, dropdownVals);
        let aadhaarAddressObj = isEmpty(response?.aadhaarAddresses) ? aadhaarCardState.aadhaarAddress : mapaadhaarAddress(response?.aadhaarAddresses[0], dropdownVals);
        return {
            aadhaarInformation: {...aadhaarInformationObj},
            aadhaarAddress: {...aadhaarAddressObj},
            errors: {
                document: {
                    type: '',
                    error: ''
                },
                aadhaarInformation: (isFromOnboardingFlow) ? errors.aadhaarInformation :
                    validateAadhaarInformation({
                        aadhaarInformation: aadhaarInformationObj,
                        errors: errors.aadhaarInformation
                    }, 'SUBMIT'),
                aadhaarAddress: (isFromOnboardingFlow) ? errors.aadhaarAddress :
                    validateaadhaarAddress({
                        aadhaarAddress: aadhaarAddressObj,
                        errors: errors.aadhaarAddress
                    }, 'SUBMIT'),
            },
            scrollToFieldId: getFirstErrorFieldId({
                aadhaarInformation : validateAadhaarInformation({
                    aadhaarInformation: aadhaarInformationObj,
                    errors: errors.aadhaarInformation
                }, 'SUBMIT'),
                aadhaarAddress : validateaadhaarAddress({
                    aadhaarAddress: aadhaarAddressObj,
                    errors: errors.aadhaarAddress
                }, 'SUBMIT'),

            })
        };
    };

    const mapAadhaarInformation = (response, dropdownVals) => {
        return {
            nameAsPerAadhaar: isEmpty(response?.nameAsPerAadhaar) ? '' : response.nameAsPerAadhaar,
            firstName: isEmpty(response?.firstName) ? '' : response.firstName,
            middleName: isEmpty(response?.middleName) ? '' : response.middleName,
            lastName: isEmpty(response?.lastName) ? '' : response.lastName,
            aadhaarNumber: isEmpty(response?.aadhaarNumber) ? '' : response.aadhaarNumber.replace(/ +/g, ""),
            dateOfBirth: isEmpty(response?.dateOfBirth) ? '' : response.dateOfBirth,
            gender: isEmpty(response?.gender) ? '' : getDropdownSelectedObj('genders', response.gender, dropdownVals),
        }
    };

    const mapaadhaarAddress = (aadhaarAddress, dropdownVals) => {
        return {
            addressLine1: isEmpty(aadhaarAddress?.addressLine1) ? '' : aadhaarAddress.addressLine1,
            addressLine2: isEmpty(aadhaarAddress?.addressLine2) ? '' : aadhaarAddress.addressLine2,
            addressLine3: isEmpty(aadhaarAddress?.addressLine3) ? '' : aadhaarAddress.addressLine3,
            city: isEmpty(aadhaarAddress?.city) ? '' : aadhaarAddress.city,
            district: isEmpty(aadhaarAddress?.district) ? '' : aadhaarAddress.district,
            stateCode: isEmpty(aadhaarAddress?.stateCode) ? '' : getDropdownSelectedObj('states', aadhaarAddress.stateCode, dropdownVals),
            zipCode: isEmpty(aadhaarAddress?.zipCode) ? '' : aadhaarAddress.zipCode,
            countryCode: 'IND'
        }
    };

    const getDropdownSelectedObj = (type, selectedValue, dropDownValues) => {
        return dropDownValues[type]?.find(dropdownVal => dropdownVal.value === selectedValue)
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return;
        }

        return data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken]}
        });
    };

    const disAssociateDoc = (updatedCompState, name, type) => {
        let document = updatedCompState?.document;
        const {onboardingApplicationId} = props;
        let updatedDocuments = updatedCompState?.document;

        if (type === "SINGLE") {
            const frontDoc = document.find(doc => doc.documentCategory === "FRONT");
            const backDoc = document.find(doc => doc.documentCategory === "BACK");

            if (frontDoc && frontDoc.id) {
                disAssociateDocument(frontDoc.id, onboardingApplicationId)
                    .then(res => {
                        if (res.success) {
                            updatedDocuments = updatedDocuments.filter(doc => doc.documentCategory !== "FRONT");
                            updatedDocuments.push(
                                {
                                    documentType: 'AADHAR_CARD',
                                    documentCategory: 'FRONT',
                                    originalFileName: '',
                                    uploadedFileName: '',
                                    previewImage: '',
                                    filePath: '',
                                    fileName: '',
                                    id: ''
                                }
                            );
                            if (backDoc && backDoc.id) {
                                disAssociateDocument(backDoc.id, onboardingApplicationId)
                                    .then(res => {
                                        if (res.success) {
                                            updatedDocuments = updatedDocuments.filter(doc => doc.documentCategory !== "BACK");
                                            updatedDocuments.push(
                                                {
                                                    documentType: 'AADHAR_CARD',
                                                    documentCategory: 'BACK',
                                                    originalFileName: '',
                                                    uploadedFileName: '',
                                                    previewImage: '',
                                                    filePath: '',
                                                    fileName: '',
                                                    id: ''
                                                }
                                            );
                                            updatedCompState[name] = type;
                                            updatedCompState.document = updatedDocuments;
                                            setAadhaarCardState(prevStep => ({
                                                ...prevStep,
                                                ...updatedCompState,
                                                errors: {
                                                    ...prevStep.errors,
                                                    document: {
                                                        type: '',
                                                        error: ''
                                                    },
                                                },
                                            }));
                                            return
                                        }
                                    });
                            } else {
                                updatedCompState[name] = type;
                                updatedCompState.document = updatedDocuments;
                                setAadhaarCardState(prevStep => ({
                                    ...prevStep,
                                    ...updatedCompState,
                                    errors: {
                                        ...prevStep.errors,
                                        document: {
                                            type: '',
                                            error: ''
                                        },
                                    },
                                }));
                                return
                            }
                        }
                    });
            } else {
                if (backDoc && backDoc.id) {
                    disAssociateDocument(backDoc.id, onboardingApplicationId)
                        .then(res => {
                            if (res.success) {
                                updatedDocuments = updatedDocuments.filter(doc => doc.documentCategory !== "BACK");
                                updatedDocuments.push(
                                    {
                                        documentType: 'AADHAR_CARD',
                                        documentCategory: 'BACK',
                                        originalFileName: '',
                                        uploadedFileName: '',
                                        previewImage: '',
                                        filePath: '',
                                        fileName: '',
                                        id: ''
                                    }
                                );
                                updatedCompState[name] = type;
                                updatedCompState.document = updatedDocuments;
                                setAadhaarCardState(prevStep => ({
                                    ...prevStep,
                                    ...updatedCompState,
                                    errors: {
                                        ...prevStep.errors,
                                        document: {
                                            type: '',
                                            error: ''
                                        },
                                    },
                                }));
                                return

                            }
                        });
                }
            }


        } else if (type === "DOUBLE") {
            const completeDoc = document.find(doc => doc.documentCategory === "COMPLETE");
            if (completeDoc && completeDoc?.id) {
                disAssociateDocument(completeDoc?.id, onboardingApplicationId)
                    .then(res => {
                        if (res.success) {
                            updatedDocuments = updatedDocuments.filter(doc => doc.documentCategory !== "COMPLETE");
                            updatedDocuments.push(
                                {
                                    documentType: 'AADHAR_CARD',
                                    documentCategory: 'COMPLETE',
                                    originalFileName: '',
                                    uploadedFileName: '',
                                    previewImage: '',
                                    filePath: '',
                                    fileName: '',
                                    id: ''
                                }
                            );
                            updatedCompState[name] = type;
                            updatedCompState.document = updatedDocuments;
                            setAadhaarCardState(prevStep => ({
                                ...prevStep,
                                ...updatedCompState,
                                errors: {
                                    ...prevStep.errors,
                                    document: {
                                        type: '',
                                        error: ''
                                    },
                                },
                            }));
                            return
                        }
                    });
            }
        }
        updatedCompState[name] = type;
        updatedCompState.document = updatedDocuments;
        setAadhaarCardState(prevStep => ({
            ...prevStep,
            ...updatedCompState,
            errors: {
                ...prevStep.errors,
                document: {
                    type: '',
                    error: ''
                },
            },
        }));

    };

    const handleChange = (name, value, fieldType) => {
        if (name === 'scrollToFieldId') {
            setAadhaarCardState(prevStep => ({
                ...prevStep,
                scrollToFieldId: value
            }));
            return
        }
        let updatedCompState = {...aadhaarCardState};
        if (value === null) {
            value = ''
        }

        if (fieldType === "aadhaarInformation") {
            updatedCompState.aadhaarInformation[name] = value;
        } else if (fieldType === "aadhaarAddress") {
            updatedCompState.aadhaarAddress[name] = value;
        } else if (name === "aadhaarCardType") {
            disAssociateDoc(updatedCompState, name, value);
            return
        } else {
            updatedCompState[name] = value;
        }

        const validateObject = validateChanges(updatedCompState, name, fieldType);

        setAadhaarCardState(prevStep => ({
            ...prevStep,
            ...updatedCompState,
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));

    };

    const validateChanges = (componentState, name, fieldType) => {
        const {errors} = componentState;
        let errorObj = errors;

        if (fieldType === 'aadhaarInformation' || isEmpty(fieldType)) {
            errorObj.aadhaarInformation = validateAadhaarInformation(componentState, name);
        }

        if (fieldType === 'aadhaarAddress' || isEmpty(fieldType)) {
            errorObj.aadhaarAddress = validateaadhaarAddress(componentState, name);
        }

        return errorObj;
    };

    const validateAadhaarInformation = (componentState, name) => {
        const {aadhaarInformation, errors} = componentState;
        const {firstName, middleName, lastName, aadhaarNumber, dateOfBirth, gender, nameAsPerAadhaar} = aadhaarInformation;
        let errorObj = {...errors.aadhaarInformation};

        if (name === 'nameAsPerAadhaar' || name === 'SUBMIT') {
            if (isEmpty(nameAsPerAadhaar)) {
                errorObj.nameAsPerAadhaar = 'Please enter "Name as per Aadhaar"'
            } else if ((isIncludeSpecialCharacters(nameAsPerAadhaar) || isAlphabetic(nameAsPerAadhaar))) {
                errorObj.nameAsPerAadhaar = 'Please enter alphabets only'
            } else if (nameAsPerAadhaar?.length > 60) {
                errorObj.nameAsPerAadhaar = 'Please enter 60 alphabets only'
            } else {
                errorObj.nameAsPerAadhaar = ''
            }
            if (name === 'nameAsPerAadhaar') {
                return errorObj
            }
        }

        if (name === 'firstName' || name === 'SUBMIT') {
            if (isEmpty(firstName)) {
                errorObj.firstName = 'Please enter first name'
            } else if (firstName.length > 20) {
                errorObj.firstName = 'Please enter 20 alphabets only'
            } else {
                errorObj.firstName = ''
            }

            if (name === 'firstName') {
                return errorObj
            }
        }

        if (name === 'middleName' || name === 'SUBMIT') {
            if (middleName.length > 20) {
                errorObj.middleName = 'Please enter 20 alphabets only'
            } else {
                errorObj.middleName = ''
            }

            if (name === 'middleName') {
                return errorObj
            }
        }

        if (name === 'lastName' || name === 'SUBMIT') {
            if ((lastName.length > 20) && !isEmpty(lastName)) {
                errorObj.lastName = 'Please enter 20 alphabets only'
            } else {
                errorObj.lastName = ''
            }

            if (name === 'lastName') {
                return errorObj
            }
        }

        if (name === 'aadhaarNumber' || name === 'SUBMIT') {
            if (isEmpty(aadhaarNumber)) {
                errorObj.aadhaarNumber = 'Please enter Aadhaar number'
            } else if (aadhaarNumber.length !== 12) {
                errorObj.aadhaarNumber = 'Aadhaar number must be of 12 digits'
            } else {
                errorObj.aadhaarNumber = ''
            }

            if (name === 'aadhaarNumber') {
                return errorObj
            }
        }

        if (name === 'dateOfBirth' || name === 'SUBMIT') {
            let isMinorApplicant = validateAge(dateOfBirth, 18);
            if (isEmpty(dateOfBirth)) {
                errorObj.dateOfBirth = 'Please enter date of birth'
            } else if (isMinorApplicant) {
                errorObj.dateOfBirth = 'Applicant age must be above 18'
            } else {
                errorObj.dateOfBirth = ''
            }

            if (name === 'dateOfBirth') {
                return errorObj
            }
        }

        if (name === 'gender' || name === 'SUBMIT') {
            if (isEmpty(gender)) {
                errorObj.gender = 'Please select gender'
            } else {
                errorObj.gender = ''
            }

            if (name === 'gender') {
                return errorObj
            }
        }
        return errorObj
    };

    const validateaadhaarAddress = (componentState, name) => {
        const {aadhaarAddress, errors} = componentState;
        const {addressLine1, addressLine2, addressLine3, zipCode, stateCode, city, district} = aadhaarAddress;

        let errorObj = {...errors.aadhaarAddress};

        if ((name === 'addressLine1') || name === 'SUBMIT') {
            if (isEmpty(addressLine1)) {
                errorObj.addressLine1 = "Please enter address line 1"
            } else if (addressLine1.length > 80) {
                errorObj.addressLine1 = "Please enter 80 characters only"
            } else {
                errorObj.addressLine1 = ""
            }
            if (name === 'addressLine1') {
                return errorObj
            }
        }

        if ((name === 'addressLine2') || name === 'SUBMIT') {
            if (addressLine2.length > 50) {
                errorObj.addressLine2 = "Please enter 50 characters only"
            } else {
                errorObj.addressLine2 = ""
            }
            if (name === 'addressLine2') {
                return errorObj
            }
        }

        if ((name === 'addressLine3') || name === 'SUBMIT') {
            if (addressLine3.length > 50) {
                errorObj.addressLine3 = "Please enter 50 characters only"
            } else {
                errorObj.addressLine3 = ""
            }
            if (name === 'addressLine3') {
                return errorObj
            }
        }

        if ((name === 'city') || name === 'SUBMIT') {
            if (isEmpty(city)) {
                errorObj.city = "Please enter city"
            } else if (city.length > 30) {
                errorObj.city = "Please enter 30 characters only"
            } else {
                errorObj.city = ""
            }
            if (name === 'city') {
                return errorObj
            }
        }

        if ((name === 'zipCode') || name === 'SUBMIT') {
            if (isEmpty(zipCode)) {
                errorObj.zipCode = "Please enter pin code"
            } else if (zipCode.length !== 6) {
                errorObj.zipCode = "Please enter 6 characters only"
            } else {
                errorObj.zipCode = ""
            }
            if (name === 'zipCode') {
                return errorObj
            }
        }

        if ((name === 'district') || name === 'SUBMIT') {
            if (isEmpty(district)) {
                errorObj.district = " Please enter district"
            } else if (district?.length > 30) {
                errorObj.district = 'Please enter 30 character only'
            } else {
                errorObj.district = ""
            }
            if (name === 'district') {
                return errorObj
            }
        }

        if ((name === 'stateCode') || name === 'SUBMIT') {
            if (isEmpty(stateCode)) {
                errorObj.stateCode = "Please select state"
            } else {
                errorObj.stateCode = ""
            }
            if (name === 'stateCode') {
                return errorObj
            }
        }

        return errorObj
    };

    const getFirstErrorFieldId = (errors) => {
        let sectionOrderToSort = ["aadhaarInformation", "aadhaarAddress"];
        for (const section of sectionOrderToSort) {
            if (errors[section]) {
                for (const key in errors[section]) {

                    const error = errors[section][key];
                    if (error && typeof error === 'object' && 'label' in error && error.label !== 'type') {
                        return section + '-' + error.label + '-id';
                    }
                    if (typeof error === 'string' && error && key !== 'type' && key !== 'parentType') {
                        return section + '-' + key + '-id';
                    }
                }
            }
        }
        return 'aadhaar-information';
    };

    const checkIfValidateObj = () => {
        const {firstName, aadhaarNumber, dateOfBirth, gender, nameAsPerAadhaar} = aadhaarCardState.aadhaarInformation;
        const {addressLine1, zipCode, stateCode, city, district} = aadhaarCardState.aadhaarAddress;

        if (nameAsPerAadhaar && firstName && aadhaarNumber && dateOfBirth && gender && addressLine1 &&
            zipCode && stateCode && district && city) {
            return true;
        }
        return false;
    };

    const simplifyObjectValues = (input) => {
        // Create a copy of the input object to avoid mutating the original object
        const output = {...input};

        // Iterate through the keys of the object
        for (const key in output) {
            // If the property's value is an object and has a 'value' key, replace it
            if (typeof output[key] === 'object' && output[key] !== null && 'value' in output[key]) {
                output[key] = output[key].value;
            }
        }
        return output;
    };

    const assignResponseError = (response, stateErrors) => {
        const errorResponse = response?.validationErrors;

        let newStateErrors = {...stateErrors};
        let responseErrors = [];

        if (isEmpty(errorResponse)) { //handled state if reponse status is failed
            setAadhaarCardState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...newStateErrors,
                },
                scrollToFieldId: getFirstErrorFieldId(stateErrors),
                isLoaderVisible: false,
                responseErrors: []
            }));
            return
        }

        errorResponse.forEach(error => { //assign each field by section name
            const section = error.section;
            if (!newStateErrors[section]) {
                return;
            }
            if ((error.fields).length > 1) { //add page level validation error
                responseErrors.push(error.message)
            } else {
                if (section === 'document') {
                    newStateErrors[section].error = error.message;
                    newStateErrors[section].type = error.fields[0]
                } else {
                    //assign each field error to respected field and log if invalid field is found
                    error.fields.forEach(field => {
                        newStateErrors[section][field] = error.message;
                    });
                }
            }
        });

        const errors = {
            ...aadhaarCardState.errors,
            ...newStateErrors,
        };
        setAadhaarCardState(prevStep => ({
            ...prevStep,
            errors: errors,
            responseErrors: responseErrors,
            totalErrors: responseErrors.length,
            isLoaderVisible: false,
            scrollToFieldId: isEmpty(responseErrors) ? getFirstErrorFieldId(errors) : 'page-level-errors'
        }));
    };

    const onNextClick = () => {
        const {handleClickNext, isProceedWithErrors, accountId, selectedScreenObj, saveFailureErrorMessage,
            onboardingApplicationId} = props;

        setAadhaarCardState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));

        const validateObject = validateChanges(aadhaarCardState, 'SUBMIT');

        let updatedData = {
            ...aadhaarCardState,
            ...aadhaarCardState.aadhaarInformation
        };
        delete updatedData.errors;
        delete updatedData.dropdownValues;
        delete updatedData.images;
        delete updatedData.aadhaarInformation;
        delete updatedData.isLoaderVisible;
        delete updatedData.scrollToFieldId;
        delete updatedData.responseErrors;
        delete updatedData.totalErrors;
        delete updatedData.document;


        let profileDataObj = {
            ...updatedData,
        };
        profileDataObj = simplifyObjectValues(profileDataObj);
        profileDataObj.aadhaarAddress = simplifyObjectValues(updatedData.aadhaarAddress);
        profileDataObj.accountId = accountId;
        profileDataObj.personId = selectedScreenObj?.personId;
        profileDataObj.onboardingApplicationId = onboardingApplicationId;
        let errorId = '';

        updateAadharData(profileDataObj)
            .then(res => {
                if (res.success) {
                    if (isProceedWithErrors) {
                        handleClickNext();
                        setAadhaarCardState(prevState => ({
                            ...prevState,
                            isLoaderVisible: false
                        }));
                        return
                    }
                    if (isEmpty(res.data?.validationErrors) && checkIfValidateObj()) {
                        handleClickNext();
                        setAadhaarCardState(prevState => ({
                            ...prevState,
                            isLoaderVisible: false
                        }));
                    } else {
                        assignResponseError(res.data, validateObject)
                    }
                } else {
                    store.dispatch(showToastMessage('warning', saveFailureErrorMessage));
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                        errorId = 'page-level-errors'
                    }
                    setAadhaarCardState(prevStep => ({
                        ...prevStep,
                        errors: {
                            ...prevStep.errors,
                            ...validateObject,
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                        scrollToFieldId: errorId
                    }));
                }
            });
    };

    const isTabUI = () => {
        const {isLGView, isMobileView} = props;
        return isMobileView || isLGView
    };

    const handleDeleteFromState = (documentType) => {
        const {document, aadhaarCardType, onboardingApplicationId} = aadhaarCardState;
        const deleteObjType = aadhaarCardType === 'SINGLE' ? 'COMPLETE' : documentType;

        const updatedDocs = document?.map(doc => {
            if (doc.documentCategory === deleteObjType) {
                return {
                    documentType: 'AADHAR_CARD',
                    documentCategory: doc.documentCategory,
                    originalFileName: '',
                    uploadedFileName: '',
                    previewImage: '',
                    filePath: '',
                    id: ''
                }
            }
            return doc
        });

        return updatedDocs
    };

    const associateDocument = (type, response, documentType) => {
        const {selectedScreenObj, onboardingApplicationId} = props;
        const {aadhaarCardType, document, errors} = aadhaarCardState;
        let updatedDocs = document;
        if (type === 'ERROR') {
            setAadhaarCardState(prevStep => ({
                ...prevStep,
                errors: {
                    ...errors,
                    document: {
                        type: documentType,
                        error: response
                    },
                },
            }));
            return;
        }
        if (type === 'DELETE') {
            setAadhaarCardState(prevStep => ({
                ...prevStep,
                responseErrors: [],
                totalErrors: 0,
                document: handleDeleteFromState(documentType)
            }));
            return;
        }

        setAadhaarCardState(prevStep => ({
            ...prevStep,
            isLoaderVisible: true
        }));

        const currentDoc = document.find(doc => {
            if (aadhaarCardType === 'SINGLE') {
                updatedDocs = updatedDocs?.map(doc => {
                    if (doc.documentCategory === 'FRONT' || doc.documentCategory === 'BACK') {
                        return {
                            documentType: 'AADHAR_CARD',
                            documentCategory: doc.documentCategory,
                            originalFileName: '',
                            uploadedFileName: '',
                            previewImage: '',
                            filePath: '',
                            id: ''
                        }
                    }
                    return doc
                });
                return doc.documentCategory === "COMPLETE"
            }
            updatedDocs = updatedDocs?.map(doc => {
                if (doc.documentCategory === 'COMPLETE') {
                    return {
                        documentType: 'AADHAR_CARD',
                        documentCategory: doc.documentCategory,
                        originalFileName: '',
                        uploadedFileName: '',
                        previewImage: '',
                        filePath: '',
                        id: ''
                    }
                }
                return doc
            });
            return doc.documentCategory === documentType
        });

        const associatePayload = {
            type: currentDoc.documentType,
            aadhaarType: currentDoc.documentCategory,
            aadhaarAttachmentType: aadhaarCardType,
            personId: selectedScreenObj?.personId,
            documentRequirementId: selectedScreenObj?.documentRequirementId,
            originalFileName: response?.originalFileName,
            uploadedFileName: response?.uploadedFileName,
            onboardingApplicationId: onboardingApplicationId
        };


        getDocAssociateData(associatePayload)
            .then((res) => {
                if (res.success) {
                    const mappedState = mapAssociateData(res.data, documentType);
                    updatedDocs = updatedDocs.map(doc => {
                        if (aadhaarCardType === 'SINGLE' && doc.documentCategory === currentDoc.documentCategory) {
                            return {
                                ...doc,
                                filePath: (isEmpty(res.data?.imageFilePaths)) ? '' : res.data?.imageFilePaths[0],
                                fileName: res.data?.fileName,
                                id: res.data?.id
                            }
                        }
                        if (doc.documentCategory === documentType && aadhaarCardType === 'DOUBLE') {
                            return {
                                ...doc,
                                filePath: (isEmpty(res.data?.imageFilePaths)) ? '' : res.data?.imageFilePaths[0],
                                fileName: res.data?.fileName,
                                id: res.data?.id
                            }
                        }
                        return doc
                    });

                    setAadhaarCardState(prevStep => ({
                        ...prevStep,
                        ...mappedState,
                        document: updatedDocs,
                        isLoaderVisible: false,
                        responseErrors: [],
                        totalErrors: 0,
                        errors: {
                            ...errors,
                            document: {
                                type: '',
                                error: ''
                            },
                        },
                    }));
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                    }

                    setAadhaarCardState(prevStep => ({
                        ...prevStep,
                        ...initialErrorState,
                        responseErrors: responseErrors,
                        totalErrors: responseErrors?.length,
                        isLoaderVisible: false,
                        errors: {...initialErrorState},
                        document: [...initialDocumentsState],
                    }));
                }
            })
    };

    const mapAssociateData = (response, documentType) => {
        const {errors, dropdownValues, aadhaarInformation, aadhaarAddress} = aadhaarCardState;
        if (isEmpty(response) || isEmpty(response?.documentDetails)) {
            return {}
        }

        let updatedAadhaarInformation = {...aadhaarInformation};
        let updatedAadhaarAddress = {...aadhaarAddress};

        const keysDoc = Object.keys(response?.documentDetails);

        if (!isEmpty(response?.documentDetails) && keysDoc.length > 3) {
            updatedAadhaarInformation = mapAadhaarInformation(response?.documentDetails, dropdownValues)
        }
        if (!isEmpty(response?.documentDetails?.personAddresses)) {
            updatedAadhaarAddress = mapaadhaarAddress(response?.documentDetails?.personAddresses[0], dropdownValues)
        }

        return {
            aadhaarInformation: updatedAadhaarInformation,
            aadhaarAddress: updatedAadhaarAddress,
            errors: {
                ...errors,
                ...mapAadhaarInformationErrors(errors, response?.documentDetails),
                ...mapaadhaarAddressErrors(errors, response?.documentDetails),
                ...mapDocumentErrors(errors, response?.documentDetails, documentType)
            },
        };
    };

    const mapAadhaarInformationErrors = (errors, response) => {

        let aadhaarInformationObj = {...errors.aadhaarInformation};
        if (!isEmpty(response?.nameAsPerAadhaar)) {
            aadhaarInformationObj.nameAsPerAadhaar = ''
        }
        if (!isEmpty(response?.firstName)) {
            aadhaarInformationObj.firstName = ''
        }
        if (!isEmpty(response?.middleName)) {
            aadhaarInformationObj.middleName = ''
        }
        if (!isEmpty(response?.lastName)) {
            aadhaarInformationObj.lastName = ''
        }
        if (!isEmpty(response?.gender)) {
            aadhaarInformationObj.gender = ''
        }
        if (!isEmpty(response?.dateOfBirth)) {
            aadhaarInformationObj.dateOfBirth = ''
        }
        if (!isEmpty(response?.aadhaarNumber)) {
            aadhaarInformationObj.aadhaarNumber = ''
        }

        return {
            aadhaarInformation: aadhaarInformationObj
        };
    };

    const mapaadhaarAddressErrors = (errors, response) => {
        let aadhaarAddressObj = {...errors.aadhaarAddress};
        if(isEmpty(response?.personAddresses)){
            return {
                aadhaarAddress: aadhaarAddressObj
            }
        }

        if (!isEmpty(response?.personAddresses[0]?.addressLine1)) {
            aadhaarAddressObj.addressLine1 = ''
        }
        if (!isEmpty(response?.personAddresses[0]?.addressLine2)) {
            aadhaarAddressObj.addressLine2 = ''
        }
        if (!isEmpty(response?.personAddresses[0]?.addressLine3)) {
            aadhaarAddressObj.addressLine3 = ''
        }
        if (!isEmpty(response?.personAddresses[0]?.city)) {
            aadhaarAddressObj.city = ''
        }
        if (!isEmpty(response?.personAddresses[0]?.stateCode)) {
            aadhaarAddressObj.stateCode = ''
        }
        if (!isEmpty(response?.personAddresses[0]?.zipCode)) {
            aadhaarAddressObj.zipCode = ''
        }
        if (!isEmpty(response?.personAddresses[0]?.district)) {
            aadhaarAddressObj.district = ''
        }

        return {
            aadhaarAddress: aadhaarAddressObj
        };
    };

    const mapDocumentErrors = (error, response) => {

        let documentErrorObj = {AADHAR_CARD: ''};

        if (!isEmpty(response?.AADHAR_CARD)) {
            documentErrorObj.AADHAR_CARD = error.AADHAR_CARD.AADHAR_CARD
        }

        return {
            AADHAR_CARD: documentErrorObj
        };
    };

    const onSaveAndCloseClick = () => {

        const {accountId, selectedScreenObj, handleClickSaveAndClose, saveFailureErrorMessage, onboardingApplicationId} = props;

        setAadhaarCardState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));


        let updatedData = {
            ...aadhaarCardState,
            ...aadhaarCardState.aadhaarInformation
        };
        delete updatedData.errors;
        delete updatedData.dropdownValues;
        delete updatedData.images;
        delete updatedData.aadhaarInformation;
        delete updatedData.isLoaderVisible;
        delete updatedData.scrollToFieldId;
        delete updatedData.responseErrors;
        delete updatedData.totalErrors;
        delete updatedData.document;


        let profileDataObj = {
            ...updatedData,
        };
        profileDataObj = simplifyObjectValues(profileDataObj);
        profileDataObj.aadhaarAddress = simplifyObjectValues(updatedData.aadhaarAddress);
        profileDataObj.accountId = accountId;
        profileDataObj.personId = selectedScreenObj?.personId;
        profileDataObj.onboardingApplicationId = onboardingApplicationId;
        let errorId = '';

        updateAadharData(profileDataObj)
            .then(res => {
                if (res.success) {
                    handleClickSaveAndClose();
                } else {
                    store.dispatch(showToastMessage('warning', saveFailureErrorMessage));

                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                        errorId = 'page-level-errors'
                    }
                    setAadhaarCardState(prevStep => ({
                        ...prevStep,
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                        scrollToFieldId: errorId
                    }));
                }
            });
    };

    return (
        <AadhaarView {...props}
                     {...aadhaarCardState}
                     handleChange={handleChange}
                     onNextClick={onNextClick}
                     isTabUI={isTabUI}
                     associateDocument={associateDocument}
                     onSaveAndCloseClick={onSaveAndCloseClick}
                     getDownloadImageFileName={getDownloadImageFileName}
        />);
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    profile: state.userState.profile,
    accountId: state.onboardingFlowState.accountId,
    onboardingApplicationId: state.onboardingFlowState.onboardingApplicationId
});

export default connect(mapStateToProps, {})(Aadhaar);
