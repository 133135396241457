import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getManagementClientAccountType} from "../../../HomePageActions";
import DashboardWidget from "../../../DashboardWidget";
import {
    resetClientListingFilters,
    setAccountType
} from "../../../../clients/components/clientsListing/clientListingAction";

const ClientAccountType=(props)=> {
    const [accountTypeState, setAccountTypeState] = useState({
        accountTypeData: {},
        isShowLoader: false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {
        setAccountTypeState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getManagementClientAccountType().then(res => {
            if (res.success) {
                setAccountTypeState(prevState => ({
                    ...prevState,
                    accountTypeData: res.data,
                    isShowLoader: false,
                }));
            } else {
                setAccountTypeState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        resetClientListingFilters();
        setAccountType([item.key]);
        props.history.push('/clients');
    };


    return (
        <DashboardWidget
            {...props}
            {...accountTypeState}
            detailsObj={accountTypeState.accountTypeData}
            setFilters={setFilters}
            title={"Account Type"}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(ClientAccountType);
