import React from 'react';
import DLText from "../DLText/DLText";
import DLButton from "../DLButton/DLButton";


function LoggedOutUserView(props) {

    return (
        <div className='d-flex align-items-center justify-content-center w-100'
             style={{minHeight: 'calc(100vh - 164px)'}}>
            <div className=''>
                <div>
                    <DLText text={'Your session has expired'}
                            marginBottom={'md'}
                            fontSize={'xl'}
                            customWrapperStyle={{textAlign: 'center'}}
                            fontWeight={'bold'}
                    />
                    <DLText text={'To continue using, login again.'}
                            marginBottom={'md'}
                            fontSize={'md'}
                            customWrapperStyle={{textAlign: 'center'}}
                    />
                </div>
                <div className='d-flex justify-content-center mt-3'>
                    <DLButton id={''}
                              label={'Log in'}
                              borderType={"square"}
                              buttonSize={"sm"}
                              fontSize={"md"}
                              sideMargin={"none"}
                              onClick={() => {
                                  window.location.href = '/';
                                  props.logoutUser();
                              }}
                    />
                </div>
            </div>
        </div>
    );
}

LoggedOutUserView.propTypes = {};
export default LoggedOutUserView;
