import React from 'react';

import DLModal from "../../../../../core/components/DLModal/DLModal";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../../../core/components/DLButton/DLButton";
import DLModalTitle from "../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLInputTextField from "../../../../../core/components/DLInputTextField/DLInputTextField";
import DLModalActions from "../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLModalContainer from "../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLText from "../../../../../core/components/DLText/DLText";
import DLRadioGroup from "../../../../../core/components/DLRadioGroup/DLRadioGroup";
import {numberWithCommas} from "../../../../../utils/helper";

const AddNewTransactionView = (props) => {

    const {
        history, isAddNewTransactionDialogOpen, handleCloseAddNewTransaction,
        isShowLoader, modalErrorMessage, handleNext, handleChange, transactionOptions, txnOption, errors, isTxnOptionSelected,
        amount, handleInitiate
    } = props;

    const renderModalError = () =>{
        if(modalErrorMessage){
            return(
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderTransactionOptions = () =>{
        return(
            <div className='mb-1 pb-1 align-items-center d-flex message-block'>
                <div>
                    <DLText id={''}
                            text={"Select Transaction Type"}
                            fontColor={errors.txnOptionError !== '' ? 'danger' : 'grayDark'}
                            fontSize={"xs"}
                    />
                    <div style={{marginLeft: '10px'}}>
                        <DLRadioGroup
                            id={'transactionOptions-radioBtn'}
                            options={transactionOptions}
                            label={''}
                            value={txnOption}
                            type={'block'}
                            labelFontSize={'sm'}
                            labelFontColor={'black'}
                            onChangeValue={(value) => {handleChange("txnOption",value)}}
                            error={errors.txnOptionError !== ''}
                            helperMessage={errors.txnOptionError}
                            helperMessageColor={"danger"}
                            disableHelperMessageHeight={true}
                            disableDescriptionMessageHeight={true}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderAmountUi = () =>{
        return(
            <div className='py-2 text-start'>
                <DLInputTextField
                    id={''}
                    label={'Amount'}
                    value={numberWithCommas(amount)}
                    marginBottom={"none"}
                    inputType={"number"}
                    isRequired={true}
                    disableHelperMessageHeight={true}
                    error={errors.amountError !== ""}
                    helperMessage={errors.amountError}
                    hideCharLength={true}
                    isClearable={false}
                    maxCharLength={15}
                    onChangeValue={(value) => {
                        handleChange("amount", value)
                    }}
                />
            </div>
        )
    };

    const renderUi = () =>{
        if(isTxnOptionSelected){
            return(
                <div>
                    {renderAmountUi()}
                </div>
            )
        }
        return(
            <div>
                {renderTransactionOptions()}
            </div>
        )
    };

    const renderButtonsUi = () =>{
        if(isTxnOptionSelected){
            return(
                <div>
                    <DLButton
                        id={'new-transaction-cancelBtn'}
                        history={history}
                        label={'Cancel'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => handleCloseAddNewTransaction()}/>
                    <DLButton
                        id={'new-transaction-initiateBtn'}
                        history={props.history}
                        label={'Initiate'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        sideMargin={"sm"}
                        onClick={() => handleInitiate()}
                    />
                </div>
            )
        }
        return(
            <div>
                <DLButton
                    id={'new-transaction-cancelBtn'}
                    history={history}
                    label={'Cancel'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => handleCloseAddNewTransaction()}/>
                <DLButton
                    id={'new-transaction-assignBtn'}
                    history={props.history}
                    label={'Next'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    sideMargin={"sm"}
                    onClick={() => handleNext()}
                />
            </div>
        )
    };


    const renderModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"xs"}
                    onClose={() => handleCloseAddNewTransaction()}
                    open={isAddNewTransactionDialogOpen}
                    id={'new-transaction-modal'}>
                    <DLModalTitle
                        id={'new-transaction-title'}
                        onClose={() => handleCloseAddNewTransaction()}
                        title={'New Transaction'}/>
                    <DLModalContainer
                        id={'new-transaction-container'}>
                        <div style={{minHeight : '300px'}}>
                            {renderModalError()}
                            <div>
                                {renderUi()}
                            </div>
                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'new-transaction-action'}>
                        <div className='mb-4' style={{paddingTop: '7px'}}>
                            {renderButtonsUi()}
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    const overlayRemove = () => {
        return (
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='p-3'>
                        <DLText
                            id={'new-transaction'}
                            fontSize={'md'}
                            marginBottom={"none"}
                            fontWeight={'semi-bold'}
                            text={'New Transaction'}
                        />
                    </div>
                    <div className='p-3'>
                        <i className="fa-solid fa-xmark cursor default-icon"
                           onClick={() => handleCloseAddNewTransaction()}/>
                    </div>
                </div>
                <div className='px-3'
                     style={{
                         maxHeight: 'calc(100dvh - 190px)',
                         overflow: 'auto',
                     }}>
                    <div className='pb-5'>
                        {renderModalError()}
                        <div className='text-center'>
                            {renderUi()}
                        </div>
                    </div>
                </div>
                <div className='fixed-bottom mb-2'>
                    <div className='mb-2 d-flex justify-content-center bg-white'
                         style={{paddingTop: '7px', minHeight: '43px'}}>
                        {renderButtonsUi()}
                    </div>
                </div>
            </div>
        )
    };

    const renderLoader = () => {
        return (
            <DLLoader type={"screen"} isVisible={isShowLoader}/>
        )
    };

    return (
        <div>
            {renderLoader()}
            {props.isOverlay ? overlayRemove() : renderModal()}
        </div>
    );
};

export default AddNewTransactionView;