import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLModalContainerView from "./DLModalContainerView";

class DLModalContainer extends Component {
  render() {
    return (
        <DLModalContainerView {...this.props} />
    );
  }
}

DLModalContainer.propTypes = {
  id: PropTypes.string.isRequired,  // for testing purpose
  children: PropTypes.any.isRequired,
  padding: PropTypes.oneOf([
    'none',
    'sm',
    'md',
    'lg'
  ]),
};

DLModalContainer.defaultProps = {
  padding: 'md'
}

export default DLModalContainer;
