import React, {Component} from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import makeStyles from '@mui/styles/makeStyles';
import DLTooltip from "../DLTooltip/DLTooltip";
import {getFontColor, getFontFamily, getTheme, getThemeColor, isEmpty, getFontSize } from "./../DLComponentHelper";
import "../../../assets/css/designToken.css"

const switchMarginBottoms = {
  'none': '',
  'xxs': 'mb-1',
  'xs': 'mb-2',
  'sm': 'mb-3',
  'md': 'mb-4'
};



function SwitchInput(props) {
  let themeColor = (props.buttontype === 'danger') ? 'var(--danger)' : 'var(--blue-dark)';

  const useStyles = makeStyles({
    root: {
      width: 32,
      height: 20,
      padding: 0,
      borderRadius: 25,
      alignItems: 'center',
      display: 'flex',
      '&:hover': {
        backgroundColor: 'transparent',
      },


    },
    switchBase: {
      padding: '4px 4px',
      color: 'var(--white)',
      borderColor: themeColor,
      "&$checked": {
        color: "var(--white)"
      }
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',

    },
    track: {
      border: 'none',
      borderRadius: 45,
      backgroundColor: 'var(--gray)',
      opacity: 1,
      "$checked$checked + &": {
        opacity: 1,
        backgroundColor: themeColor
      }
    },
    disabled: {
      '&$disabled + $track': {
        opacity: 1,
        shadow:'none',
        borderColor:'var(--disable)',
        backgroundColor: "var(--disable) !important"
      },
    },
    checked: {
      marginLeft:-8
    },
  });

  const classes = useStyles();

  return (
    <Switch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      disableRipple={true}
      // thumbSwitchedStyle={classes.thumb}
      inputProps={{'aria-label': 'switch'}}
      {...props}
    />
  );
}

class DLSwitchView extends Component {
  getStyle = (fontSize, fontColor) =>{
    return{
      fontFamily: getFontFamily(),
      ...getFontSize(fontSize),
      color: getFontColor(fontColor)
    }
  };

  render() {
    const {isChecked, label, switchPosition, labelFontColor, fontSize, fontColor, labelFontSize, isRequired, onChangeValue, isDisabled,
      marginBottom, tooltipContent, disableHelperMessageHeight, helperMessage, id, buttontype} = this.props;
    let theme = getTheme();
    return (
      <div>

        <div className={'d-flex align-items-center ' + switchMarginBottoms[marginBottom] + (switchPosition === 'end' ? ' justify-content-between ' : '')}>
          <div
            hidden={!label}
            style={{...this.getStyle(fontSize, fontColor)}}
            className={'dl-component-label ' + (switchPosition === 'default' ? 'me-4' : '')}
          >
            {label}
            <span style={{visibility: isRequired ? 'visible' : 'hidden',color: getThemeColor('danger')}}> *</span>
            {!isEmpty(tooltipContent) &&
              <span className={isRequired ? 'ml-3' : ''}>
                <DLTooltip id={id + 'radio-tool-tip'} tooltipContent={tooltipContent}/>
              </span>
            }
            {/*<span className='ml-3'><DLTooltip id={id + 'radio-tool-tip'} tooltipContent={tooltipContent}/></span>*/}
          </div>
          <FormControlLabel
            id={id + '-switch'}
            className={'m-0 position-relative'}
            control={<SwitchInput
              color={'primary'}
              checked={isChecked}
              buttontype={buttontype}
              onChange={(e) => {
                onChangeValue(e.target.checked);
              }}
              disabled={isDisabled}
            />}
            label={''}
            labelPlacement="start"
            disabled={isDisabled}
          />

        </div>
        <div style={{minHeight: disableHelperMessageHeight ? '0' : '19px'}}>{helperMessage}</div>
      </div>

    );
  }
}

export default DLSwitchView;
