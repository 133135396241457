import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'ClientsListingReducer',
    initialState: {
        clientsFilter: {
            keyword: '',
            fromDate: '',
            toDate: '',
            status: ['ALL'],
            category: ['ALL'],
            type: ['ALL'],
            CRMId: ['ALL'],
            location: ['ALL'],
            skipCount: 0,
            limitCount: 50,
            page: 0,
            rowsPerPage: 50
        }
    },
    reducers: {
        setSearchKeywordValue(state, action) {
            state.clientsFilter.keyword = action.payload;
        },

        setFromDateValue(state, action) {
            state.clientsFilter.fromDate = action.payload;
        },

        setToDateValue(state, action) {
            state.clientsFilter.toDate = action.payload;
        },

        setStatusValue(state, action) {
            state.clientsFilter.status = action.payload;
        },

        setCategoryValue(state, action) {
            state.clientsFilter.category = action.payload;
        },

        setAccountTypeValue(state, action) {
            state.clientsFilter.type = action.payload;
        },

        setCRMValue(state, action) {
            state.clientsFilter.CRMId = action.payload;
        },

        setLocationValue(state, action) {
            state.clientsFilter.location = action.payload;
        },

        setSkipCountValue(state, action) {
            state.clientsFilter.skipCount = action.payload;
        },

        setLimitCountValue(state, action) {
            state.clientsFilter.limitCount = action.payload;
        },

        setPageValue(state, action) {
            state.clientsFilter.page = action.payload;
        },

        setRowsPerPageValue(state, action) {
            state.clientsFilter.rowsPerPage = action.payload;
        },

        resetAllFilters(state, action) {
            state.clientsFilter = {
                ...state.clientsFilter,
                keyword: '',
                fromDate: '',
                toDate: '',
                status: ['ALL'],
                category: ['ALL'],
                type: ['ALL'],
                CRMId: ['ALL'],
                location: ['ALL'],
                skipCount: 0,
                page: 0,
            };
        },
    }
});

export const {
    setSearchKeywordValue, setFromDateValue, setStatusValue, setCategoryValue, setAccountTypeValue,
    setToDateValue, setSkipCountValue, setLimitCountValue, resetAllFilters, setPageValue, setRowsPerPageValue, setCRMValue,
    setLocationValue
} = appSlice.actions;
export default appSlice.reducer
