import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../AppComponents/AppConstants";
import store from "../../../store";

import {showToastMessage} from "../../../AppComponents/AppActions";
import {createPortfolio, fundsListing, getPortfolio} from "../../portfolioAction";

import PortfolioCreateView from "./PortfolioCreateView";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

const PortfolioCreate = (props) => {
    const [portfolioCreateState, setPortfolioCreateState] = useState({
        transactions: [],
        listingCount: 0,
        funds: [],
        portfolioName: '',
        errors: {
            portfolioName: ''
        },
        nodeLinks: [],
        handleErrorInComponent : true,
        UIState: '',
        errorInApi: '',
        isShowLoader: false,
        isShowInlineLoader: false,
        modalErrorMessage: '',
        isCancelPopUpRequired: false,
        scrollToFieldId: '',
    });

    useEffect(()=>{
        const {profile} = props;
        let portfolioType = props.location?.state?.requestType;
        let portfolioId = props.location?.state?.portfolioId;
        if(isEmpty(profile)){
            return;
        }
        if(!profile?.isManagement){
            props.history.push('/access-denied');
        }
        if(portfolioType === "EXISTING"){
            getExistingPortfolioData(portfolioId)
        }else {
            setPortfolioCreateState((prevStep) => ({
                ...prevStep,
                UIState: APP_CONST.CONTENT_AVAILABLE
            }));
        }
    },[])

    const getExistingPortfolioData = (portfolioId) => {

        if (isEmpty(portfolioId)) {
            return;
        }

        getPortfolio(portfolioId)
            .then((res) => {
                    if (res.success) {
                        getMapPortfolio(res?.data)
                    } else {
                        setPortfolioCreateState((prevStep) => ({
                            ...prevStep,
                            UIState: APP_CONST.CONTENT_UNAVAILABLE
                        }));
                    }
                }
            );
    };

    const getMapPortfolio = (portfolioData) => {
        const {nodeLinks} = portfolioCreateState;
        if(isEmpty(portfolioData)){
            return;
        }
        let data = portfolioData?.portfolioFunds;
        if(!isEmpty(data)){
            data.map((item)=>{
                nodeLinks.push({
                    index: '',
                    keyword: '',
                    fund: item?.productMaster?.description,
                    fundError: '',
                    percentage: item?.allocationPercentage,
                    percentageError: '',
                    code: item?.productId,
                    isSelected : true
                });
            })
        }

        setPortfolioCreateState((prevState) => ({
            ...prevState,
            nodeLinks: nodeLinks,
            portfolioName: portfolioData?.name,
            UIState: APP_CONST.CONTENT_AVAILABLE
        }));
    };

    const addOneMoreParam = () => {
        const {nodeLinks} = portfolioCreateState;

        nodeLinks.push({
            index: '',
            keyword: '',
            fund: '',
            fundError: '',
            percentage: '',
            percentageError: '',
            code: '',
            isSelected : false
        });
        let modalErrors = validateModalErros("nodeLinks", portfolioCreateState);
        setPortfolioCreateState((prevState) => ({
            ...prevState,
            nodeLinks: nodeLinks,
            modalErrorMessage: modalErrors
        }));
    };

    const handleSearch = (keyword) => {
        const payload = {
            keyword: keyword,
            skipCount: 0,
            limitCount: 30
        };

        if (isEmpty(keyword)) {
            return;
        }

        setPortfolioCreateState(prevState => ({
            ...prevState,
            isShowInlineLoader: true,
        }))

        fundsListing(payload)
            .then((res) => {
                    if (res.success) {
                        setPortfolioCreateState((prevStep) => ({
                            ...prevStep,
                            funds: res?.data?.products,
                            isShowInlineLoader: false
                        }));
                    } else {
                        setPortfolioCreateState((prevStep) => ({
                            ...prevStep,
                            isShowInlineLoader: false,
                            funds: []
                        }));
                    }
                }
            );
    };

    const setFundAllocation = (name, value, targetIndex, code) => {
        const {nodeLinks} = portfolioCreateState;

        if(name === "keyword"){
            nodeLinks[targetIndex][name] = value;
            setPortfolioCreateState((prevState) => ({
                ...prevState,
                nodeLinks: nodeLinks
            }));
            handleSearch(value)
        }
        if(name === "fund"){

            nodeLinks[targetIndex][name] = value;
            nodeLinks[targetIndex]['isSelected'] = true;
            nodeLinks[targetIndex]['code'] = code;
            isAllocationPercentPresent('fund', targetIndex)

        }
        if(name === "percentage"){
            nodeLinks[targetIndex][name] = value;
            isAllocationPercentPresent('percentage', targetIndex);
        }
        else{
            nodeLinks[targetIndex][name] = value;
        }

        setPortfolioCreateState((prevState) => ({
            ...prevState,
            nodeLinks: nodeLinks
        }));

    };

    const handleChange = (name, value) => {
        let updatedState = {...portfolioCreateState};
        updatedState[name] = value;

        const validateObject = validateChanges(name, updatedState);

        setPortfolioCreateState(prevStep => ({
            ...prevStep,
            ...updatedState,
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));
    };

    const validateChanges = (title, compState) => {
        const {portfolioName} = compState;
        let errorObj = {...portfolioCreateState.errors};

        if (title === "portfolioName" || title === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(portfolioName)) {
                errorObj.portfolioName = "Please enter portfolio name";
            } else {
                errorObj.portfolioName = "";
            }
            if (title === 'portfolioName') {
                return errorObj;
            }
        }

        return errorObj;
    };

    const isAllocationPercentPresent = (title, id) => {
        const {nodeLinks} = portfolioCreateState;
        nodeLinks.forEach((item, index)=>{
            if(title === APP_CONST.FORM_SUBMISSION){
                if(isEmpty(item.percentage)){
                    item.percentageError = 'Enter allocation';
                    setPortfolioCreateState(prevState => ({
                        ...prevState,
                        scrollToFieldId: 'allocation-field-'+index,
                    }))

                }if(isEmpty(item.fund)){
                    item.fundError = 'Please select fund.';
                    setPortfolioCreateState(prevState => ({
                        ...prevState,
                        scrollToFieldId: 'search-field-'+index,
                    }))

                }
            }
            if(index === id){
                if(title === 'percentage'){
                    if(isEmpty(item.percentage)){
                        item.percentageError = 'Enter allocation';
                    }else{
                        item.percentageError = '';
                    }
                }
                if(title === 'fund'){
                    if(isEmpty(item.fund)){
                        item.fundError = 'Please select fund.';
                    }else{
                        item.fundError = '';
                    }
                }
            }
        })
        setPortfolioCreateState((prevState) => ({
            ...prevState,
            nodeLinks: nodeLinks
        }));
    };

    const isAllocationPercentNotPresent = () => {
        const {nodeLinks} = portfolioCreateState;
        let flag = false;
        nodeLinks.forEach((item)=>{
            if(isEmpty(item.fund) || isEmpty(item.percentage)){
                flag = true
            }
        })
        return flag;
    };

    const validateModalErros = (title, compState) => {

        const {nodeLinks, portfolioName} = compState;
        let error = '';
        if(title === APP_CONST.FORM_SUBMISSION){
            if (isEmpty(nodeLinks)) {
                return "Please enter funds to the portfolio";
            }
            if(getTotalAllocation() !== 100){
                return "Percentage allocation is not equal to 100%";
            }
        }
        if(title === "nodeLinks"){
            if (isEmpty(nodeLinks) && !isEmpty(portfolioName)) {
                error = "Please enter funds to the portfolio";
            }else {
                error = "";
            }
            if(title === "nodeLinks"){
                return error;
            }
        }
        if(title === "percentageAllocation"){
            if(getTotalAllocation() !== 100){
                error =  "Percentage allocation is not equal to 100%";
            }else {
                error =  "";
            }
            if(title === "percentageAllocation"){
                return error;
            }
        }
        return error;
    };

    const checkIfValidateObj = (validateObj, fieldErrors) => {
        const {portfolioName} = validateObj;
        if (portfolioName || fieldErrors) {
            return false;
        }
        return true;
    };

    const getSelectedFunds = () => {
        const {nodeLinks} = portfolioCreateState;
        let array = []
        nodeLinks.forEach((item)=>{
            if(item.isSelected){
                array.push({productId : item?.code, allocationPercentage : isEmpty(item?.percentage) ? null : item?.percentage})
            }
        })
        return array;
    };

    const handleSave = () => {
        const {handleErrorInComponent, portfolioName} = portfolioCreateState;
        let params = props.location?.state;
        let portfolioType = '';
        let portfolioId = '';
        if(!isEmpty(params)){
            portfolioType = params?.requestType;
            portfolioId = params?.portfolioId;
        }

        const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION, portfolioCreateState);
        const validateModalErrorsObj = validateModalErros(APP_CONST.FORM_SUBMISSION, portfolioCreateState);
        isAllocationPercentPresent(APP_CONST.FORM_SUBMISSION);
        const fieldErrors = isAllocationPercentNotPresent();

        if (!checkIfValidateObj(validateObj, fieldErrors)) {
            setPortfolioCreateState(prevStep => ({
                ...prevStep,
                isApiInProgress: false,
                errors: {
                    ...prevStep.errors,
                    ...validateObj
                }
            }));
            return;
        }

        if (!isEmpty(validateModalErrorsObj)) {
            setPortfolioCreateState(prevStep => ({
                ...prevStep,
                isApiInProgress: false,
                errors: {
                    ...prevStep.errors,
                    ...validateObj
                },
                modalErrorMessage: validateModalErrorsObj
            }));
            return;
        }

        const payload = {
            id: portfolioId,
            name: portfolioName,
            description: "",
            funds: getSelectedFunds()
        };


        setPortfolioCreateState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));

        createPortfolio(payload, handleErrorInComponent, portfolioType)
            .then((res) => {
                    if (res.success) {
                        store.dispatch(showToastMessage('success', 'portfolio created'))
                        setPortfolioCreateState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false
                        }));
                        props.history.push('/portfolio-management');
                    } else {
                        setPortfolioCreateState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false,
                            modalErrorMessage: res?.__error
                        }));
                    }
                }
            );
    };

    const removeLinking = (targetingIndex) => {
        const {nodeLinks} = portfolioCreateState;

        nodeLinks.splice(targetingIndex, 1);

        setPortfolioCreateState((prevState) => ({
            ...prevState,
            nodeLinks: nodeLinks
        }));
    };

    const isCancelConfirmationRequired = () => {
        const {nodeLinks, portfolioName} = portfolioCreateState;
        return !isEmpty(nodeLinks) || !isEmpty(portfolioName);
    };

    const handleCancel = () => {
        if(isCancelConfirmationRequired()){
            setPortfolioCreateState((prevState) => ({
                ...prevState,
                isCancelPopUpRequired: true
            }));
        }else{
            props.history.push('/portfolio-management')
        }
    };

    const handleCloseCancelModal = () => {
        if(isCancelConfirmationRequired()){
            setPortfolioCreateState((prevState) => ({
                ...prevState,
                isCancelPopUpRequired: false
            }));
        }
    };

    const handleRedirect = () => {
        props.history.push('/portfolio-management')
    };

    const getDropdownItem = (id, index) => {
        const {funds} = portfolioCreateState;
        const label = funds?.find(item => item.id === id)?.description || "";
        setFundAllocation("fund", label, index, id)
    }

    const getTotalAllocation = () => {
        const {nodeLinks} = portfolioCreateState;
        let totalPercentage = 0
        nodeLinks.forEach((item)=>{
            let currentValue = totalPercentage;
            let newValue = isEmpty(item?.percentage)? 0 : item?.percentage
            totalPercentage = parseInt(currentValue) + parseInt(newValue);
        })
        return totalPercentage;
    };

    const handleScrollToErrorElement = () => {
        setPortfolioCreateState(prevStep => ({
            ...prevStep,
            scrollToFieldId: ''
        }));
    }

    if (isEmpty(portfolioCreateState.UIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    return (
        <PortfolioCreateView
            {...props}
            {...portfolioCreateState}
            handleSearch={handleSearch}
            addOneMoreParam={addOneMoreParam}
            setFundAllocation={setFundAllocation}
            removeLinking={removeLinking}
            handleChange={handleChange}
            handleSave={handleSave}
            getTotalAllocation={getTotalAllocation}
            isCancelConfirmationRequired={isCancelConfirmationRequired}
            handleCloseCancelModal={handleCloseCancelModal}
            handleCancel={handleCancel}
            handleRedirect={handleRedirect}
            getDropdownItem={getDropdownItem}
            handleScrollToErrorElement={handleScrollToErrorElement}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    NFTFilter: state.NFTState.NFTFilter,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(PortfolioCreate);
