import React from 'react';
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import DLModal from "../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLText from "../../../core/components/DLText/DLText";
import RenderKYCStatusBadge from "./RenderKYCStatusBadge";
import {isEmpty} from "../../../core/components/DLComponentHelper";
import {getFullName} from "../../OnboardingHelper";

const KYCStatusView = (props) => {
    const {
        isKycStatusModelOpen, handleCloseKycStatusModal, isShowLoader, history, lastUpdatedDate, startDate,
        getKycModeDescription, getPersonVerification, selectedKycDetails, isFromIIN, formatDate, isMDView
    } = props;

    let selectedPANNumber = isFromIIN ? selectedKycDetails?.PANNumber : selectedKycDetails?.PAN_Number;

    const getStatusBadge = (status) => {
        return (
            <RenderKYCStatusBadge status={status}/>
        );

    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderKYCDatails = () =>{
        return(
            <div>
                <div className='row mb-4'>
                    <div className='col-4'>
                        <DLText id={'kyc-Summary'}
                                fontSize={'xs'}
                                fontColor={'grayDark'}
                                text={'PAN'}/>
                        <DLText id={'kyc-Summary'}
                                fontWeight={"semi-bold"}
                                text={selectedPANNumber}/>
                    </div>
                    <div className='col-8'>
                        <DLText id={'kyc-Summary'}
                                fontColor={'grayDark'}
                                fontSize={'xs'}
                                text={'Name'}/>
                        <DLText id={'kyc-Summary'}
                                fontWeight={"semi-bold"}
                                text={getFullName(selectedKycDetails.firstName,selectedKycDetails.middleName,selectedKycDetails.lastName)}/>
                    </div>
                </div>
                <div className='row mb-4'>
                    <div className='col-4'>
                        <DLText id={'kyc-Summary'}
                                fontSize={'xs'}
                                fontColor={'grayDark'}
                                text={'KRA'}/>
                        <DLText id={'kyc-Summary'}
                                fontWeight={"semi-bold"}
                                text={selectedKycDetails?.kycDetails?.KRAMasterCode}/>
                    </div>
                    <div className='col-8'>
                        <DLText id={'kyc-Summary'}
                                fontSize={'xs'}
                                fontColor={'grayDark'}
                                text={'KYC Mode'}/>
                        <DLText id={'kyc-Summary'}
                                fontWeight={"semi-bold"}
                                text={getKycModeDescription}/>
                    </div>
                </div>
                <div className='row mb-4'>
                    <div className='col-4'>
                        <DLText id={'kyc-Summary'}
                                fontSize={'xs'}
                                fontColor={'grayDark'}
                                text={'In Person Verification'}/>
                        <DLText id={'kyc-Summary'}
                                fontWeight={"semi-bold"}
                                text={getPersonVerification}/>
                    </div>
                    <div className='col-8'>
                        <DLText id={'kyc-Summary'}
                                fontSize={'xs'}
                                fontColor={'grayDark'}
                                text={'Ultimate Beneficiary Owner '}/>
                        <DLText id={'kyc-Summary'}
                                fontWeight={"semi-bold"}
                                text={selectedKycDetails?.kycDetails?.uboFlagCode}/>
                    </div>
                </div>
                <div className='row mb-4'>
                    <div className='col-4'>
                        <DLText id={'kyc-Summary'}
                                fontSize={'xs'}
                                fontColor={'grayDark'}
                                text={'KYC on Hold Remark'}/>
                        <DLText id={'kyc-Summary'}
                                fontWeight={"semi-bold"}
                                text={selectedKycDetails?.kycDetails?.remarks}/>
                    </div>
                </div>
                <div className='row mb-4'>
                    <div className='col-4'>
                        <DLText id={'kyc-Summary'}
                                fontSize={'xs'}
                                fontColor={'grayDark'}
                                text={'Modification Remark'}/>
                        <DLText id={'kyc-Summary'}
                                fontWeight={"semi-bold"}
                                text={selectedKycDetails?.kycDetails?.modificationRemarks}/>
                    </div>
                </div>
            </div>
        )
    };

    const renderKYCStatus= ()=>{
        return(
            <div className='border-rounded bg-red border p-3 mt-2 bg-light'>
                <div className='row mb-4'>
                    <DLText id={'kyc-Summary'}
                            fontSize={'xs'}
                            fontColor={'grayDark'}
                            text={'KYC Status'}/>
                    <div className={'d-flex'}>{
                        getStatusBadge(selectedKycDetails?.kycDetails?.kycStatus?.description)}
                    </div>
                    {(!isEmpty(selectedKycDetails?.kycDetails?.lastUpdatedDate)) && <DLText id={'kyc-Summary'}
                            fontSize={"xs"}
                            fontColor={"grayDark"}
                            text={'as of '+ formatDate(selectedKycDetails?.kycDetails?.lastUpdatedDate)}/>}
                </div>
                <div className='row'>
                    <DLText id={'kyc-Summary'}
                            fontSize={'xs'}
                            fontColor={'grayDark'}
                            text={'Modification Status'}/>
                    <div className={'d-flex'}>{
                        getStatusBadge(selectedKycDetails?.kycDetails?.kycModificationStatus?.description)}</div>
                    {(!isEmpty(selectedKycDetails?.kycDetails?.latestUpdatedDate)) && <DLText id={'kyc-Summary'}
                                                            fontSize={"xs"}
                                                            fontColor={"grayDark"}
                                                            text={'as of '+ formatDate(selectedKycDetails?.kycDetails?.latestUpdatedDate)}/>}
                </div>
            </div>
        )
    };

    const renderKYCStatusModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"md"}
                    onClose={() =>
                        handleCloseKycStatusModal()
                    }
                    open={isKycStatusModelOpen}
                    id={'kyc-modal'}>
                    <DLModalTitle
                        id={'kyc-title'}
                        onClose={() =>
                            handleCloseKycStatusModal()
                        }
                        title={'KYC Details'}/>
                    <DLModalContainer
                        id={'kyc-container'}>
                        <div className={'row'}>
                            <div className='col-8'>
                                {renderKYCDatails()}
                            </div>
                            <div className='col-4'>
                                {renderKYCStatus()}
                            </div>
                        </div>
                    </DLModalContainer>
                </DLModal>
            </div>
        );
    };


    const overlayKYCStatusModel = () => {
        return <div className='pb-3' >
            <div className='d-flex justify-content-between align-items-center mb-2 border-bottom ps-3 '>
                <div className='py-3'>
                    <DLText
                        id={'select-account'}
                        fontSize={'md'}
                        marginBottom={"none"}
                        fontWeight={'semi-bold'}
                        text={'KYC Details'}
                    />
                </div>
                <div className='p-3'>
                    <i className="fa-solid fa-xmark "
                       style={{fontSize: '16px'}}
                       onClick={() =>
                           handleCloseKycStatusModal()
                       }
                    />
                </div>
            </div>
            <div className='pb-5 px-3 ' style={{maxHeight: 'calc(100dvh - 180px)',overflow:'auto'}}>
                <div className="mb-3">
                    <DLText id={'kyc-Summary'}
                            fontSize={'xs'}
                            fontColor={'grayDark'}
                            text={'PAN'}/>
                    <DLText id={'kyc-Summary'}
                            fontWeight={"semi-bold"}
                            text={selectedPANNumber}/>
                </div>
                <div className="mb-3">
                    <DLText id={'kyc-Summary'}
                            fontColor={'grayDark'}
                            fontSize={'xs'}
                            text={'Name'}/>
                    <DLText id={'kyc-Summary'}
                            fontWeight={"semi-bold"}
                            text={getFullName(selectedKycDetails.firstName,selectedKycDetails.middleName,selectedKycDetails.lastName)}/>
                </div>
                <div className='col-3 mb-3'>
                    <DLText id={'kyc-Summary'}
                            fontSize={'xs'}
                            fontColor={'grayDark'}
                            text={'KRA'}/>
                    <DLText id={'kyc-Summary'}
                            fontWeight={"semi-bold"}
                            text={selectedKycDetails?.kycDetails?.KRAMasterCode}/>
                </div>
                <div className='col-3 mb-3'>
                    <DLText id={'kyc-Summary'}
                            fontSize={'xs'}
                            fontColor={'grayDark'}
                            text={'KYC Mode'}/>
                    <DLText id={'kyc-Summary'}
                            fontWeight={"semi-bold"}
                            text={getKycModeDescription}/>
                </div>

                <div className="mb-3">
                    <DLText id={'kyc-Summary'}
                            fontSize={'xs'}
                            fontColor={'grayDark'}
                            text={'In Person Verification'}/>
                    <DLText id={'kyc-Summary'}
                            fontWeight={"semi-bold"}
                            text={getPersonVerification}/>
                </div>
                <div className="mb-3">
                    <DLText id={'kyc-Summary'}
                            fontSize={'xs'}
                            fontColor={'grayDark'}
                            text={'Ultimate Beneficiary Owner '}/>
                    <DLText id={'kyc-Summary'}
                            fontWeight={"semi-bold"}
                            text={selectedKycDetails?.kycDetails?.uboFlagCode}/>
                </div>
                <div className="mb-3">
                    <DLText id={'remarks'}
                            fontSize={'xs'}
                            fontColor={'grayDark'}
                            text={'KYC on Hold Remark'}/>
                    <DLText id={'kyc-Summary'}
                            fontWeight={"semi-bold"}
                            text={selectedKycDetails?.kycDetails?.remarks}/>
                </div>
                <div className="mb-3">
                    <DLText id={'kyc-Summary'}
                            fontSize={'xs'}
                            fontColor={'grayDark'}
                            text={'Modification Remark'}/>
                    <DLText id={'kyc-Summary'}
                            fontWeight={"semi-bold"}
                            text={selectedKycDetails?.kycDetails?.modificationRemarks}/>
                </div>

                <div>
                    {renderKYCStatus()}
                </div>
            </div>
        </div>
    };


    return (
        <div>
            {renderLoader()}
            {props.isOverlay ? overlayKYCStatusModel() : renderKYCStatusModal()}
        </div>
    );
};

export default KYCStatusView;