import React from 'react';
import ListingForManagement from "./listingForManagement/ListingForManagement";
import ListingForCRM from "./listingForCRM/ListingForCRM";


const OnboardingsListingView = (props) => {
    const {isManagementUser, isSetUserType} = props;

    if(!isSetUserType){
        return ''
    }

    if (isManagementUser) {
        return (
            <div>
                <ListingForManagement {...props}/>
            </div>
        );
    } else {
        return (
            <div className='w-100'>
                <ListingForCRM {...props} />
            </div>
        );
    }
};

export default OnboardingsListingView;