import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import {getTimeFromNow} from "../../../../../utils/helper";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";

import {downloadAndViewForm, eConsent, getIINFormData, regenerate, submitIINData} from "../../FormsAndReviewActions";
import IINFormView from "./IINFormView";

const IINForm = (props) => {

    const {onboardingApplicationId} = props;

    const [IINFormState, setIINFormState] = useState({
        electronicConsent: true,
        formId: '',
        fileName: '',
        filePath: '',
        timeStamp: '',
        isReviewed: false,
        isMarkAsReviewedModalOpen: false,
        isUploadIINFormModalOpen: false,
        isAddRemarkModalOpen: false,
        isViewActivitiesModalOpen: false,
        UIState: '',
        responseErrors: '',
        isShowLoader: false,
        isFormReviewed: false,
        isIINSubmitted: false,
        generatedTime: '',
        uploadedTime: '',
        isManagementUser: false
    });

    useEffect(() => {
        getData()
    }, [props.generatedFormDetails]);

    const getData = () => {

        const {generatedFormDetails, profile} = props;

        if (isEmpty(generatedFormDetails)) {
            setIINFormState(prevState => ({
                ...prevState,
                formId: '',
                fileName: '',
                filePath: '',
                timeStamp: '',
                generatedTime: '',
                uploadedTime: '',
                electronicConsent: true,
                UIState: APP_CONST.CONTENT_AVAILABLE,
                isReviewed: false,
                isManagementUser: profile?.isManagement
            }));
        } else {
            let details = sortIINData(generatedFormDetails);
            setIINFormState((preState) => ({
                    ...preState,
                    formId: details?.formId,
                    fileName: details?.fileName,
                    filePath: details?.filePath,
                    generatedTime: details?.generationTimestamp,
                    uploadedTime: details?.uploadTimestamp,
                    timeStamp: isEmpty(details?.updatedAt) ? '' : getTimeFromNow(details?.updatedAt, 'DD-MM-YYYY'),
                    electronicConsent: details?.eConsent,
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                    isReviewed: details?.isReviewed,
                    isManagementUser: profile?.isManagement
                }
            ))
        }
    };

    const refreshData = () => {
        const {accountId, updateGeneratedForms, onboardingApplicationId} = props;
        setIINFormState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));
        getIINFormData(accountId, onboardingApplicationId).then(res => {
            if (res?.success) {
                if (isEmpty(res.data)) {
                    setIINFormState((preState) => ({
                            ...preState,
                            UIState: APP_CONST.CONTENT_UNAVAILABLE,
                            isShowLoader: false
                        }
                    ))
                } else {
                    let details = sortIINData(res?.data?.accountForms);
                    updateGeneratedForms(res?.data?.accountForms);
                    setIINFormState((preState) => ({
                            ...preState,
                            formId: details?.formId,
                            fileName: details?.fileName,
                            filePath: details?.filePath,
                            isShowLoader: false,
                            generatedTime: details?.generationTimestamp,
                            uploadedTime: details?.uploadTimestamp,
                            timeStamp: isEmpty(details?.updatedAt) ? '' : getTimeFromNow(details?.updatedAt, 'DD-MM-YYYY'),
                            electronicConsent: details?.eConsent,
                            UIState: APP_CONST.CONTENT_AVAILABLE,
                            isReviewed: details?.isReviewed
                        }
                    ))
                }
            } else {
                setIINFormState(prevState => ({
                    ...prevState,
                    formId: '',
                    fileName: '',
                    filePath: '',
                    timeStamp: '',
                    generatedTime: '',
                    uploadedTime: '',
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    isReviewed: false
                }));
            }
        })
    };

    const sortIINData = (obj) => {

        let IINDetails = [];
        if (isEmpty(obj)) {
            return IINDetails;
        }

        IINDetails = obj.find((item) => {
            return item?.formType === "IIN";
        });

        return IINDetails;
    };

    const regenerateForm = () => {
        const {accountId} = props;
        setIINFormState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));

        let payload = {
            accountId: accountId,
            onboardingApplicationId: onboardingApplicationId,
            formDetails: {
                IIN: true
            }
        };

        regenerate(payload).then(res => {
            if (res?.success) {
                setIINFormState((preState) => ({
                        ...preState,
                        isShowLoader: false,
                        responseErrors: ''
                    }
                ));
                refreshData();
            } else {
                setIINFormState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    responseErrors: res?.__error
                }));
            }
        })
    };

    const setEConsent = (value) => {
        const {onboardingApplicationId} = props;

        setIINFormState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));

        let payload = {
            id: IINFormState.formId,
            eConsent: value,
            onboardingApplicationId:onboardingApplicationId
        };

        eConsent(payload).then(res => {
            if (res?.success) {

            }
            setTimeout(() => {
                setIINFormState((preState) => ({
                        ...preState,
                        isShowLoader: false,
                    }
                ))
            }, 500)
        })
    };

    const setElectronicConsent = (value) => {
        setIINFormState((prevState) => ({
            ...prevState,
            electronicConsent: value
        }));
        setEConsent(value)
    };

    const handleOpenMarkAsReviewCheckBox = () => {
        setIINFormState((prevState) => ({
            ...prevState,
            isMarkAsReviewedModalOpen: true
        }))
    };

    const handleCloseMarkAsReviewCheckBox = () => {
        setIINFormState((prevState) => ({
            ...prevState,
            isMarkAsReviewedModalOpen: false,
            responseErrors: '',
        }))
    };

    const setReviewedFlag = () => {
        setIINFormState((prevState) => ({
            ...prevState,
            isReviewed: true
        }))
    };

    const handleOpenUploadModal = () => {
        setIINFormState((prevState) => ({
            ...prevState,
            isUploadIINFormModalOpen: true
        }))
    };

    const handleCloseUploadModal = () => {
        setIINFormState((prevState) => ({
            ...prevState,
            isUploadIINFormModalOpen: false,
            responseErrors: '',
        }));
        refreshData()
    };

    const handleOpenAddRemarkModal = () => {
        setIINFormState((prevState) => ({
            ...prevState,
            isAddRemarkModalOpen: true
        }))
    };

    const handleCloseAddRemarkModal = () => {
        setIINFormState((prevState) => ({
            ...prevState,
            isAddRemarkModalOpen: false,
            responseErrors: '',
        }))
    };

    const selectedOption = (option) => {
        if (option === "Upload") {
            handleOpenUploadModal()
        } else if (option === "Add a Remark") {
            handleOpenAddRemarkModal()
        } else if (option === "View Activities") {
            handleOpenViewActivitiesModal()
        }
    };

    const downloadAndViewDocument = (action) => {
        const {filePath, fileName} = IINFormState;
        setIINFormState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));
        downloadAndViewForm(fileName, filePath, action);
        setTimeout(() => {
            setIINFormState((preState) => ({
                    ...preState,
                    isShowLoader: false,
                }
            ))
        }, 500)
    };

    const handleOpenViewActivitiesModal = () => {
        setIINFormState((prevStep) => ({
            ...prevStep,
            isViewActivitiesModalOpen: true,
        }));
    };

    const handleCloseViewActivitiesModal = () => {
        setIINFormState((prevStep) => ({
            ...prevStep,
            isViewActivitiesModalOpen: false,
            responseErrors: '',
        }));
    };

    const submitIIN = () => {
        const {onboardingApplicationId} = props;
        setIINFormState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));
        let payloadData = {
            onboardingApplicationId: onboardingApplicationId
        };
        submitIINData(payloadData)
            .then(res => {
                if (res?.success) {
                    setIINFormState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        responseErrors: '',
                        isIINSubmitted: true
                    }));
                } else {
                    setIINFormState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        isIINSubmitted: false,
                        responseErrors: res?.__error
                    }));
                }
            })
    };
    const resetIINSubmissionStatus = () => {

        setIINFormState(prevState => ({
            ...prevState,
            isIINSubmitted: false
        }));
    };
    const setActivityStatus = (error) => {
        setIINFormState(prevState => ({
            ...prevState,
            responseErrors: error
        }));
    };

    return (
        <IINFormView
            {...props}
            {...IINFormState}
            handleOpenUploadModal={handleOpenUploadModal}
            handleCloseUploadModal={handleCloseUploadModal}
            handleOpenAddRemarkModal={handleOpenAddRemarkModal}
            handleCloseAddRemarkModal={handleCloseAddRemarkModal}
            setElectronicConsent={setElectronicConsent}
            regenerateForm={regenerateForm}
            handleOpenMarkAsReviewCheckBox={handleOpenMarkAsReviewCheckBox}
            handleCloseMarkAsReviewCheckBox={handleCloseMarkAsReviewCheckBox}
            setReviewedFlag={setReviewedFlag}
            selectedOption={selectedOption}
            downloadAndViewDocument={downloadAndViewDocument}
            handleOpenViewActivitiesModal={handleOpenViewActivitiesModal}
            handleCloseViewActivitiesModal={handleCloseViewActivitiesModal}
            refreshData={refreshData}
            submitIIN={submitIIN}
            resetIINSubmissionStatus={resetIINSubmissionStatus}
            setActivityStatus={setActivityStatus}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
    isLGView: state.appState.deviceInfo.isLGView,
    accountId: state.onboardingFlowState.accountId,
    onboardingApplicationId: state.onboardingFlowState.onboardingApplicationId
});

export default connect(mapStateToProps, {})(IINForm);
