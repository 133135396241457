import React from 'react';

import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLInputTextField from "../../../../../../core/components/DLInputTextField/DLInputTextField";
import DLModalActions from "../../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";

const AddRemarkView = (props) => {

    const {history, isAddRemarkModalOpen, handleCloseAddRemarkModal, handleChange,
         handleSubmit, isShowLoader, errors, responseError} = props;

    const renderModalError = () =>{
        if(responseError){
            return(
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            )
        }
    };

    const renderAddRemarkModal = () => {
        return (
                <DLModal
                    history={history}
                    maxWidth={"sm"}
                    onClose={() => handleCloseAddRemarkModal()}
                    open={isAddRemarkModalOpen}
                    id={'add-remark-modal'}>
                    <DLModalTitle
                        id={'add-remark-title'}
                        onClose={() => handleCloseAddRemarkModal()}
                        title={'Add a Remark'}/>
                    <DLModalContainer
                        id={'add-remark-container'}>
                        {renderModalError()}
                        <DLInputTextField
                            id={''}
                            label={'Remark'}
                            value={''}
                            marginBottom={"none"}
                            inputType={"text"}
                            isRequired={true}
                            isMulti={true}
                            rows={5}
                            maxRows={5}
                            disableHelperMessageHeight={true}
                            error={errors.remarkError !== ""}
                            helperMessage={errors.remarkError}
                            hideCharLength={true}
                            isClearable={false}
                            maxCharLength={500}
                            onChangeValue={(value) => {
                                handleChange("remark", value)
                            }}
                            onBlur={() => {

                            }}
                        />

                    </DLModalContainer>
                    <DLModalActions id={'add-remark-action'}>
                        <div className='mb-4' style={{paddingTop: '7px'}}>
                            <DLButton
                                id={'create-lead-cancelBtn'}
                                history={history}
                                label={'Cancel'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                styleType={"outlined"}
                                backgroundColor={"grey"}
                                sideMargin={"sm"}
                                onClick={() => handleCloseAddRemarkModal()}/>
                            <DLButton
                                id={'add-remark-submitBtn'}
                                history={props.history}
                                label={'Add a Remark'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                sideMargin={"sm"}
                                onClick={() => {handleSubmit()}}
                             />
                        </div>
                    </DLModalActions>
                </DLModal>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    return (
        <div>
            {renderAddRemarkModal()}
            {renderLoader()}
        </div>
    );
};

export default AddRemarkView;