import React from 'react';
import NFTDashboard from "./NFTManagementDashboard/NFTManagementDashboard";
import NFTDashboardCRM from "./NFTCRMDashboard/NFTCRMDashboard";

const NFTDashboardView = (props) => {

    const {isManagementUser, isSetUserType} = props;

    if(!isSetUserType){
        return ''
    }

    if (isManagementUser) {
        return (
            <div>
                <NFTDashboard {...props}/>
            </div>
        );
    } else {
        return (
            <div>
                <NFTDashboardCRM {...props} />
            </div>
        );
    }
};

export default NFTDashboardView;