import React from 'react';
import KYCStatusView from "./KYCStatusView";
import {connect} from "react-redux";
import {isEmpty} from "../../../core/components/DLComponentHelper";
import moment from "moment";

const KYCStatus = (props) => {
    const {selectedKycDetails} = props;

    const getPersonVerification = () => {
        if (selectedKycDetails?.kycDetails?.kycInPersonVerification?.code === selectedKycDetails?.kycDetails?.inPersonVerificationCode) {
            return selectedKycDetails?.kycDetails?.kycInPersonVerification?.description;
        }
        return '-';
    };

    const getKycModeDescription = () => {

        if (selectedKycDetails.kycDetails?.kycMode?.code === selectedKycDetails?.kycDetails?.mode) {
            return selectedKycDetails?.kycDetails?.kycMode?.description;
        }
        return '-';
    };


    const lastUpdatedDate = () => {
        if (!isEmpty(selectedKycDetails?.kycDetails?.lastUpdatedDate)) {
            return moment(selectedKycDetails?.kycDetails?.lastUpdatedDate).format('DD-MM-YYYY hh:mm A');
        }
        return '-';
    };

    const startDate = () => {
        if (!isEmpty(selectedKycDetails?.kycDetails?.kycCreationDate)) {
            return moment(selectedKycDetails?.kycDetails?.kycCreationDate).format('DD-MM-YYYY');
        }
        return '-';
    };

    const formatDate = (date) =>{
        if (!isEmpty(date)) {
            return moment(date).format('DD-MM-YYYY hh:mm A');
        }
        return '-';
    };


    return (
        <KYCStatusView
            {...props}
            startDate={startDate()}
            lastUpdatedDate={lastUpdatedDate()}
            getKycModeDescription={getKycModeDescription()}
            getPersonVerification={getPersonVerification()}
            formatDate={formatDate}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    profile: state.userState.profile,

});

export default connect(mapStateToProps, {})(KYCStatus);
