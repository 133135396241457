import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'OnboardingFlowReducer',
    initialState: {
        onboardingFilter: {
            keyword: '',
            fromDate: '',
            toDate: '',
            iinStatus: ['ALL'],
            category: ['ALL'],
            type: ['ALL'],
            CRMId: ['ALL'],
            location: ['ALL'],
            status: ['ALL'],
            ecsStatus: ['ALL'],
            skipCount: 0,
            limitCount: 50,
            page:0,
            rowsPerPage: 50,
            title: '',
            agingKey: ''
        }
    },
    reducers: {
        setSearchKeywordValue (state,action) {
            state.onboardingFilter.keyword = action.payload;
        },

        setFromDateValue (state,action) {
            state.onboardingFilter.fromDate = action.payload;
        },

        setToDateValue (state,action) {
            state.onboardingFilter.toDate = action.payload;
        },

        setStatusValue (state,action) {
            state.onboardingFilter.iinStatus = action.payload;
        },

        setCategoryValue (state,action) {
            state.onboardingFilter.category = action.payload;
        },

        setAccountTypeValue (state,action) {
            state.onboardingFilter.type = action.payload;
        },

        setCRMValue (state,action) {
            state.onboardingFilter.CRMId = action.payload;
        },

        setLocationValue (state,action) {
            state.onboardingFilter.location = action.payload;
        },

        setApplicationStatusValue(state,action) {
            state.onboardingFilter.status = action.payload;
        },

        setBankMandateStatusValue(state,action) {
            state.onboardingFilter.ecsStatus = action.payload;
        },

        setSkipCountValue(state,action){
            state.onboardingFilter.skipCount = action.payload;
        },

        setLimitCountValue(state,action){
            state.onboardingFilter.limitCount = action.payload;
        },

        setPageValue(state,action){
            state.onboardingFilter.page = action.payload;
        },

        setRowsPerPageValue(state,action){
            state.onboardingFilter.rowsPerPage = action.payload;
        },
        setStatusTitle(state,action){
            state.onboardingFilter.title = action.payload;
        },
        setAgingKey(state,action){
            state.onboardingFilter.agingKey = action.payload;
        },

        resetAllFilters (state,action) {
            state.onboardingFilter = {
                ...state.onboardingFilter,
                keyword:'',
                fromDate:'',
                toDate:'',
                iinStatus: ['ALL'],
                category: ['ALL'],
                type: ['ALL'],
                CRMId: ['ALL'],
                location: ['ALL'],
                status: ['ALL'],
                ecsStatus: ['ALL'],
                skipCount: 0,
                page:0,
                title: ''
            };
        },
    }
});

export const {setSearchKeywordValue, setFromDateValue, setStatusValue, setCategoryValue, setAccountTypeValue,
    setToDateValue,setSkipCountValue,setLimitCountValue,resetAllFilters,setPageValue, setRowsPerPageValue, setCRMValue,
    setApplicationStatusValue, setBankMandateStatusValue, setLocationValue, setStatusTitle, setAgingKey
} = appSlice.actions;
export default appSlice.reducer
