//noinspection DuplicatedCode,JSUnresolvedVariable,SpellCheckingInspection,JSUnresolvedFunction
import React, {Component} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DLText from "../../DLText/DLText";
import {getFontColor, getFontFamily, getFontSize, isEmpty} from "./../../DLComponentHelper";
import '../DLModal.css'

class DLModalTitleView extends Component {

    getTitle = (title) =>{
        if (isEmpty(title)){
            return ''
        }

        return(
            <span className='text-wrap text-break'>{title}</span>
        )
    };

    render() {
        const {title, history, t, onClose, fontColor, showIcon, crossIconColor, crossIconFontSize} = this.props;

        return (
            <div className='border-bottom'>
                <DialogTitle sx={{ m: 0, p: 0 }}>
                    <div className='d-flex justify-content-between align-items-center title-alignment'>
                        <div className='' style={{fontFamily: getFontFamily()}}>
                            <DLText
                                id={'modal-title'}
                                type={"normal"}
                                history={history}
                                t={t}
                                fontSize={'md'}
                                fontWeight={'semi-bold'}
                                fontColor={fontColor}
                                marginBottom={"none"}
                                text={this.getTitle(title)}
                            />
                        </div>
                        {showIcon && <i className="fa-solid fa-xmark"
                                        style={{
                                            color: getFontColor(crossIconColor),
                                            ...getFontSize(crossIconFontSize),
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => onClose()}/>}
                    </div>
                </DialogTitle>
            </div>
        );
    }
}

export default DLModalTitleView;
