import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLLoaderView from './DLLoaderView';


class DLLoader extends Component {
  render() {
    return (<DLLoaderView {...this.props} />);
  }
}

DLLoader.propTypes = {
  type: PropTypes.oneOf([
    'screen', // to show the loader on complete screen
    'inline', // to show the loader on current position
    'splash', // to show the logo on complete screen
  ]),
  isVisible: PropTypes.bool,
  color: PropTypes.string,
  customStyle: PropTypes.object,
  thickness:PropTypes.number,
};

DLLoader.defaultProps = {
  type: 'screen',
  isVisible: false,
  color: 'primary',
  customStyle: {},
  thickness:7
};


export default DLLoader;

