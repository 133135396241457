import React from 'react';

import {connect} from "react-redux";

import LinkExpiredView from "./LinkExpiredView";

const LinkExpired = (props) => {

    return (
        <LinkExpiredView
            {...props}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    windowHeight: state.appState.deviceInfo.windowHeight,
});

export default connect(mapStateToProps, {})(LinkExpired);

