import React, {Component} from 'react';
import {getFontColor, getFontFamily, getFontSize, getFontWeight, getTheme, isEmpty} from "../DLComponentHelper";
import {componentDefaults} from "../componentDefaults";

class DLTextView extends Component {

  getFontFamily = () => {
    let fontFamily = getFontFamily();
    if(isEmpty(fontFamily)){
      return {}
    }
    return {
      fontFamily: fontFamily
    }
  };

  getIconStyle = () => {
    const {customIconStyle, fontSize, fontColor, fontWeight} = this.props;
    let style = {};
    style = {
      ...style,
      ...getFontSize(fontSize),
      ...getFontWeight(fontWeight),
      ...this.getFontFamily(),
      ...this.getLinkStyle(),
      ...customIconStyle,
    };
    return style;
  };

  getLinkStyle = () => {
    const {isClickable, fontColor} = this.props;

    if(!isClickable){
      return {color: getFontColor(fontColor)}
    }

    let theme = getTheme();
    let style = {
      cursor: 'pointer'
    };
    let linkStyle = theme.linkStyle;
    if(typeof linkStyle === "object" && !isEmpty(linkStyle)){
      style = {
        ...style,
        ...linkStyle
      }
    } else {
      style = {
        ...style,
        underline: {textDecorationLine: 'underline'}
      }
    }
    return style;
  };

  getTextStyle = () => {
    const {customTextStyle, fontSize, fontColor, fontWeight} = this.props;
    let style = {};
    style = {
      ...style,
      ...getFontSize(fontSize),
      ...getFontWeight(fontWeight),
      color : getFontColor(fontColor),
      ...this.getFontFamily(),
      ...this.getLinkStyle(),
      ...customTextStyle,
    };
    return style;
  };

  getWrapperBottomMargin = () => {
    const {marginBottom} = this.props;
    if(isEmpty(marginBottom)){
      return componentDefaults.marginBottoms.xs
    }
    return componentDefaults.marginBottoms[marginBottom];
  };

  getWrapperStyle = () => {
    const {isInline, customWrapperStyle, truncateText} = this.props;
    let style = {};
    if(isInline){
      style = {
        ...style,
        display: 'inline'
      }
    }
    style = {
      ...style,
      marginBottom: this.getWrapperBottomMargin()
    };

    if (truncateText){
      //  whiteSpace:'nowrap', minWidth:'100%', overflow:'hidden', textOverflow:'ellipsis'
      style = {
        ...style,
        whitespace: 'nowrap',
        minWidth: '100%',
        overflow:'hidden',
        textOverflow:'ellipsis'
      }
    }

    if(!isEmpty(customWrapperStyle)){
      style = {
        ...style,
        ...customWrapperStyle
      }
    }

    return style;
  };


  handleClick = () => {
    const {isClickable, onClick} = this.props;
    if(isClickable && onClick){
      onClick();
    }
  };


  render() {

    const {id, text, icon, iconPosition} = this.props;

    let iconUI = (
        <span id={'icon-' + id}
              onClick={this.handleClick}
              style={{...this.getIconStyle()}}>
        {icon}
      </span>);
    let textUI = (
        <span id={'text-' + id}
              onClick={this.handleClick}
              style={{...this.getTextStyle()}}>
        {isEmpty(text) ? '-' : text}
      </span>
    );

    if(iconPosition === 'start'){
      return (
          <div style={{...this.getWrapperStyle()}}  >
            {icon && iconUI}{textUI}
          </div>
      );
    }

    return (
        <div style={{...this.getWrapperStyle()}}>
          {textUI}{icon && iconUI}
        </div>
    );
  }
}

DLTextView.propTypes = {};

export default DLTextView;
