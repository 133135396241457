import React, {useEffect} from 'react';
import DLText from '../../../../core/components/DLText/DLText';
import DLInputTextField from '../../../../core/components/DLInputTextField/DLInputTextField';
import DLRadioGroup from '../../../../core/components/DLRadioGroup/DLRadioGroup';
import DLButton from '../../../../core/components/DLButton/DLButton';
import DLSearchAutoComplete from '../../../../core/components/DLSearchAutoComplete/DLSearchAutoComplete';
import {isEmpty} from "../../../../utils/validations";
import appTheme from "../../../../assets/appTheme";
import {debounce} from "../../../../core/components/DLComponentHelper";
import DLDocUploader from "../../../../core/components/DLDocUploader/DLDocUploader";
import {handleFileError} from "../../../../utils/FileHelper";
import DLLoader from "../../../../core/components/DLLoader/DLLoader";
import {searchOnWordBoundary} from "../../../../utils/helper";

const CancelledChequeView = (props) => {

    const {isMobileView, isLoaderVisible, isFromOnboardingFlow, setDocumentType} = props;

    useEffect(() => {
        const {scrollToFieldId} = props;
        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);

            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                handleScrollChangeWithDebounce()
            }
        }
        return () => {
            window.scrollTo(0, 0)
        }
    }, [props.scrollToFieldId]);

    const handleScrollChangeWithDebounce = debounce(() => {
        props.handleChange('scrollToFieldId', '')
    }, 1000);

    const renderDocumentUploader = () => {
        const {associateDocument, document, errors, profile, getDownloadImageFileName, accountHolderDetails} = props;
        let downloadFileName = getDownloadImageFileName(accountHolderDetails?.accountHolderName, document.documentType);
        return (
            <DLDocUploader
                id={'resource-doc-uploader'}
                history={props.history}
                key={'doc-uploader'}
                label={''}
                imageHeight={'781px'}
                boxLabel={document.documentType === "CANCELLED_CHEQUE" ? "Signed Cancelled Cheque" : "Others (Bank Statement/Passbook)"}
                onChangeValue={(type, value) => {
                    associateDocument(type, value);
                }}
                downloadFileName={isEmpty(downloadFileName) ? document.fileName : downloadFileName}
                isShowLabel={false}
                disableHelperMessageHeight={false}
                helperMessage={errors.document.cancelledChequeError}
                isRequired={true}
                filePath={document.filePath}
                uploadedFileName={document.fileName}
                handleError={(error) => handleFileError(error, 62914560, 1)}
                documentId={document?.id}
                isDisableDelete={props.profile?.isManagement}
            />
        )
    };

    const renderBankProofType = () => {
        const {document} = props;

        return (
            <div>
                <DLText id={""}
                        text={"Select Bank Proof"}
                        fontSize={"xs"}
                        fontColor={"grayDark"}
                />
                <div className='ms-2 mb-4'>
                    <div>
                        <DLRadioGroup
                            id={'hours-radioBtn'}
                            options={[
                                {
                                    label: 'Signed Cancelled Cheque',
                                    value: 'CANCELLED_CHEQUE',
                                }]}
                            label={''}
                            value={document?.documentType}
                            type={'inline'}
                            labelFontSize={'sm'}
                            labelFontColor={'black'}
                            onChangeValue={(e) => {
                                setDocumentType(e)
                            }}
                            disableHelperMessageHeight={true}
                            disableDescriptionMessageHeight={true}
                        />
                    </div>
                    <div>
                        <DLRadioGroup
                            id={'hours-radioBtn'}
                            options={[
                                {
                                    label: 'Others (Bank Statement/Passbook)',
                                    value: 'BANK_PROOF_OTHERS',
                                }]}
                            label={''}
                            value={document?.documentType}
                            type={'inline'}
                            labelFontSize={'sm'}
                            labelFontColor={'black'}
                            onChangeValue={(e) => {
                                setDocumentType(e)
                            }}
                            disableHelperMessageHeight={true}
                            disableDescriptionMessageHeight={true}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderUpLoaderUi = () => {
        return (
            <div className='d-flex flex-column h-100 mt-2 pt-1'>
                <div className=''>
                    {renderBankProofType()}
                </div>
                {renderDocumentUploader()}
            </div>
        )
    };

    const renderMobileBankDetails = () => {
        const {bankDetails, errors, handleChange, onSearchClick, dropdownValues} = props;

        return (<div id='bank-details' className='block-padding'>
            <DLText
                id={'m-bank-account'}
                type={'normal'}
                text={"Bank Account"}
                fontSize={'sm'}
                fontWeight={'semi-bold'}
                marginBottom={'md'}
                fontColor={'black'}
            />
            <div id='bankDetails-IFSCCode-id' className="d-flex align-items-center">
                <div className={"flex-grow-1 me-2"}>
                    <DLInputTextField id={'m-ifsc-code'}
                                      label={"IFSC Code"}
                                      value={bankDetails?.IFSCCode}
                                      maxCharLength={11}
                                      isClearable={false}
                                      error={errors.bankDetails?.IFSCCode !== ""}
                                      helperMessage={errors.bankDetails?.IFSCCode}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'sm'}
                                      onChangeValue={(e) => {
                                          handleChange("IFSCCode", e, "bankDetails")
                                      }}
                                      isRequired={true}
                    />
                </div>
                <div className={errors.bankDetails?.IFSCCode !== "" ? "mb-3 mt-1" : "mt-1"}>
                    <DLButton
                        id={'search-button'}
                        styleType={"outlined"}
                        borderType={'square'}
                        buttonSize={'sm'}
                        fontSize={'sm'}
                        sideMargin={'none'}
                        onClick={() => {
                            onSearchClick(bankDetails?.IFSCCode)
                        }}
                        label={"Search"}
                    />
                </div>
            </div>

            <div id='bankDetails-bankName-id' className={'input-text-field-wrapper'}>
                <DLText
                    id={'m-account-type'}
                    type={'normal'}
                    text={"Bank Name"}
                    fontSize={'xs'}
                    fontColor={'grayDark'}
                />
                <DLSearchAutoComplete id={'account-type-dropDown'}
                                      value={bankDetails?.bankName}
                                      loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.banks)}
                                      placeholder={"Select"}
                                      onSelect={(e) => handleChange("bankName", e, "bankDetails")}
                                      isClearable={false}
                                      isSearchable={true}
                                      error={errors.bankDetails?.bankName !== ""}
                                      helperMessage={errors.bankDetails?.bankName}
                                      itemNotFoundText={"No options found'"}
                                      isMulti={false}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'sm'}
                                      defaultOptions={dropdownValues?.banks}
                                      noOptionsMessage={"No options found'"}
                                      isRequired={true}
                />
            </div>

            <div id='bankDetails-branch-id'>
                <DLInputTextField id={'m-branch'}
                                  label={"Branch"}
                                  value={bankDetails?.branch}
                                  maxCharLength={30}
                                  isClearable={false}
                                  error={errors.bankDetails?.branch !== ""}
                                  helperMessage={errors.bankDetails?.branch}
                                  disableHelperMessageHeight={true}
                                  marginBottom={'sm'}
                                  onChangeValue={(e) => {
                                      handleChange("branch", e, "bankDetails")
                                  }}
                                  isRequired={true}
                />
            </div>

            <div id='bankDetails-MICRNo-id'>
                <DLInputTextField id={'m-micr-no'}
                                  label={"MICR No (Optional)"}
                                  value={bankDetails?.MICRNo}
                                  maxCharLength={9}
                                  isClearable={false}
                                  inputType={"number"}
                                  error={errors.bankDetails?.MICRNo !== ""}
                                  helperMessage={errors.bankDetails?.MICRNo}
                                  disableHelperMessageHeight={true}
                                  marginBottom={'sm'}
                                  onChangeValue={(e) => {
                                      handleChange("MICRNo", e, "bankDetails")
                                  }}
                />
            </div>
        </div>)
    };

    const renderMobileBankAddressDetails = () => {
        const {bankAddress, errors, handleChange, dropdownValues} = props;

        return (<div className='block-padding'>
            <DLText
                id={'m-address'}
                type={'normal'}
                text={"Bank Address"}
                fontSize={'sm'}
                fontWeight={'semi-bold'}
                marginBottom={'md'}
                fontColor={'black'}
            />
            <div id='bankAddress-addressLine1-id'>
                <DLInputTextField
                    id={'address-line-1'}
                    label={"Address"}
                    value={bankAddress?.addressLine1}
                    maxCharLength={120}
                    isMulti={true}
                    rows={3}
                    maxRows={4}
                    isClearable={false}
                    error={errors.bankAddress?.addressLine1 !== ""}
                    helperMessage={errors.bankAddress?.addressLine1}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    onChangeValue={(e) => {
                        handleChange("addressLine1", e, "bankAddress")
                    }}
                    isRequired={true}
                />
            </div>
            {/*<div id='bankAddress-addressLine2-id'>*/}
            {/*    <DLInputTextField*/}
            {/*        id={'address-line-2'}*/}
            {/*        label={"Address Line 2 (Optional)"}*/}
            {/*        value={bankAddress?.addressLine2}*/}
            {/*        maxCharLength={50}*/}
            {/*        isClearable={true}*/}
            {/*        error={errors.bankAddress?.addressLine2 !== ""}*/}
            {/*        helperMessage={errors.bankAddress?.addressLine2}*/}
            {/*        disableHelperMessageHeight={true}*/}
            {/*        marginBottom={'sm'}*/}
            {/*        onChangeValue={(e) => {*/}
            {/*            handleChange("addressLine2", e, "bankAddress")*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<div id='bankAddress-addressLine3-id'>*/}
            {/*    <DLInputTextField*/}
            {/*        id={'address-line-3'}*/}
            {/*        label={"Address Line 3 (Optional)"}*/}
            {/*        value={bankAddress?.addressLine3}*/}
            {/*        maxCharLength={50}*/}
            {/*        isClearable={true}*/}
            {/*        error={errors.bankAddress?.addressLine3 !== ""}*/}
            {/*        helperMessage={errors.bankAddress?.addressLine3}*/}
            {/*        disableHelperMessageHeight={true}*/}
            {/*        marginBottom={'sm'}*/}
            {/*        onChangeValue={(e) => {*/}
            {/*            handleChange("addressLine3", e, "bankAddress")*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</div>*/}

            <div id='bankAddress-city-id'>
                <DLInputTextField
                    id={'m-city'}
                    label={"City"}
                    value={bankAddress?.city}
                    maxCharLength={30}
                    isClearable={false}
                    error={errors.bankAddress?.city !== ""}
                    helperMessage={errors.bankAddress?.city}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    onChangeValue={(e) => {
                        handleChange("city", e, "bankAddress")
                    }}
                    isRequired={true}
                />
            </div>
            <div id='bankAddress-zipCode-id'>
                <DLInputTextField
                    id={'m-pin-code'}
                    label={"Pin Code (Optional)"}
                    value={bankAddress?.zipCode}
                    maxCharLength={6}
                    isClearable={false}
                    error={errors.bankAddress?.zipCode !== ""}
                    helperMessage={errors.bankAddress?.zipCode}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    inputType={"number"}
                    onChangeValue={(e) => {
                        handleChange("zipCode", e, "bankAddress")
                    }}
                    isRequired={true}
                />
            </div>

            <div id='bankAddress-state-id' className={'input-text-field-wrapper'}>
                <DLSearchAutoComplete
                    id={'stateCode-dropDown'}
                    label={'stateCode'}
                    value={bankAddress?.stateCode}
                    placeholder={""}
                    onSelect={(e) => handleChange("stateCode", e, "bankAddress")}
                    isClearable={false}
                    isSearchable={true}
                    error={errors.bankAddress?.stateCode !== ""}
                    helperMessage={errors.bankAddress?.stateCode}
                    itemNotFoundText={"No options found'"}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    isMulti={false}
                    loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.states)}
                    defaultOptions={dropdownValues?.states}
                    noOptionsMessage={"No options found'"}
                    isRequired={true}
                />
            </div>
        </div>)
    };

    const renderMobileAccountHolderDetails = () => {
        const {accountHolderDetails, errors, handleChange, dropdownValues} = props;

        return (<div className='block-padding'>
            <DLText
                id={'m-bank-account'}
                type={'normal'}
                text={"Account Holder Details"}
                fontSize={'sm'}
                fontWeight={'semi-bold'}
                marginBottom={'md'}
                fontColor={'black'}
            />
            <div id='accountHolderDetails-accountHolderName-id'>
                <DLInputTextField
                    id={'m-account-holder-name'}
                    label={"Account Holder Name"}
                    value={accountHolderDetails?.accountHolderName}
                    maxCharLength={60}
                    isClearable={false}
                    error={errors.accountHolderDetails?.accountHolderName !== ""}
                    helperMessage={errors.accountHolderDetails?.accountHolderName}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    onChangeValue={(e) => {
                        handleChange("accountHolderName", e, "accountHolderDetails")
                    }}
                    isRequired={true}
                />
            </div>
            <div id='accountHolderDetails-accountNumber-id'>
                <DLInputTextField
                    id={'m-account-number'}
                    label={"Account Number"}
                    value={accountHolderDetails?.accountNumber}
                    maxCharLength={20}
                    isClearable={false}
                    inputType={"number"}
                    error={errors.accountHolderDetails?.accountNumber !== ""}
                    helperMessage={errors.accountHolderDetails?.accountNumber}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    onChangeValue={(e) => {
                        handleChange("accountNumber", e, "accountHolderDetails")
                    }}
                    isRequired={true}
                />
            </div>
            <div id='accountHolderDetails-bankAccountType-id' className={'input-text-field-wrapper'}>
                <DLSearchAutoComplete
                    id={'account-type-dropDown'}
                    label={'Account Type'}
                    value={accountHolderDetails?.bankAccountType}
                    placeholder={"Select"}
                    onSelect={(e) => handleChange("bankAccountType", e, "accountHolderDetails")}
                    isClearable={false}
                    isSearchable={false}
                    error={errors.accountHolderDetails?.bankAccountType !== ""}
                    helperMessage={errors.accountHolderDetails?.bankAccountType}
                    itemNotFoundText={"No options found'"}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    isMulti={false}
                    dropDownPlacement={"top"}
                    defaultOptions={dropdownValues?.accountTypes}
                    noOptionsMessage={"No options found'"}
                    isRequired={true}
                />
            </div>
        </div>)
    };

    const renderMobileUi = () => {
        return <div>
            <div className='px-3'>
                {renderErrorUI()}
            </div>
            <div className={'mb-5 bg-white'}>

                {renderMobileBankDetails()}
                <div style={{height: '5px', backgroundColor: appTheme.appColor.gray}}/>
                {renderMobileBankAddressDetails()}
                <div style={{height: '5px', backgroundColor: appTheme.appColor.gray}}/>
                {renderMobileAccountHolderDetails()}
            </div>
            {renderButtonUI()}
        </div>
    };


    const renderBankDetails = () => {
        const {bankDetails, errors, handleChange, onSearchClick, dropdownValues} = props;

        return (
            <div id='bank-details'
                 className='mb-4 border-bottom'>
                <div className='section-title'
                     id={'bank-account'}>
                    Bank Information
                </div>
                <div className='panel'>
                    <div id='bankDetails-IFSCCode-id' className='d-flex justify-content-start align-items-center'>
                        <div className=' flex-grow-1 pe-2 '>
                            <DLInputTextField
                                id={'ifsc-code-input-field'}
                                label={'IFSC Code'}
                                value={bankDetails?.IFSCCode}
                                maxCharLength={11}
                                isClearable={false}
                                error={errors.bankDetails?.IFSCCode !== ''}
                                helperMessage={errors.bankDetails?.IFSCCode}
                                disableHelperMessageHeight={true}
                                marginBottom={'sm'}
                                onChangeValue={(e) => {
                                    handleChange('IFSCCode', e, 'bankDetails')
                                }}
                                isRequired={true}
                            />
                        </div>
                        <div className={errors.bankDetails?.IFSCCode !== '' ? 'mb-3' : 'mt-1'}>
                            <DLButton
                                id={'search-button'}
                                styleType={'outlined'}
                                borderType={'square'}
                                buttonSize={'sm'}
                                fontSize={'sm'}
                                sideMargin={'none'}
                                onClick={() => {
                                    onSearchClick(bankDetails?.IFSCCode)
                                }}
                                label={'Search'}
                            />
                        </div>

                    </div>
                    <div id='bankDetails-bankName-id'>
                        <DLSearchAutoComplete
                            id={'account-type-dropDown'}
                            label={'Bank Name'}
                            loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.banks)}
                            value={bankDetails?.bankName}
                            placeholder={''}
                            onSelect={(e) => handleChange('bankName', e, 'bankDetails')}
                            isClearable={false}
                            isSearchable={true}
                            error={errors.bankDetails?.bankName !== ''}
                            helperMessage={errors.bankDetails?.bankName}
                            itemNotFoundText={'No options found'}
                            disableHelperMessageHeight={true}
                            marginBottom={'sm'}
                            isMulti={false}
                            isRequired={true}
                            defaultOptions={dropdownValues?.banks}
                            noOptionsMessage={'No options found'}
                        />
                    </div>
                    <div id='bankDetails-branch-id' >
                        <DLInputTextField
                            id={'branch-input-field'}
                            label={'Branch'}
                            maxCharLength={30}
                            value={bankDetails?.branch}
                            isClearable={false}
                            disableHelperMessageHeight={true}
                            marginBottom={'sm'}
                            error={errors.bankDetails?.branch !== ''}
                            helperMessage={errors.bankDetails?.branch}
                            onChangeValue={(e) => {
                                handleChange('branch', e, 'bankDetails')
                            }}
                            isRequired={true}
                        />
                    </div>
                    <div id='bankDetails-MICRNo-id' >
                        <DLInputTextField
                            id={'micr-no-input-field'}
                            label={'MICR No (Optional)'}
                            value={bankDetails?.MICRNo}
                            maxCharLength={9}
                            isClearable={false}
                            disableHelperMessageHeight={true}
                            marginBottom={'md'}
                            inputType={'number'}
                            error={errors.bankDetails?.MICRNo !== ''}
                            helperMessage={errors.bankDetails?.MICRNo}
                            onChangeValue={(e) => {
                                handleChange('MICRNo', e, 'bankDetails')
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderBankAddressDetails = () => {
        const {bankAddress, errors, handleChange, dropdownValues} = props;

        return (
            <div className='mb-4 border-bottom'>
                <div className='section-title'
                     id={'address'}>
                    Bank Address
                </div>
                <div className='panel mt-2'>
                    <div id='bankAddress-addressLine1-id'>
                        <DLInputTextField
                            id={'bank-address-input-field'}
                            label={'Address'}
                            isRequired={true}
                            value={bankAddress?.addressLine1}
                            maxCharLength={120}
                            isMulti={true}
                            rows={3}
                            maxRows={4}
                            isClearable={false}
                            error={errors.bankAddress?.addressLine1 !== ''}
                            helperMessage={errors.bankAddress?.addressLine1}
                            disableHelperMessageHeight={true}
                            marginBottom={'sm'}
                            onChangeValue={(e) => {
                                handleChange('addressLine1', e, 'bankAddress')
                            }}
                        />
                    </div>
                    <div id='bankAddress-city-id'>
                        <DLInputTextField
                            id={'city-input-field'}
                            label={'City'}
                            value={bankAddress?.city}
                            maxCharLength={30}
                            isClearable={false}
                            error={errors.bankAddress?.city !== ''}
                            helperMessage={errors.bankAddress?.city}
                            disableHelperMessageHeight={true}
                            marginBottom={'sm'}
                            onChangeValue={(e) => {
                                handleChange('city', e, 'bankAddress')
                            }}
                            isRequired={true}
                        />
                    </div>
                    <div id='bankAddress-zipCode-id'>
                        <DLInputTextField
                            id={'pin-code-input-field'}
                            label={'Pin Code (Optional)'}
                            value={bankAddress?.zipCode}
                            maxCharLength={6}
                            isClearable={false}
                            inputType={'number'}
                            error={errors.bankAddress?.zipCode !== ''}
                            helperMessage={errors.bankAddress?.zipCode}
                            disableHelperMessageHeight={true}
                            marginBottom={'sm'}
                            onChangeValue={(e) => {
                                handleChange('zipCode', e, 'bankAddress')
                            }}
                            isRequired={true}
                        />
                    </div>
                    <div id='bankAddress-stateCode-id'>
                        <DLSearchAutoComplete
                            id={'stateCode-dropDown'}
                            label={'State'}
                            loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.states)}
                            value={bankAddress?.stateCode}
                            placeholder={''}
                            onSelect={(e) => handleChange('stateCode', e, 'bankAddress')}
                            isClearable={false}
                            isSearchable={true}
                            error={errors.bankAddress?.stateCode !== ''}
                            helperMessage={errors.bankAddress?.stateCode}
                            itemNotFoundText={'No options found'}
                            disableHelperMessageHeight={true}
                            marginBottom={'md'}
                            isMulti={false}
                            defaultOptions={dropdownValues?.states}
                            noOptionsMessage={'No options found'}
                            isRequired={true}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderAccountHolderDetails = () => {
        const {accountHolderDetails, errors, handleChange, dropdownValues,isFromOnboardingFlow} = props;

        return (
            <div className={isFromOnboardingFlow ? 'mb-2 pb-2 ':''}>
                <div className='section-title'
                     id={'bank-account'}>
                    Account Holder Details
                </div>
                <div className={'pt-2 panel'}>
                    <div id='accountHolderDetails-accountHolderName-id'>
                        <DLInputTextField
                            id={'account-holder-name-input-field'}
                            label={'Name'}
                            value={accountHolderDetails?.accountHolderName}
                            maxCharLength={60}
                            isClearable={false}
                            error={errors.accountHolderDetails?.accountHolderName !== ''}
                            helperMessage={errors.accountHolderDetails?.accountHolderName}
                            disableHelperMessageHeight={true}
                            marginBottom={'sm'}
                            onChangeValue={(e) => {
                                handleChange('accountHolderName', e, 'accountHolderDetails')
                            }}
                            isRequired={true}
                        />
                    </div>
                    <div id='accountHolderDetails-accountNumber-id'>
                        <DLInputTextField
                            id={'account-number-input-field'}
                            label={'Account Number'}
                            value={accountHolderDetails?.accountNumber}
                            maxCharLength={20}
                            isClearable={false}
                            disableHelperMessageHeight={true}
                            marginBottom={'sm'}
                            inputType={'number'}
                            error={errors.accountHolderDetails?.accountNumber !== ''}
                            helperMessage={errors.accountHolderDetails?.accountNumber}
                            onChangeValue={(e) => {
                                handleChange('accountNumber', e, 'accountHolderDetails')
                            }}
                            isRequired={true}
                        />
                    </div>
                    <div id='accountHolderDetails-bankAccountType-id'>
                        <DLSearchAutoComplete
                            id={'account-type-dropDown'}
                            label={'Account Type'}
                            value={accountHolderDetails?.bankAccountType}
                            placeholder={'Select'}
                            onSelect={(e) => handleChange('bankAccountType', e, 'accountHolderDetails')}
                            isClearable={false}
                            isSearchable={false}
                            error={errors.accountHolderDetails?.bankAccountType !== ''}
                            helperMessage={errors.accountHolderDetails?.bankAccountType}
                            itemNotFoundText={'No options found'}
                            isMulti={false}
                            dropDownPlacement={'top'}
                            disableHelperMessageHeight={true}
                            marginBottom={'sm'}
                            defaultOptions={dropdownValues?.accountTypes}
                            noOptionsMessage={'No options found'}
                            isRequired={true}
                        />
                    </div>
                </div>
            </div>
        )
    };


    const renderButtonUI = () => {
        const {onNextClick, isFromOnboardingFlow, onSaveAndCloseClick} = props;
        return (
            <div className='sticky-bottom-buttons'>
                {isFromOnboardingFlow ?
                    <>
                        <DLButton
                            id={'save&close-button'}
                            styleType={'outlined'}
                            borderType={'square'}
                            buttonSize={'sm'}
                            fontSize={'sm'}
                            sideMargin={'none'}
                            onClick={() => {
                                onSaveAndCloseClick()
                            }}
                            label={'Save & Close'}
                        />
                        <DLButton id={'next-button'}
                                  buttonSize={'sm'}
                                  fontSize={'sm'}
                                  sideMargin={'none'}
                                  onClick={() => {
                                      onNextClick()
                                  }}
                                  label={'Next'}/>
                    </>
                    :
                    <DLButton id={'delete-button'}
                              buttonSize={'sm'}
                              fontSize={'sm'}
                              sideMargin={'none'}
                              onClick={() => {
                                  onNextClick()
                              }}
                              label={"Save"}/>
                }

            </div>
        );
    };

    const renderErrorUI = () => {
        const {responseErrors, totalErrors} = props;

        if (isEmpty(responseErrors) || totalErrors === 0) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className='pt-2 mt-1 px-5'>
                {responseErrors.slice(0, 3).map((errorMessage, index) => (
                    <div key={index} className='pb-2 align-items-center d-flex message-block'>
                        <div className='error-vertical-line'/>
                        <div className='error-panel'>
                            {errorMessage}
                        </div>
                    </div>
                ))}
                {totalErrors > 3 && (
                    <div className='pb-2 align-items-center d-flex'>
                        {`...${totalErrors - 3} More`}
                    </div>
                )}
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isLoaderVisible}/>);
    };

    const renderDesktopUi = () => {

        return <div>
            {renderBankDetails()}
            {renderBankAddressDetails()}
            {renderAccountHolderDetails()}
            {renderButtonUI()}
        </div>
    };

    const renderUi = () => {
        const {isLGView} = props;
        if (isMobileView) {
            return (
                <div>
                    <div className='px-3 mb-sm-2'>
                        {renderUpLoaderUi()}
                    </div>
                    {renderMobileUi()}
                </div>
            );
        }

        return (
            <div style={{
                overflowY: 'auto',
                maxHeight: !isFromOnboardingFlow ? 'calc(100dvh - 217px)' : 'calc(100dvh - 217px)'
            }}>
                {renderErrorUI()}
                <div className='row p-0 m-0'>
                    <div className="col-6 px-5 pe-0">
                        {renderUpLoaderUi()}
                    </div>
                    <div className='col-6 px-5 p-0'>
                        {renderDesktopUi()}
                    </div>
                </div>
            </div>
        );
    };

    return (<div>
        {renderUi()}
        {renderLoader()}
    </div>);
};

export default CancelledChequeView;
