import React from 'react';
import moment from "moment";

import DLText from "../../../core/components/DLText/DLText";
import DLDropDown from "../../../core/components/DLDropdown/DLDropDown";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

import AccountHolderCreate from "./accountHolderCreate";
import AccountDeleteModal from "./accountRemove";

import {isEmpty} from "../../../utils/validations";

import {modeOfHolding} from "../../OnboardingConsts";
import KYCStatus from "../kyc/KYCStatus";
import RenderKYCStatusBadge from "../kyc/RenderKYCStatusBadge";

import "../../../assets/css/app.css";
import "../../../assets/css/listing.css";
import appTheme from "../../../assets/appTheme";
import DLTooltip from "../../../core/components/DLTooltip/DLTooltip";

const IInVerificationView = (props) => {
    const {
        isShowLoader, isMobileView, selectedAccountType, isOpenCreateModal, openCreateModal, onChangeData,
        isXSView, handleOpenKycStatusModal, refreshButton, isLGView
    } = props;


    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderHeader = () => {
        return (
            <div className='page-header'>
                Onboarding
            </div>
        )
    };

    const renderAccountDetailsModal = () => {
        const {history, closeCreateModal, selectedAccountType, handleAddPAN, getKycStatus, PANDetails} = props;

        if (isMobileView) {
            if (isOpenCreateModal) {
                return <div className="modal-overlay " onClick={closeCreateModal}>
                    <div className="modal-content box-shadow h-100" onClick={(e) => e.stopPropagation()}>
                        <AccountHolderCreate
                            history={history}
                            isOpenCreateModal={isOpenCreateModal}
                            closeCreateModal={closeCreateModal}
                            selectedAccountType={selectedAccountType}
                            handleAddPAN={handleAddPAN}
                            getKycStatus={getKycStatus}
                            PANDetails={PANDetails}
                            isOverlay={true}
                        />
                    </div>
                </div>
            }
        } else {
            return (
                <AccountHolderCreate
                    history={history}
                    isOpenCreateModal={isOpenCreateModal}
                    closeCreateModal={closeCreateModal}
                    selectedAccountType={selectedAccountType}
                    handleAddPAN={handleAddPAN}
                    getKycStatus={getKycStatus}
                    PANDetails={PANDetails}
                />
            )
        }
    };

    const renderRemoveModal = () => {
        const {history, handleCloseRemoveModal, isOpenRemoveModal, handleRemovePANDetails, removePANNumber} = props;

        return (
            <AccountDeleteModal
                history={history}
                removePANNumber={removePANNumber}
                isOpenRemoveModal={isOpenRemoveModal}
                handleCloseRemoveModal={handleCloseRemoveModal}
                handleRemovePANDetails={handleRemovePANDetails}
            />
        )
    };

    const renderAccountHolderDetails = (PANDetails) => {

        return (
            <div>
                <div className={((isEmpty(PANDetails?.kycDetails)) ? '': 'border-bottom ') +'d-flex justify-content-between pb-2 mb-2 '}>
                    <div className=' pb-2 row '>
                        <div className='data-label col-5 '
                             style={{minWidth: '50px'}}>
                            <DLText id={'kyc-Summary'}
                                    fontSize={'xs'}
                                    fontColor={'grayDark'}
                                    text={'PAN'}/></div>
                        <div className=' mb-3'>
                            <div className='d-flex align-items-center data-value'>
                                {isEmpty(PANDetails.PAN_Number) ? '-' : PANDetails.PAN_Number}
                                {PANDetails?.existingPerson ?
                                    ''
                                    : <span className='badge-blue'>New</span>
                                }
                            </div>
                        </div>
                    </div>
                    {isEmpty(PANDetails.firstName) && isEmpty(PANDetails.lastName) ? <></> :
                        <div className='col-7'>
                            <div className='data-label' style={{minWidth: '50px'}}>
                                <DLText id={'kyc-Summary'}
                                        fontSize={'xs'}
                                        fontColor={'grayDark'}
                                        text={'Name'}/></div>
                            <div className=' '>
                                <div className='data-value'>{createFullName(PANDetails)}</div>
                            </div>
                        </div>
                    }
                </div>
                {(!isEmpty(PANDetails?.kycDetails)) &&
                    <div className=''>
                        <div className='data-label'
                             style={{minWidth: '50px'}}>
                            <DLText id={'kyc-Summary'}
                                    fontSize={'xs'}
                                    fontColor={'grayDark'}
                                    text={'KYC Status'}/>
                        </div>
                        <div className=' '>
                            <div className='d-flex align-items-center data-value '>
                                {!isEmpty(PANDetails?.kycDetails) &&
                                <div className='d-flex'>
                                    <RenderKYCStatusBadge status={PANDetails?.kycDetails?.kycStatus?.description}/>
                                    {isEmpty(PANDetails?.kycDetails?.lastUpdatedDate) ? ''
                                        :  selectedAccountType === 'JOINT' ? isLGView ? '' : <span
                                            className='align-self-center px-2'>
                                                <DLText id={'kyc-Summary'}
                                                        fontSize={'xs'}
                                                        fontColor={'grayDark'}
                                                        marginBottom={'none'}
                                                        text={'as of ' + moment(PANDetails?.kycDetails.lastUpdatedDate).format('DD-MM-YYYY')}/>
                                            </span>
                                            :
                                            <span
                                            className='align-self-center px-2'>
                                                <DLText id={'kyc-Summary'}
                                                        fontSize={'xs'}
                                                        fontColor={'grayDark'}
                                                        marginBottom={'none'}
                                                        text={'as of ' + moment(PANDetails?.kycDetails.lastUpdatedDate).format('DD-MM-YYYY')}/>
                                            </span>
                                    }
                                </div>}
                                <div
                                    onClick={() => refreshButton(PANDetails)}
                                >
                                    <DLTooltip tooltipPlacement={'right'}
                                               tooltipLabel={
                                                   <i className="fa-solid fa-rotate-right align-self-center px-2  cursor"/>
                                               }
                                               id={''}
                                               customUi={true}
                                               tooltipContent={<DLText id={''}
                                                                       fontSize={'xs'}
                                                                       text={'Refresh from KYC Portal'}
                                                                       marginBottom={'none'}/>}
                                    />
                                </div>
                                <div className='cursor d-flex flex-grow-1 justify-content-end'
                                     onClick={() => handleOpenKycStatusModal(PANDetails)}>
                                    <div style={{color: appTheme.linkStyle.color}}><u> View More </u></div>
                                </div>
                            </div>
                            {isLGView && selectedAccountType === 'JOINT' && <div
                                className='align-self-center' >
                                                <DLText id={'kyc-Summary'}
                                                        fontSize={'xs'}
                                                        fontColor={'grayDark'}
                                                        marginBottom={'none'}
                                                        text={'as of ' + moment(PANDetails?.kycDetails.lastUpdatedDate).format('DD-MM-YYYY')}/>
                                            </div>}
                        </div>
                    </div>}
            </div>

        )
    };

    function createFullName(PANDetails) {
        const {firstName, middleName, lastName} = PANDetails;

        // Create an array of names and filter out empty values
        const names = [firstName, middleName, lastName].filter(name => name?.trim() !== '');

        // Join the names with a space and return the result
        return names.join(' ') || '';
    }

    const renderStatus = () => {
        const {NSEOnboardingStatus, isLGView, isXLView, selectedAccountType} = props;

        if (isEmpty(NSEOnboardingStatus)) {
            return <></>
        }

        const getWidth = () => {
            if (selectedAccountType === 'INDIVIDUAL') {
                return isMobileView ? '100%' : (isLGView || isXLView) ? '716px' : '100%';
            }
            return '100%';
        };

        return (
            <div className={'pt-3 mt-1 d-flex' + (isMobileView ? ' w-100' : '')} style={{minHeight: '200px'}}>
                <div className='mb-5' style={{width: getWidth()}}>
                    <div className='panel p-0'>
                        <div className='panel-header'>
                            NSE Onboarding Status
                        </div>
                        {isEmpty(NSEOnboardingStatus?.iin) ?
                            <div className='panel-container' style={{minHeight: '60px'}}>
                                <DLText
                                    id={'NSE-status'}
                                    text={'Not Found.'}
                                />
                            </div>
                            :
                            <div className='panel-container' style={{minHeight: '120px'}}>
                                {NSEOnboardingStatus.isIINAssociatedWithOtherBroker ?
                                    <DLText id={'NSE-status'}
                                            text={'This person is already having an account with another broker.'}
                                            fontSize={"xs"}/>
                                    :
                                    <DLText id={'NSE-status'}
                                            text={'This person is already having an account with following details.'}
                                            fontSize={"xs"}/>
                                }

                                <div className='d-flex justify-content-start gap-4 py-2'>
                                    <DLText
                                        id={'NSE-status'}
                                        text={'IIN'}
                                        fontColor={'gray'}
                                    />
                                    <DLText
                                        id={'NSE-status'}
                                        text={NSEOnboardingStatus?.IINDetails?.IIN}
                                    />
                                </div>

                                <div key={'err-NSE-warning'}
                                     className='py-2  d-flex align-items-center justify-content-center'>
                                    <div className='warning-vertical-line'/>
                                    <div className='warning-panel w-100'>
                                        {NSEOnboardingStatus.isIINAssociatedWithOtherBroker ?
                                            <DLText
                                                id={'NSE-warning'}
                                                text={'You need to submit ARN change form to transfer this account.'}
                                                fontSize={"xs"}
                                                marginBottom={'none'}
                                            />
                                            :
                                            <DLText
                                                id={'NSE-warning'}
                                                text={'You cannot create a new ' + (selectedAccountType === 'JOINT' ? ' joint' : ' individual' ) +' account for ' + NSEOnboardingStatus?.IINDetails?.investorName}
                                                fontSize={"xs"}
                                                marginBottom={'none'}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };

    const renderAddButton = (index) => {

        return (<div className='w-100 d-flex justify-content-center py-3 my-1'>
            <DLButton id={'add' + index}
                      styleType={"outlined"}
                      buttonSize={'sm'}
                      onClick={() => {
                          openCreateModal(index)
                      }}
                      isDisabled={checkIsDisabled(index)}
                      type={'black'}
                      label={'Add'}/>
        </div>);
    };

    const checkIsDisabled = (buttonIndex) => {
        const {PANDetails} = props;

        if (isEmpty(buttonIndex) || buttonIndex === 0) {
            return false;
        }

        if (buttonIndex === 1) {
            return isEmpty(PANDetails[0]?.PAN_Number);
        }

        return isEmpty(PANDetails[0]?.PAN_Number) || isEmpty(PANDetails[1]?.PAN_Number);
    };


    const getAccountHolderName = (index) => {
        if (selectedAccountType === 'INDIVIDUAL') {
            return 'Account Holder'
        }
        return 'Account Holder ' + (index + 1)
    };

    const getMinWidthForAccountBlock = () => {
        const {isXSView, isSMView, isMDView, isLGView, isXLView, isMobileView} = props;

        if (selectedAccountType !== 'INDIVIDUAL') {
            return '100%';
        }

        if (isMobileView) {
            if (isXSView) {
                return '100%';
            } else if (isSMView) {
                return '75%';
            } else if (isMDView) {
                return '50%';
            }
        } else {
            if (isXLView || !isLGView) {
                return '35%';
            } else if (isLGView) {
                return '50%';
            }
        }
        return '';
    };


    const renderAccountHolderBlock = (PANDetails, index) => {
        const {handleOpenRemoveModal} = props;

        return (
            <div className='W-100 panel h-100 p-0'
                 style={{width: getMinWidthForAccountBlock()}}>
                <div className='d-flex align-items-center justify-content-between panel-header'>
                    {getAccountHolderName(index)}
                    {(!PANDetails.isEmptyBlock) &&
                    <i
                        style={{fontSize: 15}}
                        className='fas fa-trash-alt mx-1 default-icon cursor'
                        onClick={() => {
                            handleOpenRemoveModal(PANDetails.PAN_Number)
                        }}
                        hidden={isShowLoader}
                    />
                    }
                </div>
                <div className='panel-container' style={{minHeight: '120px'}}>
                    {(PANDetails.isEmptyBlock) ? renderAddButton(index) : renderAccountHolderDetails(PANDetails)}
                </div>
            </div>
        )
    };

    const renderIndividualAccount = () => {
        const {PANDetails} = props;

        return (
            <div className='w-100'>
                {renderAccountHolderBlock(PANDetails[0])}
            </div>
        )
    };

    const renderJointAccount = () => {
        const {PANDetails} = props;

        return (<div className={isMobileView ? '':'w-100 d-md-flex justify-content-between'} style={{gap: isXSView ? 30 : 20}}>
            {
                PANDetails.map((item, index) => (
                    <div className={isMobileView && !isXSView ? 'pb-md-0 pb-4 w-sm-75 w-100 mb-4':'pb-md-0 pb-4 w-sm-75 w-100'} key={index} style={{flex: 1}}>
                        {renderAccountHolderBlock(item, index)}
                    </div>
                ))
            }
        </div>)
    };

    const renderPANAccount = () => {

        switch (selectedAccountType) {
            case 'INDIVIDUAL':
                return renderIndividualAccount();
            case 'JOINT':
                return renderJointAccount();
            default :
                return ''
        }
    };

    const renderMobile = () => {
        const {accountTypes, selectedAccountType, selectedModeOfHolding, holdingNatures, selectedModeOfHoldingError} = props;
        return (
            <div className='m-1'>
                <DLText id='block-title'
                        text={'IIN/KYC Verification'}
                        fontWeight={"semi-bold"}
                        fontSize={"lg"}
                />
                <div>
                    <DLText id='block-sub-title'
                            text={'Simplicity uses NSE Mutual Fund Platform for all Mutual Fund Investments. An investor identification number(IIN) is alloted by NSE for each customer account.'}
                    />
                    <DLText id='enter-panel-title'
                            text={'Please enter your PAN details to check for existing IINs against your PAN.'}
                    />
                </div>
                {renderErrorUI()}
                <div className='mt-4'>
                    <div style={{color: 'var(--gray-dark)'}}>
                        Account Type
                    </div>
                    <div className='pt-2'>
                        <DLDropDown
                            id={'reason-dropdown'}
                            label={''}
                            noOptionsMessage={'No options found'}
                            marginBottom={"none"}
                            options={accountTypes}
                            onSelect={(val) => {
                                onChangeData('selectedAccountType', val)
                            }}
                            value={selectedAccountType}
                            minWidth={isXSView ? '100%' : '50%'}
                        />
                    </div>
                </div>
                <div className='mt-4'>
                    <div style={{color: 'var(--gray-dark)'}}>
                        Mode of Holding
                    </div>
                    <div className='pt-2'>
                        <DLDropDown
                            id={'reason-dropdown'}
                            label={''}
                            labelPlacement={'top'}
                            noOptionsMessage={'No options found'}
                            marginBottom={"none"}
                            options={getOptionsForModeOfHolding(holdingNatures)}
                            isDisabled={(selectedAccountType === 'INDIVIDUAL' && selectedModeOfHolding === 'SI')}
                            onSelect={(val) => {
                                onChangeData('selectedModeOfHolding', val)
                            }}
                            helperMessage={selectedModeOfHoldingError}
                            isRequired={true}
                            error={!isEmpty(selectedModeOfHoldingError)}
                            value={selectedModeOfHolding}
                            minWidth={isXSView ? '100%' : '50%'}
                        />
                    </div>
                </div>
                <div className={isMobileView ? 'mt-4 pt-2':'mt-4 pt-2 d-flex'}>
                    {renderPANAccount()}
                </div>
                <div className='mt-3 pt-2 d-flex pb-5'>
                    {renderStatus()}
                </div>
            </div>
        )
    };

    const getOptionsForModeOfHolding = (holdingNatures) => {
        const {selectedAccountType} = props;

        holdingNatures.forEach(nature => {
            modeOfHolding.forEach(mode => {
                if (nature.value === mode.val) {
                    nature.label = mode.label;
                }
            });
        });

        if (selectedAccountType === "JOINT") {
            holdingNatures = holdingNatures.filter(nature => nature.label !== 'Single')
        }
        return holdingNatures
    };

    const renderDesktop = () => {
        const {accountTypes, selectedAccountType, selectedModeOfHolding, holdingNatures, selectedModeOfHoldingError} = props;

        return (
            <div id='desktop-view'
                 className='bg-white p-3 border-rounded'
                 style={{minHeight: 'calc(100dvh - 154px)'}}>
                <div>
                    <DLText
                        id='block-title'
                        text={'IIN/KYC Verification'}
                        fontWeight={"semi-bold"}
                        fontSize={"lg"}
                    />
                    <div>
                        <DLText
                            id='block-sub-title'
                            text={'Simplicity uses NSE Mutual Fund Platform for all Mutual Fund Investments. An investor identification number(IIN) is alloted by NSE for each customer account.'}/>
                        <DLText
                            id='enter-panel-title'
                            text={'Please enter your PAN details to check for existing IINs against your PAN.'}
                        />
                    </div>
                    {renderErrorUI()}
                    <div className='d-flex align-items-center justify-content-start gap-3 py-3'>
                        <DLDropDown
                            id={'reason-dropdown'}
                            label={'Account Type'}
                            labelPlacement={'top'}
                            noOptionsMessage={'No options found'}
                            marginBottom={"none"}
                            options={accountTypes}
                            onSelect={(val) => {
                                onChangeData('selectedAccountType', val)
                            }}
                            disableHelperMessageHeight={isEmpty(selectedModeOfHoldingError)}
                            value={selectedAccountType}
                            minWidth={isXSView ? '100%' : '350px'}
                        />

                        <DLDropDown
                            id={'reason-dropdown'}
                            label={'Mode of Holding'}
                            labelPlacement={'top'}
                            noOptionsMessage={'No options found'}
                            marginBottom={"none"}
                            options={getOptionsForModeOfHolding(holdingNatures)}
                            isDisabled={(selectedAccountType === 'INDIVIDUAL' && selectedModeOfHolding === 'SI')}
                            onSelect={(val) => {
                                onChangeData('selectedModeOfHolding', val)
                            }}
                            disableHelperMessageHeight={isEmpty(selectedModeOfHoldingError)}
                            helperMessage={selectedModeOfHoldingError}
                            isRequired={true}
                            error={!isEmpty(selectedModeOfHoldingError)}
                            value={selectedModeOfHolding}
                            minWidth={isXSView ? '100%' : '350px'}
                            fontColor={selectedAccountType === 'INDIVIDUAL' ? 'grayDark' : 'black'}
                            placeholder={'Select'}
                        />
                    </div>

                    <div className='pt-1 d-flex' style={{minHeight: '200px'}}>
                        {renderPANAccount()}
                    </div>
                    {renderStatus()}

                </div>
            </div>
        );
    };

    const renderButtons = () => {
        const {canCreateAccount, handleContinueClick, handleCancelClick} = props;

        return (
            <div className='sticky-bottom-buttons'>
                <DLButton
                    id={'cancel-button'}
                    styleType={"outlined"}
                    borderType={'square'}
                    buttonSize={'sm'}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    style={{
                        border: "1px solid var(--black)",
                        background: "var(--white)",
                        color: "var(--black)",
                        fontWeight: '500',
                        minWidth: '150px'
                    }}
                    onClick={() => handleCancelClick()}
                    label={"Cancel"}
                />
                <DLButton id={'continue-button'}
                          buttonSize={'sm'}
                          fontSize={'sm'}
                          sideMargin={'none'}
                          isDisabled={!canCreateAccount}
                          style={{
                              minWidth: '150px'
                          }}
                          onClick={() => handleContinueClick()}
                          label={'Continue'}/>
            </div>
        );
    };

    const renderKycStatusModal = () => {
        const {isMobileView, handleCloseKycStatusModal, isKycStatusModelOpen} = props;

        if (isKycStatusModelOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseKycStatusModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <KYCStatus {...props} isOverlay={true} isFromIIN={false}/>
                    </div>
                </div>
            } else {
                return (
                    <KYCStatus {...props} isFromIIN={false}/>
                )
            }
        }

    };


    const renderScreen = () => {
        if (isMobileView) {
            return <div className='page-container'>
                {renderMobile()}
            </div>
        }
        return <div className='bg-light page-container'>
            {renderDesktop()}
        </div>
    };

    const renderErrorUI = () => {
        const {error} = props;
        if (isEmpty(error)) {
            return '';
        }
        return (
            <div id='page-level-errors'
                 className={isMobileView ? 'mt-3' : 'pt-3'}>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {error}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={isMobileView ? 'bg-white ' : 'bg-light h-100'}>
            {renderHeader()}
            {renderScreen()}
            {renderButtons()}
            {renderAccountDetailsModal()}
            {renderRemoveModal()}
            {renderLoader()}
            {renderKycStatusModal()}
        </div>
    );
};

export default IInVerificationView;