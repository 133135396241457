import React, {Component} from 'react';
import Box from '@mui/material/Box';
import '../../../assets/css/dlComponent.css'
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {isEmpty} from "../DLComponentHelper";
import {getHeight} from "../DLComponentHelperWeb";


class DLDropDownView extends Component {

    constructor(){
        super();
        this.state={
            bottom: false,
        }
    }

    getStyle = () =>{
        return{
            fontSize:'14px',
            color:'#575757',
            fontWeight:500
        }
    };

    toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        this.setState({ ...this.state, [anchor]: open });
    };

    getValue = () =>{
        const {value, options} = this.props;

        if(isEmpty(value)){
            return <span>{''}</span>
        }

        let simulatedValue = options.find((option)=>{
            return option.value === value
        });

        if(isEmpty(simulatedValue)){
            return <span>{''}</span>
        }

        return <span style={{...this.getStyle()}}>{simulatedValue.label}</span>
    };

    render() {

        const {options, onSelect, inputHeight, title} = this.props;
        // onKeyDown={this.toggleDrawer(anchor, false)}

        const list = (anchor) => (
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                role="presentation"
            >
                <List className='px-1'>
                    <div className='d-flex justify-content-between border-bottom px-3 mb-2'
                         style={{paddingTop:8, paddingBottom:12}}>
                        <div className='' >
                            <span style={{fontSize: '18px', fontWeight: 500, fontFamily: 'Barlow'}}>{title}</span>
                        </div>
                        <div>
                            <i className="fa-sharp fa-solid fa-circle-xmark cross-icon"
                               onClick={() => {
                                   this.setState({
                                       bottom:false
                                   })
                               }}/>
                        </div>
                    </div>
                    <div style={{maxHeight:'300px', overflowY:'auto'}}>
                    {options.map((option, index) => (
                        <ListItem key={index} disablePadding onClick={()=> {
                            this.setState({
                                bottom:false
                            });

                            onSelect(option.value)
                        }}>
                            <ListItemButton>
                                <ListItemText className='optionsStyle' primary={option.label}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                    </div>
                </List>
            </Box>
        );

        return (
                <React.Fragment>
                    <div className='inputBox'
                         style={{minHeight: getHeight(inputHeight)}}
                         onClick={this.toggleDrawer('bottom', true)}>
                         {this.getValue()}
                         <i className="fa-solid fa-sort-down d-flex" style={{color:'#276dbc', marginTop: '-5px'}}/>
                     </div>

                    <SwipeableDrawer
                        anchor={'bottom'}
                        open={this.state['bottom']}
                        onClose={this.toggleDrawer('bottom', false)}
                    >
                        {list('bottom')}
                    </SwipeableDrawer>
                </React.Fragment>
        );
    }
}



export default DLDropDownView;
