import store from "../../store";
import {
    resetFTAllFilters, setFTCRMIdValue,
    setFTLimitCountValue,
    setFTPageValue,
    setFTRowsPerPageValue, setFTSearchKeywordValue,
    setFTSkipCountValue, setFTStatusValue
} from "./FTReducer";
import {httpDelete, httpGet, httpPost, httpPut} from "../../utils/httpHelper";


export const setFTSkipCount = (data) => {
    store.dispatch(setFTSkipCountValue(data))
};

export const setFTLimitCount = (data) => {
    store.dispatch(setFTLimitCountValue(data))
};

export const setFTPageCount = (data) => {
    store.dispatch(setFTPageValue(data))
};

export const setFTRowPerPageCount = (data) => {
    store.dispatch(setFTRowsPerPageValue(data))
};

export const resetFTFilters = (data) => {
    store.dispatch(resetFTAllFilters(data))
};

export const setFTSearchKeyword = (data) => {
    store.dispatch(setFTSearchKeywordValue(data))
};

export const setFTCRMId = (data) => {
    store.dispatch(setFTCRMIdValue(data))
};

export const setFTStatus = (data) => {
    store.dispatch(setFTStatusValue(data))
};

export const getFTStatuses = () => {
    return httpGet('/service/advisory/financialTransaction/status/all', true).then(res => {
        return res;
    })
};

export const getFinancialTransactionRequest = (data) => {
    return httpPost('/service/advisory/financialTransaction/all', data, true).then(res => {
        return res;
    })
};

export const getFTTypes = () => {
    return httpGet('/service/advisory/txnRequestType/all', true).then(res => {
        return res;
    })
};

export const getTransactionDetails = (requestId) => {
    return httpGet('/service/advisory/financialTransaction/fundRequest/all?txnRequestId=' + requestId).then(res => {
        return res;
    });
};

export const discardTransaction = (requestId) => {
    return httpDelete('/service/advisory/financialTransaction?txnRequestId=' + requestId, {}).then(res => {
        return res;
    })
};

export const sendToManagement = (data) => {
    return httpPut('/service/advisory/financialTransaction/management/in_progress', data, true).then(res => {
        return res;
    })
};

export const moveToDraft = (data) => {
    return httpPut('/service/advisory/financialTransaction/status/draft', data, true).then(res => {
        return res;
    })
};

export const approveTransaction = (data) => {
    return httpPut('/service/advisory/financialTransaction/management/approve', data, true).then(res => {
        return res;
    })
};

export const rejectTransaction = (data) => {
    return httpPut('/service/advisory/financialTransaction/management/reject', data, true).then(res => {
        return res;
    })
};

export const getPaymentModes = () => {
    return httpGet('/service/advisory/paymentMechanism/all').then(res => {
        return res;
    });
};

export const getChequeDepositModes = () => {
    return httpGet('/service/advisory/chequeDepositMode/all').then(res => {
        return res;
    });
};

export const editPaymentDetails = (data, handleErrorInComponent, type) => {
    if (type === "EDIT") {
        return httpPut('/service/advisory/financialTransaction/payment', data, handleErrorInComponent).then(res => {
            return res;
        })
    } else {
        return httpPost('/service/advisory/financialTransaction/payment', data, handleErrorInComponent).then(res => {
            return res;
        });
    }
};

export const getPaymentDetails = (paymentId) => {
    return httpGet('/service/advisory/financialTransaction/payment?paymentId=' + paymentId).then(res => {
        return res;
    });
};

export const submitFundRequest = (data, handleErrorInComponent) => {
    return httpPost('/service/advisory/financialTransaction/fundRequest', data, handleErrorInComponent).then(res => {
        return res;
    });
};

export const serachPurchaseProducts = (data) => {
    return httpPost('/service/advisory/master/product/search', data, true).then(res => {
        return res;
    });
};

export const selectProduct = (txnRequestId, productId) => {
    return httpGet('/service/advisory/financialTransaction/product/limits?txnRequestId='+txnRequestId+'&productId='+productId, true).then(res => {
        return res;
    });
};

export const addPurchaseScheme = (data) => {
    return httpPost('/service/advisory/financialTransaction/fund', data, true).then(res => {
        return res;
    });
};