import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";

import FTDetailsView from "./FTDetailsView";

const FTDetails = (props) => {
    const [FTDetailsState, setFTDetailsState] = useState({
        isManagementUser: false,
        isSetUserType: false
    });

    useEffect(() => {
        const {profile} = props;
        setFTDetailsState((prevState) => ({
            ...prevState,
            isManagementUser: profile?.isManagement,
            isSetUserType: true
        }))
    }, []);

    return (
        <FTDetailsView
            {...props}
            {...FTDetailsState}
        />);
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(FTDetails);
