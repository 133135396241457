import React from 'react';
import DLButton from "../../../core/components/DLButton/DLButton";
import DLModal from "../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLText from "../../../core/components/DLText/DLText";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import appTheme from "../../../assets/appTheme";

const MarkAsCompletedView = (props) => {

    const {
        history, isMarkAsCompletedModalOpen,
        isApiInProgress, modalErrorMessage, handleMarkAsCompleted, handleCloseMarkAsCompletedModal, isApiSuccessful, handleDone
    } = props;

    const renderModalError = () =>{
        if(modalErrorMessage){
            return(
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderUiText = () =>{
        if(isApiSuccessful){
            return(
                <div className='mb-2 pb-1 text-center'>
                    <div>
                        <i className="fa-solid fa-circle-check"
                           style={{color: appTheme.appColor.success, fontSize : '48px'}}/>
                    </div>
                    <DLText id={''}
                            text={"Onboarding completed successfully!"}
                            fontSize={"sm"}
                            fontColor={"success"}
                            fontWeight={"semi-bold"}
                            marginBottom={"none"}
                    />
                </div>
            )
        }
        return(
            <div className='mb-2 pb-1 text-center'>
                <DLText id={''}
                        text={"Are you sure you want to mark the onboarding as complete?"}
                        fontSize={"sm"}
                        fontWeight={"semi-bold"}
                        marginBottom={"none"}
                />
                <div className='my-2'>
                    <DLText id={''}
                            text={"An email will be sent to the client confirming"}
                            fontSize={"sm"}
                            marginBottom={"none"}
                    />
                    <DLText id={''}
                            text={"successful onboarding."}
                            fontSize={"sm"}
                            marginBottom={"none"}
                    />
                </div>
                <DLText id={''}
                        text={"This action cannot be undone."}
                        fontSize={"sm"}
                        fontColor={"danger"}
                        marginBottom={"none"}
                />
            </div>
        )
    };

    const renderButtons = () =>{
        if(isApiSuccessful){
            return(
                <div>
                    <DLButton
                        id={'mark-as-completed-doneBtn'}
                        history={history}
                        label={'Done'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => handleDone()}/>
                </div>
            )
        }
        return(
            <div>
                <DLButton
                    id={'mark-as-completed-cancelBtn'}
                    history={history}
                    label={'Cancel'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => handleCloseMarkAsCompletedModal()}/>
                <DLButton
                    id={'mark-as-completed-Btn'}
                    history={props.history}
                    label={'Mark as Complete'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    sideMargin={"sm"}
                    onClick={() => handleMarkAsCompleted()}
                />
            </div>
        )
    }

    const renderMarkAsCompletedModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"sm"}
                    onClose={() => handleCloseMarkAsCompletedModal()}
                    open={isMarkAsCompletedModalOpen}
                    id={'mark-as-completed-modal'}>
                    <DLModalTitle
                        id={'mark-as-completed-title'}
                        onClose={() => handleCloseMarkAsCompletedModal()}
                        title={'Confirmation'}/>
                    <DLModalContainer
                        id={'mark-as-completed-container'}>
                        <div>
                            {renderModalError()}
                            {renderUiText()}
                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'mark-as-completed-action'}>
                        <div className='mb-4' style={{paddingTop: '7px'}}>
                            {renderButtons()}
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    const overlayMarkAsCompleted = () => {
        return (
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='p-3'>
                        <DLText
                            id={'mark-as-completed-text'}
                            fontSize={'md'}
                            marginBottom={"none"}
                            fontWeight={'semi-bold'}
                            text={'Confirmation'}
                        />
                    </div>
                    <div className='p-3'>
                        <i className="fa-solid fa-xmark cursor default-icon"
                           onClick={() => handleCloseMarkAsCompletedModal()}/>
                    </div>
                </div>
                <div className='px-3'
                     style={{
                         maxHeight: 'calc(100dvh - 190px)',
                         overflow: 'auto',
                     }}>
                    <div className='pb-5'>
                        {renderModalError()}
                        <div className='my-4'>
                            {renderUiText()}
                        </div>
                    </div>
                </div>
                <div className='fixed-bottom mb-2'>
                    <div className='mb-2 d-flex justify-content-center bg-white'
                         style={{paddingTop: '7px', minHeight: '43px'}}>
                        {renderButtons()}
                    </div>
                </div>
            </div>
        )
    };

    const renderLoader = () => {
        return (
            <DLLoader type={"screen"} isVisible={isApiInProgress}/>
        )
    }

    return (
        <div>
            {renderLoader()}
            {props.isOverlay ? overlayMarkAsCompleted() : renderMarkAsCompletedModal()}
        </div>
    );
};

export default MarkAsCompletedView;