import React from 'react';

import appTheme from "../../assets/appTheme";
import logo from "../../assets/img/simplicity_logo.jpg";

import DLButton from "../../core/components/DLButton/DLButton";
import OtpInput from "react-otp-input";
import DLText from "../../core/components/DLText/DLText";
import DLLoader from "../../core/components/DLLoader/DLLoader";

const ClientsVerificationView = (props) => {
    const {isMobileView, isXSView, isShowLoader} = props;

    const renderCodeVerifyUI = () => {
        const {
            handleChange, validateOtp, resendCode, isMobileView, otp, otpLengthError, otpError
        } = props;
        return (
            <>
                <div className="d-flex flex-column justify-content-center align-items-center h-100">
                    <div className="text-center">
                        <DLText id={'enter-security-code'}
                                alignText={"center"}
                                text={'Enter Security Code'}
                                fontWeight={"bold"}
                                marginBottom={'md'}
                                fontSize={"lg"}/>
                        <div className='mb-5'>
                            <DLText id={'eventItem-clone-text1'}
                                    type={'normal'}
                                    alignText={"center"}
                                    marginBottom={'none'}
                                    fontSize={'md'}
                                    text={'As this is a financial transaction to ensure security we have sent one time verification code to your registered email id'}
                            />
                        </div>
                        <div className='text-break text-wrap flex-wrap'>
                            <DLText id={'eventItem-clone-text1'}
                                    type={'normal'}
                                    alignText={"center"}
                                    marginBottom={'none'}
                                    fontSize={'sm'}
                                    fontColor={"grayDark"}
                                    text={'Please check the spam folder for the verification code'}
                            />
                        </div>

                        <DLText id={'email-code-digit-text'}
                                marginBottom={'sm'}
                                alignText={"center"}
                                fontSize={'sm'}
                                fontColor={"grayDark"}
                                text={'Type your 6 digit security code'}/>

                        <div className='d-flex align-items-center justify-content-center mb-3 py-3 w-100'>
                            <OtpInput id={'email-code-otp'}
                                      value={otp}
                                      onChange={(value) => handleChange('otp', value)}
                                      numInputs={6}
                                      separator={<span className={'mx-1'}/>}
                                      hasErrored={otpError || otpLengthError}
                                      shouldAutoFocus={true}
                                      marginBottom={'md'}
                                      isInputNum={true}
                                      className={isMobileView ? '' : 'flex-grow-1'}
                                      errorStyle={{borderColor: ' red'}}
                                      inputStyle={{
                                          minWidth: isMobileView ? '42px' : '55px',
                                          flexGrow: 1,
                                          height: isMobileView ? '45px' : '55px',
                                          padding: '0px 10px',
                                          backgroundColor: '#F4F4F4',
                                          borderRadius: '5px',
                                          border: '1px solid rgb(179, 179, 179)'
                                      }}
                                      focusNextInput={true}
                                      renderInput={(props) => <input {...props} className={'mx-1'}/>}
                            />
                        </div>
                        {otpLengthError !== "" &&
                        <DLText id={'email-code-error-wrong'}
                                fontColor={"danger"}
                                alignText={"center"}
                                fontSize={'md'}
                                text={otpLengthError}
                                hidden={otpError !== ""}
                        />
                        }
                        {otpError !== "" &&
                        <DLText id={'email-code-error-empty'}
                                fontColor={"danger"}
                                alignText={"center"}
                                text={otpError}
                                fontSize={'md'}
                                hidden={otpLengthError !== ""}
                        />
                        }

                    </div>
                    <div className='text-center'>
                        <DLText id={'email-code-expiry-text'}
                                type={"normal"}
                                alignText={"center"}
                                fontSize={'sm'}
                                fontColor={"grayDark"}
                                text={'The verification code will expire in next 10 minutes'}/>
                    </div>
                    <div className='d-grid mx-auto w-100 text-center my-2 py-1'>
                        <DLButton id={'email-code-verify'}
                                  onClick={() => validateOtp()}
                                  sideMargin={"none"}
                                  fontSize={'md'}
                                  buttonSize={'sm'}
                                  label={'Verify'}/>
                    </div>
                    <div className="para-text text-center">
                        <div id={'didnt-got-code-text'}>
                            <DLText id={'didnt-got-code-text'}
                                    isInline={true}
                                    fontSize={'sm'}
                                    text={'Didn\'t get code? '}
                            />
                            <DLText isClickable={true}
                                    id={'email-code-resend'}
                                    fontColor={'primary'}
                                    onClick={() => resendCode()}
                                    isInline={true}
                                    fontSize={'sm'}
                                    customTextStyle={{color: appTheme.linkStyle.color,}}
                                    text={'Resend'}/>
                        </div>
                    </div>
                </div>
            </>

        );
    };

    const renderUI = () => {
        return renderCodeVerifyUI();
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    return (
        <div className='w-100 d-flex flex-column align-items-center justify-content-center'
             style={{backgroundColor: "#F0F0F0", height: 'calc(100dvh)'}}>
            <div className="text-center mb-3">
                <span className="p-0">
                    <div className='d-flex align-items-center'>
                         <img className='rounded-2 me-1'
                              src={logo}
                              alt={'logo'}
                              width={48}
                              height={48}/>
                                <div id={"logo"}
                                     style={{color: appTheme.appColor.black, fontSize: 28, fontWeight: 500}}>
                                    Advisory
                                </div>
                    </div>
                </span>
            </div>
            <div className={isXSView ? "bg-white" : "panel bg-white"}
                 style={{
                     padding: "34px 27px 34px 27px",
                     width: isMobileView ? '' : "478px"
                 }}>
                {renderLoader()}
                {renderUI()}
            </div>
        </div>
    );
};

export default ClientsVerificationView;
