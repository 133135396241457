//noinspection DuplicatedCode,JSUnresolvedVariable,SpellCheckingInspection,JSUnresolvedFunction
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import clsx from "clsx";
import Paper from '@mui/material/Paper';
import AsyncSelect from 'react-select/async';
import {components} from "react-select";
import {
  getFontFamily,
  getTheme,
  isEmpty,
  debounce,
  getThemeColor,
  getFontSize,
  getFontColor
} from "./../DLComponentHelper";
import {muiThemeObj} from "./../DLComponentHelperWeb";
import createTheme from '@mui/material/styles/createTheme';
import "./DLSearchAutoCompleteStyle.css"

const autoCompleteMarginBottoms = {
  'none': '',
  'xxs': 'mb-1',
  'xs': 'mb-2',
  'sm': 'mb-3',
  'md': 'mb-4'
};

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

const inputComponent = React.forwardRef((props, ref) =>
  <div ref={ref} {...props} />);

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const ClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <i className="fa fa-times px-1 cursor icon-cross"  style={{color:'#666666'}}/>
    </components.ClearIndicator>
  );
};

function DropdownIndicator(props) {
  const {isDisabled} = props;
  if (isDisabled){
    return <></>
  }

   return (
      <components.DropdownIndicator {...props}>
        <i className="fa-sharp fa-solid fa-caret-down me-2" style={{color:'#666666', fontSize: 13}}/>
      </components.DropdownIndicator>
  );
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: {TextFieldProps},
  } = props;
  return (
    <TextField
      id="outlined-basic"
      label=""
      fullWidth
      size="small"
      InputLabelProps={{style: {fontFamily: getFontFamily()}}}
      InputProps={{
        inputComponent,
        inputProps: {
          className: 'autocomplete-input-container',
          style: {
            display: 'flex',
          },
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      variant={'outlined'}
      {...TextFieldProps}
    />
  );
}


function Option(props) {
  return (
    <div className="multiselect-menu-items max-height-150">
      <MenuItem
        ref={props.innerRef}
        selected={props.isFocused}
        style={{
          fontWeight: props.isSelected ? 500 : 400,
          zIndex: 9999, padding: 10,
        }}
        {...props.innerProps}>
        <div className="text-wrap"
             style={{fontSize: '14px'}}>
          {props.children}
        </div>
      </MenuItem>
    </div>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

function Placeholder(props) {

  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}>
      Please Select
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  return (
    <Typography>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return <div
    className={props.selectProps.classes.valueContainer}>
    {props.children}
  </div>;
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};


function MultiValue(props) {
  return (
    <Chip
      disabled={props.isDisabled}
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<i style={{marginTop: '10px'}} className='fa fa-times font-weight-bold px-1 cursor'/>}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}>
      {props.children}
    </Paper>
  );
}


Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};


const dropdownComponents = {
  Control,
  ClearIndicator,
  DropdownIndicator,
  SingleValue,
  IndicatorSeparator: () => null,
};

class DLSearchAutoCompleteView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      searchValue: "",
      selectedValueCopy: props.isMulti ? [] : null
    }
  }

  onInputChange = (value) => {
    this.setState({
      searchValue: value
    })
  };

  handleChangeMulti = (selectedValue) => {
    const {isMulti, onChangeSelectedValue} = this.props;
    if (isMulti) {
      onChangeSelectedValue((isEmpty(selectedValue) ? [] : selectedValue), 'web');
    } else {
      onChangeSelectedValue((isEmpty(selectedValue) ? null : selectedValue), 'web');
    }
  };


  loadSearchOptions = inputValue => new Promise(resolve => {
    this.setDebounce(inputValue, resolve);
  });

  setDebounce = debounce((inputValue, resolve) =>
      resolve(this.props.onChangeInput(inputValue)), 500);



  defaultUi = (item, index) => {
    const {t, enableTranslation} = this.props;
    return (
      <span id={'autocomplete-option-' + index}>{enableTranslation ? t(item.label) : item.label}</span>
    )
  };

  getAutocompleteStyle = (searchValue, hasOptions) => {


    let displayValue = 'block';
    if (isEmpty(searchValue) && !hasOptions) {
      displayValue = "none";
    }

    return {
      multiValue: (base) => ({
        ...base,
        fontSize: '14px',
        borderRadius: '25px',
        alignItems: 'center',
        padding: '0 5px'
      }),
      multiValueRemove: () => ({
        paddingBottom: '2px',
        cursor: 'pointer'
      }),
      menuList: (base) => ({
        ...base,
        maxHeight: '190px!important',
        borderRadius: '4px',
        fontSize: '14px',
        cursor: 'pointer',
        // boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px',
        "::-webkit-scrollbar": {
          width: "5px",
        },
        "::-webkit-scrollbar-track": {
          background: "#e9e9e9"
        },
        "::-webkit-scrollbar-thumb": {
          background: "#a8a8a8",
          borderRadius: 20
        },
      }),
      menu: (base) => ({
        ...base,
        display: displayValue,
        marginTop: '0 !important',
        zIndex: 100
      }),
      option: (base, state) => ({
        ...base,
        backgroundColor: (
          state.isSelected ? '#f5f5f5 !important' : '#ffffff !important'
          && state.isFocused ? '#f5f5f5 !important' : '#ffffff'),
        color: '#212529',
        fontSize: '14px',
        cursor: getThemeColor('black'),
        fontFamily: "'Poppins', sans-serif",

      }),
      indicatorsContainer: base => ({
        ...base,
        cursor: 'pointer',
        paddingRight:5,
        borderBottomRightRadius: 7,
        borderTopRightRadius: 7
      }),
      dropdownIndicator: base => ({
        ...base,
        color: '#276DBC',
        cursor: 'pointer',
        fontSize: '16px',
        padding: 2
      }),
      clearIndicator: (base) => ({
        ...base,
        color: getThemeColor('black'),
        cursor: 'pointer',
        fontSize: '16px',
        padding: 2
      }),
      valueContainer: (base) => ({
        ...base,
        display: 'inline-flex',
        borderBottomLeftRadius: 7,
        borderTopLeftRadius: 7,
        paddingLeft: 14,
        fontSize: '14px',
        fontFamily: "'Poppins', sans-serif",
      }),

    };
  };

  getStyle = (labelFontColor, labelFontSize, error)  =>{
    return{
      fontFamily: getFontFamily(),
      ...getFontSize(labelFontSize),
      color: (error && this.props.isRequired) ? getThemeColor('danger') : getFontColor(labelFontColor)
    }
  };

  render() {

    const {
      t, classes, selectedValue, filteredOptions, customUi, disableHelperMessageHeight,
      isMulti, isClearable, isSearchable, placeholder, enableTranslation,
      label, isRequired, helperMessage, error, isDisabled, id, elementId, marginBottom, style,
      noOptionsMessage, labelFontColor, labelFontSize,dropDownPlacement
    } = this.props;


    const {searchValue} = this.state;

    let theme = getTheme();

    const customTheme = createTheme({
      ...muiThemeObj(theme)
    });

    let customNoOptionMessage = "";

    if (isEmpty(searchValue)) {
      // do nothing
    } else {
      if (isEmpty(noOptionsMessage)) {
        customNoOptionMessage = 'No options';
      } else {
        customNoOptionMessage = noOptionsMessage;
      }
    }

    return (
      <ThemeProvider theme={customTheme}>

        <div className={autoCompleteMarginBottoms[marginBottom]}>
          <div className={'style-4'}/>
          <div
            style={{
              lineHeight: '21px',
              marginBottom: isEmpty(label) ? '0px' : '4px',
              ...style
            }}>
            {<span style={{...this.getStyle(labelFontColor, labelFontSize, error)}}>{(enableTranslation ? t(label) : label)}</span>}
            {/*{isRequired && (<span style={{ color: getThemeColor('danger')}}> *</span>)}*/}
          </div>
          <AsyncSelect
            classes={classes}
            styles={this.getAutocompleteStyle(searchValue, !isEmpty(filteredOptions))}
            cacheOptions={false}
            menuPlacement={dropDownPlacement}
            defaultOptions={filteredOptions}
            value={selectedValue}
            isMulti={isMulti}
            isDisabled={isDisabled}
            isClearable={isClearable}
            isSearchable={isSearchable}
            onInputChange={this.onInputChange}
            onChange={this.handleChangeMulti}
            loadOptions={this.loadSearchOptions}
            onMenuClose={() => {
              this.props.onChangeInput('');
            }}
            onFocus={() => {
              if (this.props.isMobileView) {
                let element = document.getElementById(id + '-search-auto-input');
                if (element) {
                  let topOffset = 90;
                  window.scrollTo({
                    top: element.getBoundingClientRect().top + window.scrollY - topOffset,
                    behavior: 'smooth',
                  });
                }
              }
            }}
            noOptionsMessage={() => customNoOptionMessage}
            formatOptionLabel={(option) => customUi ? customUi(option) : this.defaultUi(option)}
            TextFieldProps={{
              label: '',
              error: error,
              helperText: helperMessage,
              disabled: isDisabled,
              id: id + '-search-auto-input',
              InputLabelProps: {
                htmlFor: {elementId},
              },
              className: '',
            }}
            components={dropdownComponents}
            placeholder={placeholder ? placeholder : ""}
          />
        </div>
        <div style={{minHeight: disableHelperMessageHeight || !isEmpty(helperMessage) ? '0' : '19px'}}/>
      </ThemeProvider>
    );
  }

}

DLSearchAutoCompleteView.propTypes = {};

export default DLSearchAutoCompleteView;
