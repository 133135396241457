import React from 'react';
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLText from "../../../../../../core/components/DLText/DLText";
import {isEmpty} from "../../../../../../utils/validations";
import appTheme from "../../../../../../assets/appTheme";
import {getTimeFromNow} from "../../../../../../utils/helper";

const ViewActivitiesView = (props) => {
    const {history, isViewActivitiesModalOpen, handleCloseViewActivitiesModal, events, getAdvisoryUser, getSentenceCase} = props;
    const overlayViewActivitiesModel = () => {
        return (
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='p-3'>
                        <DLText
                            id={'Activities'}
                            fontSize={'md'}
                            marginBottom={"none"}
                            fontWeight={'semi-bold'}
                            text={'Activities'}
                        />
                    </div>
                    <div className='p-3'>
                        <i className="fa-solid fa-xmark cursor default-icon"
                           onClick={() => handleCloseViewActivitiesModal()}/>
                    </div>
                </div>
                {events.map((item, index) => (
                    getDetails(item, index)
                ))}

            </div>
        )
    };

    const getHeading = (type, remarks) => {
        if (type === 'Approved by NSE') {
            return <div className='d-flex' style={{lineHeight: "15px"}}>
                <DLText id={'ViewActivity-Summary'}
                        fontWeight={'semi-bold'}
                        fontSize={'sm'}
                        fontColor={'success'}
                        text={type}/>
                <div className='d-flex align-items-center'>
                    <i className="fa-solid fa-check mx-2 mb-1" style={{color: appTheme.appColor.success}}/>
                </div>
            </div>
        }
        const title = getSentenceCase(type);
        if (!isEmpty(remarks)) {
            return (
                <div className=''
                     style={{lineHeight: "15px"}}>
                    <div className={"text-break"}>
                        <DLText id={'ViewActivity-Summary'}
                                marginBottom={"none"}
                                fontWeight={'semi-bold'}
                                fontSize={'sm'}
                                isInline={true}
                                text={title}/>
                        <DLText id={'ViewActivity-Summary'}
                                marginBottom={"none"}
                                fontWeight={'semi-bold'}
                                fontSize={'sm'}
                                isInline={true}
                                text={"-"}/>
                        <DLText id={'ViewActivity-Summary'}
                                fontSize={'sm'}
                                marginBottom={"none"}
                                isInline={true}
                                text={remarks}/>
                    </div>
                </div>

            );
        }
        return (
            <div style={{lineHeight: "15px"}}>
                <DLText id={'ViewActivity-Summary'}
                        fontSize={'sm'}
                        fontWeight={'semi-bold'}
                        text={title}/>
            </div>
        );
    };


    const getDetails = (item, index) => {
        let date = isEmpty(item?.createdAt) ? '' : ' '+ getTimeFromNow(item?.createdAt);
        let name = getAdvisoryUser(item?.createdBy?.id);
        return (
            <div className='d-flex mb-3' key={index}>
                <div>
                    <div className='vertical-step-icon'/>
                    <div className={(events.length === index+1) ? 'd-flex justify-content-center h-50': 'd-flex justify-content-center h-100'}>
                        <div className='step-connector'/>
                    </div>
                </div>

                <div className='mb-1'>
                    {getHeading(item?.type, item?.remarks)}
                    <DLText id={'ViewActivity-Summary'}
                            fontColor={'grayDark'}
                            fontSize={'xs'}
                            text={'By ' + name + date}/>
                </div>
            </div>
        );
    };

    const renderViewActivitiesModal = () => {
        return (<DLModal
                history={history}
                maxWidth={"sm"}
                onClose={() => {
                    handleCloseViewActivitiesModal()
                }}
                open={isViewActivitiesModalOpen}
                id={'ViewActivity-modal'}>
                <DLModalTitle
                    id={'ViewActivity-title'}
                    onClose={() => {
                        handleCloseViewActivitiesModal()
                    }}
                    title={'Activities'}/>
                <DLModalContainer
                    id={'ViewActivity-container'}>
                    <div className='mt-3' style={{minHeight: 200}}>
                        {events.map((item, index) => (
                            getDetails(item, index)
                        ))}
                    </div>
                </DLModalContainer>
            </DLModal>
        )
    };
    return (
        <div>
            {props.isOverlay ? overlayViewActivitiesModel() : renderViewActivitiesModal()}
        </div>
    )
};


export default ViewActivitiesView;