import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLCheckboxView from './DLCheckboxView';
import appTheme from "../../../assets/appTheme";

class DLCheckbox extends Component {

  render() {

    if(this.props.hidden){
      return null;
    }

    return (
        <>
          <DLCheckboxView
              {...this.props}
          />
        </>
    );
  }
}

DLCheckbox.propTypes = {
  id: PropTypes.string.isRequired,  // for testing purpose
  label: PropTypes.any,
  onChangeValue: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['single', 'multiple']),
  labelPlacement: PropTypes.oneOf(['start', 'end', 'top', 'bottom']),
  isDisabled: PropTypes.bool,
  fontSize: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
  hidden: PropTypes.bool,   // for hiding the component

  // for multiple pass following options
  options: PropTypes.array,   // should be array of obj ie: [{label:'Vegan Forum', value:'vegan-forum,isChecked:true}]
  enableTranslation: PropTypes.bool,  // will attach translation to label and options
  helperMessage: PropTypes.string,
  checkboxcolor: PropTypes.string,
  disableHelperMessageHeight: PropTypes.bool,
  marginBottom: PropTypes.oneOf([
    'none',
    'xxs',
    'xs',
    'sm',
    'md',
  ]),
  marginBottomItem: PropTypes.oneOf([
    'none',
    'xxs',
    'xs',
    'sm',
    'md',
  ]),
  labelColor : PropTypes.string
};
DLCheckbox.defaultProps = {
  type: 'single',
  labelPlacement:'start',
  hidden: false,
  enableTranslation: false,
  disableHelperMessageHeight: false,
  helperMessage:'',
  marginBottom: 'sm',
  fontSize: 'xs',
  marginBottomItem:'none',
  labelColor: appTheme.appColor.gray
};

export default DLCheckbox;

