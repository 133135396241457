import React, {Component} from 'react';
import DialogContent from '@mui/material/DialogContent';
import {getFontFamily, getTheme} from "./../../DLComponentHelper";

let modelContainerPadding = {
  'none': '0',
  'sm': '4px 12px',
  'md': '8px 24px',
  'lg': '12px 36px'
}

class DLModalContainerView extends Component {
  render() {
    const {children, padding} = this.props;
    let theme = getTheme();
    return (
        <DialogContent style={{
          padding: modelContainerPadding[padding],
          fontFamily: getFontFamily()
        }}>
          {children}
        </DialogContent>
    );
  }
}

export default DLModalContainerView;
