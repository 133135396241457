import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {isEmpty} from "../../../core/components/DLComponentHelper";
import {getLostReasons, updateLeadStatus} from "../../LeadsActions";
import {formatDataForDropDown} from "../../../onboarding/OnboardingHelper";
import LeadLostView from "./LeadsLostView";
import {APP_CONST} from "../../../AppComponents/AppConstants";

const LeadsLost = (props) => {

    const [leadLostState, setLeadLostState] = useState({
        lostReasons: [],
        selectedLostReason: '',
        selectedLostReasonError: '',
        lostComment: '',
        lostCommentError: '',
        modalErrorMessage: ''

    });

    useEffect(() => {
        getLeadLostReasons();
    }, []);

    const getLeadLostReasons = () => {
        getLostReasons().then((res) => {
            if (res?.success) {
                setLeadLostState((prevStep) => ({
                    ...prevStep,
                    lostReasons: formatDataForDropDown(res.data?.lostReasonType)
                }));
            }
        })
    };

    const checkIfValidateObj = (validateObj) => {
        const {selectedLostReasonError, lostCommentError} = validateObj;
        if (selectedLostReasonError || lostCommentError) {
            // If any mandatory error is present (non-empty), return false
            return false;
        }
        // If no mandatory errors are present, return true
        return true;
    };

    const validateChanges = (name) => {
        const {selectedLostReason, lostComment} = leadLostState;
        let errorObj = {};

        if (name === "selectedLostReason" || name === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(selectedLostReason)) {
                errorObj.selectedLostReasonError = "Please select reason.";
            }
        }

        if (name === "lostComment" || name === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(lostComment)) {
                errorObj.lostCommentError = "Please enter comments";
            }
        }

        return errorObj;
    };

    const updateLeadStatusToLost = () => {
        const {selectedLostReason, lostComment} = leadLostState;
        const {getLeadId, getDetails,handleHistoryBlockRefresh} = props;
        let leadId = getLeadId();

        const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION);

        if (isEmpty(leadId)) {
            return
        }

        if (!checkIfValidateObj(validateObj)) {
            setLeadLostState(prevStep => ({
                ...prevStep,
                ...validateObj
            }));
            return;
        }
        let data = {
            "id": leadId,
            "type": "LOST",
            "details": {
                "lostReasonType": selectedLostReason,
                "comment": lostComment
            }
        };
        updateLeadStatus(data).then((res) => {
                if (res.success) {
                    getDetails();
                    handleHistoryBlockRefresh(true);
                    clearData()
                } else {
                    setLeadLostState((prevStep) => ({
                        ...prevStep,
                        modalErrorMessage: res?.__error
                    }));
                }
            }
        );
    };

    const onBlurInputText = (name) => {
        const validateObject = validateChanges(name, leadLostState);

        setLeadLostState(prevStep => ({
            ...prevStep,
            ...validateObject
        }));
    };

    const clearData = () => {
        const {closeLostModal} = props;
        closeLostModal();
        setLeadLostState((prevStep) => ({
            ...prevStep,
            selectedLostReason: '',
            lostComment: '',
            modalErrorMessage: ''
        }));
    };

    const onChangeData = (name, value) => {
        setLeadLostState(prevStep => ({
            ...prevStep,
            [name]: value,
            [name + "Error"]: ''
        }));
    };

    return (
        <LeadLostView
            {...props}
            {...leadLostState}
            updateLeadStatusToLost={updateLeadStatusToLost}
            clearData={clearData}
            onChangeData={onChangeData}
            onBlurInputText={onBlurInputText}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(LeadsLost);
