import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLSearchAutoCompleteView from "./DLSearchAutoCompleteView";
import {getSortedObjectArray, isEmpty} from "./../DLComponentHelper";

class DLSearchAutoComplete extends Component {

  constructor(props) {
    super(props);

    let platform = "web";
    if (!isEmpty(props?.route?.params)) {
      platform = 'native';
    }

    this.state = {
      platform: platform,
      inputValue: "",
      apiOptionsData: [],
      selectedValue: this.getSelectedValue(props),
      filteredOptions: this.getSelectedOptions(props),
    }
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    // if((prevProps?.route?.params !== this.props?.route?.params) ||
    //   (prevProps !== this.props)){

    if (prevProps !== this.props) {
      this.setState({
        selectedValue: this.getSelectedValue(this.props),
        filteredOptions: this.getSelectedOptions(this.props),
      });
    }


  }


  getSelectedValue = (props) => {
    if (!isEmpty(props?.route?.params)) {
      return props.route.params.value;
    } else {
      return props.value;
    }
  };

  getSelectedOptions = (props) => {
    if (!isEmpty(props?.route?.params)) {
      return props.route.params.defaultOptions;
    } else {
      return props.defaultOptions;
    }
  };


  onChangeInput = (inputValue) => {

    const {isMobileView} = this.props;

    let loadApiData;
    if (this.props?.route?.params) {
      loadApiData = this.props.route.params.loadApiData;
    } else {
      loadApiData = this.props.loadApiData;
    }

    let defaultOptions;
    if (this.props?.route?.params) {
      defaultOptions = this.props.route.params.defaultOptions;
    } else {
      defaultOptions = this.props.defaultOptions;
    }

    if (loadApiData) {

      if(isEmpty(inputValue)){
        return [];
      }

      if(this.state.platform === 'web'){
        return loadApiData(inputValue);
      }

      loadApiData(inputValue)
        .then(res => {
          this.setState({
            apiOptionsData: res,
            inputValue: inputValue
          });
        });


    } else {

      let label = '';
      const {enableTranslation, t} = this.props;
      let filterOptions = defaultOptions.filter(obj => {
        if(enableTranslation){
          label = ("" + t(obj.label)).toLowerCase().trim();
        }else {
          label = ("" + obj.label).toLowerCase().trim();
        }
        let tempValue = ("" + inputValue).toLowerCase().trim();
        return  label.includes(tempValue);
      });

      this.setState({
        filteredOptions: filterOptions,
        inputValue: inputValue
      });

      if(!isMobileView){
        return filterOptions;
      }

    }
  };

  handleSelectedOption = (e) => {

    let defaultOptions;
    if (this.props?.route?.params) {
      defaultOptions = this.props.route.params.defaultOptions;
    } else {
      defaultOptions = this.props.defaultOptions;
    }


    let isMulti;
    if (this.props?.route?.params) {
      isMulti = this.props.route.params.isMulti;
    } else {
      isMulti = this.props.isMulti;
    }

    const {apiOptionsData, selectedValue} = this.state;

    let loadOptions;

    if (isEmpty(apiOptionsData)) {
      loadOptions = defaultOptions;
    } else {
      loadOptions = apiOptionsData
    }

    if (isMulti) {

      let array = [];

      if (Array.isArray(selectedValue)) {
        array = selectedValue
      }

      if (array.find(obj => obj.value === e.value)) {
        array = array.filter(obj => obj.value !== e.value);
      } else {
        array.push(e);
      }

      array = getSortedObjectArray(array, 'label');
      this.onChangeSelectedValue(array);

    } else {

      this.onChangeSelectedValue(loadOptions.find(obj => obj.value === e));

    }

  };


  onChangeSelectedValue = (value, platform) => {
    this.setState({
      selectedValue: value
    }, () => {
      if(platform === 'web'){
        this.onApply();
      }
    })
  };


  onApply = () => {
    const {selectedValue} = this.state;
    let onSelect;
    if (this.props?.route?.params) {
      onSelect = this.props.route.params.onSelect;
      onSelect(selectedValue);
      this.props.navigation.goBack();
    } else {
      onSelect = this.props.onSelect;
      onSelect(selectedValue);
    }
  };





  render() {

    return (
      <>
        <DLSearchAutoCompleteView
          {...this.props}
          {...this.state}
          onApply={() => this.onApply()}
          onChangeInput={(inputValue) => this.onChangeInput(inputValue)}
          handleSelectedOption={(value) => this.handleSelectedOption(value)}
          onChangeSelectedValue={(value, platform) => this.onChangeSelectedValue(value, platform)}
        />
      </>
    );
  }
}

DLSearchAutoComplete.propTypes = {
  id: PropTypes.string.isRequired,  // for automation testing
  label: PropTypes.any,
  placeholder: PropTypes.string,
  defaultOptions: PropTypes.array,  // for default options for list. it should be object {label: 'option', value: 'value'}
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.bool,
  helperMessage: PropTypes.string,   // in case of error to error message
  isMulti: PropTypes.bool,     // type of selection multiple or single
  isClearable: PropTypes.bool,
  enableTranslation: PropTypes.bool,
  onSelect: PropTypes.func,  // event will be fired after selection of value/s
  loadApiData: PropTypes.func,// for function which will fetch api data and pass it back to component
  customUi: PropTypes.func, // customized  ui for values and options,
  value: PropTypes.any,   // if isMulti then pass array otherwise pass {label:'', value:''}
  marginBottom: PropTypes.oneOf([
    'none',
    'xxs',
    'xs',
    'sm',
    'md',
  ]),
  disableHelperMessageHeight: PropTypes.bool,    // to disable the min height for input text field if helper message passed it will expand in bottom
  viewScreenLabel: PropTypes.string,            // string value used to show label on next screen used in nativ
  labelFontSize: PropTypes.string,
  labelFontColor: PropTypes.oneOf([
    'primary', 'secondary', 'success', 'warning', 'danger', 'gray', 'black', 'white','grayLight','orange', 'grayDark'
  ]),
  dropDownPlacement: PropTypes.oneOf([
    'top',
    'bottom',
    'auto'
  ]),
};

DLSearchAutoComplete.defaultProps = {
  isRequired: false,
  isMulti: false,
  marginBottom: 'sm',
  disableHelperMessageHeight: false,
  labelFontSize:'xs',
  labelFontColor: 'grayDark',
  dropDownPlacement: 'bottom'
};


export default DLSearchAutoComplete;
