import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import DashboardWidget from "../../../DashboardWidget";
import {
    getManagementOnboardingCRM,
    resetOnboardingListingFilters,
    setCRMForListing,
    setOnboardingStatusTitle
} from "../../../HomePageActions";

const OnboardingByCrm = (props) => {
    const [crmState, setCRMState] = useState({
        onboardingCRM : {},
        isShowLoader : false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {
        setCRMState(prevState => ({
            ...prevState,
            isShowLoader : true
        }));

        getManagementOnboardingCRM().then(res => {
            if (res?.success) {
                setCRMState(prevState => ({
                    ...prevState,
                    onboardingCRM : res.data,
                    isShowLoader : false,
                }));
            } else {
                setCRMState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        resetOnboardingListingFilters();
        setCRMForListing([item.key]);
        setOnboardingStatusTitle('');
        props.history.push('/onboardings');
    };


    return (
        <DashboardWidget
            {...props}
            {...crmState}
            detailsObj={crmState.onboardingCRM}
            setFilters={setFilters}
            title={"CRM"}
        />
    );
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(OnboardingByCrm);