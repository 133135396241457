import io from 'socket.io-client';
import store from '../store';
import {getAppEnvironmentVariables} from "./httpHelper";
import {
    changeAppRoomJoinedStatus,
    changeSocketStatus
} from "../AppComponents/AppReducer";
import {
    setACHFormStatus,
    setIINFormStatus,
    refreshIINStatus,
    refreshACHStatus
} from "../onboarding/onboardingFlowReducer";

let socket;

export const connect = () => {
    console.log('socket: in connect')

    const {socketStatus} = store.getState().appState.socket;

    if (socketStatus === 'DISCONNECTED') {
        try {
            const {REACT_APP_API_URL} = getAppEnvironmentVariables();
            socket = io(REACT_APP_API_URL, {
                transports: ['websocket', 'polling']
            });
        } catch (e) {
            // store.dispatch(changeSocketStatus('DISCONNECTED'));
        }
    }


};

export const acceptNotification = () => {
    console.log('socket: Socket io acceptNotification ');

    socket.on('connect', function () {
        store.dispatch(changeSocketStatus('CONNECTED'));

    });

    socket.on('disconnect', function () {
        console.log('socket: inside socket disconnect');
        store.dispatch(changeSocketStatus('DISCONNECTED'));
        store.dispatch(changeAppRoomJoinedStatus(false));

    });

    socket.on('status', (data) => {
        console.log('socket: $$$Received status from server: ', data);
    });
    socket.on('error', (err) => {
        store.dispatch(changeSocketStatus('DISCONNECTED'));
        store.dispatch(changeAppRoomJoinedStatus(false));
    });
    socket.on('reconnect', () => {
        console.log('socket: Socket io reconnect event received ');
    });
    socket.on('reconnect_error', (err) => {
        console.log('socket: inside reconnect error',err);
        store.dispatch(changeSocketStatus('DISCONNECTED'));
        store.dispatch(changeAppRoomJoinedStatus(false));
    });
    socket.on('FATKA_REGISTRATION', function (data) {
        console.log('socket: FATKA_REGISTRATION received',data);
        store.dispatch(setIINFormStatus(true));
    });
    socket.on('IIN_STATUS', function (data) {
        console.log('socket: IIN_STATUS received',data);
        store.dispatch(setIINFormStatus(true));
    });
    socket.on('IIN_REGISTRATION', function (data) {
        console.log('socket: IIN_REGISTRATION received',data);
        store.dispatch(setIINFormStatus(true));
    });
    socket.on('ACH_REGISTRATION', function (data) {
        console.log('socket: ACH_REGISTRATION received',data);
        store.dispatch(setACHFormStatus(true));
    });
    socket.on('SUBMIT_IIN_STATUS', function (data) {
        console.log('socket: SUBMIT_IIN_STATUS  received',data);
        store.dispatch(refreshIINStatus(true));

    });
    socket.on('SUBMIT_ACH_STATUS', function (data) {
        console.log('socket: SUBMIT_ACH_STATUS  received',data);
        store.dispatch(refreshACHStatus(true));
    });

};

export const disconnect = () => {
    if (socket) {
        console.log('socket: inside socket disconnect');
        socket.disconnect();
        store.dispatch(changeSocketStatus('DISCONNECTED'));
    }
};

export const joinRoom = () => {

    const {appRoomJoined} = store.getState().appState.socket;
    if (appRoomJoined) {
        console.log('socket: User room is already joined. No action required.');
    } else {
        if (socket) {
            let onboardingAppId = store.getState().onboardingFlowState?.applicationId;
            console.log('socket: in join room', onboardingAppId);
            socket.emit("application-join", {applicationId: onboardingAppId});
            // socket.emit('join', {email: store.getState().userState?.profile?.userId});
            store.dispatch(changeAppRoomJoinedStatus(true));
        }
    }
};

export const leaveRoom = () => {
    const {socketStatus} = store.getState().appState.socket;
    if (socket && socketStatus === 'CONNECTED') {
        let onboardingAppId = store.getState().onboardingFlowState?.applicationId;
        console.log('socket: inside leave user room');
        // console.log('Socket Service: User leaving server socket for notifications.');
        socket.emit("application-leave", {applicationId: onboardingAppId});
        store.dispatch(changeAppRoomJoinedStatus(false));
    }

};
