export const headingAccountData = ['Bank Name','Branch','IFSC','Account Holder Name','Account Number', 'Account Type', 'Status'];

export const headingMandateData = ['Bank Name','Account Number' , 'Date Range',  'Status', 'Bank Mandate Amount',];

export const clientMandateCellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '240px',
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '240px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '240px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '240px'
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '167px',
        maxWidth: '200px',
        paddingRight: '20px'
    },

];

export const clientBankAccountCellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '80px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '80px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '80px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '80px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '70px',
        paddingRight: '20px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '80px'
    },

];

export const clientMandateSmallScreenCellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '10px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '80px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '70px'
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '147px',
        maxWidth: '147px',
    },

];

export const clientNomineeHeadingData = ['Name', 'Date of Birth', 'PAN', 'Relation', '% Allocation'];

export const clientNomineeheadingMobileData = ['Name', 'Date of Birth', '% Alloc.'];