import React from 'react';
import ListingForCRM from "./components/clientsListing/components/listingForCRM/ListingForCRM";
import ListingForManagement from "./components/clientsListing/components/listingForManagement/ListingForManagement";

const Clients = (props) => {

    const {isManagementUser, isSetUserType} = props;

    if(!isSetUserType){
        return ''
    }

    if (isManagementUser) {
        return (
            <div>
                <ListingForManagement {...props}/>
            </div>
        );
    } else {
        return (
            <div>
                <ListingForCRM {...props} />
            </div>
        );
    }
};

export default Clients;