export const getAppPlatform = () => {
  return 'WEB';
};

export const getAppEnvironmentVariables = () => {
  return process.env;
};


export const logAPIError = () => {
  // function is used for native only.
  // just declaring here
};
