import React from 'react';
import appTheme from "../../../../../../assets/appTheme";

import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLInputTextField from "../../../../../../core/components/DLInputTextField/DLInputTextField";
import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import DLCheckbox from "../../../../../../core/components/DLCheckbox/DLCheckbox";

const AddRemarkView = (props) => {

    const {history, isAddRemarkModalOpen, handleCloseAddRemarkModal, handleChange, sendToClient, handleSubmit, isShowLoader, modalErrorMessage, errors } = props;

    const renderModalError = () => {
        if (modalErrorMessage) {
            return (
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderAddRemarkModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"sm"}
                    onClose={() => handleCloseAddRemarkModal()}
                    open={isAddRemarkModalOpen}
                    id={'add-remark-modal'}>
                    <DLModalTitle
                        id={'add-remark-title'}
                        onClose={() => handleCloseAddRemarkModal()}
                        title={'Add a Remark'}/>
                    <DLModalContainer
                        id={'add-remark-container'}>
                        {renderModalError()}
                        <DLInputTextField
                            id={''}
                            label={'Remark'}
                            value={''}
                            marginBottom={"none"}
                            isRequired={true}
                            inputType={"text"}
                            isMulti={true}
                            rows={5}
                            maxRows={5}
                            disableHelperMessageHeight={true}
                            error={errors.remarksError !== ""}
                            helperMessage={errors.remarksError}
                            hideCharLength={true}
                            isClearable={false}
                            maxCharLength={200}
                            onChangeValue={(value) => {
                                handleChange("remarks", value)
                            }}
                            onBlur={() => {

                            }}
                        />

                        <DLCheckbox
                            id={'send-to-client-check'}
                            label={'Send email to client (Generated form attached)'}
                            isChecked={sendToClient}
                            disableHelperMessageHeight={true}
                            onChangeValue={(value) => {
                                handleChange("sendToClient", value)
                            }}
                            labelPlacement={'end'}
                            marginBottom={'none'}
                            checkboxcolor={'black'}
                            labelColor={appTheme.appColor.black}
                        />
                    </DLModalContainer>
                    <DLModalActions id={'add-remark-action'}>
                        <div className='mb-4'
                             style={{paddingTop: '7px'}}>
                            <DLButton
                                id={'create-lead-cancelBtn'}
                                history={history}
                                label={'Cancel'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                styleType={"outlined"}
                                backgroundColor={"grey"}
                                sideMargin={"sm"}
                                onClick={() => handleCloseAddRemarkModal()}/>
                            <DLButton
                                id={'add-remark-submitBtn'}
                                history={props.history}
                                label={'Add a Remark'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                sideMargin={"sm"}
                                onClick={() => {
                                    handleSubmit()
                                }}
                            />
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    return (
        <div>
            {renderAddRemarkModal()}
            {renderLoader()}
        </div>
    );
};

export default AddRemarkView;