import {useState} from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const MultiselectDropdown = (props) => {
    const { statusFilterOptions, isXSView, onStatusChange, initialStatus } = props;
    const [selectedStatus, setSelectedStatus] = useState(initialStatus);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        const newStatus = typeof value === 'string' ? value.split(',') : value;
        setSelectedStatus(newStatus);
        onStatusChange(newStatus);
    };

    const getInputValue = (selectedStatus) =>{
        if (selectedStatus.length > 0){
            return selectedStatus.length + ' Selected'
        }
        return 'All'
    }

    const fontSize = isXSView ? '13px' : '14px';
    return (
        <div>
            <FormControl className={"w-100 multi-select-dropdown-input"}>
                <Select
                    id="demo-multiple-checkbox"
                    multiple
                    displayEmpty
                    value={selectedStatus}
                    onChange={handleChange}
                    input={<OutlinedInput label={getInputValue(selectedStatus)} />}
                    renderValue={() => getInputValue(selectedStatus)}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: isXSView ? 150 : 200,
                                fontFamily: "'Poppins', sans-serif"
                            },
                        }
                    }}
                    size="small"
                    sx={{
                        fontSize,
                        fontFamily: "'Poppins', sans-serif"
                    }}
                >
                    {statusFilterOptions.map(({ label, value }) => (
                        <MenuItem
                            key={value}
                            value={value}
                            sx={{
                                fontSize,
                                padding: '0px 16px',
                                fontFamily: "'Poppins', sans-serif",
                            }}
                        >
                            <Checkbox checked={selectedStatus.includes(value)} />
                            <ListItemText primary={label} sx={{ fontSize, fontFamily: 'Poppins' }} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default MultiselectDropdown;
