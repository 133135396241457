import React from 'react';

import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalActions from "../../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLText from "../../../../../../core/components/DLText/DLText";
import DLDropDown from "../../../../../../core/components/DLDropdown/DLDropDown";
import {isEmpty} from "../../../../../../utils/validations";
import DLInputTextField from "../../../../../../core/components/DLInputTextField/DLInputTextField";
import moment from "moment";
import DLDatePicker from "../../../../../../core/components/DLDatePicker/DLDatePicker";

const EditPaymentDetailsView = (props) => {

    const {
        history, isEditPaymentModalOpen, handleCloseEditPaymentModal, isApiInProgress, modalErrorMessage, handleChange,
        errors, bankAccounts, paymentModes, selectedPaymentMode, selectedBankDetails, handleEditBankAccountDetails, getIFSCCode,
        getPaymentMechanism, chequeNumber, MICRNumber, chequeDate, chequeDepositModes, selectedChequeDepositMode
    } = props;

    const renderModalError = () => {
        if (modalErrorMessage) {
            return (
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderSupportingDetailsUi = () => {
        let paymentMode = getPaymentMechanism(selectedPaymentMode);
        if(paymentMode === "CHEQUE"){
            return (
                <div>
                    <div className='row'>
                        <div className='col-6'>
                            <DLInputTextField
                                id={'cheque-number'}
                                label={'Cheque Number'}
                                value={chequeNumber}
                                marginBottom={"none"}
                                inputType={"number"}
                                isRequired={true}
                                disableHelperMessageHeight={true}
                                error={errors.chequeNumberError !== ""}
                                helperMessage={errors.chequeNumberError}
                                hideCharLength={true}
                                isClearable={false}
                                maxCharLength={15}
                                onChangeValue={(value) => {
                                    handleChange("chequeNumber", value)
                                }}
                            />
                        </div>
                        <div className='col-6'>
                            <DLInputTextField
                                id={'micr-number'}
                                label={'MICR Number'}
                                value={MICRNumber}
                                inputType={"text"}
                                isRequired={true}
                                disableHelperMessageHeight={true}
                                error={errors.MICRNumberError !== ""}
                                helperMessage={errors.MICRNumberError}
                                hideCharLength={true}
                                isClearable={false}
                                maxCharLength={15}
                                onChangeValue={(value) => {
                                    handleChange("MICRNumber", value)
                                }}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <DLDatePicker
                                id={'cheque-date'}
                                label={'Cheque Date'}
                                minimumDate={moment()}
                                value={chequeDate}
                                format={'dd-MM-yyyy'}
                                isRequired={true}
                                disableHelperMessageHeight={false}
                                error={errors.chequeDateError !== ""}
                                helperMessage={errors.chequeDateError}
                                minWidth={'100%'}
                                onChangeValue={(val) => {
                                    handleChange('chequeDate', val)
                                }}/>
                        </div>
                        <div className='col-6'>
                            <DLDropDown
                                id={"cheque-deposit-mode-filter"}
                                label={'Cheque Deposit Mode'}
                                labelFontColor={'grayDark'}
                                labelFontSize={'xs'}
                                value={selectedChequeDepositMode[0]}
                                minWidth={"100%"}
                                options={chequeDepositModes}
                                isRequired={true}
                                onSelect={(e) => {
                                    handleChange('selectedChequeDepositMode', e)
                                }}
                                error={errors?.selectedChequeDepositModeError !== ""}
                                helperMessage={errors?.selectedChequeDepositModeError}
                                disableHelperMessageHeight={true}
                                marginBottom={"none"}
                                labelPlacement={'top'}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        if(paymentMode === "Debit Mandate"){
            return (
                <div className='py-2 text-start'>

                </div>
            )
        }
        return (
            <div className='py-2 text-start'>
                {!isEmpty(getIFSCCode(selectedBankDetails)) &&
                <div>
                    <DLText id={''}
                            text={"RTGS/IFSC Code"}
                            fontWeight={"normal"}
                            fontColor={"grayDark"}
                    />
                    <DLText id={''}
                            text={getIFSCCode(selectedBankDetails)?.IFSCCode}
                            fontWeight={"normal"}
                    />
                </div>}
            </div>
        )
    };

    const renderDetailsUi = () => {
        return (
            <div className='py-2 text-start'>
                <DLDropDown
                    id={"filter"}
                    label={'Bank Name & Account Number'}
                    labelFontColor={'grayDark'}
                    labelFontSize={'sm'}
                    value={selectedBankDetails}
                    minWidth={"100%"}
                    options={bankAccounts}
                    onSelect={(e) => {
                        handleChange('selectedBankDetails', e)
                    }}
                    error={errors.selectedBankDetailsError !== ''}
                    helperMessage={errors.selectedBankDetailsError}
                    disableHelperMessageHeight={true}
                    labelPlacement={'top'}
                />
                <DLDropDown
                    id={"filter"}
                    label={'Payment Mode'}
                    labelFontColor={'grayDark'}
                    labelFontSize={'sm'}
                    value={selectedPaymentMode}
                    minWidth={"100%"}
                    options={paymentModes}
                    error={errors.selectedPaymentModeError !== ''}
                    helperMessage={errors.selectedPaymentModeError}
                    onSelect={(e) => {
                        handleChange('selectedPaymentMode', e)
                    }}
                    disableHelperMessageHeight={true}
                    labelPlacement={'top'}
                />
                {renderSupportingDetailsUi()}
            </div>
        )
    };

    const renderUi = () => {
        return (
            <div>
                {renderDetailsUi()}
            </div>
        )
    };

    const renderButtonsUi = () => {
        return (
            <div>
                <DLButton
                    id={'cancelBtn'}
                    history={history}
                    label={'Cancel'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => handleCloseEditPaymentModal()}/>
                <DLButton
                    id={'saveBtn'}
                    history={props.history}
                    label={'Save'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleEditBankAccountDetails()
                    }}
                />
            </div>
        )
    };

    const renderModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"sm"}
                    onClose={() => handleCloseEditPaymentModal()}
                    open={isEditPaymentModalOpen}
                    id={'edit-payment-modal'}>
                    <DLModalTitle
                        id={'new-transaction-title'}
                        onClose={() => handleCloseEditPaymentModal()}
                        title={'Payment Details'}/>
                    <DLModalContainer
                        id={'edit-payment-container'}>
                        <div>
                            {renderModalError()}
                            <div className='text-center'>
                                {renderUi()}
                            </div>
                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'edit-payment-action'}>
                        <div className='mb-4' style={{paddingTop: '7px'}}>
                            {renderButtonsUi()}
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    const overlayRemove = () => {
        return (
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='p-3'>
                        <DLText
                            id={'new-transaction'}
                            fontSize={'md'}
                            marginBottom={"none"}
                            fontWeight={'semi-bold'}
                            text={'New Transaction'}
                        />
                    </div>
                    <div className='p-3'>
                        <i className="fa-solid fa-xmark cursor default-icon"
                           onClick={() => handleCloseEditPaymentModal()}/>
                    </div>
                </div>
                <div className='px-3'
                     style={{
                         maxHeight: 'calc(100dvh - 190px)',
                         overflow: 'auto',
                     }}>
                    <div className='pb-5'>
                        {renderModalError()}
                        <div className='text-center'>
                            {renderUi()}
                        </div>
                    </div>
                </div>
                <div className='fixed-bottom mb-2'>
                    <div className='mb-2 d-flex justify-content-center bg-white'
                         style={{paddingTop: '7px', minHeight: '43px'}}>
                        {renderButtonsUi()}
                    </div>
                </div>
            </div>
        )
    };

    const renderLoader = () => {
        return (
            <DLLoader type={"screen"} isVisible={isApiInProgress}/>
        )
    };

    return (
        <div>
            {renderLoader()}
            {props.isOverlay ? overlayRemove() : renderModal()}
        </div>
    );
};

export default EditPaymentDetailsView;