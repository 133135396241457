import React from 'react';

import DLButton from "../../../core/components/DLButton/DLButton";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import DLDropDown from "../../../core/components/DLDropdown/DLDropDown";
import DLModal from "../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../core/components/DLModal/DLModalActions/DLModalActions";

const LeadLostView = (props) => {

    const {onBlurInputText, modalErrorMessage} = props;

    const renderModalError = () =>{
        if(modalErrorMessage){
            return(
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderLostLeadModal = () => {
        const {history, updateLeadStatusToLost, isOpenLostModal, lostReasons, selectedLostReason, selectedLostReasonError,
            clearData, lostComment, lostCommentError, onChangeData} = props;

        return (
            <DLModal
                history={history}
                maxWidth={"sm"}
                onClose={() => clearData()}
                open={isOpenLostModal}
                id={'lead-lost-modal'}>
                <DLModalTitle
                    id={'lead-lost-title'}
                    onClose={() => clearData()}
                    title={'Lost'}/>
                <DLModalContainer
                    id={'lead-lost-container'}>
                    {renderModalError()}
                    <div>
                        <div className='mb-2'>
                            <DLDropDown
                                id={'reason-dropdown'}
                                isRequired={true}
                                label={'Reason Category'}
                                labelPlacement={'top'}
                                noOptionsMessage={'No options found'}
                                marginBottom={"none"}
                                options={lostReasons}
                                error={selectedLostReasonError !== ""}
                                helperMessage={selectedLostReasonError}
                                onSelect={(val) => {
                                    onChangeData('selectedLostReason', val)
                                }}
                                disableHelperMessageHeight={false}
                                value={selectedLostReason}
                                minWidth={'100%'}
                            />
                        </div>
                        <div className="mb-2">
                            <DLInputTextField
                                id={'lead-lost-comment'}
                                isRequired={true}
                                label={'Comments'}
                                value={lostComment}
                                marginBottom={"none"}
                                inputType={"text"}
                                isMulti={true}
                                maxRows={5}
                                disableHelperMessageHeight={false}
                                error={lostCommentError !== ""}
                                helperMessage={lostCommentError}
                                hideCharLength={true}
                                isClearable={false}
                                maxCharLength={300}
                                onBlur={()=>{
                                    onBlurInputText('lostComment')
                                }}
                                onChangeValue={(val) => {
                                    onChangeData('lostComment', val)
                                }}
                                style={{width: '100%'}}
                            />
                        </div>
                    </div>
                </DLModalContainer>
                <DLModalActions id='lead-lost-action'>
                    <div className='mb-4 d-flex justify-content-center'>
                        <DLButton
                            id={'add-holder-cancelBtn'}
                            history={history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => clearData()}/>
                        <DLButton id={"lost-button"}
                                  sideMargin={'sm'}
                                  styleType={"contained"}
                                  type={"danger"}
                                  onClick={() => {
                                      updateLeadStatusToLost()
                                  }}
                                  label={'Lost'}
                        />
                    </div>
                    <div className='mb-4'>

                    </div>
                </DLModalActions>
            </DLModal>
        );
    };

    return (
        <div>
            {renderLostLeadModal()}
        </div>
    );
};

export default LeadLostView;