import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import moment from "moment";

import {isEmpty} from "../../../../../core/components/DLComponentHelper";

import {APP_CONST} from "../../../../../AppComponents/AppConstants";

import {
    setSearchKeyword,
    setFromDate,
    setToDate,
    setStatus,
    setSkipCount,
    setLimitCount,
    setPageCount,
    setLeadsRowPerPageCount,
    getTransactionListing,
    setTypeOfRequest,
    getCategoryOptions,
    getTypeOfRequestFilterOptions,
    getCRMs,
    setCRMId,
    setCategory,
    setLocation, setAgeing
} from "../../../NFTAction";

import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import NFTListForManagementView from "./NFTListForManagementView";
import {getClientAccountTypes} from "../../../../../clients/ClientsActions";

const NFTListForManagement = (props) => {
    const [NFTState, setNFTState] = useState({
        transactions: [],
        listingCount: 0,
        skipCount: 0,
        limitCount: 10,
        isShowFilterMobile: false, //CHECK
        isCreateModalOpen: false, //CHECK

        UIState: '',
        errorInApi: '',
        isShowLoader: false,
        filters: {
            keyword: '',
            fromDate: '',
            toDate: '',
            requestType: '',
            status: [],
            CRMId: [],
            category: [],
            location: [],
            ageing: []
        },
        sourceNames: [],
        accountCategories: [],
        dropdownValues: {
            categoryOptions: [],
            typeOfRequestFilterOptions: [],
            accountTypeOptions: [],
            CRMOptions: []
        },
        badgeData: {
            title: '',
            data: ''
        }
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        getCategoryValues();
    }, []);

    useEffect(() => {
        getTransactions();
    }, [props.NFTFilter]);

    const getCategoryValues = () => {
        const {NFTFilter} = props;
        setNFTState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getCategoryOptions()
            .then(res => {
                if (res?.success) {

                    getClientAccountTypes()
                        .then(accountTypeRes => {

                            if (accountTypeRes?.success) {

                                getCRMs()
                                    .then(CRMRes => {
                                        if (CRMRes?.success) {

                                            getTypeOfRequestFilterOptions()
                                                .then(reqTypeRes => {
                                                    if (reqTypeRes?.success) {

                                                        setNFTState(prevState => ({
                                                            ...prevState,
                                                            dropdownValues: {
                                                                ...prevState.dropdownValues,
                                                                categoryOptions: setValuesForDropdown(res.data?.categoryType, "name", "id"),
                                                                typeOfRequestFilterOptions: setRequestValuesForDropdown(reqTypeRes.data?.nftRequestTypes, "name", "id"),
                                                                accountTypeOptions: setValuesForDropdown(accountTypeRes.data?.accountType, "name", "id"),
                                                                CRMOptions: setRequestValuesForCRMDropdown(CRMRes.data?.advisoryUsers, "firstName", "id")
                                                            },
                                                            badgeData: applyFilterBadge(NFTFilter, res.data?.categoryType),
                                                            accountCategories: res.data?.categoryType,
                                                            isShowLoader: false,
                                                            UIState: APP_CONST.CONTENT_AVAILABLE
                                                        }));
                                                    } else {
                                                        setNFTState(prevState => ({
                                                            ...prevState,
                                                            isShowLoader: false,
                                                            UIState: APP_CONST.CONTENT_UNAVAILABLE
                                                        }));
                                                    }
                                                })
                                        }
                                    })
                            }
                        })
                }
            })
    };

    const isManagementUser = () => {
        const {profile} = props;
        return profile?.isManagement;
    }

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return;
        }

        return data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken]}
        });
    };

    const setRequestValuesForDropdown = (data, labelToken, valueToken) => {
        let list = [{label: "All", value: "ALL"}]
        if (isEmpty(data)) {
            return;
        }
        data.map((item) => {
            list.push({label: item?.[labelToken], value: item?.[valueToken]})
        });
        return list;
    };

    const setRequestValuesForCRMDropdown = (data, labelToken, valueToken) => {
        let list = [{label: "All", value: 'ALL'}, {label: "Unassigned", value: 'UNASSIGNED'}]
        if (isEmpty(data)) {
            return;
        }
        data.map((item) => {
            list.push({label: item?.[labelToken] + ' ' + item?.lastName, value: item?.[valueToken]})
        });
        return list;
    };

    const generateFilter = (filter) => {
        let filterObject = {};
        if (!isEmpty(filter.keyword)) {
            filterObject.keyword = filter.keyword;
        }
        if (!isEmpty(filter.toDate)) {
            filterObject.toDate = filter.toDate;
        }
        if (!isEmpty(filter.fromDate)) {
            filterObject.fromDate = filter.fromDate;
        }
        if (!isEmpty(filter.status)) {
            if (filter.status[0] === "ALL") {
                delete filterObject.status
            } else {
                filterObject.status = filter.status;
            }
        }
        if (!isEmpty(filter.requestType)) {
            if (filter.requestType[0] === "ALL") {
                delete filterObject.requestType
            } else {
                filterObject.requestType = filter.requestType;
            }
        }
        if (!isEmpty(filter.CRMId)) {
            if (filter.CRMId[0] === "ALL") {
                delete filterObject.CRMId
            } else {
                filterObject.CRMId = filter.CRMId;
            }
        }
        if (!isEmpty(filter.category)) {
            if (filter.category[0] === "ALL") {
                delete filterObject.category
            } else {
                filterObject.category = filter.category;
            }
        }
        if (!isEmpty(filter.location)) {
            if (filter.location[0] === "ALL") {
                delete filterObject.location
            } else {
                filterObject.location = filter.location;
            }
        }
        return filterObject;
    };

    const getTransactions = () => {
        const {NFTFilter} = props;

        setNFTState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            "skipCount": NFTFilter.skipCount,
            "limitCount": NFTFilter.limitCount,
            "filters": generateFilter(NFTFilter)
        };

        getTransactionListing(payload)
            .then(res => {
                if (res?.success) {
                    setNFTState(prevState => ({
                        ...prevState,
                        transactions: res?.data?.nftRequests,
                        listingCount: res.data?.count,
                        skipCount: NFTFilter.skipCount,
                        limitCount: NFTFilter.limitCount,
                        isShowLoader: false
                    }));
                    setPage(isEmpty(NFTFilter.page) ? 0 : NFTFilter.page);
                    setRowsPerPage(isEmpty(NFTFilter.rowsPerPage) ? 10 : NFTFilter.rowsPerPage);
                } else {
                    setNFTState(prevState => ({
                        ...prevState,
                        isShowLoader: false
                    }));
                }
            })
    };

    // Function to determine whether to enable/disable the pagination control buttons
    const getPaginationNextButtonStatus = () => {
        const {listingCount, skipCount, limitCount} = NFTState;
        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setNFTState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    keyword: value,
                }
            }));
            setSearchKeyword(value);
        }

        if (name === 'fromDate') {
            let fromDate = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            setNFTState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    fromDate: fromDate
                }
            }));
            setFromDate(fromDate);
        }

        if (name === 'toDate') {
            let toDate = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            setNFTState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    toDate: toDate,
                }
            }));
            setToDate(toDate);
        }

        if (name === 'status') {
            setNFTState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    status: [...value]
                }
            }));
            setStatus([...value]);
        }

        if (name === 'requestType') {
            setNFTState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    requestType: [value]
                }
            }));
            setTypeOfRequest([value]);
        }
        if (name === 'ageing') {
            setNFTState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    ageing: [value]
                }
            }));
            setAgeing([value]);
        }
        if (name === 'category') {
            setNFTState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    category: [value]
                }
            }));
            setCategory([value]);
        }
        if (name === 'location') {
            setNFTState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    location: [value]
                }
            }));
            setLocation([value]);
        }
        if (name === 'CRMId') {
            setNFTState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    CRMId: [value]
                }
            }));
            setCRMId([value]);
        }
        if (name === 'title') {
            setNFTState((prevStep) => ({
                ...prevStep,
                badgeData: {
                    title: '',
                    data: '',
                    filterKey: '',
                }
            }));
        }

    };

    const resetPagination = () => {
        setLeadsRowPerPageCount(10);
        setLimitCount(10);
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangeRowsPerPage = (event) => {
        setNFTState(prevState => ({
            ...prevState,
            isSearchFilterUpdated: true
        }));

        setLeadsRowPerPageCount(parseInt(event.target.value, 10));
        setLimitCount(parseInt(event.target.value, 10));
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangePage = (event, newPage) => {
        let {limitCount, skipCount} = NFTState;
        const isForward = newPage > page;
        if (isForward) {
            skipCount = skipCount + rowsPerPage;
            limitCount = rowsPerPage
        } else {
            skipCount = skipCount - rowsPerPage;
            limitCount = rowsPerPage
        }

        setSkipCount(skipCount);
        setLimitCount(limitCount);
        setPageCount(newPage);
    };

    const handleToggleMobileFilter = (value) => {
        setNFTState((prevStep) => ({
            ...prevStep,
            isShowFilterMobile: value
        }));
    };

    const onSaveSuccess = () => {
        getTransactions();
    };

    const getLabel = (data, value) => {
        if (isEmpty(data) || isEmpty(value)) {
            return ''
        }
        const listingLabel = data.find(item => item.value === value);
        return listingLabel ? listingLabel.label : "";
    };

    const handleRefreshButton = () => {
        getTransactions();
    };

    const getCategory = (selectedStatus, accountCategories) => {
        if (isEmpty(selectedStatus) || isEmpty(accountCategories)) {
            return '-'
        }
        if (selectedStatus === 'CORPORATE') {
            return 'Null'
        }
        if (selectedStatus === 'UNASSIGNED') {
            return 'Unassigned';
        }
        const status = accountCategories.find(status => status.id === selectedStatus);
        return status ? status.name : '-';
    };

    const applyFilterBadge = (NFTFilter, accountCategories) => {
        let badgeObj = {
            title: '',
            data: '',
            filterKey: '',
        };

        if (NFTFilter?.title === 'Category' && !isEmpty(NFTFilter?.category)) {
            if ((NFTFilter.category?.[0] === 'ALL')) {
                badgeObj.title = '';
                badgeObj.data = '';
                badgeObj.filterKey = '';
            } else {
                badgeObj.title = NFTFilter?.title + ': ';
                badgeObj.data = getCategory(NFTFilter.category?.[0], accountCategories);
                badgeObj.filterKey = 'category'
            }
        } //pending

        if (NFTFilter?.title === 'Ageing' && !isEmpty(NFTFilter.ageing)) {
            if ((NFTFilter.ageing?.[0] === 'ALL')) {
                badgeObj.title = '';
                badgeObj.data = '';
                badgeObj.filterKey = '';
            } else {
                badgeObj.title = NFTFilter?.title + ': ';
                badgeObj.data = NFTFilter.ageing?.[0];
                badgeObj.filterKey = 'ageing'
            }

        }
        if (NFTFilter?.title === 'Location' && !isEmpty(NFTFilter.location)) {
            if ((NFTFilter.location?.[0] === 'ALL')) {
                badgeObj.title = '';
                badgeObj.data = '';
                badgeObj.filterKey = '';
            } else if ((NFTFilter.location?.[0] === 'UNASSIGNED')) {
                badgeObj.title = NFTFilter?.title + ': ';
                badgeObj.data = 'Unassigned';
                badgeObj.filterKey = 'location'
            } else {
                badgeObj.title = NFTFilter?.title + ': ';
                badgeObj.data = NFTFilter.location?.[0];
                badgeObj.filterKey = 'location'
            }

        }
        return badgeObj;
    };

    const getAccountType = (value) => {
        const {dropdownValues} = NFTState;
        const {accountTypeOptions} = dropdownValues;
        let accountType = '';

        accountType = accountTypeOptions.find((item) => {
            return item?.value === value;
        })
        return accountType?.label + " Account";
    };

    const getCRM = (CRMId, CRMOptions) => {
        if (isEmpty(CRMId) || isEmpty(CRMOptions)) {
            return '-'
        }
        const CRM = CRMOptions.find(crm => crm.value === CRMId);
        return CRM ? CRM.label : '-';
    };

    const redirectToDetailsScreen = (id) => {
        props.history.push('/non-financial-transaction/' + id);
    };

    if (isEmpty(NFTState.UIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    return (
        <NFTListForManagementView
            {...props}
            {...NFTState}
            page={page}
            rowsPerPage={rowsPerPage}
            handleToggleMobileFilter={handleToggleMobileFilter}
            handleChangePage={handleChangePage}
            redirectToDetailsScreen={redirectToDetailsScreen}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getPaginationNextButtonStatus={getPaginationNextButtonStatus}
            getTransactions={getTransactions}
            handleRefreshButton={handleRefreshButton}
            onChangeFilter={onChangeFilter}
            onSaveSuccess={onSaveSuccess}
            getLabel={getLabel}
            getCategory={getCategory}
            getAccountType={getAccountType}
            isManagementUser={isManagementUser}
            getCRM={getCRM}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    NFTFilter: state.NFTState.NFTFilter,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(NFTListForManagement);
