import React from 'react';
import UserDetailsBarView from "./UserDetailsBarView";
import {connect} from "react-redux";

const UserDetailsBar = (props) => {

    return (
        <UserDetailsBarView
            {...props}
        />);
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    accountType: state.onboardingFlowState.accountType
});

export default connect(mapStateToProps, {})(UserDetailsBar);
