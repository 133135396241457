import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLMenuOptionsView from './DLMenuOptionsView';
import {isEmpty} from "./../DLComponentHelper";

let ellipsisTypes = {
  V: 'V',
  H: 'H',
}

class DLMenuOptions extends Component {
  render() {

    if (this.props.hidden || isEmpty(this.props.options)) {
      return null
    }

    return (
      <>
        <DLMenuOptionsView
          {...this.props}
          ellipsisTypes={ellipsisTypes}
        />
      </>
    );
  }
}

DLMenuOptions.defaultProps = {
  options: [],
  value: {},
  ellipsisType: ellipsisTypes.V,
  size: 18,
  color: '#666666',
  fontColor: '#333333',
  fontSize: 'xs',
  minWidth: '130px'
};

DLMenuOptions.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  fontColor: PropTypes.string,
  fontSize: PropTypes.string,
  minWidth: PropTypes.string,
  ellipsisType: PropTypes.oneOf([ellipsisTypes.H, ellipsisTypes.V]),
  anchorUi: PropTypes.any
};


export default DLMenuOptions;
