import CRMList from "./components/listingForCRM/CRMList";
import ManagementList from "./components/listingForManagement/ManagementList";

const ClientRequestsView = (props) => {
    const {isManagementUser} = props;

    const renderUI = () => {
        if (isManagementUser) {
            return <ManagementList {...props}/>;
        }

        return (
            <div className='w-100'>
                <CRMList {...props} />
            </div>
        )
    }

    return renderUI();
};

export default ClientRequestsView;