import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import DashboardWidget from "../../../DashboardWidget";
import {getManagementMyLeads, resetLeadFilters, setStatus} from "../../../HomePageActions";

const AllLeadsStatus = (props) => {
    const [totalLeadsState, setTotalLeadsState] = useState({
        myLeads: {},
        isShowLoader: false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {
        setTotalLeadsState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getManagementMyLeads().then(res => {
            if (res.success) {
                setTotalLeadsState(prevState => ({
                    ...prevState,
                    myLeads: res.data,
                    isShowLoader: false,
                }));
            } else {
                setTotalLeadsState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        resetLeadFilters();
        setStatus([item.key]);
        props.history.push('/leads')
    };


    return (
        <DashboardWidget
            {...props}
            {...totalLeadsState}
            detailsObj={totalLeadsState.myLeads}
            setFilters={setFilters}
            title={"Status"}
        />
    );
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(AllLeadsStatus);
