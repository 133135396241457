export  const nomineeMenuOption=["edit","delete"]

export const headingData = ['Name', 'Date of Birth', 'PAN', 'Relation', '% Allocation'];
 export const headingMobileData = ['Name', 'Date of Birth', '% Alloc.', 'Action'];

 export const cellConfig = [
     {
         textAlign: 'left',
         width: 'auto',
         minWidth: '120px'
     },
     {
         textAlign: 'left',
         width: 'auto',
         minWidth: '10px'
     },
     {
         textAlign: 'left',
         width: 'auto',
         minWidth: '80px'
     },
     {
         textAlign: 'left',
         width: 'auto',
         minWidth: '70px'
     },
     {
         textAlign: 'right',
         width: 'auto',
         minWidth: '100px',
         maxWidth: '100px'
     },
     {
         textAlign: 'center',
         width: 'auto',
         minWidth: '100px'
     }
 ];

export const cellConfigMobile = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '90px',
        padding: 0
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '90px',
        padding: 0
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '70px',
        maxWidth: '70px',
        padding: 0
    },
    {
        textAlign: 'center',
        width: 'auto',
        minWidth: '80px',
        padding: 0
    }
];

export const cellConfigTab = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '90px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '90px'
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '90px'
    },
    {
        textAlign: 'center',
        width: 'auto',
        minWidth: '80px'
    }
];