import React, {Component} from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {getFontColor, getFontFamily, getFontSize, isEmpty} from "./../DLComponentHelper";

let iconColors = {
    'white': '#fff',
    'default': '#000',
};


class DLMenuOptionsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    }
  }

    getStyle = (fontColor, fontSize)  =>{
        return{
            fontFamily: getFontFamily(),
            ...getFontSize(fontSize),
            color: getFontColor(fontColor)
        }
    };

  handleItemOpen = (e) => {
    this.setState({
      anchorEl: e.target
    })
  };

  handleItemClose(e){
    this.setState({
      anchorEl: null
    },()=>{
      if(e){
        this.props.onSelect(e);
      }
    });

  }

  renderAnchorUi = () => {
    const {ellipsisType, anchorUi, ellipsisTypes, color} = this.props;

    if(isEmpty(anchorUi)){
      if(ellipsisType === ellipsisTypes.H){
        return <i className='fa fa-ellipsis-h' style={{color: color}}/>
      }
      return <i className='fa fa-ellipsis-v' style={{color: color}}/>
    }
    return anchorUi;
  }

  render() {
    const {anchorEl} = this.state;
    const {options, id, iconColor, fontColor, fontSize,minWidth} = this.props;
    return (
        <div>
          <span style={{cursor: 'pointer'}} id={id+'-menu-opt-icon'} className='px-2'  onClick={this.handleItemOpen}>
            {this.renderAnchorUi()}
          </span>
          <Menu
              id={id+'-menu-opt'}
              className="p-2"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={()=>this.handleItemClose()}
              PaperProps={{
                style: {
                  minWidth: minWidth,
                },
              }}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
          >
            {
              options.map((opt, index)=>(
                  <MenuItem key={'menu-item-'+opt.value}
                            id={id + '-item-' + index}
                            className="px-3"
                            style={{...this.getStyle(fontColor, fontSize)}}
                            onClick={()=>this.handleItemClose(opt.value)}>
                    {opt.label}
                  </MenuItem>
              ))
            }
          </Menu>
        </div>
    );
  }
}

DLMenuOptionsView.propTypes = {

};



export default DLMenuOptionsView;
