import React, {Component} from 'react';
import Tooltip from '@mui/material/Tooltip';
import { ClickAwayListener } from '@mui/base';
import makeStyles from '@mui/styles/makeStyles';


const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
class DLTooltipView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  handleTooltipClose = () => {
    this.setState({isVisible: false});
  };

  handleTooltipOpen = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({isVisible: true});
  };

  render() {
    let {isVisible} = this.state;
    const {tooltipContent, tooltipLabel, customUi, backgroundColor, borderColor, color, arrowColor} = this.props;
    const classes = makeStyles({
      root: {
        maxWidth: '100px!important',
      },
    });

    if (isMobile || isIpad) {
      return (<ClickAwayListener
          onClickAway={this.handleTooltipClose}>
        <div className={classes.root + ' d-inline d-xl-none'}>
          <Tooltip
              onClose={this.handleTooltipClose}
              open={isVisible}
              arrow
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement={'bottom'}
              componentsProps={{
                  tooltip: {
                      sx: {
                          bgcolor: backgroundColor,
                          color: color,
                          padding:'10px !important',
                          border: '1px solid ' + borderColor,
                          boxShadow: '0 4px 10px #00000040',
                          '& .MuiTooltip-arrow': {
                              color: arrowColor,
                          },
                      },
                  },
              }}
              title={
                <div className="">
                  {tooltipContent}
                </div>
              }>
              {customUi ? <span  onClick={this.handleTooltipOpen}>{tooltipLabel}</span> :
                  <i className={'fas fa-question-circle cursor '} style={{color:'#000'}} onClick={this.handleTooltipOpen}/>}
          </Tooltip>
        </div>
      </ClickAwayListener>);
    }

    return (
        <span>
          <Tooltip
              className="d-xl-inline"
              placement={'bottom'}
              arrow
              title={(
                  <div>
                    {tooltipContent}
                  </div>
              )}
              componentsProps={{
                  tooltip: {
                      sx: {
                          bgcolor: backgroundColor,
                          color: color,
                          padding: '10px !important',
                          border: '1px solid ' + borderColor,
                          boxShadow: '0 4px 10px #00000040',
                          '& .MuiTooltip-arrow': {
                              color: arrowColor,
                          },
                      },
                  },
              }}
          >
            {customUi ? <span>{tooltipLabel}</span> :
                <i className={'fas fa-question-circle cursor '} style={{color:'#000'}} />}
          </Tooltip>
        </span>
    );
  }
}


export default DLTooltipView;
