import React from 'react';

import DLButton from "../../../core/components/DLButton/DLButton";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import DLModal from "../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLDatePicker from "../../../core/components/DLDatePicker/DLDatePicker";
import moment from "moment";

const LeadsOnHoldView = (props) => {

    const {modalErrorMessage} = props;

    const renderModalError = () =>{
        if(modalErrorMessage){
            return(
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderOnHoldLeadModal = () => {
        const {history, updateLeadStatusToOnHold, isOpenOnHoldModal, followUpDate, onHoldComment,
            onChangeData, clearData, onHoldCommentError, followUpDateError, onBlurInputText} = props;

        return (
            <DLModal
                history={history}
                maxWidth={"sm"}
                onClose={() => clearData()}
                open={isOpenOnHoldModal}
                id={'lead-onHold-modal'}>
                <DLModalTitle
                    id={'lead-onHold-title'}
                    onClose={() => clearData()}
                    title={'On Hold'}/>
                <DLModalContainer
                    id={'lead-onHold-container'}>
                    {renderModalError()}
                    <div>
                        <div className={"mb-2"}>
                            <DLInputTextField
                                id={'first-name-field'}
                                label={'Comments'}
                                value={onHoldComment}
                                marginBottom={"none"}
                                inputType={"text"}
                                isMulti={true}
                                maxRows={5}
                                disableHelperMessageHeight={false}
                                error={onHoldCommentError !== ""}
                                helperMessage={onHoldCommentError}
                                hideCharLength={true}
                                isRequired={true}
                                isClearable={false}
                                maxCharLength={300}
                                onBlur={()=>{
                                    onBlurInputText('onHoldComment')
                                }}
                                onChangeValue={(val) => {
                                    onChangeData('onHoldComment', val)
                                }}
                                style={{width: '100%'}}
                            />
                        </div>
                        <div className={"mb-2"}>
                            {/*<DLText id={''}*/}
                            {/*        isInline={true}*/}
                            {/*        fontColor={'gray'}*/}
                            {/*        text={'Next Follow Up'}/>*/}
                            <DLDatePicker
                                label={'Next Follow Up'}
                                id={'lead-from-date'}
                                minimumDate={moment()}
                                value={followUpDate}
                                format={'dd-MM-yyyy'}
                                isRequired={true}
                                disableHelperMessageHeight={false}
                                error={followUpDateError !== ""}
                                helperMessage={followUpDateError}
                                minWidth={'100%'}
                                onChangeValue={(val) => {
                                    onChangeData('followUpDate', val)
                                }}/>
                        </div>
                    </div>
                </DLModalContainer>
                <DLModalActions id='lead-onHold-action'>
                    <div className='mb-4 d-flex justify-content-center'>
                        <DLButton
                            id={'add-holder-cancelBtn'}
                            history={history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => clearData()}/>
                        <DLButton id={"onHold-button"}
                                  sideMargin={'sm'}
                                  styleType={"contained"}
                                  type={"black"}
                                  onClick={() => {
                                      updateLeadStatusToOnHold()
                                  }}
                                  label={'On Hold'}
                        />
                    </div>
                </DLModalActions>
            </DLModal>
        );
    };

    return (
        <div>
            {renderOnHoldLeadModal()}
        </div>
    );
};

export default LeadsOnHoldView;