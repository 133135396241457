import React, {useState} from 'react';
import {connect} from "react-redux";

import UploadINFormView from "./UploadIINFormView";
import {getDocAssociateIINOrECSForm} from "../../../../../OnboardingActions";

const UploadIINForm = (props) => {

    const [uploadState, setUploadState] = useState({
        document: {
            originalFileName: '',
            uploadedFileName: '',
            filePath: '',
            fileName: '',
            id: ''
        },
        responseErrors: [],
        totalErrors: 0,
        isPdfDocument : false,
        isLoaderVisible : false
    });

    const isPdf = (fileName) => {
        return fileName.includes("pdf") || fileName.includes("Pdf") || fileName.includes("PDF");
    };

    const associateDocument = (type, response) => {
        const {formId, refreshData, onboardingApplicationId} = props;
        const {document} = uploadState;
        setUploadState(prevStep => ({
            ...prevStep,
            isLoaderVisible: true,
        }));
        if(type === 'ERROR'){
            setUploadState(prevStep => ({
                ...prevStep,
                uploadFormError: response,
                isLoaderVisible: false,
            }));
            return;
        }
        if (type === 'DELETE') {
            setUploadState(prevStep => ({
                ...prevStep,
                document: {
                    ...document,
                    filePath: '',
                    fileName: '',
                },
                responseErrors: [],
                totalErrors: 0,
            }));
            return;
        }



        const associatePayload = {
            id: formId,
            originalFileName: response?.originalFileName,
            uploadedFileName: response?.uploadedFileName,
            onboardingApplicationId: onboardingApplicationId
        };

        getDocAssociateIINOrECSForm(associatePayload)
            .then((res) => {
                if (res.success) {
                    setUploadState(prevStep => ({
                        ...prevStep,
                        document: {
                            ...document,
                            filePath: isPdf(response?.originalFileName) ? "" : res.data?.filePath,
                            fileName: isPdf(response?.originalFileName) ? "" : res.data?.fileName,
                            id: isPdf(response?.originalFileName) ? "" : res.data?.id
                        },
                        isLoaderVisible: false,
                        responseErrors: [],
                        totalErrors: 0,
                        isPdfDocument: isPdf(response?.originalFileName)
                    }));
                    refreshData()
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                    }
                    setUploadState(prevStep => ({
                        ...prevStep,
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                        document: {
                            ...document,
                            filePath: '',
                            id: ''
                        },
                        isPdfDocument: isPdf(response?.originalFileName)
                    }));
                }
            })
    };

    return (
        <UploadINFormView
            {...props}
            {...uploadState}
            associateDocument={associateDocument}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    onboardingApplicationId: state.onboardingFlowState.onboardingApplicationId
});

export default connect(mapStateToProps, {})(UploadIINForm);
