import React, {useEffect, useState} from 'react';
import LeadsList from "./components/leadsList/LeadsList";
import {connect} from "react-redux";
import ManagementLeadList from "./components/leadListForManagement/ManagementLeadsList";


const LeadsView = (props) => {
    const {isManagementUser, isSetUserType} = props;

        if(!isSetUserType){
           return ''
        }

        if (isManagementUser) {
            return (
                <div>
                    <ManagementLeadList {...props}/>
                </div>
            );
        }else{
            return (
                <div className='w-100'>
                    <LeadsList {...props} />
                </div>
            );
        }
    }
;

const mapStateToProps = (state) => ({
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(LeadsView);