import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import NFTListView from "./NFTListView";


const NFTList = (props) => {

    const [NFTListState, setNFTListState] = useState({
        isManagementUser: false,
        isSetUserType: false
    });

    useEffect(() => {
        const {profile} = props;
        setNFTListState((prevState) => ({
            ...prevState,
            isManagementUser: profile?.isManagement,
            isSetUserType: true
        }))
    }, []);


    return (
        <div>
            <NFTListView
                {...props}
                {...NFTListState}
            />
        </div>
    );
};
const mapStateToProps = (state) => ({
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(NFTList);