//noinspection DuplicatedCode,JSUnresolvedVariable,SpellCheckingInspection,JSUnresolvedFunction
export const APP_CONST = {
    LANG_ENG: 'en',
    LANG_HIN: 'hi',
    CLASS_ALL: 'CLASS_ALL',
    FORM_SUBMISSION: 'FORM_SUBMISSION',
    APP_ORIENTATION_LANDSCAPE: 'LANDSCAPE',
    APP_ORIENTATION_PORTRAIT: 'PORTRAIT',
    SET_POST_LOGIN_URL: 'SET_POST_LOGIN_URL',
    CLEAR_POST_LOGIN_URL: 'CLEAR_POST_LOGIN_URL',
    PLATFORM_WEB: 'WEB',
    MAX_LIMIT_COUNT: 9999,

    CHANGE_DRAWER_WIDTH: "CHANGE_DRAWER_WIDTH",
    CHANGE_DRAWER_STATUS: "CHANGE_DRAWER_STATUS",
    FULL_DRAWER_WIDTH: 250,
    HALF_DRAWER_WIDTH: 100,

    SET_CURRENT_PATHNAME: "SET_CURRENT_PATHNAME",
    FILTER_MIN_LIMIT_DATE: '2020-12-30',
    FILTER_MAX_LIMIT_DATE: '2099-12-30',

    APP_ACCOUNT_AVAILABLE:"APP_ACCOUNT_AVAILABLE",
    APP_ACCOUNT_UNAVAILABLE:"APP_ACCOUNT_UNAVAILABLE",
    APP_FILTEREDeMAILS_AVAILABLE:"APP_FILTEREDeMAILS_AVAILABLE",
    APP_FILTEREDeMAILS_UNAVAILABLE:"APP_FILTEREDeMAILS_UNAVAILABLE",

    CONTENT_UNAVAILABLE: "CONTENT_UNAVAILABLE",
    CONTENT_AVAILABLE: "CONTENT_AVAILABLE"
};




