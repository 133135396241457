import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import DLSearchDropdownView from "./DLSearchDropdownView";
import {debounce} from "../DLComponentHelper";

const DLSearchDropdown = (props) => {
    const {
        onChangeData,
        dropdownHeight,
        debounceTimer,
    } = props;

    const [keyword, setKeyword] = useState('');
    const [loader, setLoader] = useState(false);
    const [debouncedValue, setDebouncedValue] = useState("");

    useEffect(() => {
        if (debouncedValue) {
            onChangeData(debouncedValue);
            setLoader(false);
        }
    }, [debouncedValue]);

    const handleChange = useCallback(debounce((value) => setDebouncedValue(value), debounceTimer), []);

    const onChangeValue = (value) => {
        setKeyword(value);
        setLoader(true);
        handleChange(value);
    }

    return (
        <DLSearchDropdownView
            {...props}
            keyword={keyword}
            onChangeValue={onChangeValue}
            dropdownHeight={dropdownHeight}
            loader={loader}
        />
    );
}

DLSearchDropdown.propTypes = {
    onChangeData: PropTypes.func.isRequired,
    onClickItem: PropTypes.func.isRequired,
    dropdownHeight: PropTypes.string,
    debounceTimer: PropTypes.number,
    helperMessage: PropTypes.any,
    disableHelperMessageHeight: PropTypes.bool,
    error: PropTypes.bool,
    renderCustomItemUI: PropTypes.func,
    inputMarginBottom: PropTypes.string,
};

DLSearchDropdown.defaultProps = {
    dropdownHeight: 'calc(100vh - 555px)',
    debounceTimer: 300,
    error: false,
    helperMessage: '',
    disableHelperMessageHeight: false,
    isCustomDropdownItemUI: false,
    noMatchMessage: "No match found.",
    inputMarginBottom: '0px',
};

export default DLSearchDropdown;
