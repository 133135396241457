import React, {useState} from 'react';
import {isEmpty} from "../../utils/validations";

import {verifyOTP, verifyOTPUrl} from "../clientsApprovalAction";
import {showToastMessage} from "../../AppComponents/AppActions";

import store from "../../../src/store";
import {connect} from "react-redux";

import ClientsVerificationView from "./clientsVerificationView";
import DLLoader from "../../core/components/DLLoader/DLLoader";

const ClientsVerification = (props) => {

    const {uuid, otpVerified} = props;
    const [verificationState, setVerification] = useState({
        email: '',
        otherAccount: false,
        isShowLoader: false,
        otp: '',
        otpError: '',
        otpLengthError: '',
        codeResent: false,
        isSuccess: false,
        isError: false,
        UIState: 'CODE_VERIFY'
    });


    const changeValue = (name, value) => {
        setVerification(prevverificationState => ({
            ...prevverificationState,
            [name]: value,
            otpError: '',
            otpLengthError: ''
        }));
    };

    const validateOtp = () => {
        const {otp} = verificationState;
        if (!validateChanges()) {
            return;
        }
        verifyOTP(uuid, otp)
            .then(res => {
                if (res.success) {
                    setVerification(prevState => ({
                        ...prevState,
                        UIState: ''
                    }));
                    let userData = {
                        accountDetails: res?.data?.accountDetails,
                        clientData: res?.data?.clientData
                    }
                    otpVerified(userData)
                } else {
                    setVerification(prevState => ({
                        ...prevState,
                        otpError: res?.__error
                    }));
                }
            })
    };

    const validateChanges = () => {
        const {otp} = verificationState;
        if (isEmpty(otp)) {
            setVerification(prevState => ({
                ...prevState,
                otpError: 'Please enter Security Code',
                otpLengthError: ''
            }));
            return false;
        }
        if (otp.length < 6) {
            setVerification(prevState => ({
                ...prevState,
                otpLengthError: 'Please enter 6 digit Security Code',
                otpError: ''
            }));
            return false;
        }
        setVerification(prevState => ({
            ...prevState,
            otpError: '',
        }));

        return true;
    };

    const resendCode = () => {
        const {uuid} = props;

        setVerification(prevverificationState => ({
            ...prevverificationState,
            isShowLoader: true,
        }));

        let payload = {
            uuid: uuid
        };

        setTimeout(()=>{
            verifyOTPUrl(payload, true).then(res => {
                if (res?.success) {
                    setVerification(prevverificationState => ({
                        ...prevverificationState,
                        isError: false,
                        codeResent: true,
                        isShowLoader: false,
                        otp: '',
                        otpError: '',
                        otpLengthError: '',
                    }));
                    store.dispatch(showToastMessage('success', 'Re-verification code is sent to your email address.'))
                }
            })
        },300)

    };

    return (
        <ClientsVerificationView
            {...props}
            {...verificationState}
            verificationState={verificationState}
            handleChange={changeValue}
            validateOtp={validateOtp}
            resendCode={resendCode}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(ClientsVerification);

