import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'portfolioReducer',
    initialState: {
        portfolioFilter: {
            keyword: ''
        }
    },
    reducers: {
        setSearchKeywordValue (state,action) {
            state.portfolioFilter.keyword = action.payload;
        },

        resetAllFilters (state,action) {
            state.portfolioFilter = {
                ...state.portfolioFilter,
                keyword:''
            };
        }
    }
});

export const {setSearchKeywordValue,resetAllFilters} = appSlice.actions;
export default appSlice.reducer

