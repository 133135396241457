import React, {useState} from 'react';
import {connect} from "react-redux";

import {APP_CONST} from "../../../../../../AppComponents/AppConstants";
import {isEmpty} from "../../../../../../core/components/DLComponentHelper";

import AddRemarkView from "./AddRemarkView";
import {remark} from "../../../FormsAndReviewActions";

const AddRemark = (props) => {

    const [addRemarkState, setAddRemarkState] = useState({
        formId: "",
        remarks: "",
        sendToClient: false,
        isShowLoader: false,
        modalErrorMessage: '',
        errors: {
            remarksError: ''
        }
    });

    const handleChange = (name, value) => {
        let updatedState = {...addRemarkState};
        updatedState[name] = value;

        const validateObject = validateChanges(name, updatedState);

        setAddRemarkState(prevStep => ({
            ...prevStep,
            ...updatedState,
            modalErrorMessage: '',
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));
    };

    const validateChanges = (name, compState) => {
        const {remarks} = compState;
        let errorObj = {...addRemarkState.errors};

        if (name === "remarks" || name === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(remarks)) {
                errorObj.remarksError = "Please enter remarks";
            } else {
                errorObj.remarksError = "";
            }

            if (name === 'remarks') {
                return errorObj;
            }
        }

        return errorObj;
    };

    const checkIfValidateObj = (validateObj) => {
        const {remarksError} = validateObj;
        if (remarksError) {
            // If any mandatory error is present (non-empty), return false
            return false;
        }
        // If no mandatory errors are present, return true
        return true;
    };

    const handleSubmit = () => {

        const {formId, handleCloseAddRemarkModal, onboardingApplicationId} = props;
        const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION, addRemarkState);

        if (!checkIfValidateObj(validateObj)) {
            setAddRemarkState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObj
                }
            }));
            return;
        }
        setAddRemarkState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));

        let payload = {
            formId: formId,
            remarks: addRemarkState.remarks,
            isInternal: !(addRemarkState.sendToClient),
            onboardingApplicationId: onboardingApplicationId
        };

        remark(payload).then(res => {
            if (res?.success) {
                setAddRemarkState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false,
                }));
            } else {
                setAddRemarkState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false,
                    modalErrorMessage: res.__error
                }));
            }
            handleCloseAddRemarkModal()
        })
    };

    return (
        <AddRemarkView
            {...props}
            {...addRemarkState}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    onboardingApplicationId: state.onboardingFlowState.onboardingApplicationId
});

export default connect(mapStateToProps, {})(AddRemark);
