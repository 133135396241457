import React, {useState} from 'react';
import {connect} from "react-redux";

import {rejectTransaction} from "../../../../FTAction";

import RejectTransactionView from "./RejectTransactionView";
import {APP_CONST} from "../../../../../../AppComponents/AppConstants";
import {isEmpty} from "../../../../../../utils/validations";


const RejectTransaction = (props) => {

    const {txnRequestId} = props;
    const [rejectTransactionState, setRejectTransactionState] = useState({
        comment: '',
        isApiInProgress: false,
        handleErrorInComponent: true,
        modalErrorMessage: '',
        errors: {
            commentError: ''
        }
    });

    const handleChange = (name, value) => {
        let updatedState = {...rejectTransactionState};
        updatedState[name] = value;

        const validateObject = validateChanges(name, updatedState);

        setRejectTransactionState(prevStep => ({
            ...prevStep,
            ...updatedState,
            responseError: '',
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));
    };

    const validateChanges = (name, compState) => {
        const {comment} = compState;
        let errorObj = {...rejectTransactionState.errors};

        if (name === "comment" || name === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(comment)) {
                errorObj.commentError = "Please enter comment";
            } else {
                errorObj.commentError = "";
            }

            if (name === 'comment') {
                return errorObj;
            }
        }
        return errorObj;
    };

    const checkIfValidateObj = (validateObj) => {
        const {commentError} = validateObj;
        if (commentError) {
            // If any mandatory error is present (non-empty), return false
            return false;
        }
        // If no mandatory errors are present, return true
        return true;
    };

    const handleReject = () => {
        const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION, rejectTransactionState);
        if (!checkIfValidateObj(validateObj)) {
            setRejectTransactionState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObj
                }
            }));
            return;
        }
        let payload = {
            txnRequestId: txnRequestId,
            comment: rejectTransactionState.comment
        }
        setRejectTransactionState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));

        rejectTransaction(payload)
            .then((res) => {
                    if (res.success) {
                        setRejectTransactionState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false
                        }));
                        props.history.goBack();
                    } else {
                        setRejectTransactionState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false,
                            modalErrorMessage: res.__error
                        }));
                    }
                }
            );
    };

    return (
        <RejectTransactionView
            {...props}
            {...rejectTransactionState}
            handleReject={handleReject}
            handleChange={handleChange}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(RejectTransaction);
