import React from 'react';

import DLText from "../../../../../../core/components/DLText/DLText";
import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../../../../core/components/DLModal/DLModalActions/DLModalActions";

const ReviewedView = (props) => {

    const {
        history, markAsReviewed, handleCloseMarkAsReviewCheckBox, isMarkAsReviewedModalOpen,
        isShowLoader
    } = props;

    const renderReviewedModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"sm"}
                    onClose={() => {
                        handleCloseMarkAsReviewCheckBox()
                    }}
                    open={isMarkAsReviewedModalOpen}
                    id={'mar-modal'}>
                    <DLModalTitle
                        id={'mar-title'}
                        onClose={() => {
                            handleCloseMarkAsReviewCheckBox()
                        }}
                        title={'Confirmation'}/>
                    <DLModalContainer
                        id={'mar-container'}>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <DLText id={''}
                                    text={"Are you sure you want to proceed?"}
                                    fontSize={'sm'}
                                    isInline={true}
                            />
                            <DLText id={''}
                                    text={"This action cannot be undone."}
                                    fontSize={'sm'}
                                    isInline={true}
                                    fontColor={"danger"}
                            />
                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'mar-action'}>
                        <div className='mb-4' style={{paddingTop: '7px'}}>
                            <DLButton
                                id={'mar-cancelBtn'}
                                history={history}
                                label={'Cancel'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                styleType={"outlined"}
                                backgroundColor={"grey"}
                                sideMargin={"sm"}
                                onClick={() => {
                                    handleCloseMarkAsReviewCheckBox()
                                }}/>
                            <DLButton
                                id={'mar-submitBtn'}
                                history={props.history}
                                label={'Mark as Reviewed'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                sideMargin={"sm"}
                                onClick={() => {
                                    markAsReviewed()
                                }}
                            />
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    return (
        <div>
            {renderReviewedModal()}
            {renderLoader()}
        </div>
    );
};

export default ReviewedView;