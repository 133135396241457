import React from 'react';

import {isEmpty} from "../../../utils/validations";
import {convertUTCToLocalTime, getTimeFromNow} from "../../../utils/helper";

import DLModal from "../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLText from "../../../core/components/DLText/DLText";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

const LeadHistoryView = (props) => {

    const {history, isHistoryModalOpen, handleCloseHistoryModal, selectedLeadName, events,
        getAdvisoryUser,getSentenceCase,isShowLoader,isMobileView} = props;

    const getHeading = (type, remark, assignedTo) => {

        const title = getSentenceCase(type);
        if(type === "ASSIGNED")
        {
            return (
                <div style={{lineHeight: "15px"}}>
                    <div className={"text-break d-flex gap-1"}>
                        <DLText id={'ViewActivity-Summary'}
                                marginBottom={"none"}
                                fontWeight={'semi-bold'}
                                fontSize={'sm'}
                                isInline={true}
                                text={title}/>
                        <DLText id={'ViewActivity-Summary'}
                                marginBottom={"none"}
                                fontWeight={'semi-bold'}
                                fontSize={'sm'}
                                isInline={true}
                                text={" to "}/>
                        <DLText id={'ViewActivity-Summary'}
                                fontSize={'sm'}
                                marginBottom={"none"}
                                isInline={true}
                                text={getAdvisoryUser(assignedTo)}/>
                    </div>
                </div>

            )
        }


        if (!isEmpty(remark)) {
            return (
                <div style={{lineHeight: "15px"}}>
                    <div className={"text-break d-flex gap-1 pe-3"}>
                        <span>
                            <DLText id={'ViewActivity-Summary'}
                                    marginBottom={"none"}
                                    fontWeight={'semi-bold'}
                                    fontSize={'sm'}
                                    isInline={true}
                                    text={title}/>
                        <DLText id={'ViewActivity-Summary'}
                                marginBottom={"none"}
                                fontWeight={'semi-bold'}
                                fontSize={'sm'}
                                isInline={true}
                                text={" - "}/>
                        <DLText id={'ViewActivity-Summary'}
                                fontSize={'xs'}
                                marginBottom={"none"}
                                isInline={true}
                                text={remark}/>
                        </span>
                    </div>
                </div>

            );
        }

        return (
            <div style={{lineHeight: "15px"}}>
                <DLText id={'ViewActivity-Summary'}
                        fontSize={'sm'}
                        fontWeight={'semi-bold'}
                        text={title}/>
            </div>
        );
    };

    const getFollowUpDate=(item)=>{
        return <div className='d-flex gap-1 pt-1'>
            <DLText id={'ViewActivity-Summary'}
                    marginBottom={"none"}
                    fontWeight={'semi-bold'}
                    fontSize={'xs'}
                    isInline={true}
                    text={'Follow up '}/>
            <DLText id={'ViewActivity-Summary'}
                    marginBottom={"none"}
                    fontSize={'xs'}
                    isInline={true}
                    text={convertUTCToLocalTime(item?.details?.followUpDate, 'DD-MM-YYYY')}/>
        </div>
    };

    const renderUi = () =>{
        if(isEmpty(events)){
            return(
                <div className={"d-flex justify-content-center align-items-center"}
                     style={{minHeight: isMobileView ? 'calc(100dvh - 250px)':'calc(100dvh - 270px)',
                    maxHeight: isMobileView ? '':'calc(100dvh - 270px)'}}>
                    No data available.
                </div>
            )
        }
        return(
            <div
                 style={{minHeight: isMobileView ? 'calc(100dvh - 250px)':'calc(100dvh - 270px)',
                     maxHeight: isMobileView ? '':'calc(100dvh - 270px)'
                 }}>
                {events.map((item, index) => (
                    getDetails(item, index)
                ))}
            </div>
        )
    };

    const getDetails = (item, index) => {
        let date = isEmpty(item?.createdAt) ? '' : getTimeFromNow(item?.createdAt);
        let name = getAdvisoryUser(item?.createdBy?.id);
        let remarks='';
        if(item?.details?.comment)  {
            remarks = item?.details?.comment;
        }
        if(item?.details?.remark)
        {
            remarks = item?.details?.remark;
        }
        let assignedTo=isEmpty(item?.details?.assignedTo) ? '':item?.details?.assignedTo;
        return (
            <div className='d-flex mb-3' key={index}>
                <div>
                    <div className='vertical-step-icon mx-1'/>
                    <div className='d-flex justify-content-center h-100'>
                        <div className={ index===events?.length-1 ? 'step-connector h-50':'step-connector' }/>
                    </div>
                </div>

                <div className='mb-1 ms-1'>
                    {getHeading(item?.type, remarks,assignedTo)}
                    {item?.type==='HOLD' &&
                    getFollowUpDate(item)
                    }
                    <DLText id={'ViewActivity-Summary'}
                            fontColor={'grayDark'}
                            fontSize={'xxs'}
                            text={'By ' + name + date}/>
                </div>
            </div>
        );
    };

    const renderLeadHistoryModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"sm"}
                    onClose={() => handleCloseHistoryModal()}
                    open={isHistoryModalOpen}
                    id={'lead-history-modal'}>
                    <DLModalTitle
                        id={'lead-history-title'}
                        onClose={() => handleCloseHistoryModal()}
                        title={selectedLeadName + ' - History'}/>
                    <DLModalContainer
                        padding={"sm"}
                        id={'lead-history-container'}>
                            <div className={"my-4"}>
                                {renderErrorUI()}
                                {renderUi()}
                            </div>
                    </DLModalContainer>
                </DLModal>
            </div>
        );
    };

    const overlayLeadHistory = () => {
        return (
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='p-3'>
                        <DLText
                            id={'confirmation'}
                            fontSize={'md'}
                            marginBottom={"none"}
                            fontWeight={'semi-bold'}
                            text={selectedLeadName + ' - History'}
                        />
                    </div>
                    <div className='p-3'>
                        <i className="fa-solid fa-xmark cursor default-icon"
                           onClick={() => handleCloseHistoryModal()}/>
                    </div>
                </div>
                <div className='px-3'
                     style={{
                         maxHeight: 'calc(100dvh - 190px)',
                         overflow: 'auto',
                     }}>
                    <div className='pb-5'>
                        <div className='text-center my-4'>
                            <div className='my-4'>
                                {renderErrorUI()}
                                {renderUi()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderErrorUI = () => {
        const {responseError} = props;

        if (isEmpty(responseError)) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className={'mt-1'}>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            {renderLoader()}
            {props.isOverlay ? overlayLeadHistory() : renderLeadHistoryModal()}
        </div>
    );
};

export default LeadHistoryView;