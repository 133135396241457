import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLSwitchView from './DLSwitchView';

class DLSwitch extends Component {
  render() {

    if(this.props.hidden){
      return null;
    }

    return ( <DLSwitchView {...this.props}/> );

  }
}

DLSwitch.propTypes = {
  id: PropTypes.string.isRequired,  // for testing purpose
  type: PropTypes.oneOf(['default']),
  buttonType: PropTypes.oneOf(['default', "danger"]),
  label: PropTypes.any,
  isRequired: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isDoubleValue: PropTypes.bool,
  marginBottom: PropTypes.oneOf([
    'none',
    'xxs',
    'xs',
    'sm',
    'md',
  ]),
  hidden: PropTypes.bool,
  tooltipContent: PropTypes.any,
  helperMessage: PropTypes.string,
  labelFontSize: PropTypes.string,
  labelFontColor: PropTypes.oneOf([
    'primary', 'secondary', 'tertiary', 'success', 'successLight', 'danger', 'darkBlue', 'lightBlue', 'lightGray','grayLight', 'grayDark', 'gray', 'disable', 'black', 'white'
  ]),
  disableHelperMessageHeight: PropTypes.bool,
  switchPosition: PropTypes.oneOf([
    'end',
    'default'
  ])
};

DLSwitch.defaultProps = {
  type : 'default',
  buttonType : 'default',
  isRequired: false,
  isDoubleValue: false,
  marginBottom: 'sm',
  hidden: false,
  helperMessage:'',
  disableHelperMessageHeight: false,
  switchPosition: 'end',
  labelFontSize: 'xs',
  labelFontColor: 'lightGray'
};

export default DLSwitch;
