import {httpDelete, httpGet, httpPost, httpPut} from "../utils/httpHelper";
import store from "../store";
import {
    setSearchKeywordValue, resetAllFilters
} from "./portfolioReducer"

export const getPortfolioListing = (data) => {
    let URL = '/service/advisory/portfolio/all';

    return httpPost(URL, data)
        .then(res => {
            return res;
        });
};

export const removePortfolio = (portfolioId) => {
    return httpDelete('/service/advisory/portfolio?id='+portfolioId,{}, true).then(res => {
        return res;
    })
};

export const copyManagementPortfolio = (data) => {
    let URL = '/service/advisory/portfolio/copy';

    return httpPost(URL, data)
        .then(res => {
            return res;
        });

};

export const createPortfolio = (data,handleErrorInComponent, portfolioType) => {
    if(portfolioType === "EXISTING"){
        return httpPut('/service/advisory/portfolio', data, handleErrorInComponent).then(res => {
            return res;
        })
    }else{
        return httpPost('/service/advisory/portfolio', data, handleErrorInComponent).then(res => {
            return res;
        })
    }
};

export const fundsListing = (data,handleErrorInComponent) => {
    return httpPost('/service/advisory/master/product/search', data, handleErrorInComponent).then(res => {
        return res;
    })
};

export const getPortfolio = (id) => {
    return httpGet('/service/advisory/portfolio?id=' + id)
        .then(res => {
            return res;
        })
};


export const setSearchKeyword = (data) => {
    store.dispatch(setSearchKeywordValue(data))
}

export const resetNFTFilters = (data) => {
    store.dispatch(resetAllFilters(data))
};

export const applyPortfolio = (data,handleErrorInComponent) => {
    return httpPost('/service/advisory/financialTransaction/applyPortfolio', data, handleErrorInComponent).then(res => {
        return res;
    })
};