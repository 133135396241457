import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLTooltipView from './DLTooltipView';
import {isEmpty} from "./../DLComponentHelper";

class DLTooltip extends Component {
  render() {
    if(this.props.hidden || isEmpty(this.props.tooltipContent)){
        return null;
    }
    return (
        <>
          <DLTooltipView
              {...this.props}
          />
        </>
    );
  }
}

DLTooltip.propTypes = {
    id: PropTypes.string.isRequired,  // for testing purpose
    tooltipContent: PropTypes.any.isRequired, // any content which should be rendered inside the tooltip
    customUi: PropTypes.bool, // pass tooltipLabel to show custom UI
    hidden: PropTypes.bool,
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
    color: PropTypes.string,
    arrowColor: PropTypes.string,
};

DLTooltip.defaultProps = {
    backgroundColor: '#ffffff',
    borderColor:'#D2D2D2',
    color: '#000000',
    arrowColor: '#FFFFFF'
};
export default DLTooltip;
