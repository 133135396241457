import React, {useEffect} from 'react';

import DLButton from "../../../../core/components/DLButton/DLButton";
import DLRadioGroup from "../../../../core/components/DLRadioGroup/DLRadioGroup";
import DLInputTextField from "../../../../core/components/DLInputTextField/DLInputTextField";
import DLDropDown from "../../../../core/components/DLDropdown/DLDropDown";
import DLText from "../../../../core/components/DLText/DLText";

import {isEmpty} from "../../../../utils/validations";
import {debounce, getRandomId} from "../../../../core/components/DLComponentHelper";
import {isCorporateUser} from "../../../../utils/helper";

import DLModal from "../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLSearchDropdown from "../../../../core/components/DLSearchDropdown/DLSearchDropdown";


const NFTCreateView = (props) => {

    const {
        handleCreate,
        onChangeFormData,
        modalErrorMessage,
        errors,
        requestType,
        isXSView,
        scrollToFieldId,
        onBlurInputText,
        comment,
        name,
        phoneNumber,
        email,
        dropdownValues,
        category,
        clients,
        handleClick,
        isOptionSelected,
        setCategoryValues,
        accountHolders,
        accountType,
        IINNumber,
        getAccountType,
        clearData,
        categoryType,
        companyName,
        isShowInlineLoader
    } = props;

    useEffect(() => {
        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);
            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                handleScrollChangeWithDebounce()
            }
        }
        return () => {
            window.scrollTo(0, 0)
        }
    }, [props.scrollToFieldId]);

    const handleScrollChangeWithDebounce = debounce(() => {
        props.onChangeFormData('scrollToFieldId', '');
    }, 500);

    const getUi = (id) => {
        const item = clients.find(element => element.accountId === id);
        if (!item) {
            return null;
        }
        return (
            <div>
                <div>
                    <DLText
                        id={""}
                        text={getAccountType(item?.accountType) + ' - '}
                        fontWeight="normal"
                        fontSize="xs"
                        isInline={true}
                        marginBottom="none"
                    />
                    <DLText
                        id={""}
                        text={item.IINNumber || ""}
                        fontWeight="normal"
                        fontSize="xs"
                        isInline={true}
                        marginBottom="none"
                    />
                </div>
                {isCorporateUser(item.accountType)
                    ? renderCompanyName(item.corporate?.name)
                    : renderAccountHolders(item.accountHolders)}
            </div>
        );
    };


    const renderCompanyName = (companyName) => {
        if (isEmpty(companyName)) {
            return '-';
        }
        return (
            <div>
                <DLText id={''}
                        text={companyName}
                        fontWeight={"normal"}
                        isInline={true}
                        fontSize={"xs"}
                        fontColor={"grayDark"}
                />
            </div>
        )

    }


    const renderAccountHolders = (accountHolders) => {
        if (isEmpty(accountHolders)) {
            return '-';
        }
        return (
            <div className=''>
                {
                    accountHolders.map((item, index) => {
                        if (item?.accountHolderType === "GUARDIAN") {
                            return <></>;
                        }
                        return (
                            <div className='d-flex' key={getRandomId()}>
                                <div>
                                    <DLText id={''}
                                            text={item?.fullName}
                                            fontWeight={"normal"}
                                            isInline={true}
                                            fontSize={"xs"}
                                            fontColor={"grayDark"}
                                    />
                                </div>
                                {index <= (accountHolders?.length - 2) && item.accountHolderType !== 'PRIMARY_HOLDER_MINOR' &&
                                    <div className='me-1'>
                                        <DLText id={''}
                                                text={','}
                                                isInline={true}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                                fontColor={"grayDark"}
                                        />
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        )

    }

    const getId = (data) => {
        return data?.map(item => ({
            value: item?.accountId,
        }));
    }

    const renderSearchBar = () => {
        const {handleSearch} = props;

        return (
            <DLSearchDropdown
                placeholder={"Search by Name or IIN"}
                isShowInlineLoader={isShowInlineLoader}
                onChangeData={e => handleSearch(e)}
                helperMessage={'Please select client'}
                error={errors.IINNumber !== ''}
                disableHelperMessageHeight={true}
                data={getId(clients)}
                renderCustomItemUI={(id) => {
                    return getUi(id.value)
                }}
                onClickItem={(id) => handleClick(id)}
                noMatchMessage={"No clients found."}
            />
        );
    }
    const renderAccounts = () => {
        return (
            <div>
                <div className='row'>
                    <div className='col-12 mb-4' id={'name-field'}>
                        <div className='mb-4'>
                            <div className={'d-flex'}>
                                <div className='section-title-vertical-bar'/>
                                <div className="ps-2 ms-1">
                                    <div className='d-flex'>
                                        <div>
                                            <DLText id={''}
                                                    text={getAccountType(accountType) + ' - '}
                                                    fontSize={"md"}
                                                    fontWeight={"semi-bold"}
                                                    isInline={true}
                                            />
                                            <DLText id={''}
                                                    text={IINNumber}
                                                    fontSize={"md"}
                                                    fontWeight={"semi-bold"}
                                                    isInline={true}
                                            />
                                        </div>
                                        <div className='ps-3 d-flex align-items-center justify-content-center'>
                                            <DLText id={'change-client'}
                                                    isClickable={true}
                                                    marginBottom={"none"}
                                                    text={"Change Client"}
                                                    fontColor={"lightBlue"}
                                                    onClick={() => {
                                                        clearData()
                                                    }}
                                            />
                                        </div>
                                    </div>
                                    <DLText id={''}
                                            text={setCategoryValues(category)}
                                            fontSize={"sm"}
                                            fontWeight={"semi-bold"}
                                            fontColor={"grayDark"}
                                            marginBottom={"none"}
                                    />
                                </div>
                            </div>
                        </div>
                        {renderAccountHoldersUi(accountHolders, categoryType)}
                    </div>
                </div>
            </div>
        )
    }

    const renderAccountHoldersUi = (accountHolders) => {
        if (!isEmpty(companyName)) {
            return (
                <div className='mb-2 ps-3 ms-1'>
                    <DLText id={''}
                            text={companyName}
                            fontSize={"sm"}
                            isInline={true}
                    />
                </div>
            )
        }
        if (isEmpty(accountHolders)) {
            return (
                <></>
            )
        }
        return accountHolders.map((item, index) => {
            if (item?.accountHolderType === "GUARDIAN") {
                return <></>;
            }
            return (
                <div className='mb-2 ps-3 ms-1' key={index}>
                    {(accountType === "JOINT") ?
                        <DLText id={''}
                                text={'Account Holder ' + (index + 1) + ' - '}
                                fontSize={"sm"}
                                fontWeight={"semi-bold"}
                                isInline={true}
                        /> :
                        <DLText id={''}
                                text={'Account Holder ' + ' - '}
                                fontSize={"sm"}
                                fontWeight={"semi-bold"}
                                isInline={true}
                        />
                    }
                    <DLText id={''}
                            text={item?.fullName}
                            fontSize={"sm"}
                            isInline={true}
                    />
                </div>
            )
        })
    }

    const renderRequestTextUi = () => {

        return (
            <div>
                {!isOptionSelected && <div className='row mb-3'>
                    <div className='col-8' id={'search-field'}>
                        <DLText id={''}
                                text={'Search'}
                                fontColor={errors.IINNumber !== '' ? "danger" : "grayDark"}
                                fontWeight={"normal"}
                                fontSize={"xs"}
                        />
                        {renderSearchBar()}
                        {/*{errors.IINNumber !== '' &&*/}
                        {/*    <div className='ps-2'>*/}
                        {/*        <DLText id={''}*/}
                        {/*                text={'Please select client'}*/}
                        {/*                fontColor={"danger"}*/}
                        {/*                fontSize={"xs"}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*}*/}
                    </div>
                </div>}
                <div>
                    {isOptionSelected &&
                        <div>
                            {renderAccounts()}
                        </div>
                    }
                    <div className='row'>
                        <div className='col-12' id={'comment-field'}>
                            <DLInputTextField
                                id={'comment-field'}
                                label={'Comment'}
                                value={comment}
                                marginBottom={"sm"}
                                inputType={"text"}
                                isMulti={true}
                                rows={3}
                                maxRows={4}
                                maxCharLength={500}
                                disableHelperMessageHeight={true}
                                hideCharLength={true}
                                isClearable={false}
                                isRequired={true}
                                error={errors.comment !== ''}
                                helperMessage={errors.comment}
                                onChangeValue={(val) => {
                                    onChangeFormData('comment', val)
                                }}
                                onBlur={() => {
                                    onBlurInputText('comment')
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderRequestUi = () => {
        return (
            <div>
                {requestType !== "ARN_CHANGE" && <div className='row mb-3'>
                    <div className='col-12' id={'search-field'}>
                        <DLText id={''}
                                text={'Search'}
                                fontColor={"grayDark"}
                                fontWeight={"normal"}
                                fontSize={"xs"}
                        />
                        {renderSearchBar()}
                    </div>
                </div>}
                <div className='row'>
                    <div className='col-12' id={'name-field'}>
                        <DLInputTextField
                            id={'name-field'}
                            label={'Name'}
                            value={name}
                            marginBottom={"sm"}
                            inputType={"text"}
                            disableHelperMessageHeight={true}
                            hideCharLength={true}
                            isClearable={false}
                            isRequired={true}
                            maxCharLength={60}
                            error={errors.name !== ''}
                            helperMessage={errors.name}
                            onChangeValue={(val) => {
                                onChangeFormData('name', val)
                            }}
                            onBlur={() => {
                                onBlurInputText('name')
                            }}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6' id={'email-field'}>
                        <DLInputTextField
                            id={'email-field'}
                            label={'Email'}
                            value={email}
                            marginBottom={"sm"}
                            inputType={"text"}
                            maxCharLength={50}
                            disableHelperMessageHeight={true}
                            hideCharLength={true}
                            isClearable={false}
                            isRequired={true}
                            error={errors.email !== ''}
                            helperMessage={errors.email}
                            onChangeValue={(val) => {
                                onChangeFormData('email', val)
                            }}
                            onBlur={() => {
                                onBlurInputText('email')
                            }}
                        />
                    </div>
                    <div className='col-6' id={'phoneNumber-field'}>
                        <DLInputTextField
                            id={'phone-field'}
                            label={'Phone Number'}
                            value={phoneNumber}
                            marginBottom={"sm"}
                            inputType={"number"}
                            disableHelperMessageHeight={true}
                            hideCharLength={true}
                            isClearable={false}
                            isRequired={true}
                            maxCharLength={10}
                            error={errors.phoneNumber !== ''}
                            helperMessage={errors.phoneNumber}
                            onChangeValue={(val) => {
                                onChangeFormData('phoneNumber', val)
                            }}
                            onBlur={() => {
                                onBlurInputText('phoneNumber')
                            }}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-12' id={'category-field'}>
                        <DLDropDown
                            id={"category-filter"}
                            label={'Category'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'xs'}
                            value={category}
                            minWidth={"100%"}
                            options={
                                dropdownValues.categoryOptions
                            }
                            onSelect={(e) => {
                                onChangeFormData('category', e)
                            }}
                            isRequired={true}
                            error={errors.category !== ''}
                            disableHelperMessageHeight={true}
                            helperMessage={errors.category}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12' id={'comment-field'}>
                        <DLInputTextField
                            id={'comment-field'}
                            label={'Comment'}
                            value={comment}
                            marginBottom={"sm"}
                            inputType={"text"}
                            isMulti={true}
                            rows={3}
                            maxRows={4}
                            maxCharLength={500}
                            disableHelperMessageHeight={true}
                            hideCharLength={true}
                            isClearable={false}
                            isRequired={true}
                            error={errors.comment !== ''}
                            helperMessage={errors.comment}
                            onChangeValue={(val) => {
                                onChangeFormData('comment', val)
                            }}
                            onBlur={() => {
                                onBlurInputText('comment')
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const renderModalError = () => {
        if (modalErrorMessage) {
            return (
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderConfirmationModal = () => {
        const {isOpenCancelConfirmModal, onClickCancel, redirectToListing} = props;

        return (
            <DLModal
                history={props.history}
                maxWidth={"xs"}
                onClose={() => onClickCancel(false)}
                open={isOpenCancelConfirmModal}
                id={'confirmation-modal'}>
                <DLModalTitle
                    id={'confirmation-title'}
                    onClose={() => onClickCancel(false)}
                    title={'Confirmation'}/>
                <DLModalContainer id={'confirmation-container'}>
                    <div className='d-flex justify-content-center align-items-center'
                         style={{minHeight: '100px'}}>
                        <div>
                            <div className='text-center'>
                                <DLText id={'remove-text'}
                                        text={'Are you sure you want to leave this page ? You will lose all the progress if you continue.'}/>
                            </div>
                        </div>
                    </div>
                </DLModalContainer>

                <DLModalActions id='confirmation-action'>
                    <div className='mb-2' style={{paddingTop: '7px', minHeight: '43px'}}>
                        <DLButton
                            id={'confirmation-cancelBtn'}
                            history={props.history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => onClickCancel(false)}/>
                        <DLButton
                            id={'confirmation-saveBtn'}
                            history={props.history}
                            label={'Continue'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            onClick={() => {
                                redirectToListing()
                            }}/>
                    </div>
                </DLModalActions>
            </DLModal>
        );
    };


    const renderButton = () => {
        const {onClickCancel} = props;
        return (
            <div className="sticky-bottom-buttons gap-0">
                <DLButton
                    id={'raise-request-cancelBtn'}
                    history={props.history}
                    label={'Cancel'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => {
                        onClickCancel(true)
                    }}/>
                <DLButton
                    id={'raise-request-saveBtn'}
                    history={props.history}
                    label={'Raise'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    sideMargin={"none"}
                    onClick={() => handleCreate()}
                />
            </div>
        )
    };

    const renderDesktopHeader = () => {
        return (
            <div className='w-100'>
                <div
                    className={isXSView ? 'ps-3 page-header p-0 bg-white' : 'page-header d-flex justify-content-between'}>
                    <div className="d-flex align-items-center">
                        <div>
                            Raise Request
                        </div>
                    </div>

                </div>
            </div>
        )
    };

    const renderDesktopData = () => {

        return (
            <div className=" "
                 style={{maxHeight: 'calc(100dvh - 154px)', minHeight: 'calc(100dvh - 154px)', overflow: "auto"}}>
                <div className="bg-white row border-rounded p-3 m-0" style={{minHeight: 'calc(100dvh - 154px)'}}>
                    <div className="col-lg-12">
                        {renderModalError()}
                        <div className='row'
                             id={'name-field'}>
                            <div className='col-lg-4 col-12 p-0' id={'requestType-field'}>
                                <DLText id={''}
                                        text={'Request Type'}
                                        fontColor={errors.requestType !== '' ? "danger" : "grayDark"}
                                        fontSize={"xs"}
                                        fontWeight={"normal"}
                                        marginBottom={"md"}
                                />
                                <DLRadioGroup
                                    id={'hours-radioBtn'}
                                    label={''}
                                    type={'block'}
                                    radioType={'multiple'}
                                    options={dropdownValues?.NFTRequestOptions}
                                    value={requestType}
                                    disableHelperMessageHeight
                                    disableDescriptionMessageHeight
                                    marginBottom={'xs'}
                                    helperMessageColor={'danger'}
                                    helperMessage={errors.requestType}
                                    error={errors.requestType !== ''}
                                    isRequired={true}
                                    onChangeValue={(e) => {
                                        onChangeFormData('requestType', e)
                                    }}
                                />
                            </div>
                            <div className='col-lg-8 col-12 p-0'>
                                {requestType === "ARN_CHANGE" && renderRequestUi()}
                                {requestType !== "ARN_CHANGE" && renderRequestTextUi()}
                            </div>
                        </div>
                    </div>
                </div>

            </div>)
    };

    const renderDesktopUI = () => {
        return (
            <div className={isXSView ? '' : 'lead-details-container '}>
                {renderDesktopHeader()}
                <div className={isXSView ? 'p-0' : 'lead-page-container page-container bg-light mx-3 '}>
                    {renderDesktopData()}
                </div>
                {renderButton()}
            </div>
        );
    };

    return (
        <div>
            {renderDesktopUI()}
            {renderConfirmationModal()}
        </div>
    );
};

export default NFTCreateView;