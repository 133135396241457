import React from 'react';

import {isEmpty} from "../../../../../../utils/validations";
import {purchaseTransactionCellConfig, purchaseTransactionHeadingData} from "../../../../FTModal";

import DLText from "../../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLTable from "../../../../../../core/components/DLTable/DLTable";
import DLInputTextField from "../../../../../../core/components/DLInputTextField/DLInputTextField";
import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLDropDown from "../../../../../../core/components/DLDropdown/DLDropDown";
import DLModalActions from "../../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import Dropdown from "react-bootstrap/Dropdown";
import appTheme from "../../../../../../assets/appTheme";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase/InputBase";
import SearchIcon from "@mui/material/SvgIcon/SvgIcon";
import {numberWithCommas} from "../../../../../../utils/helper";
import DLCheckbox from "../../../../../../core/components/DLCheckbox/DLCheckbox";

const PurchaseTransactionView = (props) => {

    const {
        funds, totalAllocationAmount, changeAmount, redirectScreen, isShowLoader, searchKeyword,
        products, addProduct
    } = props;


    const getUI = () => {

        if (isEmpty(products)) {
            return (
                <div className="w-100" style={{height: 'calc(100vh - 555px)'}}>
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <DLText id={""}
                                text={"No products found."}
                                fontColor={"lightGray"}
                                marginBottom={"none"}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div>
                {!isEmpty(products) && <div>
                    {products.map((item, index) => {
                        return (
                            <Dropdown.Item onClick={() => {
                                addProduct(item)
                            }}
                                           key={'products-' + index}>
                                <div className={index === 0 ? "p-2 ps-3" : "p-2 ps-3 border-top"}>
                                    <div>
                                        <DLText id={""}
                                                text={item?.description}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                                isInline={true}
                                                marginBottom={"none"}
                                        />
                                    </div>

                                </div>
                            </Dropdown.Item>
                        )
                    })}
                </div>}
            </div>
        );
    };

    const renderSearchBar = () => {
        const {searchFundError, searchProduct} = props;
        return (
            <div className='d-flex justify-content-center align-items-center'>
                <div className={"w-100"}>
                    <DLText id={""}
                            text={"Scheme Name"}
                            fontSize={"xs"}
                            fontColor={searchFundError !== '' ? "danger" : "black"}
                            marginBottom={"xs"}
                    />
                    <div className="resappbar-search-box-container" style={{flex: 1}}>
                        <Dropdown className='w-100 p-0'
                                  style={{borderColor: searchFundError !== '' ? appTheme.appColor.danger : ''}}>
                            <Dropdown.Toggle id="" className='py-1 '>
                                <div className="w-100"
                                     style={{color: "var(--gray-light)"}}>
                                    <Paper
                                        className="res-app-paper"
                                        component=""
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            boxShadow: "none",
                                            p: 0
                                        }}>
                                        <InputBase
                                            sx={{flex: 1, p: 0, fontSize: '14px', fontWeight: 500}}
                                            fullWidth
                                            onChange={(e) => searchProduct(e.target.value)}
                                            autoFocus={true}
                                            placeholder={"Search..."}
                                            value={searchKeyword}
                                        />
                                        {
                                            <SearchIcon/>
                                        }
                                    </Paper>
                                </div>
                            </Dropdown.Toggle>
                        </Dropdown>
                    </div>
                    {
                        (!isEmpty(products)) &&
                        <div className='resappbar-search-box-container-options'>
                            <div style={{maxHeight: 'calc(100vh - 653px)', overflowY: "auto"}}>
                                {getUI()}
                            </div>
                        </div>
                    }
                    {!isEmpty(searchFundError) &&
                    <DLText id={""}
                            text={searchFundError}
                            fontSize={"xs"}
                            fontColor={"danger"}
                            marginBottom={"xs"}
                    />
                    }
                </div>
            </div>
        )
    };

    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between w-100'>
                <div className='page-header p-0 m-0'>
                    Purchase Transaction
                </div>
                <div>
                    {renderActionButtons()}
                </div>
            </div>
        )
    };

    const renderModalError = () => {
        const {productModalError} = props;

        if (isEmpty(productModalError)) {
            return '';
        }
        return (
            <div className={'py-2'}>
                {!isEmpty(productModalError) && <div className='pb-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className={'error-panel'}>
                        {productModalError}
                    </div>
                </div>}
            </div>
        );
    };

    const renderAddFundUI = () => {
        const {
            addedFund, reinvestProduct, isReinvested, folioNumbers, addedProductAmountError,
            onchangeValue, addedProductAmount, productMinValue, productMaxValue, productMultipleValue
        } = props;
        return (
            <div>
                <DLText id={'selected-fund'}
                        text={addedFund}
                />
                <div className='d-flex align-items-center justify-content-between'>
                    <DLCheckbox
                        id={'mark-as-reviewed-dayCheck'}
                        fontSize={"sm"}
                        label={'Reinvest'}
                        disableHelperMessageHeight={true}
                        onChangeValue={(e) => {
                            reinvestProduct(e)
                        }}
                        labelPlacement={'end'}
                        marginBottom={'none'}
                        checkboxcolor={'black'}
                        labelColor={appTheme.appColor.black}
                        isChecked={isReinvested}
                    />
                    <DLText id={''}
                            text={'Change Scheme'}
                            isClickable={true}
                            onClick={() => {
                                addProduct('')
                            }}
                    />
                </div>
                <div className='pt-3 mt-2'>
                    <DLText id={'selected-fund'}
                            text={'Folio No.'}
                            fontSize={"xs"}
                            fontColor={"grayDark"}
                    />
                    <DLDropDown
                        id={"status-filter"}
                        label={''}
                        labelFontColor={'black'}
                        labelPlacement={'top'}
                        value={'null'}
                        options={folioNumbers}
                        onSelect={() => {
                        }}
                        disableHelperMessageHeight={true}
                        minWidth={'100%'}
                    />
                </div>
                <div>
                    <div>
                        <DLText id={'selected-fund'}
                                text={'Amount'}
                                fontSize={"xs"}
                                fontColor={isEmpty(addedProductAmountError) ? "grayDark" : "danger"}
                                isInline={true}
                        />
                        <DLText id={'selected-fund'}
                                text={' Min. ₹ ' + numberWithCommas(productMinValue)}
                                fontSize={"xxs"}
                                fontColor={"disable"}
                                isInline={true}
                        />
                        <DLText id={'selected-fund'}
                                text={'& Max. ₹ ' + numberWithCommas(productMaxValue)}
                                fontSize={"xxs"}
                                fontColor={"disable"}
                                isInline={true}
                        />
                        <DLText id={'selected-fund'}
                                text={' ( multiple of ₹ ' + productMultipleValue + ' )'}
                                fontSize={"xxs"}
                                fontColor={"disable"}
                                isInline={true}
                        />
                    </div>

                    <DLInputTextField id={'leads-searchBox'}
                                      marginBottom={"none"}
                                      rows={1}
                                      onChangeValue={(e) => {
                                          onchangeValue('addedProductAmount', e)
                                      }}
                                      isClearable={false}
                                      value={addedProductAmount}
                                      error={!isEmpty(addedProductAmountError)}
                                      helperMessage={addedProductAmountError}
                                      placeholder={''}
                                      isSearchable={true}
                                      size="small"
                                      inputHeight={'sm'}
                                      inputType={"number"}
                                      disableHelperMessageHeight={true}
                    />
                </div>
            </div>
        )
    };

    const renderAddSchemeModal = () => {
        const {closeAddSchemeModal, isOpenAddSchemeModal, addedFund, addScheme} = props;
        return (
            <DLModal
                history={props.history}
                maxWidth={"xs"}
                onClose={closeAddSchemeModal}
                open={isOpenAddSchemeModal}
                id={'add-scheme-modal'}>
                <DLModalTitle
                    id={'add-scheme-title'}
                    onClose={() => closeAddSchemeModal()}
                    title={'Add Item'}/>
                <DLModalContainer
                    id={'add-scheme-container'}>
                    <div style={{
                        minHeight: '320px'
                    }}>
                        {renderModalError()}
                        {(isEmpty(addedFund)) ? renderSearchBar() : renderAddFundUI()}
                    </div>
                </DLModalContainer>
                <DLModalActions id={'add-scheme-action'}>
                    <div className='mb-4' style={{paddingTop: '7px'}}>
                        <DLButton
                            id={'add-scheme-cancelBtn'}
                            history={props.history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => closeAddSchemeModal()}/>
                        <DLButton
                            id={'add-scheme-assignBtn'}
                            history={props.history}
                            label={'Add'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            onClick={() => {
                                addScheme()
                            }}
                        />
                    </div>
                </DLModalActions>
            </DLModal>
        )
    };

    const renderDiscardModal = () => {
        const {closeAddDiscardModal, isOpenDiscardModal, redirectScreen} = props;
        return (
            <DLModal
                history={props.history}
                maxWidth={"xs"}
                onClose={closeAddDiscardModal}
                open={isOpenDiscardModal}
                id={'discard-modal'}>
                <DLModalTitle
                    id={'discard-title'}
                    onClose={() => closeAddDiscardModal()}
                    title={'Confirmation'}/>
                <DLModalContainer
                    id={'discard-container'}>
                    <div className='mb-2 pb-1 text-center'>
                        <DLText id={''}
                                text={"Are you sure you want to discard this transaction?"}
                                fontSize={"sm"}
                                fontWeight={"normal"}
                                marginBottom={"none"}
                        />
                        <DLText id={''}
                                text={"This action cannot be undone."}
                                fontSize={"sm"}
                                fontColor={"danger"}
                                marginBottom={"none"}
                        />
                    </div>
                </DLModalContainer>
                <DLModalActions id={'discard-action'}>
                    <div className='mb-4' style={{paddingTop: '7px'}}>
                        <DLButton
                            id={'discard-cancelBtn'}
                            history={props.history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => closeAddDiscardModal()}/>
                        <DLButton
                            id={'discard-btn'}
                            history={props.history}
                            styleType={"contained"}
                            type={"danger"}
                            label={'Discard'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            onClick={() => {
                                redirectScreen('DISCARD')
                            }}
                        />
                    </div>
                </DLModalActions>
            </DLModal>
        )
    };

    const renderActionButtons = () => {

        const {isXSView, clearSelectedPortfolio, openAddSchemeModal} = props;

        if (isXSView) {
            return (
                <div className={'mx-3 mt-2'}>
                    <DLButton
                        id={'assign-crm-cancelBtn'}
                        history={props.history}
                        label={'Select Portfolio'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => {

                        }}/>
                    <DLButton
                        id={'assign-crm-cancelBtn'}
                        history={props.history}
                        label={'Add Scheme'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => {
                        }}/>
                </div>
            );
        }
        return (
            <div className={'d-flex justify-content-center'}>
                {(isEmpty(funds)) ?
                    <DLButton
                        id={'assign-crm-cancelBtn'}
                        history={props.history}
                        label={'Select Portfolio'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => {
                            redirectScreen('SELECT-FOLIO')
                        }}/>
                    :
                    <DLButton
                        id={'assign-crm-cancelBtn'}
                        history={props.history}
                        label={'Clear All'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => {
                            clearSelectedPortfolio()
                        }}/>}
                <DLButton
                    id={'assign-crm-cancelBtn'}
                    history={props.history}
                    label={'Add Scheme'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => {
                        openAddSchemeModal()
                    }}/>
            </div>
        );
    };

    const renderBottomActionButtons = () => {
        const {submitTransactionDetails, validateTransaction, openDiscardModal} = props;
        let isAllErrorClear = validateTransaction();

        return (
            <div className='sticky-bottom-buttons pb-3'>
                <DLButton
                    id={'save-and-close-button'}
                    type={"danger"}
                    styleType={"contained"}
                    sideMargin={'none'}
                    onClick={() => {
                        openDiscardModal()
                    }}
                    label={"Discard"}
                />
                <DLButton id={'next-button'}
                          buttonSize={'sm'}
                          fontSize={'sm'}
                          sideMargin={'none'}
                          isDisabled={!isAllErrorClear || isEmpty(funds)}
                          onClick={() => {
                              submitTransactionDetails()
                          }}
                          label={"Done"}/>
            </div>
        );
    };

    const renderDeleteUI = (selectedTransaction) => {
        const {deleteFund} = props;
        return (
            <i className='fa-regular fa-trash-can default-icon cursor'
               onClick={() => {
                   deleteFund(selectedTransaction?.productId)
               }}
            />
        )
    };

    const renderAmountInput = (selectedFund) => {
        let amount = selectedFund?.amount;
        let productId = selectedFund?.productId;

        return (
            <div className='my-2'
                 style={{maxWidth: 120}}
                 onBlur={() => {
                     // changeFundDetails()
                 }}>
                <DLInputTextField id={'leads-searchBox'}
                                  marginBottom={"none"}
                                  rows={1}
                                  fontSize={"xs"}
                                  onChangeValue={(e) => {
                                      changeAmount(e, productId)
                                  }}
                                  isClearable={false}
                                  value={amount}
                                  onBlur={() => {
                                      console.log('in blury')
                                  }}
                                  placeholder={'Search by Name'}
                                  isSearchable={true}
                                  size="small"
                                  inputHeight={'sm'}
                                  inputType={"number"}
                                  disableHelperMessageHeight={true}
                />
            </div>
        )
    };

    const renderlastRow = (tableData) => {
        const {funds} = props;
        let totalAllocation = 0;
        let totalAmount = 0;
        if (isEmpty(funds)) {
            return tableData
        }
        funds.map((fund) => {
            if (!isEmpty(fund?.amount)) {
                totalAmount += Number(fund?.amount)
            }
            if (!isEmpty(fund?.allocationAmount)) {
                totalAllocation += Number(fund?.allocationAmount)
            }

        });
        // totalAllocation = totalAllocation.toFixed(2);
        // totalAmount = totalAmount.toFixed(2);
        return [
            "",
            "",
            {
                isCustomUI: true,
                customUI: <DLText id={''}
                                  text={isEmpty(totalAllocation) ? '' : (totalAllocation + '%')}
                                  fontWeight={"semi-bold"}
                />
            },
            {
                isCustomUI: true,
                customUI: <DLText id={''}
                                  text={numberWithCommas(totalAmount)}
                                  fontWeight={"semi-bold"}
                />
            },
            ""
        ];
    };

    const renderProductDetails = (product) => {
        let productName = product?.productName;
        let errors = product?.errors;
        const errorUI = isEmpty(errors) ? '' :
            errors.map((error) => {
                return <DLText id={''}
                               text={error}
                               fontSize={"xs"}
                               fontColor={"danger"}
                               marginBottom={"sm"}
                />
            });
        return (
            <div className='py-3'>
                <DLText id={''}
                        text={productName}
                        fontSize={"xs"}
                        marginBottom={"none"}
                />
                {errorUI}
            </div>
        )
    };

    const renderFolioNumberUI = (folioNumbers) => {
        return (
            <DLDropDown
                id={"status-filter"}
                label={''}
                labelFontColor={'black'}
                fontSize={"xs"}
                labelPlacement={'top'}
                value={'null'}
                options={folioNumbers}
                onSelect={() => {
                }}
                marginBottom={"none"}
                disableHelperMessageHeight={true}
                minWidth={'150px'}
            />
        )
    };

    const getFormattedTableData = (transactionData) => {
        let tableData = [];
        const {isXSView} = props;
        if (isEmpty(transactionData)) {
            return tableData
        }
        transactionData.map((selectedTransaction) => {
            let transactionRecords = [];
            let transactionDetails = {};
            if (!isXSView) {
                transactionRecords.push(renderProductDetails(selectedTransaction));
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderFolioNumberUI(selectedTransaction?.folioNumbers)
                });
                transactionRecords.push(selectedTransaction?.allocation);
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderAmountInput(selectedTransaction)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderDeleteUI(selectedTransaction)
                });
            } else {
                transactionRecords.push(renderProductDetails(selectedTransaction));
                transactionRecords.push('');
                transactionRecords.push(selectedTransaction?.allocation);
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderAmountInput(selectedTransaction?.amount)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderDeleteUI()
                });
            }
            transactionDetails.id = selectedTransaction?.clientRequestId;
            transactionDetails.data = transactionRecords;
            tableData.push(transactionDetails)
        });
        tableData.push({
            id: '',
            data: renderlastRow(tableData)
        });
        return tableData

    };

    const renderTransactionTable = () => {
        let tableData = getFormattedTableData(funds);
        return (
            <div className="w-100">
                <DLTable
                    id={''}
                    tableMaxHeight={'calc(100dvh - 340px)'}
                    tableMinHeight={'calc(100dvh - 340px)'}
                    isRowClickable={false}
                    cellConfig={purchaseTransactionCellConfig}
                    headerData={purchaseTransactionHeadingData}
                    tableData={tableData}
                    isShowPagination={false}
                />
            </div>
        )
    };

    const renderEmptyUI = () => {
        return (
            <div className='d-flex align-items-center justify-content-center border border-rounded bg-red'
                 style={{
                     maxHeight: 'calc(100dvh - 276px)',
                     minHeight: 'calc(100dvh - 276px)'
                 }}>
                No schemes have been added for the purchase.
            </div>
        )
    };

    const renderErrorUI = () => {
        const {responseErrors, warningMessage} = props;

        if (isEmpty(responseErrors) && isEmpty(warningMessage)) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={'mt-3'}>
                {!isEmpty(responseErrors) && <div className='pb-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className={'error-panel'}>
                        {!isEmpty(responseErrors) ? responseErrors : warningMessage}
                    </div>
                </div>}
                {!isEmpty(warningMessage) && <div className='pb-2 align-items-center d-flex message-block'>
                    <div className={!isEmpty(responseErrors) ? 'error-vertical-line' : 'warning-vertical-line'}/>
                    <div className={!isEmpty(responseErrors) ? 'error-panel' : 'warning-panel'}>
                        {!isEmpty(responseErrors) ? responseErrors : warningMessage}
                    </div>
                </div>}
            </div>
        );
    };

    const renderUi = () => {
        return (
            <div style={{minHeight: 'calc(100dvh - 175px)'}}>
                {renderHeader()}
                <div className='py-3'>
                    <DLText id={''}
                            text={'Investment Amount: ' + numberWithCommas(totalAllocationAmount)}
                            fontSize={"md"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                    />
                    {renderErrorUI()}
                </div>

                <div className=''>
                    {(isEmpty(funds)) ? renderEmptyUI() : renderTransactionTable()}
                </div>
                {renderBottomActionButtons()}
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    return (
        <div className='bg-light p-3'>
            <div className='bg-white border border-rounded p-3 m-1'>
                {renderUi()}
            </div>
            {renderAddSchemeModal()}
            {renderDiscardModal()}
            {renderLoader()}
        </div>
    );
};

export default PurchaseTransactionView;