import {userConst} from "./userConsts";
import {createSlice} from '@reduxjs/toolkit'

const appSlice = createSlice({
    name: 'userReducer',
    initialState: {
        userAuthStatus: userConst.AUTH_STATUS_PENDING,
        profile: null,
        isDrawerWidth: false,
        userStatus: ''
    },
    reducers: {
        GetMyProfileData(state, action) {
            state.profile= action.payload;
            state.isProfileLoaded=true;
            state.userAuthStatus=userConst.AUTH_STATUS_AUTHORIZED
        },
        profileCallFired(state, action) {
            state.isProfileCallFired = true
        },

        userLogoutData(state) {
            state.userAuthStatus= userConst.AUTH_STATUS_UNAUTHORIZED;
            state.profile= null;
            state.isLoggedOutScreen = false;
                state.isProfileCallFired = false
        },
        setUserAuthStatus(state, action) {
            state.userAuthStatus = action.payload;
        },
        setUserProfile(state, action) {
            state.profile = action.payload;
        },
        logoutUser(state) {
            state.userAuthStatus = userConst.AUTH_STATUS_UNAUTHORIZED
        },
        setDrawerWidthData(state, action) {
            state.isDrawerWidth = action.payload
        },
        userLoggedOut(state,action) {
            state.userStatus= userConst.AUTH_STATUS_LOGGED_OUT;
            state.isLoggedOutScreen = true;
            state.loggedOutMessage = action?.payload;
        },
        setNotAccessible(state) {
            state.userStatus= userConst.ACCESS_DENIED
        },
        resetLoggedOutState(state){
            state.userStatus = ''
        }
    }
});

export const {profileCallFired,GetMyProfileData, userLoggedOut, setUserAuthStatus, setUserProfile, resetLoggedOutState, setNotAccessible, setDrawerWidthData, logoutUser} = appSlice.actions;
export default appSlice.reducer
