import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../../../utils/validations";

import {getPersonDetails} from "../../../../ClientsActions";
import ProfileView from "./profileView";

const Profile = (props) => {

    const [profileState, setProfileState] = useState({
        allTabs: [],
        selectedProfileTab: {},
        selectedPersonDetails: [],
        isApiInProgress: false,
        profileResponseErrors: ''
    });

    useEffect(() => {
        const {applicantDetails, accountDetails, isCorporateClient, accountType} = props;
        let updatedTabs = isEmpty(accountType) ? [] : [{label: 'Account Details', value: 'ACCOUNT_DETAILS'}];

        if ((isEmpty(applicantDetails) && !isEmpty(accountType)) || (accountType === 'Minor Account')) {

            setProfileState((prevState) => ({
                ...prevState,
                allTabs: updatedTabs,
                selectedProfileTab: {label: 'Account Details', value: 'ACCOUNT_DETAILS'}
            }));
            return
        }

        if(!isCorporateClient) {
            applicantDetails.map((person, index) => {
                if(person?.holderType === 'GUARDIAN'){
                    updatedTabs.push(
                        {
                            personId: person?.personId,
                            label: 'Guardian',
                            value: 'GUARDIAN'
                        }
                    )
                }else{
                    if (accountType !== 'Joint Account') {
                        updatedTabs.push(
                            {
                                personId: person?.personId,
                                label: 'Account Holder',
                                value: 'ACCOUNT_HOLDER_' + (index + 1)
                            }
                        )
                    } else {
                        updatedTabs.push(
                            {
                                personId: person?.personId,
                                label: 'Account Holder ' + (index + 1),
                                value: 'ACCOUNT_HOLDER_' + (index + 1)
                            }
                        )
                    }
                }

            });
        }

        setProfileState((prevState) => ({
            ...prevState,
            allTabs: updatedTabs,
            selectedProfileTab: {label: 'Account Details', value: 'ACCOUNT_DETAILS'}
        }));

    }, [props.applicantDetails]);

    const handleTabChange = (selectedTab) => {
        const {accountId} = props;
        if (isEmpty(selectedTab?.personId)) {
            if (selectedTab?.value === 'ACCOUNT_DETAILS') {
                setProfileState((prevState) => ({
                    ...prevState,
                    selectedPersonDetails: {},
                    selectedProfileTab: selectedTab,
                    isApiInProgress: false,
                    profileResponseErrors: ''
                }));
            }
            return
        }

        setProfileState((prevState) => ({
            ...prevState,
            isApiInProgress: true
        }));

        getPersonDetails(accountId, selectedTab?.personId)
            .then((res) => {
                if (res?.success) {
                    setProfileState((prevState) => ({
                        ...prevState,
                        selectedPersonDetails: res?.data,
                        selectedProfileTab: selectedTab,
                        isApiInProgress: false,
                        profileResponseErrors: ''
                    }));

                } else {
                    setProfileState((prevState) => ({
                        ...prevState,
                        profileResponseErrors: res?.__error,
                        isApiInProgress: false
                    }))
                }
            })
    };

    return (
        <ProfileView
            {...props}
            {...profileState}
            handleTabChange={handleTabChange}
        />
    );
};
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(Profile);