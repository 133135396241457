import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {
    getAllAccountTypes,
    initiateOnboarding,
    searchAccount,
    getKycDetails,
    refreshData
} from "../../OnboardingActions";

import IINVerificationView from "./IINVerificationView";

import {getHoldingNatureOptions} from "../../OnboardingActions";
import {formatDataForDropDown, setValuesForDropdown} from "../../OnboardingHelper";
import {isEmpty} from "../../../utils/validations";


const IINVerification = (props) => {
    const defaultKycDetails = {
        kycDetails: {
            status: '',
            kycId:'',
            kycStatus: '',
            KRAMasterCode: '',
            lastUpdatedDate: '',
            kycStatusCode: '',
            mode: '',
            kycMode: {},
            kycCreationDate: '',
            modification: '',
            modificationRemarks: '',
            remarks: '',
            inPersonVerificationCode: '',
            kycInPersonVerification: {},
            uboFlagCode: '',
        }
    };

    const defaultPANDetails = [
        {
            PAN_Number: '',  // Permanent Account Number
            firstName: '',  // First name of the account holder
            middleName: '', // Middle name of the account holder
            lastName: '',   // Last name of the account holder
            existingPerson: false,  // Whether the person already exists in the system
            isEmptyBlock: true,    // Indicates if the account holder details block is empty or filled
            kycDetails: {}
        },
        {
            PAN_Number: '',
            firstName: '',
            middleName: '',
            lastName: '',
            existingPerson: false,
            isEmptyBlock: true,
            kycDetails: {}
        },
        {
            PAN_Number: '',
            firstName: '',
            middleName: '',
            lastName: '',
            existingPerson: false,
            isEmptyBlock: true,
            kycDetails: {}
        }
    ];

    const [clientOnboardingState, setClientOnboardingState] = useState({
        isShowLoader: false,  // Controls the visibility of a loading spinner
        accountTypes: [],     // List of account types available for selection
        holdingNatures: [],     // List of account holding nature
        selectedAccountType: 'INDIVIDUAL',  // Default selected account type
        selectedModeOfHolding: 'SI',  // Default selected account type
        PANDetails: [...defaultPANDetails],
        NSEOnboardingStatus: {},  // Stores the status of NSE onboarding process
        isOpenCreateModal: false,  // Controls the visibility of a modal window
        canCreateAccount: false,  // Flag to determine if account creation is possible and able to continue,
        isOpenRemoveModal: false,
        kycStatus: false,
        removePANNumber: null,
        leadRequestId: '',
        isKycStatusModelOpen: false,
        selectedKycDetails: '',
        error: '',
        openModalIndex: 0
    });

    useEffect(() => {
        getAccountTypes() // Gets and Sets account types array
    }, []);

    useEffect(()=>{
        const {PANDetails, openModalIndex} = clientOnboardingState;
        let selectedPAN = PANDetails[openModalIndex];

        if((!isEmpty(selectedPAN?.PAN_Number) && isEmpty(selectedPAN?.kycDetails))){
            getKycStatus(PANDetails, openModalIndex)
        }
    },[clientOnboardingState?.PANDetails]);

    const handleOpenKycStatusModal = (details) => {
        setClientOnboardingState((prevStep) => ({
            ...prevStep,
            isKycStatusModelOpen: true,
            selectedKycDetails: details
        }));
    };

    const updateDataByRefresh = (PANNumber, value) => {
        const {PANDetails}=clientOnboardingState;
        return PANDetails.map(applicantDetails =>
            applicantDetails.PANNumber === PANNumber ? {...applicantDetails, kycDetails: value} : applicantDetails
        );
    };

    const refreshButton = (fields) => {
        const payloadObj = {
            PANNumber: fields?.PAN_Number,
            kycId: fields?.kycDetails?.kycId
        };
        setClientOnboardingState((prevStep) => ({
            ...prevStep,
            isShowLoader: true,
        }));


        refreshData(payloadObj)
            .then((res) => {
                if (res.success) {
                    setClientOnboardingState((prevStep) => ({
                        ...prevStep,
                        kycDetails:updateDataByRefresh(fields?.PANNumber,getUpdatedKYCdetails(res?.data?.kyc?.kycDetails)),
                        isShowLoader: false,
                    }));

                } else {

                    setClientOnboardingState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                    }));

                }
            })
    };

    const handleCloseKycStatusModal = () => {
        setClientOnboardingState((prevStep) => ({
            ...prevStep,
            isKycStatusModelOpen: false,
            selectedKycDetails: ''
        }));
    };

    const onChangeData = (name, value) => {
        let updateObj = {};

        if (name === 'selectedAccountType') {
            updateObj = {PANDetails: defaultPANDetails, error: ''};
            if (value === 'INDIVIDUAL') {
                updateObj.selectedModeOfHolding = 'SI'
            } else {
                // if(value === 'JOINT'){
                updateObj.selectedModeOfHolding = '';
            }
        }
        setClientOnboardingState(prevStep => ({
            ...prevStep,
            ...updateObj,
            [name]: value,
            [name + 'Error']: '',
            NSEOnboardingStatus: {},
            selectedModeOfHoldingError: ''
        }));
    };

    const getAccountTypes = () => {
        let currentPath = props?.location;
        let leadId = '';
        if(currentPath){
           leadId = currentPath?.state?.leadId;
        }

        setClientOnboardingState((prevStep) => ({
            ...prevStep,
            isShowLoader: true,
            leadRequestId: leadId
        }));

        getAllAccountTypes()
            .then((res) => {
                if (res?.success) {
                    getHoldingNatureOptions()
                        .then(holdingNatureRes => {
                            if (holdingNatureRes.success) {
                                let holdingNatures = holdingNatureRes?.data?.holdingNatures;
                                if(!isEmpty(holdingNatures)){
                                    holdingNatures = holdingNatures.filter((e) => e.code !== 'ES')
                                }
                                setClientOnboardingState((prevStep) => ({
                                    ...prevStep,
                                    accountTypes: formatDataForDropDown(res.data?.accountType),
                                    holdingNatures: setValuesForDropdown(holdingNatures, 'description', 'code'),
                                    isShowLoader: false
                                }));
                            }
                        });
                } else {
                    setClientOnboardingState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                    }));
                }
            })
    };

    const openCreateModal = (index) => {
        const {selectedModeOfHolding, selectedAccountType} = clientOnboardingState;
        if ((selectedAccountType === 'JOINT') && isEmpty(selectedModeOfHolding)) {
            setClientOnboardingState((prevStep) => ({
                ...prevStep,
                selectedModeOfHoldingError: 'Please select mode of holding'
            }));
            return
        }
        setClientOnboardingState((prevStep) => ({
            ...prevStep,
            isOpenCreateModal: true,
            openModalIndex: index
        }));
    };

    const closeCreateModal = () => {
        setClientOnboardingState((prevStep) => ({
            ...prevStep,
            isOpenCreateModal: false
        }));
    };

    const checkIfStatusUpdateRequired = (PANDetails) => {
        const {selectedAccountType} = clientOnboardingState;

        const PAN_Numbers = getPANNumbersFromState(PANDetails);

        return !(selectedAccountType === 'JOINT' && PAN_Numbers.length <= 1);
    };

    const getUpdatedKYCdetails = (response) => {
        if (isEmpty(response?.kyc?.kycDetails)) {
            return defaultPANDetails;
        } else {
            const kycDetails=response?.kyc?.kycDetails[0];
            return {
                status: isEmpty(response?.kyc?.status) ? '' : response?.kyc?.status,
                kycId: isEmpty(response?.kycId) ? '' : response?.kycId,
                kycStatus: isEmpty(kycDetails?.kycStatus) ? '' : kycDetails?.kycStatus,
                KRAMasterCode: isEmpty(kycDetails?.KRAMasterCode) ? '' : kycDetails?.KRAMasterCode,
                lastUpdatedDate: isEmpty(kycDetails?.lastUpdatedDate) ? '' : kycDetails?.lastUpdatedDate,
                kycStatusCode: isEmpty(kycDetails?.kycStatusCode) ? '' : kycDetails?.kycStatusCode,
                mode: isEmpty(kycDetails?.mode) ? '-' : kycDetails?.mode,
                kycMode: isEmpty(kycDetails?.kycMode) ? '-' : kycDetails?.kycMode,
                kycCreationDate: isEmpty(kycDetails?.kycCreationDate) ? '' : kycDetails?.kycCreationDate,
                modification: isEmpty(kycDetails?.kycModificationStatusCode) ? '-' : kycDetails?.kycModificationStatusCode,
                modificationRemarks: isEmpty(kycDetails?.modificationRemarks) ? '-' : kycDetails?.modificationRemarks,
                remarks: isEmpty(kycDetails?.remarks) ? '-' : kycDetails?.remarks,
                inPersonVerificationCode: isEmpty(kycDetails?.inPersonVerificationCode) ? '-' : kycDetails?.inPersonVerificationCode,
                kycInPersonVerification: isEmpty(kycDetails?.kycInPersonVerification) ? '-' : kycDetails?.kycInPersonVerification,
                kycModificationStatus: isEmpty(kycDetails?.kycModificationStatus) ? '-' : kycDetails?.kycModificationStatus,
                uboFlagCode: isEmpty(kycDetails?.uboFlagCode) ? '-' : kycDetails?.uboFlagCode,
                latestUpdatedDate: isEmpty(kycDetails?.latestUpdatedDate) ? '' : kycDetails?.latestUpdatedDate,
            }
        }
    };

    const getKycStatus = (PANDetails, selectedPANIndex) => {
        setClientOnboardingState((prevStep) => ({
            ...prevStep,
            isShowLoader: true,
        }));
        let selectedPAN = PANDetails[selectedPANIndex]?.PAN_Number;

        let isStatusUpdateRequired = checkIfStatusUpdateRequired(PANDetails);
        getKycDetails(selectedPAN)
            .then((res) => {
                let updatedKYCdetails = {};
                if(!isEmpty(res?.data)){
                    updatedKYCdetails = getUpdatedKYCdetails(res?.data);
                }
                PANDetails[selectedPANIndex].kycDetails = updatedKYCdetails;
                if(isStatusUpdateRequired && res?.success){
                    fetch_NSE_IIN_Status(PANDetails);
                }else{
                    setClientOnboardingState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                        error: res?.__error
                    }));
                }
            })

    };

    const handleAddPAN = (PAN_Data, kycDetails) => {
        const {selectedAccountType, openModalIndex, PANDetails} = clientOnboardingState;

        let updatedPANDetails = [...PANDetails];
        switch (selectedAccountType) {
            case 'INDIVIDUAL':
                updatedPANDetails[0] = PAN_Data;
                if (kycDetails !== '') {
                    updatedPANDetails[0].kycDetails = kycDetails;
                }
                break;
            case 'JOINT':
                updatedPANDetails[openModalIndex] = PAN_Data;
                if (kycDetails !== '') {
                    updatedPANDetails[openModalIndex].kycDetails = kycDetails;
                }
                break;
        }

        updatedPANDetails = updatedPANDetails.map(PAN => {
            return {...PAN, PAN_Number: PAN.PAN_Number.toUpperCase()}
        });
        setClientOnboardingState((prevStep) => ({
            ...prevStep,
            PANDetails: updatedPANDetails,
            isOpenCreateModal: false,
            openModalIndex: (selectedAccountType === 'INDIVIDUAL') ? 0 : openModalIndex
        }));

    };

    const getPANNumbersFromState = (PANDetails) => {
        const {selectedAccountType} = clientOnboardingState;

        const PANNumbers = PANDetails.filter(item => item?.PAN_Number)
            .map(item => item?.PAN_Number);

        if (selectedAccountType === 'INDIVIDUAL') {
            return [PANDetails[0].PAN_Number];
        }
        return PANNumbers;
    };

    const fetch_NSE_IIN_Status = (PANDetails) => {

        const {selectedAccountType, selectedModeOfHolding} = clientOnboardingState;

        const PAN_Numbers = getPANNumbersFromState(PANDetails);

        const dataObjSearch = {
            PANNumbers: PAN_Numbers,
            accountType: selectedAccountType,
            holdNature: selectedModeOfHolding
        };

        searchAccount(dataObjSearch, true)
            .then((res) => {
                if (res.success) {
                    setClientOnboardingState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                        NSEOnboardingStatus: res.data,
                        canCreateAccount: res.data?.canCreateAccount,
                        PANDetails: PANDetails,
                        isOpenCreateModal: false,
                        error: ''
                    }));
                } else {
                    setClientOnboardingState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                        error: res.__error
                    }));
                }
            });
    };

    const get_NSE_IIN_Status = (updateObj) => {

        const {selectedAccountType, selectedModeOfHolding} = clientOnboardingState;

        const PAN_Numbers = getPANNumbersFromState(updateObj.PANDetails);

        const dataObjSearch = {
            PANNumbers: PAN_Numbers,
            accountType: selectedAccountType,
            holdNature: selectedModeOfHolding
        };

        searchAccount(dataObjSearch, true)
            .then((res) => {
                if (res.success) {
                    setClientOnboardingState((prevStep) => ({
                        ...prevStep,
                        ...updateObj,
                        NSEOnboardingStatus: res.data,
                        canCreateAccount: res.data?.canCreateAccount,
                    }));
                } else {
                    setClientOnboardingState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                        error: res?.__error
                    }));
                }
            });
    };

    const handleRemovePANDetails = (removePANNumber) => {
        const {PANDetails} = clientOnboardingState;

        const updatedPANDetails = PANDetails.map(item => item?.PAN_Number === removePANNumber ? defaultPANDetails[0] : item);
        const isUpdateStatusRequired = checkIsUpdateStatus(PANDetails);

        if (isUpdateStatusRequired) {
            const updateObj = {
                PANDetails: updatedPANDetails,
                isOpenRemoveModal: false
            };
            setClientOnboardingState((prevStep) => ({
                ...prevStep,
                ...updateObj,
                isOpenRemoveModal: false,
                error: ''
            }));
            get_NSE_IIN_Status(updateObj);
            return;
        }

        const updatedNSE_Status = getUpdatedStatus(updatedPANDetails);

        setClientOnboardingState(prevStep => ({
            ...prevStep,
            PANDetails: updatedPANDetails,
            NSEOnboardingStatus: updatedNSE_Status,
            isOpenRemoveModal: false,
            canCreateAccount: false,
            error: ''
        }));
    };

    const checkIsUpdateStatus = (PANDetails) => {
        const PAN_Numbers = getPANNumbersFromState(PANDetails);

        // if more than 2 pans and we are deleting one then fetch new status
        //if less than two and we are deleting one then remove the status same for single pan
        return PAN_Numbers.length > 2
    };

    const getUpdatedStatus = (PANDetails) => {
        const {NSEOnboardingStatus} = clientOnboardingState;
        const PAN_Numbers = getPANNumbersFromState(PANDetails);

        if (PAN_Numbers.length > 1) {
            return NSEOnboardingStatus
        }
        return {}
    };

    const handleContinueClick = () => {
        const {selectedAccountType, PANDetails, leadRequestId, selectedModeOfHolding} = clientOnboardingState;
        const {handleClickContinue} = props;

        setClientOnboardingState((prevStep) => ({
            ...prevStep,
            isShowLoader: true
        }));

        const updatedPanDetails = PANDetails
            .filter(obj => obj?.PAN_Number && obj?.PAN_Number.trim() !== "")
            .map(obj => ({
                PANNumber: obj.PAN_Number,
                existingPerson: obj.existingPerson
            }));

        const onboardingObj = {
            accountType: selectedAccountType,
            panDetails: updatedPanDetails,
            holdNature: selectedModeOfHolding
        };
        if (!isEmpty(leadRequestId)) {
            onboardingObj.leadRequestId = leadRequestId
        }

        initiateOnboarding(onboardingObj)
            .then(res => {
                if (res.success) {
                    const personId = res.data?.accountHolders[0]?.personId;
                    setClientOnboardingState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                        personId: personId
                    }));
                    handleClickContinue('IIN_Verification', res.data)
                } else {
                    setClientOnboardingState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false
                    }));
                }
            })
    };

    const handleCancelClick = () => {
        props.history.goBack()
    };

    const handleOpenRemoveModal = (removePANNumber) => {
        setClientOnboardingState(prevStep => ({
            ...prevStep,
            isOpenRemoveModal: true,
            removePANNumber: removePANNumber
        }));
    };

    const handleCloseRemoveModal = () => {
        setClientOnboardingState(prevStep => ({
            ...prevStep,
            isOpenRemoveModal: false,
            removePANNumber: null
        }));
    };

    return (
        <IINVerificationView
            {...props}
            {...clientOnboardingState}
            openCreateModal={openCreateModal}
            closeCreateModal={closeCreateModal}
            handleCloseRemoveModal={handleCloseRemoveModal}
            handleOpenRemoveModal={handleOpenRemoveModal}
            onChangeData={onChangeData}
            handleAddPAN={handleAddPAN}
            getKycStatus={getKycStatus}
            refreshButton={refreshButton}
            handleCancelClick={handleCancelClick}
            handleContinueClick={handleContinueClick}
            handleRemovePANDetails={handleRemovePANDetails}
            handleOpenKycStatusModal={handleOpenKycStatusModal}
            handleCloseKycStatusModal={handleCloseKycStatusModal}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    profile: state.userState.profile,

});

export default connect(mapStateToProps, {})(IINVerification);