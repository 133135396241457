import store from "../../../store";
import {
    setFromDateValue,
    setSearchKeywordValue,
    setToDateValue,
    setStatusValue,
    resetAllFilters,
    setLimitCountValue,
    setSkipCountValue,
    setPageValue,
    setRowsPerPageValue,
    setCategoryValue,
    setAccountTypeValue,
    setCRMValue,
    setLocationValue
} from "./clientListingReducer";
import {httpPost} from "../../../utils/httpHelper";


export const getClientListing = (data) => {
    let URL = '/service/advisory/client/all';

    return httpPost(URL, data)
        .then(res => {
            return res;
        });
};


export const setSearchKeyword = (data) => {
    store.dispatch(setSearchKeywordValue(data))
};

export const setFromDate = (data) => {
    store.dispatch(setFromDateValue(data))
};

export const setToDate = (data) => {
    store.dispatch(setToDateValue(data))
};

export const setStatus = (data) => {
    store.dispatch(setStatusValue(data))
};

export const setCategory = (data) => {
    store.dispatch(setCategoryValue(data))
};

export const setAccountType = (data) => {
    store.dispatch(setAccountTypeValue(data))
};

export const setCRMForListing = (data) => {
    store.dispatch(setCRMValue(data))
};

export const setLocationForListing = (data) => {
    store.dispatch(setLocationValue(data))
};

export const resetClientListingFilters = (data) => {
    store.dispatch(resetAllFilters(data))
};

export const setSkipCount = (data) => {
    store.dispatch(setSkipCountValue(data))
};

export const setLimitCount = (data) => {
    store.dispatch(setLimitCountValue(data))
};

export const setPageCount = (data) => {
    store.dispatch(setPageValue(data))
};

export const setClientsListingRowPerPageCount = (data) => {
    store.dispatch(setRowsPerPageValue(data))
};