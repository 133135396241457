import React, {useEffect, useState} from 'react';
import moment from "moment";
import {connect} from "react-redux";

import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";
import {
    getNFTDashboardChartData
} from "../../../NFTAction";

import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import NFTManagementDashboardView from "./NFTManagementDashboardView";
import {resetNFTFilters} from "../../../NFTAction"


const NFTManagementDashboard = (props) => {
        const [NFTDashboardState, setNFTDashboardState] = useState({
                chartData: [],
                interval: 'THIS_MONTH',
                xAxis: [],
                yAxis: [],
                isApiInProgress: false,
                UIState: '',

            }
        );

        useEffect(() => {
            getChartData('THIS_MONTH');
        }, []);

        const getChartData = (interval) => {
            const {profile} = props;

            setNFTDashboardState(prevState => ({
                ...prevState,
                isShowLoader: true
            }));

            getNFTDashboardChartData(interval, profile.advisoryId)
                .then(res => {

                    if (res?.success) {
                        setNFTDashboardState(prevState => ({
                            ...prevState,
                            chartData: res?.data,
                            isSelected: interval,
                            isShowLoader: false,
                            UIState: APP_CONST.CONTENT_AVAILABLE,
                        }));
                        setChartData(res?.data, interval)
                    } else {
                        setNFTDashboardState(prevState => ({
                            ...prevState,
                            isShowLoader: false,
                            isSelected: interval,
                            UIState: APP_CONST.CONTENT_UNAVAILABLE,
                        }));
                    }
                })
        };

        const setChartData = (data, interval) => {
            let xAxis = [];
            let yAxis = [];
            if (isEmpty(data)) {
                return;
            }
            data.items.map((item) => {
                xAxis.push(moment(item?.date).format(interval === "THIS_MONTH" ? "DD-MMM" : "MMM"))
                yAxis.push(item?.count)
            })
            setNFTDashboardState(prevState => ({
                ...prevState,
                xAxis: xAxis,
                yAxis: yAxis
            }));

        }

        const checkIfChartDataAvailable = () => {
            const {yAxis} = NFTDashboardState
            if (isEmpty(yAxis)) {
                return;
            }
            let maxValue = Math.max(...yAxis);
            return maxValue > 0;
        }
        const onNFTClick = () => {
            resetNFTFilters();
            props.history.push('/non-financial-transactions');
        }

        if (isEmpty(NFTDashboardState.UIState)) {
            return (<DLLoader type={"screen"} isVisible={true}/>);
        }

        return (
            <NFTManagementDashboardView
                {...props}
                {...NFTDashboardState}
                onNFTClick={onNFTClick}
                getChartData={getChartData}
                checkIfChartDataAvailable={checkIfChartDataAvailable}
            />
        );
    }
;

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(NFTManagementDashboard);
