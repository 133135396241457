import React from 'react';

import appTheme from "../../../../../../assets/appTheme";
import {isEmpty} from "../../../../../../utils/validations";
import {convertUTCToLocalTime, getTimeFromNowWithDate} from "../../../../../../utils/helper";

import DLText from "../../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";

const BankMandateActivityView = (props) => {

    const {NSEActivities, isShowLoader, submitECS, fileName, bankMandateStatus, refreshNSEActivities, IINNumber,
        sendToClient, isManagementUser, getIsLinkExpired, ACHExpiredDateTime, ECSSubmitStatus, isLGView} = props;

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderTitleUI = () => {

        return (
            <div>
                <div className={(isLGView && ECSSubmitStatus !== 'IN_PROGRESS') ? '' : 'd-flex justify-content-between align-items-center'}>
                    <div className='d-flex align-items-center'>
                        <DLText id={''}
                                text={'NSE Activities'}
                                isInline={true}
                                fontWeight={"semi-bold"}
                                fontSize={"md"}
                        />
                    </div>
                    <div className='pe-1 d-flex align-items-center'>
                        {(ECSSubmitStatus === 'IN_PROGRESS') ? <div className='d-flex' style={{gap: 4}}>
                                <div className="lds-spinner">
                                    <div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/>
                                </div>
                                <DLText id={''}
                                        text={'Processing..'}
                                        fontColor={"darkBlue"}
                                        fontSize={"xs"}
                                        marginBottom={"none"}
                                        fontWeight={"semi-bold"}
                                />
                                <div onClick={() => {
                                    refreshNSEActivities();
                                }}>
                                    <i className="fa-solid fa-rotate-right cursor px-2"/>
                                </div>
                            </div>
                            :
                            <DLButton id={'delete-button'}
                                      buttonSize={'sm'}
                                      fontSize={'sm'}
                                      sideMargin={'none'}
                                      hidden={(bankMandateStatus === 'ACCEPTED')}
                                      isDisabled={isEmpty(IINNumber) || isEmpty(fileName) || (bankMandateStatus === 'ACCEPTED') || isManagementUser}
                                      onClick={() => {
                                          submitECS()
                                      }}
                                      label={isEmpty(NSEActivities) ? "Submit" : "Submit again / Refresh"}/>
                        }

                    </div>
                </div>
            </div>
        )
    };

    const renderErrors = (nseErrors) => {
        if (isEmpty(nseErrors)) {
            return ''
        }
        return nseErrors.map((error, key) => {
            return (
                <div key={'error' + key} className={'error' + key}>
                    <DLText id={''}
                            text={error}
                            fontSize={"xs"}
                            fontColor={"danger"}
                    />
                </div>
            )
        })
    };

    const renderStatusIcon = (status, isINProgress) => {
        if (isEmpty(status)) {
            return ''
        }
        if(isINProgress){
            return (<div className='NSE-status-progress-icon'
                        style={{
                            border: '2px solid '+ appTheme.appColor.secondary
                        }}>
                <i className="fa-solid fa-exclamation"
                   style={{
                       fontSize: 10,
                       color:appTheme.appColor.secondary,
                       paddingTop: 2
                   }}/>
            </div>)
        }
        switch (status) {
            case 'FAILED':
                return <i className="fa-regular fa-circle-xmark NSE-status-icon"
                          style={{color: appTheme.appColor.danger}}/>;
            case 'SUCCESS':
                return <i className="fa-regular fa-circle-check NSE-status-icon"
                          style={{color: appTheme.appColor.success}}/>;
            case 'INITIATED':
                return <div className='NSE-status-icon' style={{border: '2px dashed #282828'}}/>;
            default:
                return '';
        }
    };
    const renderECSRegistration = (response) => {
        let isLinkExpired = getIsLinkExpired();
        if(isEmpty(response)){
            return ''
        }
        return (
            <div>
                {(response?.achMandateStatus === 'ACCEPTED') ?
                    <DLText id={''}
                            text={'Accepted'}
                            fontSize={"xs"}
                            fontColor={"success"}
                            marginBottom={"none"}
                    /> : <div>
                        {(response?.achMandateStatus === 'PENDING') && <DLText id={''}
                                text={'Pending'}
                                fontSize={"xs"}
                                fontColor={"secondary"}
                                isInline={true}
                                marginBottom={"none"}
                        />}</div>}
                {(response?.achMandateStatus === 'PENDING' && (response?.processMode === 'eMandate')) && !isEmpty(ACHExpiredDateTime) &&
                <div className={isLinkExpired ? '' : 'pt-2'}>
                    {(isLinkExpired) ?
                        <DLText id={''}
                                text={'Link has expired'}
                                fontColor={"danger"}
                                isInline={true}
                                fontSize={"xs"}
                        />
                        :
                        <div>
                            <DLButton
                                id={'search-button'}
                                styleType={"outlined"}
                                borderType={'square'}
                                buttonSize={'sm'}
                                fontSize={'sm'}
                                sideMargin={'none'}
                                isDisabled={isManagementUser}
                                onClick={() => {
                                    sendToClient()
                                }}
                                label={"Send link to Client"}
                            />
                            {!isEmpty(ACHExpiredDateTime) && <DLText id={''}
                                    text={'Link will expired at ' + convertUTCToLocalTime(ACHExpiredDateTime, 'DD-MM-YYYY hh:mm:ss')}
                                    fontSize={"xs"}
                            />}
                        </div>
                    }
                </div>
                }
            </div>
        )
    };

    const renderRemark = (response) => {
        if(!isEmpty(response?.rejectionRemarks)){
            return (<div>
                <DLText id={''}
                        text={response?.rejectionRemarks}
                        fontColor={"danger"}
                        isInline={true}
                        fontSize={"xs"}
                />
            </div>)
        }

    };

    const renderNSEActivityUI = () => {
        if (isEmpty(NSEActivities)) {
            return ''
        }
        return NSEActivities.map((item, index) => {
            return (
                <div key={'NSE-status' + index}>
                    <div className='d-flex mb-3'>
                        <div>
                            {(item?.api === 'ACHMANDATEREPORT' || item?.api === 'ACHMANDATEREGISTRATIONS') ?
                                renderStatusIcon(item?.status, item?.response?.achMandateStatus === 'PENDING'):
                                renderStatusIcon(item?.status, false)
                            }

                            <div className={index === NSEActivities.length - 1  ? 'd-flex justify-content-center h-50' : 'd-flex justify-content-center h-100'}>
                                <div className='step-connector'/>
                            </div>
                        </div>

                        <div>
                            <div>
                                <DLText id={''}
                                        text={
                                            <div style={{lineHeight: '15px'}}>
                                                {item?.apiLabel}
                                                <span className='ps-1'
                                                      style={{
                                                          fontSize: 'var(--font-size-xxs)',
                                                          color: 'var(--gray-dark)'
                                                      }}>
                                                    {getTimeFromNowWithDate(item?.updatedAt)}
                                                </span>
                                            </div>
                                        }
                                        fontWeight={"semi-bold"}
                                        isInline={true}
                                />
                            </div>
                            {(item?.api === 'ACHMANDATEREPORT' || item?.api === 'ACHMANDATEREGISTRATIONS') && renderECSRegistration(item?.response)}
                            {(item?.api === 'ACHMANDATEREPORT' || item?.api === 'ACHMANDATEREGISTRATIONS' || item?.api === 'EMANDATE_RETRIGGER') && renderRemark(item?.response)}
                            {(item?.status === "FAILED") && renderErrors(item?.nseErrors)}
                        </div>
                    </div>
                </div>
            );
        })
    };

    const renderContentUI = () => {
        if (isEmpty(NSEActivities)) {
            if(bankMandateStatus === 'ACCEPTED'){
                return ''
            }
            return (<div className={'pt-3'}>
                {isEmpty(IINNumber) ? 'Not Ready for Submission' :'Ready for Submission'}
            </div>)
        }
        return (
            <div className='pt-3'>
                {renderNSEActivityUI()}
            </div>
        )
    };

    const renderErrorUI = () => {
        const {responseErrors, isMobileView} = props;

        if (isEmpty(responseErrors)) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={isMobileView ? '' : 'mb-2'}>
                <div className='pb-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseErrors}
                    </div>
                </div>
            </div>
        );
    };

    const renderUI = () => {
        return (
            <div>
                <div className='border-top pt-3 pb-4'>
                    {renderErrorUI()}
                    {renderTitleUI()}
                    {renderContentUI()}
                </div>
            </div>
        )
    };

    return (
        <div>
            {renderUI()}
            {renderLoader()}
        </div>
    )
};

export default BankMandateActivityView;