import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../core/components/DLComponentHelper";
import {getLeadHistoryData} from "../../../onboarding/components/formsAndReview/FormsAndReviewActions";
import {APP_CONST} from "../../../AppComponents/AppConstants";

import LeadHistoryView from "./LeadHistoryView";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

const LeadHistory = (props) => {

    const {advisoryUsers, selectedLeadId,} = props;

    const [leadHistoryState, setLeadHistoryState] = useState({
        events: [],
        isShowLoader: false,
        UIState: '',
        responseError: ''
    });

    useEffect(() => {
        getData();
    }, [selectedLeadId]);

    const getData = () => {
       setLeadHistoryState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));

        getLeadHistoryData(selectedLeadId)
            .then(res => {
                if (res?.success) {
                    setLeadHistoryState((preState) => ({
                            ...preState,
                            events: isEmpty(res?.data?.events) ? [] : res?.data?.events,
                            isShowLoader: false,
                            UIState: APP_CONST.CONTENT_AVAILABLE,
                        }
                    ))
                } else {
                    setLeadHistoryState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        responseError: res.__error
                    }));
                }
            })
    };

    const getAdvisoryUser = (id) => {
        const usersName = advisoryUsers.find((item) => {
            if (item?.id === id) {
                return item;
            }
        });
        if (isEmpty(usersName)) {
            return '-';
        } else {
            let firstName = isEmpty(usersName?.firstName) ? '' : usersName?.firstName + ' ';
            let lastName = isEmpty(usersName?.lastName) ? '' : usersName?.lastName + ' ';
            return firstName + lastName;
        }
    };

    const getSentenceCase = (str) => {
        switch(str) {
            case 'HOLD':
                return 'Hold';
            case 'LOST':
                return 'Lost';
            case 'CONTACTED':
                return 'Contacted';
            case 'NOT_CONTACTED':
                return 'Not contacted';
            case 'CREATED':
                return 'Created';
            case 'REQUESTED':
                return 'Requested';
            case 'COMPLETED':
                return 'Completed';
            case 'ASSIGNED':
                return 'Assigned';
            case 'ONBOARDING_INITIATED':
                return 'Onboarding initiated';
            case 'ADDED_REMARK':
                return 'Remark';
            default:
                return str;
        }
    };

    if(isEmpty(leadHistoryState.UIState)){
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    return (
        <LeadHistoryView
            {...props}
            {...leadHistoryState}
            getAdvisoryUser={getAdvisoryUser}
            getSentenceCase={getSentenceCase}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(LeadHistory);
