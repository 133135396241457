import React from "react";
import appTheme from "../../assets/appTheme";
import {isEmpty} from "../../utils/validations";

export const renderStatusUI = (txnRequestStage, txnRequestStatus, FTStatuses) =>{
    let statusLabel = '';
    let statusType = '';
    if(isEmpty(txnRequestStage)){
        return '-'
    }
    if(isEmpty(FTStatuses)){
        return txnRequestStage
    }

    if(txnRequestStage === 'DRAFT'){
        statusLabel = 'Draft';
    } else {
        FTStatuses.map((statusObj) => {
            if (statusObj.mapping.stage === txnRequestStage && statusObj.mapping.status === txnRequestStatus) {
                statusType = statusObj.mapping.status;
                statusLabel = statusObj?.label
            }
        })
    }

    if(statusType === 'REJECTED'){
        return (<div className="d-flex align-items-center badge-red ms-0">
            <span style={{color: appTheme.appColor.white}}> {statusLabel} </span>
        </div>);
    }
    else if(statusType === "APPROVED"){
        return (<div className="d-flex align-items-center badge-green ms-0">
            <span style={{color: appTheme.appColor.white}}> {statusLabel} </span>
        </div>);
    }else{
        return (<div className="d-flex align-items-center badge-amber ms-0">
            <span style={{color: appTheme.appColor.white}}> {statusLabel} </span>
        </div>);
    }
};
