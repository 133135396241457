import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLModalActionsView from "./DLModalActionsView";

class DLModalActions extends Component {
  render() {
    return (
        <DLModalActionsView {...this.props} />
    );
  }
}

DLModalActions.propTypes = {
  id: PropTypes.string.isRequired,  // for testing purpose
  children: PropTypes.any.isRequired
};

export default DLModalActions;
