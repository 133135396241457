//noinspection DuplicatedCode,JSUnresolvedVariable,SpellCheckingInspection,JSUnresolvedFunction

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLImageView from './DLImageView';

class DLImage extends Component {
  render() {
    return (
        <>
          <DLImageView
            {...this.props}
          />
        </>
    );
  }
}

DLImage.propTypes = {
  id: PropTypes.string.isRequired,  // for testing purpose
  downloadFileName: PropTypes.string,  // for testing purpose
  src: PropTypes.any,
  onClick: PropTypes.func,    // it will override the link and allowToViewInFullScreen attributes
  resizeMode: PropTypes.oneOf(['cover', 'contain', 'stretch', 'repeat', 'center']),
  shape:PropTypes.oneOf(['default', 'circle' ]),
  allowFullScreen: PropTypes.bool,  // to view the image in full screen when tapped
  isCustomUI: PropTypes.bool,
};

DLImage.defaultProps = {
  shape: 'default',
  allowFullScreen: false,
  resizeMode: 'contain',
  isCustomUI: false
}

export default DLImage;
