import React from 'react';
import {connect} from "react-redux";

import InvestmentView from "./InvestmentvVIew";

const Investment = (props) => {
    return (
        <InvestmentView
            {...props}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(Investment);