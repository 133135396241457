import React, {useState} from 'react';
import {connect} from "react-redux";

import {markAsReview} from "../../../FormsAndReviewActions";
import ReviewedView from "./reviewedView";

const Reviewed = (props) => {

    const [reviewedState, setLReviewedState] = useState({
        isShowLoader: false
    });

    const markAsReviewed = () =>{
        const {handleCloseMarkAsReviewCheckBox, formId, setReviewedFlag, refreshValidateForm, onboardingApplicationId} = props;

        setLReviewedState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));
        let payload = {
            formId : formId,
            type : "REVIEWED",
            onboardingApplicationId: onboardingApplicationId
        };

        markAsReview(payload).then(res => {
            if (res?.success) {
                setReviewedFlag()
            }
            setTimeout(()=>{
                setLReviewedState((preState) => ({
                        ...preState,
                        isShowLoader: false,
                    }
                ));
                handleCloseMarkAsReviewCheckBox();
            },500)
        })
    };

    return (
        <ReviewedView
            {...props}
            {...reviewedState}
            markAsReviewed={markAsReviewed}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    onboardingApplicationId: state.onboardingFlowState.onboardingApplicationId
});

export default connect(mapStateToProps, {})(Reviewed);
