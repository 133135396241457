import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import KycView from "./KYCView";
import {isEmpty} from "../../../../../utils/helperCommon";
import {searchPersonByPAN, getKycDetails, refreshData} from "../../../../OnboardingActions";
import moment from "moment";
import {
    downloadAndViewForm,
    getGenerateForms,
    getGenerateLink,
    getKYCForm,
    updateEKyc
} from "../../FormsAndReviewActions";

const Kyc = (props) => {
    const {accountHoldersPAN} = props;

    const [accountHolderState, setAccountHolderState] = useState({
        isShowLoader: false,  // Controls the visibility of a loading spinner
        kycDetails: [],
        isKycStatusModelOpen: false,
        selectedKycDetails: '',
        isManagementUser: false,
        responseErrors: ''
    });

    useEffect(() => {
        getPANDetails()
    }, []);

    const downloadAndViewDocument = (action, filePath, fileName) => {
        setAccountHolderState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));
        downloadAndViewForm(fileName, filePath, action);
        setTimeout(() => {
            setAccountHolderState((preState) => ({
                    ...preState,
                    isShowLoader: false,
                }
            ))
        }, 500)
    };


    const updateDataByRefresh = (PANNumber, value) => {
        const {kycDetails} = accountHolderState;
        return kycDetails.map(applicantDetails =>
            applicantDetails.PANNumber === PANNumber ? {...applicantDetails, kycDetails: value} : applicantDetails
        );
    };

    const refreshButton = (fields) => {
        const {onboardingApplicationId, accountId} = props;
        const payloadObj = {
            PANNumber: fields?.PANNumber,
            kycId: fields?.kycDetails?.kycId,
            onboardingApplicationId: onboardingApplicationId,
            accountId: accountId

        };
        setAccountHolderState((prevStep) => ({
            ...prevStep,
            isShowLoader: true,
        }));


        refreshData(payloadObj)
            .then((res) => {
                if (res.success) {
                    setAccountHolderState((prevStep) => ({
                        ...prevStep,
                        kycDetails: updateDataByRefresh(fields?.PANNumber, getKycFormattedData(res?.data)),
                        isShowLoader: false,
                    }));

                } else {

                    setAccountHolderState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                    }));

                }
            })
    };

    const updatedKycDetails = (arr, PANNumber, value) => {
        return arr.map(applicantDetails =>
            applicantDetails.PANNumber === PANNumber ? {...applicantDetails, isEKyc: value} : applicantDetails
        );
    };

    const setEkycStatus = (value, applicantDetails) => {
        const {kycDetails} = accountHolderState;

        setAccountHolderState((prevState) => {
            return {
                ...prevState,
                kycDetails: updatedKycDetails(kycDetails, applicantDetails.PANNumber, value),
            };
        });
        updateEkyc(applicantDetails,value);
    };


    const setShowEKyc = (arr, PANNumber, value) => {
        return arr.map(applicantDetails =>
            applicantDetails.PANNumber === PANNumber ? {...applicantDetails, isShowEkyc: value} : applicantDetails
        );
    };

    const showEkycBlock = (value, applicantDetails) => {
        const {kycDetails} = accountHolderState;

        setAccountHolderState((prevState) => {
            return {
                ...prevState,
                kycDetails: setShowEKyc(kycDetails, applicantDetails.PANNumber, value),
            };
        });
    };


    const handleOpenKycStatusModal = (details) => {

        setAccountHolderState((prevStep) => ({
            ...prevStep,
            isKycStatusModelOpen: true,
            selectedKycDetails: details
        }));
    };

    const handleCloseKycStatusModal = () => {
        setAccountHolderState((prevStep) => ({
            ...prevStep,
            isKycStatusModelOpen: false,
            selectedKycDetails: ''
        }));
    };

    const getPANDetails = () => {
        const {onboardingApplicationId, profile} = props;
        if (isEmpty(accountHoldersPAN)) {
            return;
        }
        let PANDetails = [];
        accountHoldersPAN.map((data) => {
            let personDetails = {};
            const payloadObj = {
                PANNumber: data.PAN_Number.toUpperCase(),
                onboardingApplicationId: onboardingApplicationId
            };
            setAccountHolderState((prevStep) => ({
                ...prevStep,
                isShowLoader: true,
            }));


            searchPersonByPAN(payloadObj, true)
                .then((res) => {
                    if (res.success) {
                        personDetails = getFormattedData(res.data);

                        getKycDetails(res.data.PANNumber)
                            .then((res) => {
                                if (res?.success) {
                                    personDetails.kycDetails = getKycFormattedData(res.data);
                                    personDetails.personId = data.personId;

                                    getKYCForm(data.personId, onboardingApplicationId)
                                        .then((res) => {
                                            if (res?.success) {
                                                personDetails.ekycDetails = getEkycDetails(res?.data?.ekycDetails);
                                                personDetails.formDetails = getformDetails(res?.data?.formDetails);
                                                setAccountHolderState((prevStep) => ({
                                                    ...prevStep,
                                                    isShowLoader: false,
                                                    isManagementUser: profile?.isManagement
                                                }));
                                            } else {
                                                setAccountHolderState((prevStep) => ({
                                                    ...prevStep,
                                                    isShowLoader: false,
                                                    isManagementUser: profile?.isManagement
                                                }));
                                            }
                                        });

                                    setAccountHolderState((prevStep) => ({
                                        ...prevStep,
                                        isShowLoader: false,
                                        isManagementUser: profile?.isManagement
                                    }));
                                } else {
                                    setAccountHolderState((prevStep) => ({
                                        ...prevStep,
                                        isShowLoader: false,
                                        isManagementUser: profile?.isManagement
                                    }));
                                }
                                PANDetails.push(personDetails);
                            })

                    } else {
                        setAccountHolderState((prevStep) => ({
                            ...prevStep,
                            isShowLoader: false,
                            isManagementUser: profile?.isManagement
                        }));
                    }
                });


        });
        setAccountHolderState((prevStep) => ({
            ...prevStep,
            kycDetails: PANDetails
        }));
    };

    const getformDetails = (response) => {
        return ({
                id: isEmpty(response?.id) ? '' : response?.id,
                formId: isEmpty(response?.formId) ? '' : response?.formId,
                personId: isEmpty(response?.personId) ? '' : response?.personId,
                fileName: isEmpty(response?.fileName) ? '' : response?.fileName,
                filePath: isEmpty(response?.filePath) ? '' : response?.filePath,
                formType: isEmpty(response?.formType) ? '' : response?.formType,
                eConsent: isEmpty(response?.eConsent) ? '' : response?.eConsent,
                uploadTimestamp: isEmpty(response?.uploadTimestamp) ? '' : response?.uploadTimestamp,
                generationTimestamp: isEmpty(response?.generationTimestamp) ? '' : response?.generationTimestamp,
                reviewTimestamp: isEmpty(response?.reviewTimestamp) ? '' : response?.reviewTimestamp,
            }
        )
    };
    const getEkycDetails = (response) => {
        return ({
                ekyc: isEmpty(response?.ekyc) ? '' : response?.ekyc,
                eKYCLink: isEmpty(response?.eKYCLink) ? '' : response?.eKYCLink
            }
        )
    };

    const updateEkyc = (applicantDetails,value) => {
        const {accountId} = props;

        setAccountHolderState((prevStep) => ({
            ...prevStep,
            isShowLoader: true,
        }));
        const payloadObj = {
            personId: applicantDetails.personId,
            eKYC: value,
            accountId: accountId
        };

        updateEKyc(payloadObj, true)
            .then((res) => {
                if (res.success) {
                    setAccountHolderState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                    }));
                } else {
                    setAccountHolderState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                    }));
                }
            });

    };

    const updateEkycLink = (arr, personId, value) => {
        return arr.map(applicantDetails =>
            applicantDetails.personId === personId ? {...applicantDetails, ekycDetails: value} : applicantDetails
        );
    };

    const generateLink = (personId) => {
        const{accountId} = props;
        setAccountHolderState((prevStep) => ({
            ...prevStep,
            isShowLoader: true,
        }));
        const payloadObj = {
            personId: personId,
            accountId: accountId
        };

        getGenerateLink(payloadObj, true)
            .then((res) => {
                if (res.success) {
                    let obj = getEkycDetails(res?.data);
                    setAccountHolderState((prevStep) => ({
                        ...prevStep,
                        kycDetails: updateEkycLink(accountHolderState.kycDetails, personId, obj),
                        isShowLoader: false,
                    }));
                } else {
                    setAccountHolderState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                        responseErrors : res?.__error
                    }));
                }
            });
    };




    const generateForm = () => {
        const {accountId, onboardingApplicationId} = props;

        setAccountHolderState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            accountId: accountId,
            onboardingApplicationId: onboardingApplicationId,
            formDetails: {
                KYC: true,
                IIN: false,
                ECS: false
            }
        };

        getGenerateForms(payload).then(res => {
            if (res?.success) {

                accountHolderState.kycDetails.map((personsDetails) => {
                    getKYCForm(personsDetails.personId, onboardingApplicationId)
                        .then((res) => {
                            if (res?.success) {
                                personsDetails.formDetails = getformDetails(res?.data?.formDetails);
                                setAccountHolderState((prevStep) => ({
                                    ...prevStep,
                                    isShowLoader: false,
                                }));
                            } else {
                                setAccountHolderState((prevStep) => ({
                                    ...prevStep,
                                    isShowLoader: false,
                                    responseErrors: res?.__error
                                }));
                            }
                        });
                });
            } else {
                setAccountHolderState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };


    const getFormattedData = (response) => {

        return ({
                PANNumber: isEmpty(response?.PANNumber) ? '' : response?.PANNumber,
                firstName: isEmpty(response?.firstName) ? '' : response?.firstName,
                middleName: isEmpty(response?.middleName) ? '' : response?.middleName,
                lastName: isEmpty(response?.lastName) ? '' : response?.lastName,
                ekycDetails: {},
                formDetails: {},
                isShowEkyc: false
            }
        );
    };

    const getKycFormattedData = (response) => {
        const kycDetails = response?.kyc?.kycDetails[0];
        if (isEmpty(response?.kyc?.kycDetails)) {
            return {};
        } else {
            return {
                kycId: isEmpty(kycDetails?.kycId) ? '' : kycDetails?.kycId,
                kycStatus: isEmpty(kycDetails?.kycStatus) ? '' : kycDetails?.kycStatus,
                KRAMasterCode: isEmpty(kycDetails?.KRAMasterCode) ? '-' : kycDetails?.KRAMasterCode,
                lastUpdatedDate: isEmpty(kycDetails?.lastUpdatedDate) ? '' : kycDetails?.lastUpdatedDate,
                latestUpdatedDate: isEmpty(kycDetails?.latestUpdatedDate) ? '' : kycDetails?.latestUpdatedDate,
                kycStatusCode: isEmpty(kycDetails?.kycStatusCode) ? '-' : kycDetails?.kycStatusCode,
                mode: isEmpty(kycDetails?.mode) ? '-' : kycDetails?.mode,
                kycMode: isEmpty(kycDetails?.kycMode) ? '-' : kycDetails?.kycMode,
                kycCreationDate: isEmpty(kycDetails?.kycCreationDate) ? '' : kycDetails?.kycCreationDate,
                modification: isEmpty(kycDetails?.kycModificationStatusCode) ? '-' : kycDetails?.kycModificationStatusCode,
                modificationRemarks: isEmpty(kycDetails?.modificationRemarks) ? '-' : kycDetails?.modificationRemarks,
                remarks: isEmpty(kycDetails?.remarks) ? '-' : kycDetails?.remarks,
                inPersonVerificationCode: isEmpty(kycDetails?.inPersonVerificationCode) ? '-' : kycDetails?.inPersonVerificationCode,
                kycInPersonVerification: isEmpty(kycDetails?.kycInPersonVerification) ? '-' : kycDetails?.kycInPersonVerification,
                kycModificationStatus: isEmpty(kycDetails?.kycModificationStatus) ? '-' : kycDetails?.kycModificationStatus,
                uboFlagCode: isEmpty(kycDetails?.uboFlagCode) ? '-' : kycDetails?.uboFlagCode,
                isEKyc: isEmpty(response?.eKYC) ? true : response?.eKYC,
            }
        }
    };
    const getApplicantData = (applicant) => {
        return accountHolderState.kycDetails.find(data => data.PANNumber === applicant.PAN_Number)
    };

    const asOfFieldDate = (date) => {
        if (!isEmpty(date)) {
            return moment(date).format('DD-MM-YYYY');
        }
        return '';
    };

    return (
        <div>
            <KycView
                {...props}
                {...accountHolderState}
                setEkycStatus={setEkycStatus}
                generateLink={generateLink}
                updateEkyc={updateEkyc}
                generateForm={generateForm}
                asOfFieldDate={asOfFieldDate}
                refreshButton={refreshButton}
                showEkycBlock={showEkycBlock}
                getApplicantData={getApplicantData}
                downloadAndViewDocument={downloadAndViewDocument}
                handleOpenKycStatusModal={handleOpenKycStatusModal}
                handleCloseKycStatusModal={handleCloseKycStatusModal}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    accountId: state.onboardingFlowState.accountId,
});

export default connect(mapStateToProps, {})(Kyc);