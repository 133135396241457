import {httpGet, httpPost, httpPut} from "../utils/httpHelper";


export const getFTStatuses = () => {
    return httpGet('/service/advisory/financialTransaction/status/all', true).then(res => {
        return res;
    })
};

export const approveTransactionByClient = (data) => {
    return httpPut('/service/advisory/financialTransaction/client/approve', data, true).then(res => {
        return res;
    })
};

export const rejectTransactionByClient = (data) => {
    return httpPut('/service/advisory/financialTransaction/client/reject', data, true).then(res => {
        return res;
    })
};

export const getChequeDepositModes = () => {
    return httpGet('/service/advisory/chequeDepositMode/all').then(res => {
        return res;
    });
};

export const verifyOTPUrl = (data, handleErrorInComponent) => {
    return httpPost('/service/advisory/financialTransaction/client/requestOTP', data, handleErrorInComponent).then(res => {
        return res;
    });
};

export const verifyOTP = (uuid, otp) => {
    return httpGet('/service/advisory/financialTransaction/client?uuid=' + uuid + '&otp=' + otp, true).then(res => {
        return res;
    });
};
