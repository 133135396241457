import React, {useState} from 'react';
import {connect} from "react-redux";

import {markOnboardingAsCompleted} from "../../../../ClientRequestsActions";

import MarkAsCompletedView from "./MarkAsCompletedView";


const MarkAsCompleted = (props) => {

    const{getId, onSaveSuccess, handleCloseMarkAsCompletedModal} = props;
    const [markAsCompletedState, setMarkAsCompletedState] = useState({
        isApiInProgress: false,
        handleErrorInComponent:true,
        modalErrorMessage:''
    });

    const handleMarkAsCompleted = () => {
        let id = getId();
        let payload = {
            id : id
        }
        setMarkAsCompletedState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));

        markOnboardingAsCompleted(payload)
            .then((res) => {
                if (res.success) {
                    setMarkAsCompletedState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false
                    }));
                    onSaveSuccess();
                    handleCloseMarkAsCompletedModal();
                } else {
                    setMarkAsCompletedState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false,
                        modalErrorMessage:res.__error
                    }));
                }
            }
        );
    };

    return (
        <MarkAsCompletedView
            {...props}
            {...markAsCompletedState}
            handleMarkAsCompleted={handleMarkAsCompleted}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(MarkAsCompleted);
