//noinspection DuplicatedCode,JSUnresolvedVariable,SpellCheckingInspection,JSUnresolvedFunction

import React, {Component} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {getFontFamily, getFontSize, getTheme, isEmpty} from  "./../DLComponentHelper";
import {muiThemeObj} from "./../DLComponentHelperWeb";
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlined';
import "./DLCheckboxStyle.css"

const checkBoxMarginBottoms = {
    'none': '',
    'xxs': 'mb-1',
    'xs': 'mb-2',
    'sm': 'mb-3',
    'md': 'mb-4'
};
const checkBoxItemMarginBottoms = {
    'none': '',
    'xxs': 'mb-1',
    'xs': 'mb-2',
    'sm': 'mb-3',
    'md': 'mb-4'
};

class DLCheckboxView extends Component {

    constructor(props) {
        super(props);

        let showSeeMoreOpts = false;

        if (!isEmpty(props.options) && props.options.length > 7) {
            showSeeMoreOpts = true;
        }

        this.state = {
            showSeeMoreOpt: showSeeMoreOpts,
            isExpanded: !showSeeMoreOpts,
        };

    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        const {options} = this.props;

        if (!isEmpty(options)) {
            if (prevProps.options.length !== options.length) {
                let showSeeMoreOpts = false;
                if (!isEmpty(options) && options.length > 7) {
                    showSeeMoreOpts = true;
                }
                this.setState({
                    showSeeMoreOpt: showSeeMoreOpts,
                    isExpanded: !showSeeMoreOpts,
                })
            }
        }
    }

    getLabelPlacementStyle = () => {
        const {labelPlacement} = this.props;

        if (labelPlacement === 'start') {
            return "pe-2"
        }

        if (labelPlacement === 'end') {
            return "ps-2"
        }

    };

    getBorderColor = (checkboxcolor,isDisabled,isChecked,theme) => {
        if(!isEmpty(checkboxcolor)){
            return '1px solid ' + checkboxcolor
        }
        else if(isDisabled){
            return '1px solid ' + theme.appColor.lightGray
        }
        else if(isChecked){
            return '1px solid ' + theme.appColor.success
        }else{
            return '1px solid ' + theme.appColor.black

        }
    };

    render() {
        const {
            id, onChangeValue, labelPlacement, isDisabled, type, helperMessage, checkboxcolor, labelColor,
            disableHelperMessageHeight, label, fontSize, options, isChecked, marginBottom, marginBottomItem, t
        } = this.props;  // Do not remove any prop
        const {showSeeMoreOpt, isExpanded} = this.state;

        let theme = getTheme();

        const checkboxTheme = createTheme({
            ...muiThemeObj(theme)
        });
        let BorderColor = this.getBorderColor(checkboxcolor,isDisabled,isChecked,theme)

        if (type === 'multiple') {
            return (
                <ThemeProvider theme={checkboxTheme}>
                    <div style={{maxHeight: '400px', overflow: 'auto', wordBreak: 'break-word'}}
                         className="scrollbar-slim">
                        {
                            options.slice(0, isExpanded ? options.length : 3).map((opt, index) => {
                                return (<div key={'checkbox-slicer-' + index}
                                             className={checkBoxItemMarginBottoms[marginBottomItem]}>
                                    <FormControlLabel
                                        style={{margin: 0, fontFamily: getFontFamily()}}
                                        control={(
                                            <Checkbox
                                                checked={isChecked}
                                                onChange={(e) => {
                                                    onChangeValue(e.target.checked);
                                                }}
                                                checkedIcon={<CheckBoxOutlined style={{fontSize:'24px'}}/>}
                                                icon={<div className='border'
                                                                    style={{
                                                                        border: BorderColor,
                                                                        borderRadius:2,
                                                                        width:'18px',
                                                                        height:'18px',
                                                                        margin:'3px'
                                                                    }}/>}
                                                sx={{
                                                    color: isEmpty(checkboxcolor) ? theme.appColor?.darkGray : checkboxcolor,
                                                    '&.Mui-checked': {
                                                        color: isEmpty(checkboxcolor) ? theme.appColor?.success : checkboxcolor,
                                                    }
                                                }}
                                                inputProps={{'aria-label': 'controlled'}}
                                            />)}
                                        label={<span
                                            className={(labelPlacement === 'start' ? "pe-2" : "ps-2") + ' d-inline-block'}
                                            style={{
                                                ...getFontSize(fontSize),
                                                color: isEmpty(labelColor) ? theme.appColor?.darkGray : labelColor
                                            }}>
                                        {opt.label}
                                    </span>}
                                        labelPlacement={labelPlacement}
                                    />
                                </div>);
                            })
                        }
                        <div className='d-flex justify-content-end cursor' style={{cursor: 'pointer'}}>

                        </div>

                    </div>
                </ThemeProvider>
            );
        }

        return (
            <ThemeProvider theme={checkboxTheme}>
                <div style={{marginBottom: checkBoxMarginBottoms[marginBottom]}}>
                    <FormControlLabel
                        style={{margin: 0, fontFamily: getFontFamily(), paddingTop: 0.1}}
                        control={(
                            <Checkbox
                                className={'p-0'}
                                checked={isChecked}
                                checkedIcon={<CheckBoxOutlined style={{fontSize:'24px'}}/>}
                                onChange={(e) => {
                                    onChangeValue(e.target.checked);
                                }}
                                icon={<div
                                           style={{
                                               border: BorderColor,
                                               borderRadius:2,
                                               width:'18px',
                                               height:'18px',
                                               margin:'3px'
                                           }}/>}
                                inputProps={{'aria-label': 'controlled'}}
                                sx={{
                                    color: isEmpty(checkboxcolor) ? theme.appColor?.darkGray : checkboxcolor,
                                    '&.Mui-checked': {
                                        color: isEmpty(checkboxcolor) ? theme.appColor?.success : checkboxcolor,
                                    }
                                }}
                            />
                            )}

                        label={<span
                            className={this.getLabelPlacementStyle()}
                            style={{
                                ...getFontSize(fontSize),
                                color: isEmpty(labelColor) ? theme.appColor?.darkGray : labelColor
                            }}>
                            {label}
                        </span>}
                        labelPlacement={labelPlacement}
                        disabled={isDisabled}
                    />
                    <div style={{minHeight: disableHelperMessageHeight ? '0' : '19px'}}>
                        <span>{helperMessage}</span>
                    </div>
                </div>
            </ThemeProvider>

        );
    }
}

export default DLCheckboxView;
