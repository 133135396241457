import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import FTRequestListView from "./FTRequestListView";


const FTRequestList = (props) => {

    const [FTRequestListState, setFTRequestListState] = useState({
        isManagementUser: false,
        isSetUserType: false
    });

    useEffect(() => {
        const {profile} = props;
        setFTRequestListState((prevState) => ({
            ...prevState,
            isManagementUser: profile?.isManagement,
            isSetUserType: true
        }))
    }, []);


    return (
        <div>
            <FTRequestListView
                {...props}
                {...FTRequestListState}
            />
        </div>
    );

};

const mapStateToProps = (state) => ({
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(FTRequestList);