import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {APP_CONST} from "../AppComponents/AppConstants";
import ClientRequestsView from "./ClientRequestsView";
import {isEmpty} from "../utils/validations";
import DLLoader from "../core/components/DLLoader/DLLoader";

const ClientRequests = (props) => {

    const [ClientRequestsState, setClientRequestsState] = useState({
        isManagementUser: false,
        isSetUserType: false,
        UIState: ''
    });

    useEffect(() => {
        const {profile} = props;
        setClientRequestsState((prevState) => ({
            ...prevState,
            isManagementUser: profile?.isManagement,
            isSetUserType: true,
            UIState: APP_CONST.CONTENT_AVAILABLE
        }))
    }, []);

    if (isEmpty(ClientRequestsState.UIState)){
        return <DLLoader isVisible={true} type={"screen"}/>
    }

    return (
        <ClientRequestsView
            {...props}
            {...ClientRequestsState}
        />
    );
};
const mapStateToProps = (state) => ({
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(ClientRequests);