import React, {Component} from 'react';
import {setTheme} from "./../DLComponentHelper";
import PropTypes from "prop-types";

class DLThemeProvider extends Component {

    componentDidMount() {
        if (this.props?.theme) {
            setTheme(this.props?.theme)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.theme !== this.props.theme){
            setTheme(this.props?.theme)
        }
    }

    render() {
        return (
          <>
              {this.props?.children}
          </>
        );
    }
}

DLThemeProvider.propTypes = {
    theme: PropTypes.object.isRequired,
};

export default DLThemeProvider;
