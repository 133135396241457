import React from 'react';
import DLButton from "../../../core/components/DLButton/DLButton";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import HistoryBlock from "../../../core/components/historyBlock/HistoryBlock";
import DLText from "../../../core/components/DLText/DLText";
import DLCheckbox from "../../../core/components/DLCheckbox/DLCheckbox";
import appTheme from "../../../assets/appTheme";

const ReferenceDocumentsView = (props) => {
    const {documents, isXSView, handleCheckboxChange} = props;

    const renderButtons = () => {
        const {onSendAndContinue} = props;

        return (
            <div className='sticky-bottom-buttons' style={{gap:16}}>
                <DLButton
                    id={'skip-and-button'}
                    styleType={"outlined"}
                    borderType={'square'}
                    buttonSize={'sm'}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    style={{
                        border: "1px solid var(--black)",
                        background: "var(--white)",
                        color: "var(--black)",
                        fontWeight: '500',
                        minWidth: '150px'
                    }}
                    onClick={() => onSendAndContinue()}
                    label={"Skip & Continue"}
                />
                <DLButton id={'continue-button'}
                          buttonSize={'sm'}
                          fontSize={'sm'}
                          sideMargin={'none'}
                          onClick={() => onSendAndContinue()}
                          label={'Send Email & Continue'}/>
            </div>
        );
    };

    const renderLoader = () => {
        const {isShowLoader} = props;
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderHeader = () => {
        return (
            <div className='page-header'>
                Client Onboarding
            </div>
        )
    };

    return (
        <div className={isXSView ? 'bg-white ' : 'bg-light h-100'}>
            {renderHeader()}
            <div className={isXSView ? ' ' : 'page-container'}>
                <div className= {isXSView ? '' : 'd-flex justify-content-between'} style={{gap:'20px'}}>
                    <div className={isXSView ? 'bg-white page-container' : 'bg-white p-3 border-rounded'}
                         style={{
                             flex: 0.7,
                             minHeight: isXSView ? '' : 'calc(100dvh - 184px)',
                             maxHeight: isXSView ? '' : 'calc(100dvh - 184px)',
                             overflowY: "auto"
                         }}>
                        <div className="reference-documents mb-3 pb-1">
                            <DLText id={''}
                                    isInline={true}
                                    fontColor={'black'}
                                    fontSize={'lg'}
                                    fontWeight={'semi-bold'}
                                    text={'Reference Documents'}/>
                            <div className={'mt-3'}>
                                <DLText id={''}
                                        isInline={true}
                                        text={'Send following emails to client:'}/>
                            </div>
                            <div>
                                {documents.map((item, index) => {
                                        return (
                                            <div key={'refrence-document'+index} className={'pt-1 mt-3 customCheckBoxStyle'}
                                                 style={{borderBottom: "0.5px solid var(--disabled)"}}>
                                                <DLCheckbox
                                                    id={'scheduleUpload-weeklyUi-dayCheck-' + index}
                                                    label={<u>{item.name}</u>}
                                                    isChecked={item.checked}
                                                    disableHelperMessageHeight={true}
                                                    onChangeValue={() => handleCheckboxChange(item, 'funds')}
                                                    labelPlacement={'end'}
                                                    marginBottom={'none'}
                                                    checkboxcolor={'black'}
                                                    labelColor={appTheme.linkStyle.color}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    {isXSView ? <div style={{height: '5px', backgroundColor:appTheme.appColor.gray}}/> : <></>}
                    <div className='h-100' style={{flex:0.3}}>
                        <HistoryBlock/>
                    </div>
                </div>
            </div>
            {renderButtons()}
            {renderLoader()}
            <DLLoader isVisible={props.isLoaderVisible} type={'screen'}/>
        </div>
    );
};

export default ReferenceDocumentsView;