import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import NFTDashboardView from "./NFTDashboardView";

const NFTDashboard = (props) => {
    const [NFTState, setNFTState] = useState({
        isManagementUser: false,
        isSetUserType: false
    });

    useEffect(() => {
        const {profile} = props;
        setNFTState((prevState) => ({
            ...prevState,
            isManagementUser: profile?.isManagement,
            isSetUserType: true
        }))
    }, []);

    return (
        <div>
            <NFTDashboardView {...props}
                              {...NFTState}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(NFTDashboard);