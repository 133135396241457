import React, {useState} from 'react';
import UploadDocumentsView from "./referenceDocumentsView";
import {connect} from "react-redux";

const ReferenceDocuments = (props) => {

    const [isLoaderVisible, setIsLoaderVisible]=useState(false);
    const [documents, setDocuments] = useState([
        {name: 'Simplicity Investing – Introduction', checked: true},
        {name: 'Simplicity Investing – Client Service Fees', checked: true},
        {name: 'Simplicity Investing – Mutual Fund Documentation Requirements', checked: true},
        {name: 'Simplicity – Mutual Fund Disclosure requirements recommended by the AMFI', checked: true},
        {name: 'Simplicity – Equity Mutual Fund Recommendations', checked: true},
    ]);

    const handleCheckboxChange = (index) => {
        const newDocuments = [...documents];
        newDocuments[index].checked = !newDocuments[index].checked;
        setDocuments(newDocuments);
    };

    const onSendAndContinue=()=>{
        setIsLoaderVisible(true);
        //TODO: send docs api call and navigation
        setIsLoaderVisible(false);

        props.handleClickContinue('Reference_Documents')
    };


    return (
        <UploadDocumentsView
            {...props}
            isLoaderVisible={isLoaderVisible}
            documents={documents}
            handleCheckboxChange={handleCheckboxChange}
            onSendAndContinue={onSendAndContinue}
        />);
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    profile: state.userState.profile,

});

export default connect(mapStateToProps, {})(ReferenceDocuments);
