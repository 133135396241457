import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import DashboardWidget from "../../../DashboardWidget";
import {
    getOnboardingStatus,
    resetOnboardingListingFilters,
    setOnboardingAPPStatus,
} from "../../../HomePageActions";

const OnboardingStatus=(props)=> {
    const [onboardingStatusState, setOnboardingStatusState] = useState({
        onboardingStatus: {},
        isShowLoader: false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {
        setOnboardingStatusState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getOnboardingStatus().then(res => {
            if (res.success) {
                setOnboardingStatusState(prevState => ({
                    ...prevState,
                    onboardingStatus: res.data,
                    isShowLoader: false,
                }));
            } else {
                setOnboardingStatusState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        resetOnboardingListingFilters();
        setTimeout(()=>{
            setOnboardingAPPStatus([item.key]);
            props.history.push('/onboardings')
        }, 100)

    };


    return (
        <DashboardWidget
            {...props}
            {...onboardingStatusState}
            detailsObj={onboardingStatusState.onboardingStatus}
            setFilters={setFilters}
            title={"Status"}
        />
    );
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(OnboardingStatus);