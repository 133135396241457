import React from 'react';
import moment from "moment";
import {APP_CONST} from "../../../AppComponents/AppConstants";
import {isEmpty} from "../../../core/components/DLComponentHelper";

import DLText from "../../../core/components/DLText/DLText";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import DLTable from "../../../core/components/DLTable/DLTable";

import emptyLeads from '../../../assets/img/empty-leads.png';
import emptyFilter from '../../../assets/img/appIcons/emptyFilter.png';
import emptyFilterFilled from '../../../assets/img/appIcons/emptyFilterFilled.png';
import filterDot from '../../../assets/img/appIcons/filterDot.png';
import {handleKeyPress, renderStatusBlock} from "../../ClientRequestsHelper";
import {resetClientRequestsFilters} from "../../ClientRequestsActions";
import MultiselectDropdown from "../MultiselectDropdown";
import {
    statusFilterOptions,
    cellConfig,
    headingData, headingMobileData
} from "../../ClientRequestsModal";


const CRMListView = (props) => {
    const {
        isXSView,
        listingCount,
        rowsPerPage,
        page,
        handleChangePage,
        handleChangeRowsPerPage,
        clientRequests,
        clientRequestsFilter,
        isShowLoader,
        getPaginationNextButtonStatus,
        skipCount,
        onChangeFilter,
        UIState,
        isMobileView,
        isShowFilterMobile,
        redirectToDetailsScreen
    } = props;

    const {keyword} = clientRequestsFilter;

    const renderHeaderCreateButton = () => {
        const {isMobileView, isXSView} = props;

        if (isXSView) {
            return (
                <div className={'mx-3 my-2'}>
                    <DLButton
                        id={'raise-a-request'}
                        styleType={'outlined'}
                        type={'icon'}
                        padding={'none'}
                        onClick={() => props.history.push("/raise-request")}
                        startIcon={<i className="fa-solid fa-plus"/>}/>
                </div>
            );
        }
        return (
            <div className={'d-flex justify-content-center my-2 me-4' + (isMobileView ? ' page-container' : '')}>
                <DLButton
                    id={'raise-a-request'}
                    buttonSize={'sm'}
                    reduceWidth={isMobileView}
                    fontSize={'sm'}
                    sideMargin={'md'}
                    backgroundColor={"grey"}
                    styleType={"outlined"}
                    onClick={() => props.history.push("/raise-request")}
                    label={"Raise a Request"}
                />
            </div>
        )
    };

    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between'>
                <div className='page-header'>
                    Financial Transaction Request
                </div>
                <div>
                    {renderHeaderCreateButton()}
                </div>
            </div>
        )
    };

    const renderFilterIcon = () => {
        const filterIcon = isShowFilterMobile ? emptyFilterFilled : emptyFilter;

        return (
            <div className='icon-container'>
                <img src={filterIcon} alt={''} height={14} width={14}/>
                <img className='dot' alt={''} src={filterDot} height={8} width={8}/>
            </div>
        );
    };

    const renderFilterMobile = () => {
        const {
            isShowFilterMobile,
            handleToggleMobileFilter,
        } = props;

        return <div>
            <div className='d-flex align-items-center justify-content-between gap-3 page-container '>
                <div className='search-input' style={{flex: 8}}>
                    <DLInputTextField id={'clientRequests-searchBox'}
                                      marginBottom={"none"}
                                      rows={1}
                                      styleType={"search"}
                                      onChangeValue={(e) => {
                                          onChangeFilter('keyword', e)
                                      }}
                                      value={keyword}
                                      placeholder={'Search by Name'}
                                      onKeyPress={handleKeyPress}
                                      isSearchable={true}
                                      size="small"
                                      inputHeight={'sm'}
                                      inputType={"text"}
                                      disableHelperMessageHeight={true}
                    />
                </div>
                <div className="d-flex align-items-center">
                    <DLButton
                        id={'filter-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        onClick={() => handleToggleMobileFilter(!isShowFilterMobile)}
                        startIcon={renderFilterIcon()}/>
                    <div>
                        <i className="fa-solid fa-xmark cursor ms-2 "
                           onClick={() => resetClientRequestsFilters()}
                           style={{fontSize: 18}}/>
                    </div>
                </div>
            </div>

            {isShowFilterMobile &&
                <div className='bg-light mt-2' style={{paddingTop: 10, paddingBottom: 10}}>
                    <div className='page-container'>
                        <div className='d-flex align-items-start justify-content-between flex-wrap gap-2 gap-wrap-3'>
                            <div style={{flex: 1}}>
                                <DLText
                                    id={'status'}
                                    text={'Status'}
                                    fontColor={'grayDark'}
                                />
                                <MultiselectDropdown
                                    statusFilterOptions={statusFilterOptions}
                                    isXSView={isXSView}
                                    onStatusChange={(selectedStatus) => onChangeFilter('status', selectedStatus)}
                                    sx={{width: '100%'}}
                                    initialStatus={['OPEN', 'IN_PROGRESS']}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    };

    const renderFilter = () => {
        return (
            <div className='d-flex page-container'>
                <div className='search-input' style={{flex: 1.8}}>
                    <DLInputTextField
                        id={'client-request-search-filter'}
                        label={'Search'}
                        labelFontSize={'sm'}
                        labelFontColor={'grayDark'}
                        marginBottom={"none"}
                        rows={1}
                        styleType={"search"}
                        onChangeValue={(e) => {
                            onChangeFilter('keyword', e)
                        }}
                        value={isEmpty(keyword) ? '' : keyword}
                        placeholder={'Search by Name'}
                        isSearchable={true}
                        size="small"
                        inputHeight={'sm'}
                        inputProps={{style: {padding: '7px 10px', maxWidth: "350px"}}}
                        inputType={"text"}
                        disableHelperMessageHeight={true}
                    />
                </div>
                <div className='select-filter ms-3' style={{flex: 1}}>
                    <DLText
                        id={'status'}
                        text={'Status'}
                        fontColor={'grayDark'}
                    />
                    <MultiselectDropdown
                        statusFilterOptions={statusFilterOptions}
                        isXSView={isXSView}
                        onStatusChange={(selectedStatus) => onChangeFilter('status', selectedStatus)}
                        initialStatus={['OPEN', 'IN_PROGRESS']}
                    />
                </div>
            </div>
        );
    };

    const emptyList = () => {
        return <div className='d-flex justify-content-center align-items-center gray-lg-text'
                    style={{
                        minHeight: 'calc(100dvh - 132px)',
                        overflow: 'hidden',
                        fontSize: '16px'
                    }}>
            <div className='text-center'>
                <img src={emptyLeads} alt={''}/>
                <DLText id={'create-first-lead'}
                        text={'Please create your first lead.'}
                        marginBottom={'md'}
                />
                <DLButton
                    id={'create-button'}
                    buttonSize={'sm'}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => props.history.push("/raise-request")}
                    label={'Raise A Request'}/>
            </div>
        </div>
    };

    const renderMobileTable = () => {
        const {isShowFilterMobile} = props;
        let tableData = getFormattedTableData(clientRequests);

        if (isEmpty(clientRequests)) {
            return (
                <div className='d-flex align-items-center justify-content-center empty-records'
                     style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 370px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className="w-100 pagination-container">
                <DLTable
                    id={''}
                    tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 345px)'}
                    tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 345px)'}
                    isRowClickable={true}
                    onRowClick={(rowId) => {
                        redirectToDetailsScreen(rowId)
                    }}
                    headerData={headingMobileData}
                    cellConfig={cellConfig}
                    tableData={tableData}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        )
    };

    const renderCount = () => {
        const {handleRefreshButton} = props;
        return (

            <div className={isXSView ? 'page-container my-3' : 'py-3'}>
                {!isEmpty(listingCount) &&
                    <div className={'d-flex align-items-center m-0 p-0'}>
                        <DLText id={''}
                                isInline={true}
                                fontColor={'grayDark'}
                                fontSize={'sm'}
                                marginBottom={'none'}
                                fontWeight={'semi-bold'}
                                text={'Showing ' + (listingCount) + ' records'}/>

                        <div className={'d-flex align-items-center m-0 p-0 mx-2'}
                             onClick={() => {
                                 handleRefreshButton()
                             }}>
                            <i className="fa-solid fa-rotate-right cursor"/>
                        </div>
                    </div>
                }
            </div>
        )
    };

    const renderTextUi = (name, label, subLabel) => {

        if (name === "account") {
            return (
                <div className="py-1">
                    {!isEmpty(label) && <DLText id={''}
                                                text={isEmpty(label) ? '-' : label}
                                                marginBottom={isXSView ? "none" : "xs"}
                                                fontSize={"xs"}
                                                fontColor={"black"}
                    />}
                    {!isEmpty(subLabel) && <DLText id={''}
                                                   text={subLabel}
                                                   fontSize={"xs"}
                                                   fontColor={"grayDark"}
                    />}
                </div>
            );
        }

        if (name === "ticketNumber") {
            return (
                <div className="">
                    {!isEmpty(label) && <DLText id={''}
                                                text={isEmpty(label) ? '-' : label}
                                                fontSize={"xs"}
                                                fontColor={"black"}
                    />}
                    {!isEmpty(subLabel) && <DLText id={''}
                                                   text={subLabel}
                                                   fontSize={"xs"}
                                                   fontColor={"grayDark"}
                    />}
                </div>
            );
        }

        if (name === "status") {
            return (
                <div>
                    {!isEmpty(label) && <DLText id={''}
                                                text={isEmpty(label) ? '-' : label}
                                                fontSize={"xs"}
                                                fontColor={"black"}
                    />}
                    {!isEmpty(subLabel) && <DLText id={''}
                                                   text={subLabel}
                                                   fontSize={"xs"}
                                                   fontColor={"grayDark"}
                    />}
                </div>
            )
        }
        if (name === "createdDate") {
            return (
                <div className="">
                    {!isEmpty(label) && <DLText id={''}
                                                text={moment(label).format("DD-MM-YYYY")}
                                                fontSize={"xs"}
                                                fontColor={"black"}
                    />}
                </div>
            );
        }
        if (name === "statusAndCreatedDate") {
            return (
                <div className="">
                    {!isEmpty(label) && <DLText id={''}
                                                text={isEmpty(label) ? '-' : label}
                                                fontSize={"xs"}
                                                fontColor={"black"}
                    />}
                    {!isEmpty(subLabel) && <DLText id={''}
                                                   text={moment(subLabel).format("DD-MMM")}
                                                   fontSize={"xs"}
                                                   fontColor={"grayDark"}
                    />}
                </div>
            )
        }

    };

    const getAccountFullName = (accountHolders) => {
        return accountHolders.map(currentPerson => {
            return currentPerson.fullName;
        }).join(', ');
    };


    const renderAccountTypes = (accountType) => {
        if (isEmpty(accountType)) {
            return '-'
        }
        if (accountType === 'INDIVIDUAL') {
            return isXSView ? 'Individual A/C' : 'Individual Account'
        }
        if (accountType === 'JOINT') {
            return isXSView ? 'JOINT A/C' : 'Joint Account'
        }
        return accountType
    };

    const renderCategoryTypes = (category) => {
        if (category === 'HNI') {
            return category;
        } else if (category === 'UNASSIGNED') {
            return 'Unassigned';
        } else if (category === 'RETAIL') {
            return 'Retail';
        } else if (category === 'PREMIUM_RETAIL') {
            return 'Premium Retail';
        } else if (category === 'ULTRA_HNI') {
            return 'Ultra HNI';
        }
    }

    const getAccountSubLabel = (fields) => {
        const accountType = renderAccountTypes(fields?.accountType);
        const IINNumber = fields?.IINNumber
        const category = isEmpty(fields?.category) ? '-' : renderCategoryTypes(fields?.category);
        const accountSubLabel = accountType + '- ' + IINNumber + '- ' + category;
        return <div>
            <div>
                <DLText id={''}
                        isInline={true}
                        fontSize={'xs'}
                        fontWeight={'normal'}
                        text={accountSubLabel}/>
            </div>
        </div>
    }

    const getFormattedTableData = (clientRequests) => {
        let tableData = [];
        const {isXSView} = props;
        if (isEmpty(clientRequests)) {
            return tableData
        }

        clientRequests.map((selectedClientRequests) => {
            let clientRequestsRecords = [];
            let clientRequestsDetails = {};
            if (isXSView) {
                clientRequestsRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi(
                        "account",
                        getAccountFullName(selectedClientRequests?.accountHolders),
                        getAccountSubLabel(selectedClientRequests)
                    )
                });
                clientRequestsRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi("statusAndCreatedDate", renderStatusBlock(selectedClientRequests?.status), selectedClientRequests?.openDate)
                });
            } else {
                clientRequestsRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi(
                        "account",
                        getAccountFullName(selectedClientRequests?.accountHolders),
                        getAccountSubLabel(selectedClientRequests)
                    )
                });

                clientRequestsRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi("ticketNumber", isEmpty(selectedClientRequests?.ticketNumber) ? "" : selectedClientRequests.ticketNumber)
                });

                clientRequestsRecords.push({
                    isCustomUI: true,
                    customUI: renderStatusBlock(selectedClientRequests?.status)
                });

                clientRequestsRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi("createdDate", selectedClientRequests?.openDate)
                });
            }

            clientRequestsDetails.id = selectedClientRequests.clientRequestId;
            clientRequestsDetails.data = clientRequestsRecords;
            tableData.push(clientRequestsDetails)
        });

        return tableData

    };

    const renderDesktopTable = () => {
        const {isShowFilterMobile} = props;
        let tableData = getFormattedTableData(clientRequests);

        if (isEmpty(clientRequests)) {
            return (
                <div className='w-100 d-flex align-items-center justify-content-center panel'
                     style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 360px)'}}>
                    No data found.
                </div>
            )
        }

        return (
            <div className="w-100">
                <DLTable
                    id={'crm-desktop-table'}
                    tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 339px)'}
                    tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 339px)'}
                    isRowClickable={true}
                    onRowClick={(rowId) => {
                        redirectToDetailsScreen(rowId)
                    }}
                    headerData={headingData}
                    tableData={tableData}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        )
    };

    const renderClientRequestsTable = () => {
        return (
            <div className={'w-100' + ((isMobileView && !isXSView) ? '  page-container' : '')}
                 style={{minHeight: isMobileView ? 'auto' : 'calc(-185px + 100dvh)'}}>
                <div>
                    {renderCount()}
                </div>
                {isXSView ?
                    renderMobileTable()
                    :
                    <div>
                        {renderDesktopTable()}
                    </div>
                }
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    if (UIState === APP_CONST.CONTENT_UNAVAILABLE) {
        return (
            <div className='page-container'>
                {emptyList()}
            </div>
        );
    }

    if (isMobileView) {
        return (
            <div>
                <div className={'w-100'} style={{
                    background: 'rgba(0, 0, 0, 0.02)'
                }}>
                    {renderHeader()}
                    {renderFilterMobile()}
                    {renderClientRequestsTable()}
                </div>
                {renderLoader()}
            </div>
        );
    }

    return (
        <div className={'h-100 d-flex justify-content-center'}
             style={{minHeight: 'calc(100dvh - 75px)'}}>
            <div className={'w-100 bg-white'}>
                {renderHeader()}
                {renderFilter()}
                <div className={'page-container bg-light mt-3'}>
                    {renderClientRequestsTable()}
                </div>
                {renderLoader()}
            </div>
        </div>
    );
};

export default CRMListView;