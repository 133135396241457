export const headingData = ['Sr.No', 'Section', 'Error Message'];

export const headingMobileData = ['Section', 'Error Message'];

export const cellConfig = [
    {
        textAlign: 'left',
        width: '10%',
        minWidth: '100px'
    },
    {
        textAlign: 'left',
        width: '30%',
        minWidth: '150px'
    },
    {
        textAlign: 'left',
        width: '60%',
        minWidth: '150px',
        maxWidth: '200px'
    },
];

export const cellConfigMobile = [
    {
        textAlign: 'left',
        width: '50%',
        minWidth: '140px',
        maxWidth: '165px'
    },
    {
        textAlign: 'left',
        width: '50%',
        minWidth: '130px'
    }
];

export const viewActivityStatuses = [
    {label: "Generated", value : "GENERATED"},
    {label: "Sent to client", value : "SENT_TO_CLIENT"},
    {label: "Uploaded", value : "UPLOADED_BY_CLIENT"},
    {label: "Uploaded", value : "UPLOADED_BY_ADVISORY_USER"},
    {label: "Reviewed", value : "REVIEWED"},
    {label: "Submitted", value : "SUBMITTED"},
    {label: "Rejected", value : "REJECTED"},
    {label: "Approved", value : "APPROVED"},
    {label: "Remark", value : "REMARK"},
];