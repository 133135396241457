import React from 'react';

import {handleFileError} from "../../../../../../utils/FileHelper";
import {isEmpty} from "../../../../../../utils/validations";

import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import DLDocUploader from "../../../../../../core/components/DLDocUploader/DLDocUploader";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLModalActions from "../../../../../../core/components/DLModal/DLModalActions/DLModalActions";

const UploadINFormView = (props) => {

    const {
        history, isUploadIINFormModalOpen, handleCloseUploadModal, isLoaderVisible, document, isPdfDocument, uploadFormError
    } = props;

    const renderDocumentUploader = () => {
        const {associateDocument, document, profile} = props;

        return (
            <DLDocUploader
                id={'resource-doc-uploader'}
                history={props.history}
                key={'doc-uploader'}
                label={''}
                boxLabel={'Upload'}
                onChangeValue={(type,value) => {
                    associateDocument(type,value);
                }}
                allowedFileTypes={'.pdf'}
                isShowLabel={false}
                disableHelperMessageHeight={false}
                isRequired={true}
                filePath={document?.filePath}
                uploadedFileName={document?.fileName}
                documentId={document?.id}
                helperMessage={uploadFormError}
                handleError={(error) => handleFileError(error, 62914560, 1)}
                isDeletable={false}
                isDisableDelete={props.profile?.isManagement}
                placeHolder={'Drag & Drop or Upload PDF file'}
            />
        )
    };

    const renderErrorUI = () => {
        const {responseErrors, totalErrors, isMobileView} = props;

        if (isEmpty(responseErrors) || totalErrors === 0) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={isMobileView ? '' : 'mb-2'}>
                {responseErrors.slice(0, 3).map((errorMessage, index) => (
                    <div key={index} className='pb-2 align-items-center d-flex message-block'>
                        <div className='error-vertical-line'/>
                        <div className='error-panel'>
                            {errorMessage}
                        </div>
                    </div>
                ))}
                {totalErrors > 3 && (
                    <div className='py-2 align-items-center d-flex'>
                        {`...${totalErrors - 3} More`}
                    </div>
                )}
            </div>
        );
    };

    const renderButton = () =>{
        if((document?.filePath || isPdfDocument)){
            return(
                    <DLButton
                        id={'doneBtn'}
                        history={history}
                        label={'Done'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => handleCloseUploadModal()}/>
            )
        }
    };

    const renderUploadModal = () => {
        return (
            <div>
                <DLLoader type={"screen"} isVisible={isLoaderVisible}/>
                <DLModal
                    history={history}
                    maxWidth={"sm"}
                    onClose={() => {handleCloseUploadModal()}}
                    open={isUploadIINFormModalOpen}
                    id={'upload-modal'}>
                    <DLModalTitle
                        id={'upload-title'}
                        onClose={() => {handleCloseUploadModal()}}
                        title={'Upload IIN Form'}/>
                    <DLModalContainer
                        id={'upload-container'}>
                        <div style={{height : '175px'}}>
                            {renderErrorUI()}
                            {renderDocumentUploader()}
                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'IIN-upload-action'}>
                        <div className='mb-4'
                             style={{paddingTop: '7px', minHeight: 50}}>
                            {renderButton()}
                        </div>

                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    return (
        <div>
            {renderUploadModal()}
        </div>
    );
};

export default UploadINFormView;