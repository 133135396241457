import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../../../utils/validations";
import {convertUTCToLocalTime} from "../../../../../utils/helper";

import AccountDetailsView from "./AccountDetailsView";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";

const AccountDetails = (props) => {

    const [accountDetailsState, setAccountDetailsState] = useState({
        assignedCRM: '',
        IIN: '',
        activationDate: '',
        accountType: '',
        category: '',
        isShowCompanyDetails: false,
        primaryEmail: '',
        primaryPhoneNumber:'',
        address: '',
        companyName : '',
        companyCity: '',
        companyDistrict: '',
        companyPinCode: '',
        companyState: '',
        companyCountry: '',
        taxStatus: '',
        accountDetailsUIState: '',
        occupation: ''
    });

    useEffect(() => {
        setAccountData()
    }, [props?.selectedPersonDetails, props.accountDetails]);

    const setAccountData = () => {
        const {accountDetails, accountType, isCorporateClient} = props;
        if(isEmpty(accountDetails)){
            return ''
        }
        let companyDetails = getCompanyDetails(accountDetails?.corporate);
        setAccountDetailsState((prevStep) => ({
            ...prevStep,
            ...companyDetails,
            isShowCompanyDetails: isCorporateClient,
            companyName : isEmpty(accountDetails?.corporate?.name) ? '-' :  accountDetails?.corporate?.name,
            assignedCRM: accountDetails?.advisoryUserId,
            IIN: accountDetails?.accountIINDetails?.IIN,
            activationDate: isEmpty(accountDetails?.accountIINDetails?.activationDate) ? '' : convertUTCToLocalTime(accountDetails?.accountIINDetails?.activationDate, 'DD-MM-YYYY'),
            accountType: accountType,
            category: accountDetails?.accountCategorization?.categoryId,
            accountDetailsUIState: APP_CONST.CONTENT_AVAILABLE,
        }));
    };

    const getCompanyDetails = (companyDetails) => {
        let primaryEmail = companyDetails?.primaryEmail;
        let primaryPhoneNumber = companyDetails?.primaryPhoneNumber;
        let address= '';
        if(!isEmpty(companyDetails?.corporateAddresses)){
            address = formatAddress(companyDetails?.corporateAddresses);
        }
        let taxStatus = getTaxStatus(companyDetails?.corporateFinancial?.taxStatusCode);
        let personAddress = isEmpty(companyDetails?.corporateAddresses)? [] : companyDetails?.corporateAddresses[0];
        return(
            {
                primaryEmail: primaryEmail,
                primaryPhoneNumber:primaryPhoneNumber,
                address: address,
                companyCity: isEmpty(personAddress?.city) ? '-' : personAddress?.city,
                companyDistrict: isEmpty(personAddress?.district) ? '-' : personAddress?.district,
                companyPinCode: isEmpty(personAddress?.zipCode) ? '-' : personAddress?.zipCode,
                companyState: isEmpty(personAddress?.stateCode) ? '-' : personAddress?.stateCode,
                companyCountry: isEmpty(personAddress?.countryCode) ? '-' : personAddress?.countryCode,
                taxStatus: taxStatus,
                occupation: getOccupation(companyDetails?.occupationCode),
                dateOfIncorporation: isEmpty(companyDetails?.dateOfIncorporation) ? '-' : convertUTCToLocalTime(companyDetails?.dateOfIncorporation, 'DD-MM-YYYY')
            }
        )
    };

    const getTaxStatus = (status) =>{
        const {taxStatuses} = props;

        if (isEmpty(status) || isEmpty(taxStatuses)) {
            return '-'
        }
        let tempStatus = '';
        taxStatuses.map((item)=>{
            if(item?.code === status){
                tempStatus =  item?.description
            }
        });
        return (isEmpty(tempStatus))  ? '-' : tempStatus
    };

    const getOccupation = (code) =>{
        const {occupationData} = props;

        if (isEmpty(code) || isEmpty(occupationData)) {
            return '-'
        }
        let tempOccupation = '';
        occupationData.map((item)=>{
            if(item?.code === code){
                tempOccupation =  item?.description
            }
        });
        return (isEmpty(tempOccupation))  ? '-' : tempOccupation
    };

    const formatAddress = (address) => {
        if (isEmpty(address)) {
            return '-'
        }
        let personAddress = address[0];
        let formatedAddress = '';
        if (!isEmpty(personAddress?.addressLine1)) {
            formatedAddress = personAddress?.addressLine1
        }
        if (!isEmpty(personAddress?.addressLine2)) {
            formatedAddress += ' '+personAddress?.addressLine2
        }
        if (!isEmpty(personAddress?.addressLine3)) {
            formatedAddress += ' '+personAddress?.addressLine3
        }
        if (!isEmpty(personAddress?.landMark)) {
            formatedAddress += ' '+personAddress?.landMark
        }
        if (!isEmpty(personAddress?.zipCode)) {
            formatedAddress += ' - ' + personAddress?.zipCode
        }
        return formatedAddress

    };

    const getAssignedCRM = (id) => {
        const {CRMs} = props;
        if (isEmpty(CRMs) || isEmpty(id)) {
            return '-'
        }
        let crmObj = CRMs.find(item => item?.id === id);
        return crmObj?.CRMName
    };

    const getCategory = (id) => {
        const {categoryData} = props;
        if (isEmpty(categoryData) || isEmpty(id)) {
            return '-'
        }
        let categoryObj = categoryData.find(item => item?.id === id);
        return categoryObj?.name
    };

    const getStateName = (value) => {
        const {states} = props;
        if (isEmpty(states) || isEmpty(value)) {
            return '-'
        }
        let state = states.find(item => item?.code === value);
        return state?.description
    };

    return (
        <AccountDetailsView
            {...props}
            {...accountDetailsState}
            getAssignedCRM={getAssignedCRM}
            getCategory={getCategory}
            getStateName={getStateName}
        />
    );
};
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(AccountDetails);