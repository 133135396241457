import {httpGet, httpPost, httpPut, httpDelete} from "../utils/httpHelper";
import {downloadS3BucketFile, uploadData} from "../utils/FileHelper";


export const getAllAccountTypes = () => {
    return httpGet('/service/advisory/accountType/all').then(res => {
        return res;
    })
};

// IIN Verification Api calls starts
export const searchAccount = (data,handleErrorInComponent) => {
    return httpPost('/service/advisory/account/search',data, handleErrorInComponent)
        .then(res => {
        return res;
    })
};

export const searchPersonByPAN = (data,handleErrorInComponent) => {
    return httpPost('/service/advisory/person/searchByPan', data, handleErrorInComponent)
        .then(res => {
        return res;
    })
};

export const initiateOnboarding = (data) => {
    return httpPost('/service/advisory/accountOpening/initiateOnboarding', data)
        .then(res => {
        return res;
    })
};
// IIN Verification Api calls ends

export const getPhoneEmailBelongsToList = () => {
    return httpGet('/service/advisory/relationship/all').then(res => {
        return res;
    })
};

export const getStateList = () => {
    return httpGet('/service/advisory/state/all').then(res => {
        return res;
    })
};

export const getOccupationList = () => {
    return httpGet('/service/advisory/occupation/all').then(res => {
        return res;
    })
};

export const getIndustryTypeList = () => {
    return httpGet('/service/advisory/industryType/all').then(res => {
        return res;
    })
};

export const getPoliticallyExposedOptions = () => {
    return httpGet('/service/advisory/pep/all').then(res => {
        return res;
    })
};

// FINANCIAL PERSONAL PROFILE API CALLS
export const updatePersonalProfileData = (data) => {
    return httpPut('/service/advisory/person/profile', data, true)
        .then((res) => {
            return res;
        })
};

export const getPersonalProfileData = (accountId, onboardingApplicationId) => {
    return httpGet('/service/advisory/person/profile?id='+accountId+'&onboardingApplicationId='+onboardingApplicationId)
        .then(res => {
            return res;
        });
};

// FINANCIAL PROFILE PROFILE API CALLS
export const updateFinancialProfileData = (data) => {
    return httpPut('/service/advisory/account/financialProfile', data, true)
        .then((res) => {
            return res;
        });
};

export const getFinancialProfileData = (accountId, onboardingApplicationId) => {
    return httpGet('/service/advisory/account/financialProfile?accountId='+accountId+'&onboardingApplicationId='+onboardingApplicationId)
        .then(res => {
            return res;
        });
};


export const getRiskProfileTypeOptions = () => {
    return httpGet('/service/advisory/riskProfileType/all').then(res => {
        return res;
    });
};

export const getApplicableIncomeOptions = () => {
    return httpGet('/service/advisory/applicableIncome/all').then(res => {
        return res;
    });
};

export const getCategoryOptions = () => {
    return httpGet('/service/advisory/categoryType/all').then(res => {
        return res;
    });
};

export const getAccountTypeOptions = () => {
    return httpGet('/service/advisory/accountType/all').then(res => {
        return res;
    });
};

export const getHoldingNatureOptions = () => {
    return httpGet('/service/advisory/holdingNature/all').then(res => {
        return res;
    })
};

export const getProfileOptions = () => {
    return httpGet('/service/advisory/financialProfile/all').then(res => {
        return res;
    })
};

export const getPersonalityTraitOptions = () => {
    return httpGet('/service/advisory/decisionMakingStyle/all').then(res => {
        return res;
    })
};

export const getDecisionApproachOptions = () => {
    return httpGet('/service/advisory/investmentStyle/all').then(res => {
        return res;
    })
};

export const getFinancialApproachOptions = () => {
    return httpGet('/service/advisory/moneyManagementStyle/all').then(res => {
        return res;
    })
};

export const getSpentOnRealEstateOptions = () => {
    return httpGet('/service/advisory/realEstateToNetworthRatio/all').then(res => {
        return res;
    })
};

export const getSpentOnInsuranceOptions = () => {
    return httpGet('/service/advisory/insuranceToIncomeRatio/all').then(res => {
        return res;
    })
};

export const getCashFlowOptions = () => {
    return httpGet('/service/advisory/cashflowType/all').then(res => {
        return res;
    })
};

export const getSpendingHabitsOptions = () => {
    return httpGet('/service/advisory/spendingHabit/all').then(res => {
        return res;
    })
};

export const getDetailsByIFSC = (data) => {
    return httpPost('/service/advisory/ifscCode/details',data, true)
        .then(res => {
        return res;
    })
};

// FINANCIAL BANK API CALLS
export const getBankAccountType = () => {
    return httpGet('/service/advisory/bank/type/all').then(res => {
        return res;
    })
};

export const updateBankAccountData = (data) => {
    return httpPut('/service/advisory/account/bank', data,true)
        .then((res) => {
            return res;
        })
};

export const getBankData = (accountId, onboardingApplicationId) => {
    return httpGet('/service/advisory/account/bank?accountId='+accountId+'&onboardingApplicationId='+onboardingApplicationId).then(res => {
        return res;
    })
};

export const getBanks = () => {
    return httpGet('/service/advisory/bank/all').then(res => {
        return res;
    })
};

export const getAllRequiredDocuments = (accountId, onboardingApplicationId) => {
    return httpGet('/service/advisory/account/documentRequirement?accountId='+accountId+'&onboardingApplicationId='+onboardingApplicationId)
        .then(res => {
        return res;
    })
};

export const getProceedWithErrors = (onboardingApplicationId) => {
    return httpGet('/service/advisory/onboardingApplication/proceedWithErrors?onboardingApplicationId='+onboardingApplicationId)
        .then(res => {
        return res;
    })
};

export const setProceedWithErrors = (data) => {
    return httpPut('/service/advisory/onboardingApplication/proceedWithErrors',data)
        .then(res => {
        return res;
    })
};


//photo API Start //

export const uploadPhoto = (data, personId) => {
    let url='/service/media/upload/image?personId='+personId;
    return uploadData(data, url)
};

export const downloadPhoto = (filePath) => {
    let url='/service/media/download?filePath='+filePath;
    return downloadS3BucketFile(url).then(res => {
        return res;
    })
};

export const updateAadharData = (data) => {
    return httpPut('/service/advisory/person/aadhaar', data,true)
        .then((res) => {
            return res;
        })
};

export const getGenderList = () => {
    return httpGet('/service/advisory/gender/all').then(res => {
        return res;
    })
};


//photo API End //

export const updatePanCardData = (data) => {
    return httpPut('/service/advisory/person/PAN', data,true)
        .then((res) => {
            return res;
        })
};


//upload document API start //
export const updateDocument = (data, fileType) => {
    if(fileType === 'application/pdf'){
        return uploadData(data, '/service/media/upload/pdf')
    }
    return uploadData(data, '/service/media/upload/image')
};


export const downloadFile = (filePath) => {
    let url='/service/media/download?filePath='+filePath;
    return downloadS3BucketFile(url).then(res => {
        return res;
    })
};

//upload document API sendtart //


//PAN Card API Start//
export const getPANCardData = (personId, onboardingApplicationId) => {

    return httpGet('/service/advisory/person/PAN?personId='+personId+'&onboardingApplicationId='+onboardingApplicationId)
        .then(res => {
            return res;
        });

};

export const getDocAssociateData = (data) => {
    let url='/service/advisory/document';
    return httpPost(url, data,true)
        .then((res) => {
            return res;
        })
};

export const disAssociateDocument = (documentId, onboardingApplicationId) => {
    let url='/service/advisory/document?documentId='+documentId+'&onboardingApplicationId='+onboardingApplicationId;
    return httpDelete(url,{}, true)
        .then((res) => {
            return res;
        });
};

//PAN Card API End//
//onboarding details Start //
export const getOnboardingDetails = (onboardingApplicationId) => {

    return httpGet('/service/advisory/onboardingApplication?onboardingApplicationId='+onboardingApplicationId)
        .then(res => {
            return res;
        });

};

export const getPersonData = (personId, onboardingApplicationId) => {

    return httpGet('/service/advisory/person/profile?id='+personId+'&onboardingApplicationId='+onboardingApplicationId,true)
        .then(res => {
            return res;
        });

};

export const getAadhaarData = (personId, onboardingApplicationId) => {
    return httpGet('/service/advisory/person/aadhaar?personId='+personId+'&onboardingApplicationId='+onboardingApplicationId)
        .then(res => {
            return res;
        });
};

export const addNominee = (data) => {
    return httpPost('/service/advisory/account/nominee',data).then(res => {
        return res;
    })
};
export const updateNominee = (data) => {
    return httpPut('/service/advisory/account/nominee',data, true).then(res => {
        return res;
    })
};

export const removeNominee = (accountId , nomineeId, onboardingApplicationId) => {
    return httpDelete('/service/advisory/account/nominee?accountId='+accountId+'&nomineeId='+nomineeId+'&onboardingApplicationId='+onboardingApplicationId,{}).then(res => {
        return res;
    })
};

export const getNomineeListing = (accountId , onboardingApplicationId) => {
    return httpGet('/service/advisory/account/nominee/all?accountId='+accountId+'&onboardingApplicationId='+onboardingApplicationId).then(res => {
        return res;
    })  //todo account is passing is pending
};

export const getRelationsList = () => {
    return httpGet('/service/advisory/nomineeRelation/all').then(res => {
        return res;
    })
};


//Kyc Status //
export const getKycDetails = (PANId) => {
    return httpGet('/service/advisory/person/kycStatus?PANNumber='+PANId, true)
        .then(res => {
            return res;
        });
};

export const refreshData = (data) => {
    return httpPost('/service/advisory/person/kyc',data)
        .then(res => {
            return res;
        });
};

export const getNomineeDetails = (nomineeId,accountId, onboardingApplicationId) => {
    return httpGet('/service/advisory/account/nominee?nomineeId='+nomineeId+'&accountId='+accountId+'&onboardingApplicationId='+onboardingApplicationId)
        .then(res => {
            return res;
        })
};

export const getGuardianRelationsList = () => {
    return httpGet('/service/advisory/guardianRelation/all').then(res => {
        return res;
    })
};

export const updateGuardian = (data) => {
    return httpPut('/service/advisory/account/nominee/guardian',data, true).then(res => {
        return res;
    })
};

export const getCRMDetails = () => {
    return httpGet('/service/advisory/advisoryUser/all?role=CRM').then(res => {
        return res;
    })
};

export const getDocAssociateIINOrECSForm = (data) => {
    let url='/service/advisory/form/upload';
    return httpPost(url, data,true)
        .then((res) => {
            return res;
        })
};

export const updateNomineeOpted = (data) => {
    return httpPut('/service/advisory/account/nomineeEnabled',data, true)
        .then(res => {
        return res;
    })
};

export const getCRMs = () => {
    return httpGet('/service/advisory/advisoryUser/all?role=CRM').then(res => {
        return res;
    })
};

export const getLocations = () => {
    return httpGet('/service/advisory/location/all').then(res => {
        return res;
    })
};

export const assignCRMToLeads = (data) => {
    return httpPut('/service/advisory/lead/request/assign',data, true)
        .then(res => {
            return res;
        })
};

export const assignCRMToOnboarding = (data) => {
    return httpPut('/service/advisory/onboardingApplication/assign',data, true)
        .then(res => {
            return res;
        })
};

export const markOnboardingAsCompleted = (data) => {
    return httpPut('/service/advisory/onboardingApplication/markCompleted',data, true)
        .then(res => {
            return res;
        })
};

export const removeLead = (leadId) => {
    return httpDelete('/service/advisory/lead/request?id='+leadId,{}).then(res => {
        return res;
    })
};

export const removeOnboarding = (onboardingId) => {
    return httpDelete('/service/advisory/onboardingApplication?id='+onboardingId,{}, true).then(res => {
        return res;
    })
};

export const changeForceNewMandate = (data) => {
    return httpPut('/service/advisory/account/bankMandate/changeForceNewMandate',data, true)
        .then(res => {
            return res;
        })
};