export const componentDefaults = {
  colors: {
    primary: '#007bff',
    secondary: '#0fc1d0',
    success: '#28a745',
    warning: '#ffc107',
    danger: '#dc3545',
    info: '#03a9f4',
    grayLight:"#57575B",
    gray: '#666666',
    // gray: '#A9A9A9',
    black: '#282828'
},
  fontSizes: {
    'xxs': 10,
    'xs': 12,
    'sm': 14,
    'md': 16,
    'lg': 20,
    'xl': 24,
    'xxl' : 28,
    'xxxl' : 32
  },
  marginBottoms: {
    'none': 0,
    'xs': '0.25rem',
    'sm': '0.50rem',
    'md': '1rem',
    'lg': '1.5rem',
    'xl': '3rem'
  },
  fontFamily: 'sans-serif'

};
