import React, {useEffect, useState} from 'react';

import {APP_CONST} from "../../../../../AppComponents/AppConstants";
import {isEmpty} from "../../../../../utils/validations";

import {connect} from "react-redux";

import {
    getTransactionOptionsList, initiateTransaction
} from "../../../../ClientRequestsActions";

import AddNewTransactionView from "./AddNewTransactionView";

const AddNewTransaction = (props) => {
    const [addNewTransactionState, setAddNewTransactionState] = useState({
        transactionOptions: [],
        amount: "",
        txnOption: '',
        isShowLoader: false,
        modalErrorMessage: '',
        isTxnOptionSelected : false,
        errors: {
            amountError: '',
            txnOptionError: ''
        }
    });

    useEffect(()=>{
        getTransactionOptions()
    },[])

    const getId = () => {
        return props.match?.params?.id
    };

    const getTransactionOptions = () => {
        setAddNewTransactionState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));


        getTransactionOptionsList().then(res => {
            if (res?.success) {
                setAddNewTransactionState(prevState => ({
                    ...prevState,
                    transactionOptions: setValuesForDropdown(res.data?.txnRequestTypes, 'name', 'id'),
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                    modalErrorMessage: ''
                }));
            } else {
                setAddNewTransactionState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    modalErrorMessage: res.__error
                }));
            }
        })
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return;
        }

        return data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken]}
        });
    };

    const handleChange = (name, value) => {
        let updatedState = {...addNewTransactionState};
        updatedState[name] = value;

        const validateObject = validateChanges(name, updatedState);

        setAddNewTransactionState(prevStep => ({
            ...prevStep,
            ...updatedState,
            modalErrorMessage: '',
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));
    };

    const validateChanges = (name, compState) => {
        const {amount, txnOption} = compState;
        let errorObj = {...addNewTransactionState.errors};

        if (name === "amount" || name === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(amount)) {
                errorObj.amountError = "Please enter amount";
            }else {
                errorObj.amountError = "";
            }

            if (name === 'remark') {
                return errorObj;
            }
        }
        if (name === "txnOption" || name === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(txnOption)) {
                errorObj.txnOptionError = "Please select transaction type";
            }else {
                errorObj.txnOptionError = "";
            }

            if (name === 'txnOption') {
                return errorObj;
            }
        }
        return errorObj;
    };

    const handleNext = () => {
        const {txnOption} = addNewTransactionState;
        const validateObj = validateChanges("txnOption", addNewTransactionState);

        if(!isEmpty(txnOption)){
            setAddNewTransactionState(prevStep => ({
                ...prevStep,
                isTxnOptionSelected : true
            }));
            return;
        }
        setAddNewTransactionState(prevStep => ({
            ...prevStep,
            errors: {
                ...prevStep.errors,
                ...validateObj
            }
        }));
    };

    const handleInitiate = () => {
        const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION, addNewTransactionState);

        if (!isEmpty(validateObj?.amountError)) {
            setAddNewTransactionState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObj
                }
            }));
            return;
        }
        setAddNewTransactionState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));

        let payload = {
            clientRequestId: getId(),
            requestType: addNewTransactionState.txnOption,
            amount: addNewTransactionState.amount
        };

        initiateTransaction(payload, true).then(res => {
            if (res?.success) {
                setAddNewTransactionState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false,
                }));
                props.history.push("/client-requests/"+getId()+"/lumpsum-transaction/"+res.data?.id)
            } else {
                setAddNewTransactionState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false,
                    modalErrorMessage: res.__error
                }));
            }
        })
    };

    return (
        <AddNewTransactionView
            {...props}
            {...addNewTransactionState}
            handleChange={handleChange}
            handleInitiate={handleInitiate}
            handleNext={handleNext}
        />
    );
};

const mapStateToProps = (state) => ({
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(AddNewTransaction);
