import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'NFTReducer',
    initialState: {
        NFTFilter: {
            keyword: '',
            fromDate: '',
            toDate: '',
            requestType:['ALL'],
            status: ['OPEN','IN_PROGRESS'],
            CRMId: ['ALL'],
            category: ['ALL'],
            ageing: ['ALL'],
            location: ['ALL'],
            title: '',
            skipCount: 0,
            limitCount: 50,
            page:0,
            rowsPerPage: 50
        },
        CRMDetails: []
    },
    reducers: {
        setSearchKeywordValue (state,action) {
            state.NFTFilter.keyword = action.payload;
        },

        setFromDateValue (state,action) {
            state.NFTFilter.fromDate = action.payload;
        },

        setToDateValue (state,action) {
            state.NFTFilter.toDate = action.payload;
        },

        setStatusValue (state,action) {
            state.NFTFilter.status = action.payload;
        },

        setTypeOfRequestValue(state, action) {
            state.NFTFilter.requestType = action.payload;
        },

        setCRMIdValue(state, action) {
            state.NFTFilter.CRMId = action.payload;
        },

        setCategoryValue(state, action) {
            state.NFTFilter.category = action.payload;
        },

        setAgeingValue(state, action) {
            state.NFTFilter.ageing = action.payload;
        },

        setLocationValue(state, action) {
            state.NFTFilter.location = action.payload;
        },

        setStatusTitle(state,action){
            state.NFTFilter.title = action.payload;
        },
        setSkipCountValue(state,action){
            state.NFTFilter.skipCount = action.payload;
        },

        setLimitCountValue(state,action){
            state.NFTFilter.limitCount = action.payload;
        },

        setPageValue(state,action){
            state.NFTFilter.page = action.payload;
        },

        setRowsPerPageValue(state,action){
            state.NFTFilter.rowsPerPage = action.payload;
        },

        resetAllFilters (state,action) {
            state.NFTFilter = {
                ...state.NFTFilter,
                keyword:'',
                fromDate:'',
                toDate:'',
                status:['OPEN','IN_PROGRESS'],
                requestType:['ALL'],
                CRMId: ['ALL'],
                category: ['ALL'],
                ageing: ['ALL'],
                location: ['ALL'],
                title: '',
                skipCount: 0,
                page:0,
            };
        },
        setCRMDetails(state,action) {
            state.CRMDetails = action.payload;
        },
    }
});

export const {setStatusValue, setSearchKeywordValue, setFromDateValue, setToDateValue, resetAllFilters, setCRMIdValue,
    setRowsPerPageValue, setPageValue,setLimitCountValue,setSkipCountValue,setTypeOfRequestValue, setCategoryValue, setAgeingValue,
    setLocationValue, setStatusTitle} = appSlice.actions;
export default appSlice.reducer

