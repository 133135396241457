import React from 'react';

import {isEmpty} from "../../../utils/validations";
import {convertUTCToLocalTime, getTimeFromNowWithDate} from "../../../utils/helper";

import DLText from "../DLText/DLText";
import DLLoader from "../DLLoader/DLLoader";

const HistoryBlockView = (props) => {

    const {events, getAdvisoryUser, getSentenceCase, isShowLoader, isMobileView, isFrom} = props;

    const renderMultilineRemark = (remarks) => {
        if (isEmpty(remarks)) {
            return '-'
        }
        const lines = remarks.split('\n');
        return lines.map((line, index) => {
            if (isEmpty(line)) {
                return (
                    <br key={index}/>
                )
            }
            return (
                <div key={index}>
                    <DLText id={'ViewActivity-Summary'}
                            fontSize={'xs'}
                            marginBottom={"none"}
                            text={line}/>
                </div>
            )
        })
    };

    const getHeading = (type, remark, assignedTo, name, date) => {
        const title = getSentenceCase(type);
        if ("ASSIGNED" === type) {
            return (
                <div style={{lineHeight: "15px"}}>
                    <div className={"text-break d-flex gap-1"}>
                        <span><DLText id={'ViewActivity-Summary'}
                                      marginBottom={"none"}
                                      fontWeight={'semi-bold'}
                                      fontSize={'sm'}
                                      isInline={true}
                                      text={title}/>
                        <DLText id={'ViewActivity-Summary'}
                                marginBottom={"none"}
                                fontWeight={'semi-bold'}
                                fontSize={'sm'}
                                isInline={true}
                                text={" to "}/>
                        <DLText id={'ViewActivity-Summary'}
                                fontSize={'sm'}
                                marginBottom={"none"}
                                isInline={true}
                                text={getAdvisoryUser(assignedTo)}/>
                        </span>
                    </div>
                </div>

            )
        }

        if (!isEmpty(remark)) {
            return (
                <div style={{lineHeight: "15px"}}>
                    <div className={"text-break d-flex gap-1 pe-3"}>
                    <span>
                        <DLText id={'ViewActivity-Summary'}
                                marginBottom={"none"}
                                fontWeight={'semi-bold'}
                                fontSize={'sm'}
                                isInline={true}
                                text={title}/>
                        <DLText id={'ViewActivity-Summary'}
                                marginBottom={"none"}
                                fontWeight={'semi-bold'}
                                fontSize={'sm'}
                                isInline={true}
                                text={" - "}/>
                        <DLText id={'ViewActivity-Summary'}
                                fontColor={'grayDark'}
                                fontSize={'xxs'}
                                isInline={true}
                                text={'by ' + name + date}
                        />
                        <div className='' style={{marginTop: "14px"}}>
                            {renderMultilineRemark(remark)}
                        </div>
                       </span>
                    </div>
                </div>

            );
        }

        return (
            <div style={{lineHeight: "15px"}}>
                <DLText id={'ViewActivity-Summary'}
                        fontSize={'sm'}
                        fontWeight={'semi-bold'}
                        text={title}/>
            </div>
        );
    };

    const getFollowUpDate = (item) => {
        return <div className='d-flex gap-1' style={{marginTop: "14px"}}>
            <DLText id={'ViewActivity-Summary'}
                    marginBottom={"none"}
                    fontWeight={'semi-bold'}
                    fontSize={'xs'}
                    isInline={true}
                    text={'Follow up '}/>
            <DLText id={'ViewActivity-Summary'}
                    marginBottom={"none"}
                    fontSize={'xs'}
                    isInline={true}
                    text={convertUTCToLocalTime(item?.details?.followUpDate, 'DD-MM-YYYY')}/>
        </div>
    };


    const renderDetails = (item, index) => {
        let date = isEmpty(item?.createdAt) ? '' : getTimeFromNowWithDate(item?.createdAt);
        let name = getAdvisoryUser(item?.createdBy?.id);
        let remarks = '';
        if (item?.details?.comment) {
            remarks = item?.details?.comment;
        }
        if (item?.details?.remark) {
            remarks = item?.details?.remark;
        }
        let assignedTo = isEmpty(item?.details?.assignedTo) ? '' : item?.details?.assignedTo;

        return (
            <div className='d-flex mb-3' key={index}>
                <div>
                    <div className='vertical-step-icon mx-1'/>
                    <div className='d-flex justify-content-center h-100'>
                        <div className={index === events?.length - 1 ? '' : 'step-connector'}/>
                    </div>
                </div>

                <div className='mb-3 ms-1'>
                    {getHeading(item?.type, remarks, assignedTo, name, date)}
                    {item?.type === 'HOLD' &&
                    getFollowUpDate(item)
                    }
                </div>
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderErrorUI = () => {
        const {responseError} = props;

        if (isEmpty(responseError)) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className={'mt-1'}>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            </div>
        );
    };


    return (
        <div className={isFrom === "LISTING" ? "" : isMobileView ? ' mt-3 ' : 'p-3 pe-0 '}>
            <div className="history-block"
                 style={{
                     minHeight: isMobileView ? 'calc(100dvh - 250px)' : 'calc(100dvh - 241px)',
                     maxHeight: isMobileView ? 'calc(100dvh - 250px)' : 'calc(100dvh - 300px)'
                 }}>
                {isFrom !== "LISTING" && <div className={'mb-3 pb-2 pt-1 ms-1'}>
                    <DLText id={'history-block-title'}
                            text={'History'}
                            fontSize={"lg"}
                            marginBottom={'none'}
                            fontWeight={"semi-bold"}
                            isInline={true}/>
                    {renderErrorUI()}
                </div>}

                <div className={isFrom === "LISTING" ? "" : isMobileView ? 'mt-3 pb-5' : 'mt-3 pt-1 pb-3'}
                     style={{
                         minHeight: isMobileView ? 'calc(100dvh - 250px)' : 'calc(100dvh - 284px)',
                         maxHeight: isMobileView ? '' : 'calc(100dvh - 284px)',
                         overflowY: "auto"
                     }}>
                    {events.map((item, index) => (
                        renderDetails(item, index)
                    ))}
                </div>
            </div>
            {renderLoader()}
        </div>
    );
};

export default HistoryBlockView;