import React, {useState} from 'react';
import {connect} from "react-redux";

import {getOnboardingDetails, markOnboardingAsCompleted} from "../../OnboardingActions";

import MarkAsCompletedView from "./MarkAsCompletedView";
import {isEmpty} from "../../../core/components/DLComponentHelper";


const MarkAsCompleted = (props) => {

    const{getApplicationId} = props;
    const [markAsCompletedState, setMarkAsCompletedState] = useState({
        isApiInProgress: false,
        handleErrorInComponent:true,
        modalErrorMessage:'',
        isApiSuccessful: false
    });

    const handleMarkAsCompleted = () => {
        let applicationId = getApplicationId();
        let payload = {
            onboardingApplicationId: applicationId,
            isInternal: false
        }
        setMarkAsCompletedState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));

        markOnboardingAsCompleted(payload)
            .then((res) => {
                if (res.success) {
                    setMarkAsCompletedState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false,
                        isApiSuccessful: true
                    }));
                } else {
                    setMarkAsCompletedState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false,
                        modalErrorMessage:res.__error,
                        isApiSuccessful: false
                    }));
                }
            }
        );
    };

    const handleDone = () => {
        const{handleChangeOnboardingStatus, handleCloseMarkAsCompletedModal} = props;
        let applicationId = getApplicationId();
        if (isEmpty(applicationId)) {
            return
        }
        setMarkAsCompletedState((prevState) => ({
            ...prevState,
            isApiInProgress: false
        }));

        getOnboardingDetails(applicationId)
            .then((res) => {
                if (res?.success) {
                    let status = res.data?.iinStatus;
                    let onboardingApplicationStatus = res.data?.status;
                    handleChangeOnboardingStatus(status, onboardingApplicationStatus)
                    setMarkAsCompletedState((prevState) => ({
                        ...prevState,
                        isApiInProgress: false
                    }));

                } else {
                    setMarkAsCompletedState((prevState) => ({
                        ...prevState,
                        isApiInProgress: false,
                        modalErrorMessage:res?.__error
                    }));
                }
                handleCloseMarkAsCompletedModal();
            })
    };

    return (
        <MarkAsCompletedView
            {...props}
            {...markAsCompletedState}
            handleMarkAsCompleted={handleMarkAsCompleted}
            handleDone={handleDone}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(MarkAsCompleted);
