import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    getManagementOnboardingCategory,
    resetOnboardingListingFilters,
    setOnboardingCategory,
    setOnboardingStatusTitle
} from "../../../HomePageActions";
import DashboardWidget from "../../../DashboardWidget";

const OnboardingCategory=(props)=> {
    const [onboardingCategoryState, setOnboardingCategoryState] = useState({
        categoryData: {},
        isShowLoader: false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {
        setOnboardingCategoryState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getManagementOnboardingCategory().then(res => {
            if (res.success) {
                setOnboardingCategoryState(prevState => ({
                    ...prevState,
                    categoryData: res.data,
                    isShowLoader: false,
                }));
            } else {
                setOnboardingCategoryState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        resetOnboardingListingFilters();
        setOnboardingCategory([item.key]);
        setOnboardingStatusTitle('Category');
        props.history.push('/onboardings');
    };


    return (
        <DashboardWidget
            {...props}
            {...onboardingCategoryState}
            detailsObj={onboardingCategoryState.categoryData}
            setFilters={setFilters}
            title={"Category"}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(OnboardingCategory);
