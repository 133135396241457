import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../../../utils/validations";
import {convertUTCToLocalTime} from "../../../../../utils/helper";

import {downloadFile} from "../../../../../onboarding/OnboardingActions";
import {downloadAndViewForm} from "../../../../../onboarding/components/formsAndReview/FormsAndReviewActions";

import AccountHolderView from "./AccountHolderView";

const AccountHolder = (props) => {


    const [accountHolderState, setAccountHolderState] = useState({
        previewImage: '',
        personFullName: '',
        gender: '',
        DOB: '',
        birthPlace: '',
        maritalStatus: '',
        fathersName: '',
        mothersName: '',
        isShowLoader: false,
        address: '',
        primaryEmail: '',
        primaryPhoneNumber: '',
        secondaryEmail: '',
        secondaryPhoneNumber: '',
        primaryEmailRelation: '',
        primaryPhoneNumberRelation: '',
        secondaryEmailRelation: '',
        secondaryPhoneNumberRelation: '',
        companyName: '',
        occupationCode: '',
        industryType: '',
        PANNumber: '',
        aadhaarNumber: '',
        nameAsPerAadhaar: '',
        nameAsPerPAN: '',
        PEPCode: '',
        aadharFilePath: '',
        PANFilePath: '',
        taxStatus: '',
        PANDownloadFilePath: '',
        AadhaarDownloadFilePath: '',
        isShowAadharLoader: false,
        isShowPANLoader: false
    });

    useEffect(() => {
        resetAccountHolder();
    }, [props?.selectedPersonDetails]);

    const resetAccountHolder = () => {

        setAccountHolderState((prevStep) => ({
            ...prevStep,
            previewImage: '',
            personFullName: '',
            gender: '',
            DOB: '',
            birthPlace: '',
            maritalStatus: '',
            fathersName: '',
            mothersName: '',
            isShowLoader: false,
            address: '',
            primaryEmail: '',
            primaryPhoneNumber: '',
            secondaryEmail: '',
            secondaryPhoneNumber: '',
            primaryEmailRelation: '',
            primaryPhoneNumberRelation: '',
            secondaryEmailRelation: '',
            secondaryPhoneNumberRelation: '',
            companyName: '',
            occupationCode: '',
            industryType: '',
            PANNumber: '',
            aadhaarNumber: '',
            nameAsPerAadhaar: '',
            nameAsPerPAN: '',
            PEPCode: '',
            aadharFilePath: '',
            PANFilePath: '',
            taxStatus: '',
            PANDownloadFilePath: '',
            AadhaarDownloadFilePath: '',
            isShowAadharLoader: false,
            isShowPANLoader: false
        }));
        setAccountHolderData()
    };

    const getPersonaName = (selectedPersonDetails) => {
        if (isEmpty(selectedPersonDetails)) {
            return '-'
        }
        let title = selectedPersonDetails?.title;
        let firstName = selectedPersonDetails?.firstName;
        let middleName = selectedPersonDetails?.middleName;
        let lastName = selectedPersonDetails?.lastName;


        let fullName = '';

        if (isEmpty(firstName) && isEmpty(lastName)) {
            return ''
        }
        if (!isEmpty(title)) {
            fullName = title + ' '
        }
        if (!isEmpty(firstName)) {
            fullName += firstName + ' '
        }
        if (!isEmpty(middleName)) {
            fullName += middleName + ' '
        }
        if (!isEmpty(lastName)) {
            fullName += lastName
        }
        return fullName
    };

    const formatPersonAddress = (address) => {
        if (isEmpty(address)) {
            return '-'
        }
        let personAddress = address[0];
        let formatedAddress = '';
        if (!isEmpty(personAddress?.addressLine1)) {
            formatedAddress = personAddress?.addressLine1
        }
        if (!isEmpty(personAddress?.addressLine2)) {
            formatedAddress += (' '+personAddress?.addressLine2)
        }
        if (!isEmpty(personAddress?.addressLine3)) {
            formatedAddress += (' '+personAddress?.addressLine3)
        }
        if (!isEmpty(personAddress?.landMark)) {
            formatedAddress += (' '+personAddress?.landMark)
        }
        if (!isEmpty(personAddress?.zipCode)) {
            formatedAddress += ' - ' + personAddress?.zipCode
        }
        return formatedAddress

    };

    const getBelongsToDescription = (id) => {
        const {belongsToData} = props;
        if (isEmpty(belongsToData) || isEmpty(id)) {
            return ''
        }
        let categoryObj = belongsToData.find(item => item?.code === id);
        return isEmpty(categoryObj?.description) ? '' : ' - ' + categoryObj?.description
    };

    const getIndustryType = (id) => {
        const {industryData} = props;
        if (isEmpty(industryData) || isEmpty(id)) {
            return '-'
        }
        let industryObj = industryData.find(item => item?.id === id);
        return isEmpty(industryObj?.name) ? '' : industryObj?.name
    };

    const getOccupation = (id) => {
        const {occupationData} = props;
        if (isEmpty(occupationData) || isEmpty(id)) {
            return '-'
        }
        let occupationObj = occupationData.find(item => item?.code === id);
        return isEmpty(occupationObj?.description) ? '' : occupationObj?.description
    };

    const getPEPCode = (PEPCode) => {
        const {politicallyExposedData} = props;
        if (isEmpty(politicallyExposedData) || isEmpty(PEPCode)) {
            return '-'
        }
        let PEPObj = politicallyExposedData.find(item => item?.code === PEPCode);
        return isEmpty(PEPObj?.description) ? '' : PEPObj?.description

    };

    const setAccountHolderData = () => {

        const {selectedPersonDetails} = props;
        if (isEmpty(selectedPersonDetails)) {
            return
        }

        let personDocuments = selectedPersonDetails?.personDocuments;
        let parentDetails = selectedPersonDetails?.parents;
        let photograph = '';
        let panCardDocument = '';
        let aadhaarCardDocument = '';
        let fatherDocuments = {};
        let motherDocuments = {};

        if (!isEmpty(personDocuments)) {
            photograph = personDocuments.find(item => item?.document?.documentType === "PHOTOGRAPH");
            panCardDocument = personDocuments.find(item => item?.document?.documentType === "PAN_CARD");
            aadhaarCardDocument = personDocuments.find(item => item?.document?.documentType === "AADHAR_CARD");
        }
        if (!isEmpty(parentDetails)) {
            fatherDocuments = parentDetails.find(item => item?.parentType === "FATHER");
            motherDocuments = parentDetails.find(item => item?.parentType === "MOTHER");
        }


        let imageURL = '';
        let imageURLObj = photograph?.document?.imageFilePaths;
        if (!isEmpty(imageURLObj)) {
            imageURL = downloadImage(imageURLObj[0], false);
        }

        setAccountHolderState((prevStep) => ({
            ...prevStep,
            isShowLoader: false,
            previewImage: imageURL,
            personFullName: getPersonaName(selectedPersonDetails),
            gender: selectedPersonDetails?.aadhaarDetails?.gender,
            DOB: isEmpty(selectedPersonDetails?.dateOfBirth) ? '-' : convertUTCToLocalTime(selectedPersonDetails?.dateOfBirth, 'DD-MM-YYYY'),
            birthPlace: selectedPersonDetails?.birthPlace,
            maritalStatus: getMaritalStatusValue(selectedPersonDetails?.maritalStatus),
            fathersName: getPersonaName(fatherDocuments),
            mothersName: getPersonaName(motherDocuments),
            address: formatPersonAddress(selectedPersonDetails?.personAddresses),
            primaryEmail: selectedPersonDetails?.primaryEmail,
            primaryPhoneNumber: selectedPersonDetails?.primaryPhoneNumber,
            secondaryEmail: selectedPersonDetails?.secondaryEmail,
            secondaryPhoneNumber: selectedPersonDetails?.secondaryPhoneNumber,
            primaryEmailRelation: getBelongsToDescription(selectedPersonDetails?.primaryEmailRelation),
            primaryPhoneNumberRelation: getBelongsToDescription(selectedPersonDetails?.primaryPhoneNumberRelation),
            secondaryEmailRelation: getBelongsToDescription(selectedPersonDetails?.secondaryEmailRelation),
            secondaryPhoneNumberRelation: getBelongsToDescription(selectedPersonDetails?.secondaryPhoneNumberRelation),
            companyName: isEmpty(selectedPersonDetails?.profession?.companyName) ? '' : selectedPersonDetails?.profession?.companyName,
            occupationCode: getOccupation(selectedPersonDetails?.profession?.occupationCode),
            industryType: getIndustryType(selectedPersonDetails?.profession?.industryType),
            PANNumber: selectedPersonDetails?.PANDetail?.PANNumber,
            aadhaarNumber: selectedPersonDetails?.aadhaarDetails?.aadhaarNumber,
            nameAsPerAadhaar: selectedPersonDetails?.aadhaarDetails?.nameAsPerAadhaar,
            nameAsPerPAN: selectedPersonDetails?.PANDetail?.nameAsPerPAN,
            PEPCode: getPEPCode(selectedPersonDetails?.PEPCode),
            taxStatus: getTaxStatus(selectedPersonDetails?.personFinancial?.taxStatusCode),
            PANDownloadFilePath: panCardDocument?.document?.imageFilePaths[0],
            AadhaarDownloadFilePath: aadhaarCardDocument?.document?.imageFilePaths[0]
        }));
        getFilePathDownload([{
            name: 'aadharFilePath',
            filePath: aadhaarCardDocument?.document?.imageFilePaths[0]
        },
            {
                name: 'PANFilePath',
                filePath: panCardDocument?.document?.imageFilePaths[0]
            }
        ]);
    };

    const getMaritalStatusValue = (gender) => {
        if (isEmpty(gender)) {
            return ''
        }
        if (gender === 'MARRIED') {
            return 'Married'
        }
        if (gender === 'UNMARRIED') {
            return 'Unmarried'
        }
        if (gender === 'OTHERS') {
            return 'Others'
        }
        return gender

    };

    const downloadAndViewDocument = (action, filePath, fileName) => {
        setAccountHolderState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));
        downloadAndViewForm(fileName + '.jpg', filePath, action);
        setTimeout(() => {
            setAccountHolderState((preState) => ({
                    ...preState,
                    isShowLoader: false,
                }
            ))
        }, 500)
    };

    const downloadImage = (downloadURL) => {
        if (isEmpty(downloadURL)) {
            return ''
        }
        downloadFile(downloadURL)
            .then((res) => {
                if (res.success) {
                    setAccountHolderState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                        previewImage: res.blobData,
                    }));
                } else {
                    //TODO
                    setAccountHolderState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                    }));
                }
            });
    };

    const getFilePathDownload = (docsArray) => {
        if (isEmpty(docsArray)) {
            return
        }
        setAccountHolderState((prevStep) => ({
            ...prevStep,
            isShowAadharLoader: true,
            isShowPANLoader: true
        }));

        docsArray.map((doc) => {
            let docName = doc?.name;
            if (!isEmpty(doc?.filePath)) {
                downloadFile(doc?.filePath)
                    .then((res) => {

                        if (res.success) {
                            if(docName === 'PANFilePath'){
                                setAccountHolderState((prevStep) => ({
                                    ...prevStep,
                                    [docName]: res.blobData,
                                    isShowPANLoader: false
                                }));
                            }else{
                                setAccountHolderState((prevStep) => ({
                                    ...prevStep,
                                    [docName]: res.blobData,
                                    isShowAadharLoader: false,
                                }));
                            }
                        }
                    });
            }else{
                if(docName === 'PANFilePath'){
                    setAccountHolderState((prevStep) => ({
                        ...prevStep,
                        isShowPANLoader: false
                    }));
                }else{
                    setAccountHolderState((prevStep) => ({
                        ...prevStep,
                        isShowAadharLoader: false,
                    }));
                }
            }

        });


    };

    const getTaxStatus = (status) => {
        const {taxStatuses} = props;

        if (isEmpty(status) || isEmpty(taxStatuses)) {
            return '-'
        }
        let tempStatus = '';
        taxStatuses.map((item) => {
            if (item?.code === status) {
                tempStatus = item?.description
            }
        });
        return (isEmpty(tempStatus)) ? '-' : tempStatus
    };

    return (
        <AccountHolderView
            {...props}
            {...accountHolderState}
            downloadImage={downloadImage}
            downloadAndViewDocument={downloadAndViewDocument}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});


export default connect(mapStateToProps, {})(AccountHolder);
