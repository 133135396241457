import React, {Component} from 'react';
import "../../../assets/css/dlComponent.css"
import TextField from "@mui/material/TextField";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {getFontColor, getFontFamily, getFontSize, getThemeColor, isEmpty} from "./../DLComponentHelper";
import moment from 'moment'
import InputAdornment from '@mui/material/InputAdornment';
import "../../../assets/css/designToken.css"


class DLDatePickerView extends Component {

  localOnBlur = () => {
    if (this.props.onBlur) {
      return this.props.onBlur()
    } else {
      return null
    }
  };

  renderTextFiledUi = (params) => {
    const {
      value, onChangeValue, isDisabled, error, helperMessage, disableHelperMessageHeight,  id, isClearable
    } = this.props;  // DO NOT REMOVE ANY PROPS


    return (
      <>
        {/*<ThemeProvider theme={buttonTheme}>*/}
          <TextField
          {...params}
          error={error}
          helperText={
            (<span style={{
              minHeight: disableHelperMessageHeight ? '0' : '19px',
              color: error ? getThemeColor('danger') : ''
            }}>
            {helperMessage}
          </span>)
          }
          onBlur={this.localOnBlur}
          inputProps={{
            ...params.inputProps,
            placeholder: '',
            readOnly: "readonly"
          }}
          InputProps={{
            placeholder: '',
            endAdornment: isClearable && !isDisabled ?
              (<InputAdornment position="end">
                <div className='d-flex align-items-center'>
                  {
                    moment(value).isValid() ?
                      <span
                        id={id + '-input-clear'}
                        style={{cursor: isDisabled ? '' : 'pointer'}}
                        className={(isDisabled ? '' : 'cursor')}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (isDisabled) {
                            return;
                          }
                          onChangeValue(null);
                        }}>
                         <span>
                            <i className='fa fa-times'/>
                         </span>
                       </span> : null
                  }
                  <div className=''>
                    {params?.InputProps.endAdornment}
                  </div>

                </div>
              </InputAdornment>) :
              null,
          }}
          fullWidth
        />
        {/*</ThemeProvider>*/}
      </>
    )
  };

    getStyle = (labelFontColor, labelFontSize, error) => {

        return {
            fontFamily: getFontFamily(),
            ...getFontSize(labelFontSize),
            color: (error && this.props.isRequired )? getThemeColor('danger') : getFontColor(labelFontColor)
        }
    };

    onchangeDate = (event) => {
        const {onChangeValue} = this.props;
        let isvalidDate = moment(event).isValid();
        if(!isvalidDate){
            return
        }
        if(event === null){
            onChangeValue(null)
        }else{
            onChangeValue(moment(event))
        }
    };

  render() {

    const {
      value, onChangeValue, isDisabled, maximumDate, minimumDate, id, format, minWidth,labelFontColor, labelFontSize,
      error, label, isRequired, helperMessage, style} = this.props;  // DO NOT REMOVE ANY PROPS
    let minDate = moment(minimumDate).format('YYYY-MM-DD');
    let maxDate = moment(maximumDate).format('YYYY-MM-DD');

    return (

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className='dl-date-picker' id={id}>
            <div style={{
                lineHeight: '21px',
                marginBottom: isEmpty(label) ? '0px' : '4px',
                ...style
            }}>
                {
                    <span  style={{...this.getStyle(labelFontColor, labelFontSize, error)}}>{label}</span>
                }
            </div>
          <DesktopDatePicker
            orientation="portrait"
            value={moment(value).isValid() ? new Date(value) : null}
            onChange={(e) => {
                this.onchangeDate(e)
            }}
            renderInput={(params) => this.renderTextFiledUi(params)}
            minDate={minimumDate ? new Date(minDate) : null}
            maxDate={maximumDate ? new Date(maxDate) : null}
            clearable={false}
            disabled={isDisabled}
            slotProps={{
                textField:{
                    error: error,
                    helperText: isEmpty(helperMessage) ? '' : helperMessage
                },
                field: {clearable: true, onClear: () => onChangeValue(null), format: format},
                actionBar: {
                    actions: ['clear']
                }
            }}
            sx={{
                minWidth: isEmpty(minWidth) ? '' :minWidth
           }}
          />
        </div>
      </LocalizationProvider>

    );
  }
}

export default DLDatePickerView;
