import React from 'react';
import {isEmpty} from "../utils/validations";

import DLText from "../core/components/DLText/DLText";
import DLLoader from "../core/components/DLLoader/DLLoader";

const DashboardWidget = (props) => {
    const {detailsObj, setFilters, title, isShowLoader, isXSView} = props;
    let cardValues;

    if (isEmpty(detailsObj)) {
        cardValues = [];
    } else {
        cardValues = isEmpty(detailsObj.items) ? [] : detailsObj.items;
    }

    const renderData = () => {
        return cardValues.map((item, index) => {
            return (
                <div className={"d-flex justify-content-between py-1" }
                     key={'data' + index}>
                    <div className='data-card-label'>{item.description}</div>
                    <div className='data-card-value'>
                        <DLText id={'item-count'}
                                isInline={true}
                                fontSize={'md'}
                                fontColor={'grayDark'}
                                isClickable={item.count !== 0}
                                fontWeight={'semi-bold'}
                                onClick={() => setFilters(item)}
                                text={item.count}/>
                    </div>
                </div>
            )
        })
    };

    const renderWidget = () => {
        let statusObj = {key: 'ALL'};
        return <div className={"py-2 px-3 border-bottom d-flex "}>
            <div className='d-flex align-items-center'>
                <DLText id={'title-name'}
                        isInline={true}
                        fontSize={'md'}
                        marginBottom={'none'}
                        fontWeight={'semi-bold'}
                        text={title}/>
            </div>
            <div className='d-flex justify-content-end flex-grow-1 align-items-center'>
                {!isEmpty(detailsObj?.totalCount) && !isShowLoader &&
                    <DLText id={'detailsObj-totalCount'}
                            isInline={true}
                            fontSize={'md'}
                            fontColor={'grayDark'}
                            isClickable={detailsObj.totalCount !== 0}
                            marginBottom={'none'}
                            onClick={() => setFilters(statusObj)}
                            fontWeight={'semi-bold'}
                            text={detailsObj.totalCount}/>
                }
            </div>
        </div>
    };

    const renderLoader = () => {
        return (<DLLoader type={"inline"} isVisible={isShowLoader}/>);
    };

    const renderTableUi = () => {
        if (isEmpty(detailsObj) && !isShowLoader) {
            return (
                <div className='d-flex h-75 align-items-center justify-content-center'
                     style={{minHeight: 217}}>
                    <div> Data not found</div>
                </div>
            )
        } else {
            return (
                <div className={cardValues.length > 6 ? 'p-3 overflow-scroll overflow-x-hidden ' : 'p-3 '}
                     style={{height: 217}}>
                    {isShowLoader ?
                    <div className='d-flex align-items-center justify-content-center h-100'>
                        {renderLoader()}
                    </div>
                        :
                        renderData()
                    }
                </div>
            )
        }
    };

    return (
        <div className={`p-0 w-100 border-rounded panel h-100 ${isXSView ? 'mb-3' : ''}`}>
            <div className="px-0 h-100 c">
                {renderWidget()}
                {renderTableUi()}
            </div>
        </div>
    )
};

export default DashboardWidget;