import React, {useEffect} from 'react';
import DLButton from "../../../../core/components/DLButton/DLButton";
import "./NomineeList.css"
import DLModal from "../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLText from "../../../../core/components/DLText/DLText";
import DLModalActions from "../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLLoader from "../../../../core/components/DLLoader/DLLoader";
import {isEmpty} from "../../../../utils/validations";

function RemoveNominee(props) {

    const {history,handleRemoveNominee,handleCloseDeleteModal,selectedNomineeId, isDeleteNomineeDialogOpen,
        nominees} = props;

    const getNomineeName = () =>{
        let name = "";
        nominees.forEach((item)=>{
           if(item?.id === selectedNomineeId){
               name = item?.name;
           }
        })
        if(!isEmpty(name)){
            return name;
        }
        return "";
    }


    const renderRemoveNomineeModal = () => {
        return (
            <div>
                <DLLoader type={"screen"}/>
                <DLModal
                    history={history}
                    maxWidth={"xs"}
                    onClose={() => handleCloseDeleteModal()}
                    open={isDeleteNomineeDialogOpen}
                    id={'delete-menu-modal'}>
                    <DLModalTitle
                        id={'delete-menu-title'}
                        onClose={() => handleCloseDeleteModal()}
                        title={'Confirmation'}/>
                    <DLModalContainer
                        id={'delete-menu-container'}>
                        <div className="remove-nominee">
                            <DLText id={'delete-menu-container'}
                                    isInline={true}
                                    text={"Are you sure you want to delete the nominee "}
                            />
                            <DLText id={'delete-menu-container'}
                                    text={getNomineeName()+ "?"}
                                    isInline={true}
                            />
                        </div>

                    </DLModalContainer>
                    <DLModalActions id={'remove-action'}>
                        <div className='mb-4' style={{paddingTop: '7px'}}>
                            <DLButton
                                id={'cancel-Btn'}
                                history={history}
                                label={'Cancel'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                styleType={"outlined"}
                                backgroundColor={"grey"}
                                sideMargin={"sm"}
                                onClick={() => handleCloseDeleteModal()}
                            />
                            <DLButton
                                id={'delete-Btn'}
                                history={props.history}
                                label={'Remove'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                sideMargin={"sm"}
                                style={{
                                    background: "var(--danger)",
                                    color: "var(--white)",
                                }}
                                onClick={() => {handleRemoveNominee(selectedNomineeId)}}
                            />
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
    );
    };
    return (
        <div>{renderRemoveNomineeModal()}</div>

    );
    }

    export default RemoveNominee;