import React from 'react';

import {convertUTCToLocalTime, numberWithCommas, toSentenceCase} from "../../../../../utils/helper";
import {isEmpty} from "../../../../../core/components/DLComponentHelper";

import DLTable from "../../../../../core/components/DLTable/DLTable";
import DLText from "../../../../../core/components/DLText/DLText";

import {clientBankAccountCellConfig, headingAccountData, headingMandateData} from "../../../../ClientsModel";
import {clientMandateCellConfig, clientMandateSmallScreenCellConfig} from "../../../../ClientsModel";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import {renderBankMandateStatusBlock} from "../../../../ClientsHelper";


const BankAccountView = (props) => {
    const {banks, isXSView, isMobileView, getAccountType, getBankName, isBankLoaderVisible, isLGView, UIState, bankMandates} = props;

    const renderBankName = (bankName, branchName, IFSC, isShowIFSC, isDefaultBank) => {
        return (
            <div className={isMobileView ? '' : 'py-3'}>
                <div className="py-1 d-flex align-items-center ">
                    <DLText id={''}
                            text={isEmpty(bankName) ? '-' : getBankName(bankName)}
                            marginBottom={"none"}
                            fontSize={"xs"}
                            fontColor={"black"}
                    />
                    {!isEmpty(branchName) && <DLText id={''}
                                                     text={isEmpty(branchName) ? '-' : ' - ' + branchName}
                                                     marginBottom={isXSView ? "none" : "xs"}
                                                     fontSize={"xs"}
                                                     fontColor={"black"}
                    />}
                    {isDefaultBank && !isShowIFSC &&
                    <div>
                        <span style={{maxWidth: 'max-content', minWidth: 80}} className={'badge-blue ms-1 me-0'}>Default</span>
                    </div>
                    }
                </div>
                {isShowIFSC &&
                <div className='d-flex align-items-center'>
                    <DLText id={''}
                            text={isEmpty(IFSC) ? 'IFSC: -' : 'IFSC: ' + IFSC}
                            marginBottom={"none"}
                            fontSize={"xs"}
                            fontColor={"black"}
                    />
                    {isDefaultBank &&
                    <div>
                        <span style={{maxWidth: 'max-content', minWidth: 80}} className={'badge-blue ms-1 me-0'}>Default</span>
                    </div>
                    }
                </div>}
            </div>
        );
    };

    const getStatusWithDot = (status) => {
        if(isEmpty(status)){
            return "-";
        }
        let assignColor='status-dot grayDark';
        let selectedStatus = status;
        let fontSize = '12px';

        if (selectedStatus==="Activated")
        {
            assignColor="status-dot success";
        }
        return (
            <div className="d-flex">
                <span className={`${assignColor} my-1 ms-0 me-1`}/>
                <span style={{fontSize: fontSize}}>{selectedStatus}</span>
            </div>
        );

    };

    const getFormattedTableData = () => {

        let tableData = [];

        if (isEmpty(banks)) {
            return tableData
        }

        banks.map((bankAccount) => {
            let bankAccountDetails = [];
            let data = {};
            if (isMobileView) {
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: renderMobileBankAccount(bankAccount)
                });
            } else {
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: renderBankName(bankAccount?.bankName, '', '', false, bankAccount?.defaultBank)
                });
                bankAccountDetails.push(bankAccount?.branch);
                bankAccountDetails.push(bankAccount?.IFSCCode);
                bankAccountDetails.push(bankAccount?.accountHolderName);
                bankAccountDetails.push(bankAccount?.accountNumber);
                bankAccountDetails.push(getAccountType(bankAccount?.bankAccountType));
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: getStatusWithDot(bankAccount?.status)
                });
            }

            data.id = '';
            data.data = bankAccountDetails;
            tableData.push(data)
        });

        return tableData

    };

    const renderDateRange = (fromDate, toDate) => {
        if (isEmpty(fromDate) && isEmpty(toDate)) {
            return '-'
        }
        if (isEmpty(fromDate)) {
            return '-' + convertUTCToLocalTime(toDate, 'DD-MM-YYYY')
        }
        if (isEmpty(toDate)) {
            return convertUTCToLocalTime(fromDate, 'DD-MM-YYYY')
        }
        return convertUTCToLocalTime(fromDate, 'DD-MM-YYYY') + ' to ' + convertUTCToLocalTime(toDate, 'DD-MM-YYYY')
    };

    const renderMobileBankMandate = (bankAccount) => {
        return (
            <div className='pb-3'>
                <div className='pt-4'
                     style={{minWidth: '120px'}}>
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        fontSize={'xs'}
                        text={'Bank Name'}
                        fontColor={'grayDark'}
                    />
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        text={renderBankName(bankAccount?.bankName, '', '', false)}
                    />
                </div>
                <div className='d-flex'>
                    <div className='pt-4'
                         style={{flex: 1}}>
                        <DLText
                            id={'-id'}
                            type={'normal'}
                            fontSize={'xs'}
                            text={'Account Number'}
                            fontColor={'grayDark'}
                        />
                        <DLText
                            id={'-id'}
                            type={'normal'}
                            text={bankAccount?.accountNumber}
                        />
                    </div>
                    <div className='pt-4'
                         style={{flex: 1}}>
                        <DLText
                            id={'-id'}
                            type={'normal'}
                            fontSize={'xs'}
                            text={'Status'}
                            fontColor={'grayDark'}
                        />
                        <DLText
                            id={'-id'}
                            type={'normal'}
                            text={toSentenceCase(bankAccount?.achMandateStatus)}
                        />
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='pt-4'
                         style={{flex: 1}}>
                        <DLText
                            id={'-id'}
                            type={'normal'}
                            fontSize={'xs'}
                            text={'Date Range'}
                            fontColor={'grayDark'}
                        />
                        <DLText
                            id={'-id'}
                            type={'normal'}
                            text={renderDateRange(bankAccount?.fromDate, bankAccount?.toDate)}
                        />
                    </div>
                    <div className='pt-4'
                         style={{flex: 1}}>
                        <DLText
                            id={'-id'}
                            type={'normal'}
                            fontSize={'xs'}
                            text={'Bank Mandate Amount'}
                            fontColor={'grayDark'}
                        />
                        <DLText
                            id={'-id'}
                            type={'normal'}
                            text={numberWithCommas(bankAccount?.amount)}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileBankAccount = (bankAccount) => {
        return (
            <div className={isMobileView ? '' :'p-3'}>
                <div className='pt-4'
                     style={{minWidth: '120px'}}>
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        fontSize={'xs'}
                        text={'Bank & Branch'}
                        fontColor={'grayDark'}
                    />
                    <span>
                        {renderBankName(bankAccount?.bankName, bankAccount?.branch, bankAccount?.IFSCCode, true, bankAccount?.defaultBank)}
                    </span>
                </div>
                <div className='d-flex'>
                    <div className='pt-4'
                         style={{flex: 1}}>
                        <DLText
                            id={'-id'}
                            type={'normal'}
                            fontSize={'xs'}
                            text={'Account Holder Name'}
                            fontColor={'grayDark'}
                        />
                        <DLText
                            id={'-id'}
                            type={'normal'}
                            text={bankAccount?.accountHolderName}
                        />
                    </div>
                    <div className='pt-4'
                         style={{flex: 1}}>
                        <DLText
                            id={'-id'}
                            type={'normal'}
                            fontSize={'xs'}
                            text={'Account Number'}
                            fontColor={'grayDark'}
                        />
                        <DLText
                            id={'-id'}
                            type={'normal'}
                            text={bankAccount?.accountNumber}
                        />
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='pt-4'
                         style={{flex: 1}}>
                        <DLText
                            id={'-id'}
                            type={'normal'}
                            fontSize={'xs'}
                            text={'Type'}
                            fontColor={'grayDark'}
                        />
                        <DLText
                            id={'-id'}
                            type={'normal'}
                            text={getAccountType(bankAccount?.bankAccountType)}
                        />
                    </div>
                    <div className='pt-4 pb-3'
                         style={{flex: 1}}>
                        <DLText
                            id={'-id'}
                            type={'normal'}
                            fontSize={'xs'}
                            text={'Status'}
                            fontColor={'grayDark'}
                        />
                        {getStatusWithDot(bankAccount?.status)}
                    </div>
                </div>

            </div>
        )
    };

    const getFormattedMandateTableData = (data) => {

        let tableData = [];
        const {isMobileView} = props;

        if (isEmpty(data)) {
            return tableData
        }
        data.map((bankAccount) => {
            let bankAccountDetails = [];
            let data = {};
            if (isMobileView) {
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: renderMobileBankMandate(bankAccount)
                });
            } else {
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: renderBankName(bankAccount?.bankName, '', '', false)
                });
                bankAccountDetails.push(bankAccount?.accountNumber);
                bankAccountDetails.push(
                    {
                        isCustomUI: true,
                        customUI: renderDateRange(bankAccount?.fromDate, bankAccount?.toDate)
                    }
                );
                bankAccountDetails.push(
                    {
                        isCustomUI: true,
                        customUI: renderBankMandateStatusBlock(toSentenceCase(bankAccount?.achMandateStatus))
                    }
                );
                bankAccountDetails.push(numberWithCommas(bankAccount?.amount));
            }

            data.id = '';
            data.data = bankAccountDetails;
            tableData.push(data)
        });

        return tableData

    };

    const renderBankAccountTable = () => {
        let tableData = getFormattedTableData();

        if(isEmpty(banks)){
          return (
                <div className='bg-white border border-rounded'>
                    <div className={'d-flex align-items-center justify-content-center empty-records px-3' + (isXSView ? ' text-center' : '')}
                         style={{maxHeight: '200px', minHeight: '200px'}}>
                        There is no bank account associated linked to this account
                    </div>
                </div>
            )
        }
        return (
            <div>
                <DLTable
                    id={'crm-desktop-table'}
                    isDynamicHeight={true}
                    isRowClickable={false}
                    isShowPagination={false}
                    headerData={isMobileView ? [] : headingAccountData}
                    tableData={tableData}
                    cellConfig={clientBankAccountCellConfig}
                />
            </div>
        )
    };

    const renderBankMandateTable = () => {

        const{getBankMandateData} = props;
        let data = getBankMandateData(bankMandates);

        let tableData = getFormattedMandateTableData(data);

        if(isEmpty(bankMandates)){
            return (
                <div className='bg-white border border-rounded'>
                    <div className={'d-flex align-items-center justify-content-center empty-records px-3' + (isXSView ? ' text-center' : '')}
                         style={{maxHeight: '200px', minHeight: '200px'}}>
                        There are no bank mandates available.
                    </div>
                </div>
            )
        }

        return (
            <DLTable
                id={'crm-desktop-table'}
                isDynamicHeight={true}
                isRowClickable={false}
                isShowPagination={false}
                headerData={isMobileView ? [] : headingMandateData}
                cellConfig={isLGView ? clientMandateSmallScreenCellConfig: clientMandateCellConfig}
                tableData={tableData}
            />
        )
    };

    const renderBankAccount = () => {

        return (
            <div className={''}>
                <div className='d-flex justify-content-between mb-3'>
                    <DLText id={'iin-form-id'}
                            text={"Bank Accounts"}
                            fontSize={"lg"}
                            marginBottom={'none'}
                            fontWeight={"semi-bold"}
                    />
                    {/*<DLButton*/}
                    {/*    id={'cancel-button'}*/}
                    {/*    styleType={"outlined"}*/}
                    {/*    borderType={'square'}*/}
                    {/*    buttonSize={'sm'}*/}
                    {/*    fontSize={'sm'}*/}
                    {/*    sideMargin={'none'}*/}
                    {/*    label={"Add Bank Account"}*/}
                    {/*/>*/}
                </div>
                {renderBankAccountTable()}
            </div>
        )
    };

    const renderBankMandate = () => {

        return (
            <div>
                <div className='d-flex justify-content-between mb-3'>
                    <DLText id={'iin-form-id'}
                            text={"Bank Mandate"}
                            fontSize={"lg"}
                            marginBottom={'none'}
                            fontWeight={"semi-bold"}
                    />
                    {/*<DLButton*/}
                    {/*    id={'cancel-button'}*/}
                    {/*    styleType={"outlined"}*/}
                    {/*    borderType={'square'}*/}
                    {/*    buttonSize={'sm'}*/}
                    {/*    fontSize={'sm'}*/}
                    {/*    sideMargin={'none'}*/}
                    {/*    label={"Add Bank Mandate"}*/}
                    {/*/>*/}
                </div>
                {renderBankMandateTable()}
            </div>
        )
    };

    const renderErrorUI = () => {
        const {respError} = props;

        if (isEmpty(respError)) {
            return '';
        }

        return (
            <div className='align-items-center d-flex message-block pt-3'>
                <div className='error-vertical-line'/>
                <div className='error-panel'>
                    {respError}
                </div>
            </div>
        );
    };
    return (
        <div className='page-container'
        style={{
            maxHeight: !isMobileView ? 'calc(100dvh - 152px)' : 'auto',
            minHeight:  !isMobileView ? 'calc(100dvh - 152px)' :'calc(100dvh - 170px)',
            overflow: "auto"
        }}>
            {renderErrorUI()}
            <div className={'py-4'}>
                {!isEmpty(UIState) && renderBankAccount()}
                <div className='pt-5'>
                    {!isEmpty(UIState) && renderBankMandate()}
                </div>
            </div>
            <DLLoader isVisible={isBankLoaderVisible || isEmpty(UIState)} type={'screen'}/>
        </div>
    );
}

export default BankAccountView;