import React from 'react';
import AvailableDocumnetListView from "./uploadDocumentsView";
import {connect} from "react-redux";

const uploadDocuments = (props) => {

    const documents = [
        'PAN Card',
        'Aadhaar Card',
        'Bank Proof (Signed Cancelled Cheque/Bank Statement/Passbook Front Page)',
        'Photograph'
    ];

    return (
        <AvailableDocumnetListView {...props}
                                   documents={documents} />);
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    profile: state.userState.profile,

});

export default connect(mapStateToProps, {})(uploadDocuments);
