import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLRadioGroupView from './DLRadioGroupView';


class DLRadioGroup extends Component {

    render() {

        if(this.props.hidden){
            return null;
        }

        return (<DLRadioGroupView {...this.props} />);
    }
}

DLRadioGroup.propTypes = {
    id: PropTypes.string.isRequired,  // for testing purpose
    type: PropTypes.oneOf(['inline', 'block']),
    radioType: PropTypes.oneOf(['single', 'multiple']),
    label: PropTypes.string.isRequired,
    error: PropTypes.bool,
    value: PropTypes.string,
    labelFontSize: PropTypes.string,
    labelFontColor: PropTypes.oneOf([
        'primary', 'secondary', 'tertiary', 'success', 'successLight', 'danger', 'darkBlue', 'lightBlue', 'grayLight', 'grayDark', 'gray', 'disable', 'black', 'white'
    ]),
    options: PropTypes.array.isRequired,
    isRequired: PropTypes.bool.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    enableTranslation: PropTypes.bool,
    fontSize: PropTypes.oneOf([
        'xs',       // 12px
        'sm',       // 14px
        'md',       // 16px
        'lg',       // 18px
        'xl',       // 20px
    ]),
    marginBottom: PropTypes.oneOf([
        'none',
        'xxs',
        'xs',
        'sm',
        'md',
    ]),
    hidden: PropTypes.bool,
    tooltipContent: PropTypes.any,   // any render div or view components
    helperMessage: PropTypes.string,
    helperMessageColor: PropTypes.oneOf([
        'primary', 'secondary', 'tertiary', 'success', 'successLight', 'danger', 'darkBlue', 'lightBlue', 'grayLight', 'grayDark', 'gray', 'disable', 'black', 'white'
    ]),
    fontWeight: PropTypes.string,
    disableHelperMessageHeight: PropTypes.bool,
    removePadding: PropTypes.bool,
    description: PropTypes.string,
    disableDescriptionMessageHeight: PropTypes.bool,
    maxCharLength: PropTypes.number,  // to limit the characters in the string
    hideCharLength: PropTypes.bool,   // to hide char length which shown on right bottom corner
    minHeight: PropTypes.string
};

DLRadioGroup.defaultProps = {
    radioType: 'multiple',
    removePadding: false,
    fontSize: 'sm',
    isRequired: false,
    type: 'inline',
    marginBottom: 'none',
    hidden: false,
    disableHelperMessageHeight: false,
    disableDescriptionMessageHeight: false,
    helperMessage:'',
    description: '',
    error: false,
    labelFontSize: 'xs',
    labelFontColor: 'grayDark',
    fontWeight: 'normal',
    helperMessageColor: 'grayDark',
    hideCharLength: true,
    maxCharLength: 100,
    minHeight: '36px'
};

export default DLRadioGroup;