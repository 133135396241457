import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'leadsReducer',
    initialState: {
        leadsFilter: {
            keyword: '',
            fromDate: '',
            toDate: '',
            dateKey:'',
            CRMId: 'ALL',
            status: ['NOT_CONTACTED'],
            skipCount: 0,
            limitCount: 50,
            page:0,
            rowsPerPage: 50
        },
        CRMDetails: []
    },
    reducers: {
        setSearchKeywordValue (state,action) {
            state.leadsFilter.keyword = action.payload;
        },

        setFromDateValue (state,action) {
            state.leadsFilter.fromDate = action.payload;
        },

        setToDateValue (state,action) {
            state.leadsFilter.toDate = action.payload;
        },

        setStatusValue (state,action) {
            state.leadsFilter.status = action.payload;
        },

        setCRMValue(state, action) {
            state.leadsFilter.CRMId = action.payload;
        },

        setSkipCountValue(state,action){
            state.leadsFilter.skipCount = action.payload;
        },

        setLimitCountValue(state,action){
            state.leadsFilter.limitCount = action.payload;
        },

        setPageValue(state,action){
            state.leadsFilter.page = action.payload;
        },

        setRowsPerPageValue(state,action){
            state.leadsFilter.rowsPerPage = action.payload;
        },

        setLeadDateKeyValue(state,action){
            state.leadsFilter.dateKey = action.payload;
        },

        resetAllFilters (state,action) {
            state.leadsFilter = {
                ...state.leadsFilter,
               keyword:'',
               fromDate:'',
               toDate:'',
                CRMId: 'ALL',
                dateKey:'',
               status:['NOT_CONTACTED'],
               skipCount: 0,
               page:0
            };
        },
        resetLeadFilter(state, action) {
            state.leadsFilter = {
                ...state.leadsFilter,
                fromDate:'',
                toDate:''
            };
        },
        setCRMDetails(state, action) {
            state.CRMDetails = action.payload;
        },

    }
});

export const {setStatusValue, setSearchKeywordValue, setFromDateValue, setToDateValue, resetAllFilters, setCRMValue,    resetLeadFilter,
    setRowsPerPageValue, setPageValue,setLimitCountValue,setSkipCountValue,setCRMDetails,setLeadDateKeyValue} = appSlice.actions;
export default appSlice.reducer

