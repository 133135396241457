import React from 'react';

import DLButton from "../../../core/components/DLButton/DLButton";
import DLDropDown from "../../../core/components/DLDropdown/DLDropDown";
import DLModal from "../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLText from "../../../core/components/DLText/DLText";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

const AssignCRMView = (props) => {

    const {
        history, isAssignCRMModalOpen, handleCloseAssignCRMModal, onChangeFormData,
        isApiInProgress, modalErrorMessage, errors, CRMs, assignCRM, selectedCRM, CRMOptions
    } = props;

    const {selectedCRMError} = errors;

    const renderModalError = () =>{
        if(modalErrorMessage){
            return(
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderAssignCRMModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"sm"}
                    onClose={() => handleCloseAssignCRMModal()}
                    open={isAssignCRMModalOpen}
                    id={'assign-crm-modal'}>
                    <DLModalTitle
                        id={'assign-crm-title'}
                        onClose={() => handleCloseAssignCRMModal()}
                        title={'Assign to CRM'}/>
                    <DLModalContainer
                        id={'assign-crm-container'}>
                        <div>
                            {renderModalError()}
                            <div className='my-4' style={{minHeight: '250px'}}>

                                <DLDropDown
                                    id={'crm-field'}
                                    label={'CRM'}
                                    noOptionsMessage={'No options found'}
                                    placeholder={''}
                                    marginBottom={"sm"}
                                    options={CRMOptions}
                                    onSelect={(val) => {
                                        onChangeFormData('selectedCRM', val)
                                    }}
                                    value={selectedCRM}
                                    isRequired={true}
                                    labelPlacement={'top'}
                                    minWidth={'100%'}
                                    error={selectedCRMError !== ''}
                                    helperMessage={selectedCRMError}
                                />
                            </div>
                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'crm-assign-action'}>
                        <div className='mb-4' style={{paddingTop: '7px'}}>
                            <DLButton
                                id={'assign-crm-cancelBtn'}
                                history={history}
                                label={'Cancel'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                styleType={"outlined"}
                                backgroundColor={"grey"}
                                sideMargin={"sm"}
                                onClick={() => handleCloseAssignCRMModal()}/>
                            <DLButton
                                id={'assign-crm-assignBtn'}
                                history={props.history}
                                label={'Assign'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                sideMargin={"sm"}
                                onClick={() => assignCRM()}
                            />
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    const overlayAssignCRM = () => {
        return (
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='p-3'>
                        <DLText
                            id={'select-account'}
                            fontSize={'md'}
                            marginBottom={"none"}
                            fontWeight={'semi-bold'}
                            text={'Assign to CRM'}
                        />
                    </div>
                    <div className='p-3'>
                        <i className="fa-solid fa-xmark cursor default-icon"
                           onClick={() => handleCloseAssignCRMModal()}/>
                    </div>
                </div>
                <div className='px-3'
                     style={{
                         maxHeight: 'calc(100dvh - 190px)',
                         overflow: 'auto',
                     }}>
                    <div className='pb-5'>
                        {renderModalError()}
                        <div className='my-4'>
                            <div>
                                <DLDropDown
                                    id={'crm-field'}
                                    label={'CRM'}
                                    noOptionsMessage={'No options found'}
                                    placeholder={''}
                                    marginBottom={"sm"}
                                    options={CRMs}
                                    onSelect={(val) => {
                                        onChangeFormData('selectedCRM', val)
                                    }}
                                    value={selectedCRM}
                                    isRequired={true}
                                    labelPlacement={'top'}
                                    minWidth={'100%'}
                                    error={selectedCRMError !== ''}
                                    helperMessage={selectedCRMError}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='fixed-bottom mb-2'>
                    <div className='mb-2 d-flex justify-content-center bg-white'
                         style={{paddingTop: '7px', minHeight: '43px'}}>
                        <DLButton
                            id={'assign-crm-cancelBtn'}
                            history={history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => handleCloseAssignCRMModal()}/>
                        <DLButton
                            id={'assign-crm-assignBtn'}
                            history={props.history}
                            label={'Assign'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            onClick={() => assignCRM()}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderLoader = () => {
        return (
            <DLLoader type={"screen"} isVisible={isApiInProgress}/>
        )
    }

    return (
        <div>
            {renderLoader()}
            {props.isOverlay ? overlayAssignCRM() : renderAssignCRMModal()}
        </div>
    );
};

export default AssignCRMView;