import React from 'react';
import UserDetailsBar from "../../../core/components/userDetailsBar/UserDetailsBar";
import PersonalProfile from "../personalProfile/PersonalProfile";
import DocumentUpload from "../documents/DocumentUpload";
import BankAccount from "../bankAccount/BankAccount";
import FinancialProfile from "../financialProfile/FinancialProfile";
import Stepper from "../../../core/components/stepper/Stepper";
import NomineeList from "../nominee/NomineeList";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

const OnboardingWizardView = (props) => {
    const {stepperSteps, isMobileView, isShowLoader , isFromOnboardingFlow, handleClickSaveAndClose} = props;

    const renderFormsUI = () => {
        const {
            handleClickNext, getScreenKey, getSubScreenKey, screenDetails, selectedScreenObj,
            totalApplicants, isProceedWithErrors, getUplodedDocuments, saveFailureErrorMessage
        } = props;

        const screenKey = getScreenKey();
        const subScreenKey = getSubScreenKey();

        switch (screenKey) {
            case 'UPLOAD_DOCUMENTS':
                return <DocumentUpload
                    history={props.history}
                    handleClickNext={handleClickNext}
                    subScreenKey={subScreenKey}
                    totalApplicants={totalApplicants}
                    selectedScreenObj={selectedScreenObj}
                    screenDetails={screenDetails}
                    isFromOnboardingFlow={isFromOnboardingFlow}
                    isProceedWithErrors={isProceedWithErrors}
                    getUplodedDocuments={getUplodedDocuments}
                    handleClickSaveAndClose={handleClickSaveAndClose}
                    saveFailureErrorMessage={saveFailureErrorMessage}
                />;

            case 'PERSONAL_PROFILE':
                return <PersonalProfile
                    history={props.history}
                    isFromOnboardingFlow={isFromOnboardingFlow}
                    handleClickNext={handleClickNext}
                    selectedScreenObj={selectedScreenObj}
                    totalApplicants={totalApplicants}
                    subScreenKey={subScreenKey}
                    isProceedWithErrors={isProceedWithErrors}
                    handleClickSaveAndClose={handleClickSaveAndClose}
                    saveFailureErrorMessage={saveFailureErrorMessage}
                />;

            case 'FINANCIAL_PROFILE':
                return <FinancialProfile
                    isFromOnboardingFlow={isFromOnboardingFlow}
                    handleClickNext={handleClickNext}
                    subScreenKey={subScreenKey}
                    isProceedWithErrors={isProceedWithErrors}
                    handleClickSaveAndClose={handleClickSaveAndClose}
                    saveFailureErrorMessage={saveFailureErrorMessage}
                />;

            case 'BANK_ACCOUNT':
                return <BankAccount
                    isFromOnboardingFlow={isFromOnboardingFlow}
                    handleClickNext={handleClickNext}
                    subScreenKey={subScreenKey}
                    isProceedWithErrors={isProceedWithErrors}
                    handleClickSaveAndClose={handleClickSaveAndClose}
                    saveFailureErrorMessage={saveFailureErrorMessage}
                />;

            case 'NOMINEE':
                return <NomineeList
                    isFromOnboardingFlow={isFromOnboardingFlow}
                    history={props.history}
                    subScreenKey={subScreenKey}
                    handleClickSaveAndClose={handleClickSaveAndClose}
                    saveFailureErrorMessage={saveFailureErrorMessage}
                />;
        }
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderMobileUI = () => {
        const {setProceedWithError, isProceedWithErrors, getScreenKey} = props;
        const screenKey = getScreenKey();

        return (
            <div className='h-100'>
                {renderLoader()}
                <UserDetailsBar
                    {...props}
                    setProceedWithError={setProceedWithError}
                    isProceedWithErrors={isProceedWithErrors}/>
                <div style={{height: isMobileView ? '20px' : '0px'}}>
                    <div className={(isMobileView ? '' : 'mx-1 ') + ('d-flex align-items-center justify-content-between')}>
                        <div style={{minWidth: '100%'}}>
                            <Stepper
                                {...props}
                                stepperSteps={stepperSteps}
                            />
                        </div>
                    </div>
                </div>
                <div className={'bg-white w-100 ' + (screenKey === 'UPLOAD_DOCUMENTS' ? '' : 'pt-4')}>
                    {renderFormsUI()}
                </div>
            </div>
        );
    };
    const renderDesktopUI = () => {
        const {setProceedWithError, isProceedWithErrors} = props;

        return (
            <div className={'h-100 bg-white'}>
                {renderLoader()}
                <UserDetailsBar
                    {...props}
                    titleLabel={'Onboarding'}
                    setProceedWithError={setProceedWithError}
                    isProceedWithErrors={isProceedWithErrors}
                />

                <div className='bg-light'>
                    <div>
                        <div className='bg-white border-rounded'>
                            <div style={{height: isMobileView ? '20px' : ''}}>
                                <div className='me-1 d-flex align-items-center justify-content-between'>
                                    <Stepper
                                        {...props}
                                        stepperSteps={stepperSteps}
                                    />
                                </div>
                            </div>
                            <div className='bg-light w-100'>
                                {renderFormsUI()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <div className={isMobileView ? 'h-100' : ''}>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}
        </div>

    );
};

export default OnboardingWizardView;