import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLButtonView from './DLButtonView';

class DLButton extends Component {

    render() {
        const {hidden} = this.props;
        if (hidden) {
            return null;
        }
        return (<DLButtonView
          {...this.props}
        />);
    }
}

DLButton.propTypes = {
    id: PropTypes.string.isRequired,  // for testing purpose
    label: PropTypes.any,
    type: PropTypes.oneOf([
        'primary',      // uses primary color for the button
        'secondary',    // uses secondary color for the button
        'danger',       // uses red color for the button
        'icon',          // only icon showed on single button , start icon is required for this type
        'inherit',        // added for funding page button types, button color will be light grey.
        'black',
        'darkBlue'
    ]),
    styleType: PropTypes.oneOf([
        'outlined',     // outline color with selected type
        'contained',    //  filled with color of selected type
    ]),
    sideMargin: PropTypes.oneOf([
        'none',
        'xs',
        'sm',
        'md',
        'lg',
        'xl'
    ]),
    buttonSize: PropTypes.oneOf([
        'none',
        'xs',
        'sm',
        'md',
        'lg',
        'xl'
    ]),
    borderType: PropTypes.oneOf([
        'square',
        'rounded'
    ]),
    fontSize: PropTypes.oneOf([
        'xs',
        'sm',
        'md',
        'lg',
        'xl',
        'xxl',
        'xlg',
    ]),
    onClick: PropTypes.func,
    startIcon: PropTypes.element,
    tooltip: PropTypes.string,
    hidden: PropTypes.bool,
    isDisabled: PropTypes.bool,
    reduceWidth: PropTypes.bool,
    style: PropTypes.any,
    padding: PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl']),
    isLoaderButton:PropTypes.bool,
    isApiInProgress:PropTypes.bool,
    color:PropTypes.string
};

DLButton.defaultProps = {
    type: 'darkBlue',
    styleType: 'contained',
    sideMargin: 'sm',
    // borderType: 'square',
    isDisabled: false,
    padding: 'sm',
    reduceWidth: false,
    btnType: null,
    isLoaderButton:false,
    isApiInProgress:false,
    color:'#ffffff',
    fontSize:'sm',
    buttonSize:'sm',
}

export default DLButton;
