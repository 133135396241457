import React from 'react';
import DLModal from "../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLText from "../../../core/components/DLText/DLText";

const AccountRemove = (props) => {

    const renderRemoveModal = () => {
        const {isOpenRemoveModal, handleCloseRemoveModal, history, handleRemovePANDetails, removePANNumber} = props;

        return (
            <DLModal
                history={history}
                maxWidth={"xs"}
                onClose={() => handleCloseRemoveModal()}
                open={isOpenRemoveModal}
                id={'remove-holder-modal'}>
                <DLModalTitle
                    id={'remove-holder-title'}
                    onClose={() => handleCloseRemoveModal()}
                    title={'Confirmation'}/>
                <DLModalContainer id={'remove-holder-container'}>
                    <div className='d-flex justify-content-center align-items-center' style={{minHeight: '100px'}}>
                        <div>
                            <div className='text-center'>
                                <DLText id={'remove-text'}
                                        text={'Are you sure you want to remove the '}/>
                            </div>
                            <div className='text-center'>
                                <DLText id={'acc-holder-text'}
                                        text={'Account Holder?'}
                                        fontWeight={'semi-bold'}/>
                            </div>
                        </div>
                    </div>
                </DLModalContainer>

                <DLModalActions id='remove-holder-action'>
                    <div className='mb-2' style={{paddingTop: '7px', minHeight: '43px'}}>
                        <DLButton
                            id={'remove-holder-cancelBtn'}
                            history={history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => handleCloseRemoveModal()}/>
                        <DLButton
                            id={'remove-holder-saveBtn'}
                            history={props.history}
                            label={'Remove'}
                            buttonSize={"sm"}
                            type={'danger'}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            onClick={() => {
                                handleRemovePANDetails(removePANNumber)
                            }}/>
                    </div>
                </DLModalActions>
            </DLModal>
        );
    };

    return (
        <div>
            {renderRemoveModal()}
        </div>
    );
};

export default AccountRemove;