import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {APP_CONST} from "../../../AppComponents/AppConstants";
import {isEmpty} from "../DLComponentHelper";

import { getLeadHistoryData} from "../../../onboarding/components/formsAndReview/FormsAndReviewActions";
import HistoryBlockView from "./HistoryBlockView";

const HistoryBlock = (props) => {

    const [leadHistoryState, setLeadHistoryState] = useState({
        events: [],
        isShowLoader: false,
        UIState: '',
        responseError: ''
    });
    const { advisoryUser,CRMDetails, leadId,profile,handleHistoryBlockRefresh,isHistoryBlockRefresh, isFrom, handleIsContacted} = props;

    useEffect(() => {

        if(isFrom === "LISTING"){
            getData();
            return;
        }

       if (!isEmpty(leadId) && isHistoryBlockRefresh)
       {
           handleHistoryBlockRefresh(false);
           getData();
       }

    }, [props.leadId,isHistoryBlockRefresh]);

    const getIsContacted = (event) => {
        return event.some(item => item.type === "CONTACTED");
    };

    const getData = () => {
        setLeadHistoryState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));

        getLeadHistoryData(leadId)
            .then(res => {
                if (res?.success) {
                    setLeadHistoryState((preState) => ({
                            ...preState,
                            events: isEmpty(res?.data?.events) ? [] : res?.data?.events,
                            isShowLoader: false,
                            UIState: APP_CONST.CONTENT_AVAILABLE,
                        }
                    ));
                    if(!isEmpty(res?.data?.events))
                    {
                        getIsContacted(res?.data?.events) && handleIsContacted();
                    }
                } else {
                    setLeadHistoryState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        responseError: res.__error
                    }));
                }
            })
    };

    const getAdvisoryUser = (id) => {
        let usersName = '';
        if(!isEmpty(advisoryUser)) {
            usersName = advisoryUser.find((item) => {
                if (item?.id === id) {
                    return item;
                }
            });
        }
        if (isEmpty(usersName)) {
            return '-';
        } else {
            let firstName = isEmpty(usersName?.firstName) ? '' : usersName?.firstName + ' ';
            let lastName = isEmpty(usersName?.lastName) ? '' : usersName?.lastName + ' ';
            return firstName + lastName;
        }
    };

    const getSentenceCase = (str) => {
        switch(str) {
            case 'HOLD':
                return 'Hold';
            case 'LOST':
                return 'Lost';
            case 'CONTACTED':
                return 'Contacted';
            case 'NOT_CONTACTED':
                return 'Not contacted';
            case 'CREATED':
                return 'Created';
            case 'REQUESTED':
                return 'Requested';
            case 'COMPLETED':
                return 'Completed';
            case 'ASSIGNED':
                return 'Assigned';
            case 'ONBOARDING_INITIATED':
                return 'Onboarding Initiated';
            case 'ADDED_REMARK':
                return 'Remark';
            default:
                return str;
        }
    };

    return (
        <div className='bg-white border-rounded'>
          <HistoryBlockView
              {...props}
              {...leadHistoryState}
              getAdvisoryUser={getAdvisoryUser}
              getSentenceCase={getSentenceCase}
          />
        </div>
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    CRMDetails: state.leadState.CRMDetails,
});
export default connect(mapStateToProps, {})(HistoryBlock);
