export const headingData = ['Account', 'Category', 'Ticket Number', 'Type of Request', 'Open Date', 'Status'];

export const headingDataForManagement = ['Account', 'Category', 'Ticket Number', 'Type of Request', 'Open Date', 'Assigned CRM','Status'];

export const cellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '300px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '140px'
    }
];

export const cellConfigForManagement = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '260px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '140px'
    }
];

export const statusFilterOptions = [
    {label: 'Open', value: 'OPEN'},
    {label: 'In Progress', value: 'IN_PROGRESS'},
    {label: 'Completed', value: 'COMPLETED'},
];

export const monthDropdownOptionsMobileView = [
    {label: 'This Month', value: 'THIS_MONTH'},
    {label: 'This Quarter', value: 'THIS_QUARTER'},
    {label: 'This Year', value: 'THIS_YEAR'}
];