import React from 'react';
import FTRequestListForCRM from "./FTRequestListForCRM/FTRequestListForCRM";
import FTRequestListForManagement from "./FTRequestListForManagement/FTRequestListForManagement";

const FTRequestListView = (props) => {

    const {isSetUserType, isManagementUser} = props;
    if (!isSetUserType) {
        return ''
    }
    if(isManagementUser){
        return (
            <div className='w-100'>
                <FTRequestListForManagement {...props} />
            </div>
        );
    }else{
        return (
            <div className='w-100'>
                <FTRequestListForCRM {...props} />
            </div>
        );
    }

};

export default FTRequestListView;