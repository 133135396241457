//noinspection DuplicatedCode,JSUnresolvedVariable,SpellCheckingInspection,JSUnresolvedFunction
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLInputTextFieldView from './DLInputTextFieldView';
import {isEmpty, debounce} from "./../DLComponentHelper";


class DLInputTextField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      localValue: props.value,
      selectionStart:''
    };
    this.myRef = React.createRef();
    this.onChange = this.onChange.bind(this);
  }

  updateSelectionStart = () =>{
    const {inputRef} = this.state;
    let cursorPositionValue = this.myRef.current.selectionStart;

    if(this.props?.handleLength){
      this.props.handleLength(cursorPositionValue)
    }

   };


  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.props.value !== prevProps.value) && (this.state.localValue !== this.props.value)) {
      this.setState({
        localValue: this.props.value,
      });
    }
  }

  onLocalValueChange = (value) => {

    if (this.props.isTrimValue) {
      if (isEmpty(value)) {
        value = "";
      } else {
        value = value?.trim();
      }
    }

    this.setState({
      localValue: value,
    }, () => {
      this.onChange(this.state.localValue);
    });
  }

  onChange = debounce((value) => {
    this.props.onChangeValue(value);
  }, this.props.debounceTimer);


  render() {

    const {localValue} = this.state;

    if (this.props.hidden) {
      return null;
    }

    return (
      <>
        <DLInputTextFieldView
          {...this.props}
          value={localValue}
          onChangeValue={this.onLocalValueChange}
          updateSelectionStart={this.updateSelectionStart}
          inputRef={this.myRef}
          // debounceTimer={null}
        />
      </>
    );
  }
}

DLInputTextField.propTypes = {
  id: PropTypes.string.isRequired,  // for testing purpose
  label: PropTypes.any.isRequired,  // label for the textfield
  placeholder: PropTypes.string,  // placeholder for the textfield
  value: PropTypes.any,  // value of the textfield
  isRequired: PropTypes.bool,         // is required or not will give * in label
  onChangeValue: PropTypes.func.isRequired, // function will pass a new value only
  isClearable: PropTypes.bool,  // to show a cross at the end of textfield
  isDisabled: PropTypes.bool,   // to show the input field as disable
  rows: PropTypes.number,       // to expand the number of row will act as textfield
  maxRows: PropTypes.number,    // max number of lines will expand, beyond that it will get a scroll bar
  maxCharLength: PropTypes.number,  // to limit the characters in the string
  error: PropTypes.bool,           // to show in red color
  helperMessage: PropTypes.any,  // to show text below the textfield
  style: PropTypes.object,
  isMulti: PropTypes.bool,
  inputType: PropTypes.oneOf([
    'text',              // normal text input
    'number',             // will only allow number input , in RN it will open number keypad only
    'email',              // will allow email type input
    'password',           // not to show the password text
  ]),
  styleType: PropTypes.oneOf([
    'rounded',              // to show the outline as rounded border
    'normal',               // to show the outline as squared
    'outline',
    'search'//to add outline
  ]), // to show different wrap ui
  debounceTimer: PropTypes.number,    // will give the debounce of this value default value is 300
  autoFocus: PropTypes.bool,
  minNumber: PropTypes.number,   // applicable for input type number only user can put min value of min number
  maxNumber: PropTypes.number,    // applicable for input type number only for max number value
  hideBorder: PropTypes.bool,
  fontSize: PropTypes.oneOf([
    'xxs', 'xs', 'sm', 'md', 'lg', 'xl'
  ]),
  inputHeight: PropTypes.oneOf([
    'xs', 'sm', 'md', 'lg', 'xl'
  ]),
  nativeEndIcon: PropTypes.shape({
    iconName: PropTypes.string.isRequired,  // any fontAwesome 5 icon name
    onPress: PropTypes.func,
    iconSize: PropTypes.number,
    iconColor: PropTypes.string
  }),        // used for native only, icon at the end of input field
  nativeStartIcon: PropTypes.shape({
    iconName: PropTypes.string.isRequired,  // any fontAwesome 5 icon name
    onPress: PropTypes.func,
    iconSize: PropTypes.number,
    iconColor: PropTypes.string
  }),        // used for native only, icon at the start of input field
  marginBottom: PropTypes.oneOf([
    'none',
    'xxs',
    'xs',
    'sm',
    'md',
  ]),
  hidden: PropTypes.bool,
  isHandleKeypadClick: PropTypes.bool,
  onCut: PropTypes.func,
  onPaste: PropTypes.func,
  onCopy: PropTypes.func,
  hideCharLength: PropTypes.bool,   // to hide char length which shown on right bottom corner
  disableHelperMessageHeight: PropTypes.bool,    // to disable the min height for input text field if helper message passed it will expand in bottom
  onKeyPress: PropTypes.func,
  handleLength: PropTypes.func,
  onEnter: PropTypes.func,
  labelFontSize: PropTypes.string,
  labelFontColor: PropTypes.oneOf([
    'primary', 'secondary', 'tertiary', 'success', 'successLight', 'danger', 'darkBlue', 'lightBlue', 'grayLight', 'grayDark', 'gray', 'disable', 'black', 'white'
  ]),
};
DLInputTextField.defaultProps = {
  inputType: 'text',
  styleType: 'rounded',
  debounceTimer: 300,
  placeholder: '',
  isRequired: false,
  label: "",
  isGroupRulesField: false,
  marginBottom: 'sm',
  hidden: false,
  isClearable: true,
  hideCharLength: true,
  helperMessage: '',
  disableHelperMessageHeight: false,
  error: false,
  isMulti: false,
  isHandleKeypadClick: false,
  fontSize:'sm',
  labelFontSize:'xs',
  labelFontColor: 'grayDark',
  inputHeight:'sm'
};


export default DLInputTextField;

