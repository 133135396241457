import React from 'react';
import appTheme from "../../../assets/appTheme";
import DLText from "../../../core/components/DLText/DLText";
import ClientCategory from "./components/ClientCategory";
import ClientAccountType from "./components/ClientAccountType";
import OnboardingAgeing from "./components/OnboardingAgeing";
import MyTotalLeads from "./components/MyTotalLeads";
import LeadsAgeing from "./components/LeadsAgeing";
import OnboardingCategory from "./components/OnboardingCategory";
import OnboardingAccountType from "./components/OnboardingAccountType";
import OnboardingStatus from "./components/OnboardingStatus";

const CRMDashboardView=(props)=> {
    const {isXSView, isSMView} = props;
    const renderClientData = () => {
        return (
            <div className={isXSView ? 'w-100' : ' m-0 row p-0 '}>
                <div className={`col-md-6 col-lg-4  p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView) ? ' mb-3' : ' ')}>
                    <ClientCategory
                        {...props}
                    />
                </div>
                <div className={`col-md-6 col-lg-4  p-0  ${isXSView ? 'p-0' : 'px-2'}`}>
                    <ClientAccountType
                        {...props}
                    />
                </div>
            </div>
        )
    };

    const renderClientWidgets = () => {
        return (
            <div className={isXSView ? 'mb-2' : 'border-rounded panel p-0'}>
                <div className="px-0 h-100">
                    <div className={isXSView ? '' : "p-2 ps-3 border-bottom rounded-top"}
                         style={{backgroundColor: isXSView ? '' : appTheme.appColor.lightBlue}}>
                        <DLText id={'Leads'}
                                isInline={true}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                text={'Clients'}/>

                    </div>
                    <div className={isXSView ? 'pt-3' : 'py-3 px-2  '}>
                        {renderClientData()}
                    </div>
                </div>
            </div>
        )
    };

    const renderOnboardingData = () => {
        return (
            <div>
                <div className={isXSView ? 'w-100' : ' m-0 row p-0 '}>
                    <div className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView) ? ' mb-3' : ' ')}>
                        <OnboardingStatus
                            {...props}
                        />
                    </div>
                    <div className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView) ? ' mb-3 pb-0' : ' ')}>
                        <OnboardingAgeing
                            {...props}
                        />
                    </div>

                </div>
                <div className={isXSView ? 'w-100' : ' m-0 row p-0 ' + ((!isSMView) ? ' pt-3' : ' ')}>
                    <div className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView) ? ' mb-3' : ' ')}>
                        <OnboardingCategory
                            {...props}
                        />
                    </div>
                    <div className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}`}>
                        <OnboardingAccountType
                            {...props}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderOnboardingWidgets = () => {
        return (
            <div className={isXSView ? 'border-bottom mb-2' : 'border-rounded panel p-0 section-tabs-gap p-0'}>
                <div className="px-0 h-100">
                    <div className={isXSView ? '' : "p-2 ps-3 border-bottom rounded-top"}
                         style={{backgroundColor: isXSView ? '' : appTheme.appColor.lightBlue}}>
                        <DLText id={'Onboarding-Widgets'}
                                isInline={true}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                text={'Onboardings'}/>

                    </div>
                    <div className={isXSView ? 'pt-3' : 'py-3 px-2  '}>
                        {renderOnboardingData()}
                    </div>
                </div>
            </div>
        )
    };

    const renderLeadData = () => {
        return (
            <div className={isXSView ? 'w-100' : ' m-0 row p-0 '}>
                <div className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView) ? ' mb-3' : ' ')}>
                    <MyTotalLeads
                        {...props}
                    />
                </div>
                <div className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}`}>
                    <LeadsAgeing
                        {...props}
                    />
                </div>
                <div className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}`}>
                    {/*<UnassignedLeads*/}
                    {/*    {...props}*/}
                    {/*/>*/}
                </div>
            </div>
        )
    };

    const renderLeadWidgets = () => {
        return (
            <div className={isXSView ? 'border-bottom mb-2 pt-3' : 'border-rounded panel p-0 section-tabs-gap p-0'}>
                <div className="px-0 h-100">
                    <div className={isXSView ? '' : "p-2 ps-3 border-bottom rounded-top "}
                         style={{backgroundColor: isXSView ? '' : appTheme.appColor.lightBlue}}>
                        <DLText id={'Leads'}
                                isInline={true}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                text={'Leads'}/>

                    </div>
                    <div className={isXSView ? 'pt-3' : 'py-3 px-2  '}>
                        {renderLeadData()}
                    </div>
                </div>
            </div>
        )
    };
    return (
        <div className='page-container' >
            {renderLeadWidgets()}
            {renderOnboardingWidgets()}
            {renderClientWidgets()}
        </div>
    )
};

export default CRMDashboardView;