//noinspection DuplicatedCode,JSUnresolvedVariable,SpellCheckingInspection,JSUnresolvedFunction
import React, {Component} from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {getFontFamily, getFontSize, getTheme, isEmpty} from "../DLComponentHelper";
import '../../../assets/css/dlComponent.css';
import {getHeight} from "../DLComponentHelperWeb";
import "../../../assets/css/designToken.css";

let sideMargins = {
    none: 'mx-0',
    sm: 'mx-1',
    md: 'mx-2',
};


class DLButtonView extends Component {

    handleClick = (event) => {
        const {onClick, linkToProps, linkTo, history} = this.props;
        //activate on first click only to avoid hiding again on multiple clicks
        if (!event.detail || event.detail === 1) {
            // code here. // It will execute only once on multiple clicks
            if (onClick) {
                onClick();
                return;
            }
            // history.push(linkTo, linkToProps);
            history.push({
                pathname: linkTo,
                ...linkToProps
            })
        } else {
            console.info('button tapped multiple times ');
        }
    };

    getButtonContainerStyle = () => {
        const {isLoaderButton, padding} = this.props;

        if (isLoaderButton) {
            return
        }

        let paddingHorizontal;

        switch (padding) {
            case 'none':
                paddingHorizontal =0;
                break;
            case 'xs':
                paddingHorizontal = '5px';
                break;
            case 'sm':
                paddingHorizontal = '10px';
                break;
            case 'md':
                paddingHorizontal = '15px';
                break;
            case 'lg':
                paddingHorizontal = '20px';
                break;
            case 'xl':
                paddingHorizontal = '25px';
                break;
            default :
                paddingHorizontal = '10px';
                break;
        }

        return {
            paddingLeft: paddingHorizontal,
            paddingRight: paddingHorizontal
        }

    };

    render() {

        const {type, label, borderType, buttonSize, reduceWidth, isDisabled, tooltip, startIcon, styleType, id, fullWidth, style, fontSize, sideMargin, btnType, color, isApiInProgress, isLoaderButton} = this.props;
        let backgroundColor = "";
        let localBorderType = borderType;
        let theme = getTheme();

        if (isEmpty(borderType)) {
            if (isEmpty(theme?.default?.btnBorderType)) {
                localBorderType = 'square'
            } else {
                localBorderType = theme?.default?.btnBorderType
            }
        }

        switch (type) {
            case 'primary':
                backgroundColor = theme?.appColor?.primary;
                break;
            case 'secondary':
                backgroundColor = theme?.appColor?.secondary;
                break;
            case 'tertiary':
                backgroundColor = theme?.appColor?.tertiary;
                break;
            case 'success':
                backgroundColor = theme?.appColor?.success;
                break;
            case 'successLight':
                backgroundColor = theme?.appColor?.successLight;
                break;
            case 'danger':
                backgroundColor = theme?.appColor?.danger;
                break;
            case 'darkBlue':
                backgroundColor = theme?.appColor?.darkBlue;
                break;
            case 'grayLight':
                backgroundColor = theme?.appColor?.grayLight;
                break;
            case 'grayDark':
                backgroundColor = theme?.appColor?.grayDark;
                break;
            case 'gray':
                backgroundColor = theme?.appColor?.gray;
                break;
            case 'disable':
                backgroundColor = theme?.appColor?.disable;
                break;
            case 'white':
                backgroundColor = theme?.appColor?.white;
                break;
            default:
                backgroundColor = theme?.appColor?.black;
        }

        let localColor = (styleType === 'outlined') ? 'var(--primary)' : 'var(--white)';
        let localBackground = (styleType === 'outlined') ? 'transparent' : backgroundColor;
        let localBorderColor = (styleType === 'outlined') ? 'var(--primary)' : 'var(--white)';

        if (isDisabled) {
            localColor = '#00000042';
            localBackground = (styleType === 'outlined') ? 'var(--white)' : '#0000001f';
            localBorderColor = (styleType === 'outlined') ? '#0000001f' : 'var(--white)';
        }


        let buttonTheme = {
            backgroundColor: localBackground,
            color: localColor,
            minHeight: getHeight(buttonSize),
            ...getFontSize(fontSize),
            fontWeight: buttonSize === 'sm' ? 'var(--font-style-normal)' : 'var(--font-weight-semibold)',
            borderRadius: localBorderType === 'rounded' ? '25px' : '8px',
            textTransform: 'none',
            minWidth: reduceWidth ? '48px' : '100px',
            // boxShadow:'none',
            fontFamily: getFontFamily(),
            borderColor: localBorderColor,
        };

        if (isLoaderButton) {
            buttonTheme.padding = 0
        }

        if (isEmpty(style)) {
            // do nothing
        } else {
            buttonTheme = {
                ...buttonTheme,
                ...style
            }
        }

        if (type === 'icon') {
            return (
                <Button
                    id={id + '-button'}
                    key={id + '-button' + isDisabled} // isDisable added in key since button not updating in safari
                    size={'small'}
                    variant={'outlined'}
                    onClick={(btnType === 'submit') ? null : this.handleClick}
                    style={{...buttonTheme, minWidth: '36px'}}
                >
                    <div className='d-flex'>
                        {startIcon}
                    </div>
                </Button>
            );
        }


        let icon;

        if (startIcon && !isEmpty(label)) {
            icon = (<span className='mr-1'> {startIcon}</span>)
        } else {
            icon = (<span> {startIcon}</span>)
        }

        if (type === 'danger') {
            return (
                <Tooltip title={isEmpty(tooltip) ? '' : tooltip}>
                    <Button
                        id={id + '-button'}
                        key={id + '-button' + isDisabled}  // isDisable added in key since button not updating in safari
                        size={'small'}
                        className={sideMargins[sideMargin]}
                        variant={styleType}
                        // color={type}
                        onClick={this.handleClick}
                        style={buttonTheme}
                        disabled={isDisabled}>
                        {icon} {label}
                    </Button>
                </Tooltip>
            )
        }


        return (
            <Tooltip title={isEmpty(tooltip) ? '' : tooltip}>
                <Button
                    id={id + '-button'}
                    key={id + '-button' + isDisabled} // isDisable added in key since button not updating in safari
                    size={'small'}
                    className={sideMargins[sideMargin]}
                    variant={styleType}
                    // color={type}
                    onClick={(btnType === 'submit') ? null : this.handleClick}
                    disabled={isDisabled}
                    fullWidth={fullWidth}
                    style={buttonTheme}
                    type={btnType}
                >
                    <div className='d-flex' style={{...this.getButtonContainerStyle()}}>

                        {icon}
                        <div className='d-flex justify-content-center align-items-center'>
                            <div style={{paddingLeft: isLoaderButton ? 30 : 0, fontWeight: 'var(--font-weight-semibold)'}}>
                                {label}
                            </div>
                            {isLoaderButton &&
                            <div style={{
                                textAlign: 'center',
                                paddingLeft: '8px',
                                width: isLoaderButton ? 30 : 0,
                            }}>
                                {isApiInProgress &&
                                <div className="btn-loader"
                                     style={{
                                         borderTop: '2px solid ' + color,
                                         borderRight: '2px solid ' + color,
                                         borderBottom: '2px solid transparent',
                                         borderLeft: '2px solid ' + color,
                                     }}
                                />
                                }
                            </div>
                            }

                        </div>
                    </div>
                </Button>
            </Tooltip>
        );

    }
}

export default DLButtonView;
