import React, {useEffect} from 'react';

import DLButton from "../../../core/components/DLButton/DLButton";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import DLDropDown from "../../../core/components/DLDropdown/DLDropDown";
import DLText from "../../../core/components/DLText/DLText";
import DLRadioGroup from "../../../core/components/DLRadioGroup/DLRadioGroup";
import DLSearchAutoComplete from "../../../core/components/DLSearchAutoComplete/DLSearchAutoComplete";
import {searchOnWordBoundary} from "../../../utils/helper";
import {isEmpty} from "../../../utils/validations";
import {debounce} from "../../../core/components/DLComponentHelper";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

const LeadCreateView = (props) => {

    const {
        history, handleCloseCreateModal, handleCreate, onChangeFormData, name, email,
        phone, referredBy, source, getMappedSourceValues, onBlurInputText, onEnterKeyPress,
        modalErrorMessage, errors, dropdownValues, city, residentialStatusOptions, entityType, country, category, isXSView, scrollToFieldId, isApiInProgress
    } = props;

    const {
        nameError, emailError, phoneError, referredByError, sourceError, cityError, countryError, categoryError,
        residentialStatusError, entityTypeError, remarkError
    } = errors;

    useEffect(() => {
        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);
            if (!isEmpty(element)) {
                    element.scrollIntoView({behavior: 'smooth', block: 'center'});
                handleScrollChangeWithDebounce()
            }
        }
        return () => {
            window.scrollTo(0, 0)
        }
    }, [props.scrollToFieldId]);

    const handleScrollChangeWithDebounce = debounce(() => {
        props.onChangeFormData('scrollToFieldId', '');
    }, 500);


    const renderModalError = () => {
        if (modalErrorMessage) {
            return (
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderButton = () => {
        return (
            <div className="sticky-bottom-buttons gap-0">
                <DLButton
                    id={'create-lead-cancelBtn'}
                    history={history}
                    label={'Cancel'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => props.history.push("/leads")}/>
                <DLButton
                    id={'create-lead-saveBtn'}
                    history={props.history}
                    label={'Create'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    sideMargin={"sm"}
                    onClick={() => handleCreate()}
                />
            </div>
        )
    };

    const renderDesktopHeader = () => {
        const {isMobileView}=props;
        return (
            <div className='w-100'>
                <div className={isXSView ? 'ps-3 page-header p-0 bg-white' : 'page-header d-flex justify-content-between'}>
                    <div className="d-flex align-items-center">
                            Create Lead
                    </div>

                </div>
            </div>
        )
    };

    const renderDesktopLeadData = () => {
        return (
            <div className=" "
                 style={{maxHeight: 'calc(100dvh - 154px)', minHeight: 'calc(100dvh - 154px)', overflow: "auto"}}>
                <div className="bg-white row border-rounded p-3 m-0">
                    <div className="col-lg-6 ">
                        <form onKeyPress={(event) => onEnterKeyPress(event)}>
                            {renderModalError()}
                            <div className='row'
                                 id={'name-field'}   >
                                <div className='col-12 p-0'>
                                    <DLInputTextField
                                        id={'first-name-field'}
                                        label={'Name'}
                                        value={name}
                                        marginBottom={"sm"}
                                        inputType={"text"}
                                        disableHelperMessageHeight={true}
                                        hideCharLength={true}
                                        isClearable={false}
                                        isRequired={true}
                                        maxCharLength={31}
                                        error={nameError !== ''}
                                        helperMessage={nameError}
                                        onChangeValue={(val) => {
                                            onChangeFormData('name', val)
                                        }}
                                        onBlur={() => {
                                            onBlurInputText('name')
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className={isXSView ? 'p-0' : 'col-sm-6 ps-0' }
                                     id={'phone-field'}>
                                    <DLInputTextField
                                        id={'phone-field'}
                                        label={'Phone'}
                                        value={phone}
                                        marginBottom={"sm"}
                                        inputType={"number"}
                                        disableHelperMessageHeight={true}
                                        hideCharLength={true}
                                        isClearable={false}
                                        isRequired={true}
                                        maxCharLength={10}
                                        error={phoneError !== ''}
                                        helperMessage={phoneError}
                                        onChangeValue={(val) => {
                                            onChangeFormData('phone', val)
                                        }}
                                        onBlur={() => {
                                            onBlurInputText('phone')
                                        }}
                                    />
                                </div>
                                <div className={isXSView ? 'p-0' : 'col-sm-6 pe-0'} id={'email-field'}>
                                    <DLInputTextField
                                        id={'emailField'}
                                        label={'Email'}
                                        value={email}
                                        marginBottom={"sm"}
                                        inputType={"text"}
                                        disableHelperMessageHeight={true}
                                        hideCharLength={true}
                                        isClearable={false}
                                        isRequired={true}
                                        maxCharLength={51}
                                        error={emailError !== ''}
                                        helperMessage={emailError}
                                        onChangeValue={(val) => {
                                            onChangeFormData('email', val)
                                        }}
                                        onBlur={() => {
                                            onBlurInputText('email')
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className={isXSView ? 'p-0' : 'col-sm-6 ps-0'}
                                     id={'city-field'}>
                                    <DLInputTextField id={'city-input-field'}
                                                      label={'City'}
                                                      value={city}
                                                      inputType={'text'}
                                                      maxCharLength={30}
                                                      isClearable={false}
                                                      isRequired={true}
                                                      error={cityError !== ''}
                                                      helperMessage={cityError}
                                                      disableHelperMessageHeight={true}
                                                      marginBottom={'sm'}
                                                      onChangeValue={(e) => {
                                                          onChangeFormData('city', e)
                                                      }}
                                                      onBlur={() => {
                                                          onBlurInputText('city')
                                                      }}
                                    />
                                </div>
                                <div className={isXSView ? 'p-0' : 'col-6 pe-0'}
                                     id={'country-field'}>
                                    <DLSearchAutoComplete id={'country-input-field'}
                                                          value={country}
                                                          label={'Country'}
                                                          onSelect={(val) => {
                                                              onChangeFormData('country', val)
                                                          }}
                                                          loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.countryOptions)}
                                                          isClearable={false}
                                                          isSearchable={true}
                                                          itemNotFoundText={'No options found'}
                                                          marginBottom={'sm'}
                                                          isMulti={false}
                                                          isRequired={true}
                                                          error={countryError !== ''}
                                                          disableHelperMessageHeight={true}
                                                          helperMessage={countryError}
                                                          defaultOptions={dropdownValues?.countryOptions}
                                                          noOptionsMessage={'No options found'}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className={isXSView ? 'p-0' : 'col-sm-6 ps-0'}
                                     id={'category-field'}>
                                    <DLSearchAutoComplete id={'category-input-field'}
                                                          value={category}
                                                          label={'Category'}
                                                          onSelect={(val) => {
                                                              onChangeFormData('category', val)
                                                          }}
                                                          loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.categoryOptions)}
                                                          labelFontColor={categoryError !== '' ? 'danger' : 'grayDark'}
                                                          isClearable={false}
                                                          isSearchable={false}
                                                          itemNotFoundText={'No options found'}
                                                          marginBottom={'sm'}
                                                          isMulti={false}
                                                          isRequired={true}
                                                          error={categoryError !== ''}
                                                          disableHelperMessageHeight={category === ''}
                                                          helperMessage={categoryError}
                                                          defaultOptions={dropdownValues?.categoryOptions}
                                                          noOptionsMessage={'No options found'}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className={isXSView ? 'p-0' : 'col-12 p-0'}
                                     id={'residentialStatus-field'}>
                                    <div>
                                        <DLText
                                            id={'residential-status'}
                                            type={'normal'}
                                            text={'Residential Status'}
                                            fontColor={residentialStatusError !== '' ? 'danger' : 'grayDark'}
                                            fontSize={'xs'}
                                        />
                                    </div>
                                    <div id='residential-status-optionsd' className='ps-2 d-flex'>
                                        <DLRadioGroup
                                            id={'hours-radioBtn'}
                                            label={''}
                                            type={'inline'}
                                            radioType={'multiple'}
                                            options={dropdownValues.residentialStatusOptions}
                                            value={residentialStatusOptions}
                                            disableHelperMessageHeight
                                            disableDescriptionMessageHeight
                                            marginBottom={'xs'}
                                            helperMessageColor={'danger'}
                                            helperMessage={residentialStatusError}
                                            error={residentialStatusOptions !== ''}
                                            isRequired={true}
                                            onChangeValue={(e) => {
                                                onChangeFormData('residentialStatus', e)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className={isXSView ? 'p-0' : 'col-12 p-0'}
                                     id={'entityType-field'}>
                                    <div>
                                        <DLText
                                            id={'entity-type'}
                                            type={'normal'}
                                            text={'Entity Type'}
                                            fontColor={entityTypeError !== '' ? 'danger' : 'grayDark'}
                                            fontSize={'xs'}
                                        />
                                    </div>
                                    <div id='entity-type-options' className='ps-2 d-flex'>
                                        <DLRadioGroup
                                            id={'hours-radioBtn'}
                                            label={''}
                                            type={'inline'}
                                            radioType={'multiple'}
                                            options={dropdownValues.entityTypeStatusOptions}
                                            value={entityType}
                                            disableHelperMessageHeight
                                            disableDescriptionMessageHeight
                                            marginBottom={'xs'}
                                            helperMessageColor={'danger'}
                                            helperMessage={entityTypeError}
                                            error={entityType !== ''}
                                            isRequired={true}
                                            onChangeValue={(e) => {
                                                onChangeFormData('entityType', e)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className={isXSView ? 'p-0' : 'col-6 ps-0'}
                                     id={'source-field'}>
                                    <div>
                                        <DLDropDown
                                            id={'sourceField'}
                                            label={'Source'}
                                            noOptionsMessage={'No options found'}
                                            placeholder={''}
                                            marginBottom={"sm"}
                                            options={getMappedSourceValues()}
                                            onSelect={(val) => {
                                                onChangeFormData('source', val)
                                            }}
                                            value={source}
                                            isRequired={true}
                                            labelPlacement={'top'}
                                            minWidth={'100%'}
                                            disableHelperMessageHeight={true}
                                            error={sourceError !== ''}
                                            helperMessage={sourceError}
                                        />
                                    </div>
                                </div>
                                <div className={isXSView ? 'p-0' : 'col-6 pe-0'}
                                     id={'referredBy-field'}>
                                    <DLInputTextField
                                        id={'referred-by-field'}
                                        label={'Referred By'}
                                        isRequired={true}
                                        value={referredBy}
                                        marginBottom={"sm"}
                                        inputType={"text"}
                                        disableHelperMessageHeight={true}
                                        hideCharLength={true}
                                        isClearable={false}
                                        maxCharLength={31}
                                        error={referredByError !== ''}
                                        helperMessage={referredByError}
                                        onChangeValue={(val) => {
                                            onChangeFormData('referredBy', val)
                                        }}
                                        onBlur={() => {
                                            onBlurInputText('referredBy')
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12 p-0 '
                                     id={'remark-field'}>
                                    <DLInputTextField
                                        id={'remark'}
                                        label={'Remark'}
                                        value={''}
                                        marginBottom={"none"}
                                        inputType={"text"}
                                        isRequired={true}
                                        isMulti={true}
                                        rows={5}
                                        maxRows={5}
                                        disableHelperMessageHeight={true}
                                        error={remarkError !== ""}
                                        helperMessage={remarkError}
                                        hideCharLength={true}
                                        isClearable={false}
                                        maxCharLength={500}
                                        onChangeValue={(value) => {
                                            onChangeFormData("remark", value)
                                        }}
                                        onBlur={() => {

                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>)
    };

    const renderDesktopUI = () => {
        return (
            <div className={isXSView ? '':'lead-details-container '}>
                {renderDesktopHeader()}
                <div className={isXSView ? 'p-0' : 'lead-page-container page-container bg-light mx-3 '}>
                    {renderDesktopLeadData()}
                </div>
                {renderButton()}
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isApiInProgress}/>);
    };

    return (
        <div >
            {renderDesktopUI()}
            {renderLoader()}
        </div>
    );
};

export default LeadCreateView;