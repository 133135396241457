import React, {} from 'react';
import {connect} from "react-redux";

import NFTListForCRM from "./NFTListForCRM/NFTListForCRM";
import NFTListForManagement from "./NFTListForManagement/NFTListForManagement";


const NFTListView = (props) => {
        const {isSetUserType, isManagementUser} = props;
        if (!isSetUserType) {
            return ''
        }
        if(isManagementUser){
            return (
                <div className='w-100'>
                    <NFTListForManagement {...props} />
                </div>
            );
        }else{
            return (
                <div className='w-100'>
                    <NFTListForCRM {...props} />
                </div>
            );
        }
    }
;

const mapStateToProps = (state) => ({
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(NFTListView);