import React, {Component} from 'react';
import DialogActions from '@mui/material/DialogActions';

class DLModalActionsView extends Component {
  render() {
    const {children} = this.props;
    return (
        <DialogActions className='justify-content-center mb-2'>
          {children}
        </DialogActions>
    );
  }
}

export default DLModalActionsView;
