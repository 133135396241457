import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../AppComponents/AppConstants";

import {assignCRMToLeads} from "../../../onboarding/OnboardingActions";

import AssignCRMView from "./AssignCRMView";


const AssignCRM = (props) => {

    const{onSaveSuccess, selectedIds, CRMs} = props;

    const [assignCRMState, setAssignCRMState] = useState({
        selectedCRM: '',
        CRMOptions : [],
        isApiInProgress: false,
        handleErrorInComponent:true,
        modalErrorMessage:'',

        errors:{
            selectedCRMError:''
        }
    });

    useEffect(() => {
        setCRMOptions()
    }, []);

    const setCRMOptions =()=>{
        let options = [];
        CRMs.forEach((item)=>{
            if(item.value !== "ALL" && item.value !== "UNASSIGNED"){
                options.push(item)
            }
        })
        setAssignCRMState((prevStep) => ({
            ...prevStep,
            CRMOptions: options,
        }));
    }

    const validateChanges = (name, compState) => {
        const {selectedCRM} = compState;
        let errorObj = {...assignCRMState.errors};

        if (name === "selectedCRM" || name === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(selectedCRM)) {
                errorObj.selectedCRMError = "Please select CRM";
            } else {
                errorObj.selectedCRMError = "";
            }

            if (name === 'selectedCRM') {
                return errorObj;
            }
        }

        return errorObj;
    };

    const clearData = () => {
        const {handleCloseAssignCRMModal} = props;
        handleCloseAssignCRMModal();
        setAssignCRMState((prevStep) => ({
            ...prevStep,
            selectedCRM: [],
        }));
    };

    const onChangeFormData = (name, value) => {
        let updatedState = {...assignCRMState};
        updatedState[name] = value;
        updatedState[name+'Error'] = '';

        const validateObject = validateChanges(name, updatedState);

        setAssignCRMState(prevStep => ({
            ...prevStep,
            ...updatedState,
            modalErrorMessage: '',
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));
    };

    const checkIfValidateObj=(validateObj)=>{
        const {selectedCRMError} = validateObj;
        if (selectedCRMError) {
            // If any mandatory error is present (non-empty), return false
            return false;
        }
        // If no mandatory errors are present, return true
        return true;
    };

    const assignCRM = () => {
        const {selectedCRM} = assignCRMState;

        const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION, assignCRMState);

        if (!checkIfValidateObj(validateObj)) {
            setAssignCRMState(prevStep => ({
                ...prevStep,
                isApiInProgress: false,
                errors:{
                    ...prevStep.errors,
                    ...validateObj
                }
            }));
            return;
        }

        let payload = {
            leadRequestIds: selectedIds,
            CRMId: selectedCRM
        };

        setAssignCRMState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));

        assignCRMToLeads(payload)
            .then((res) => {
                if (res.success) {
                    clearData();
                    onSaveSuccess();
                    setAssignCRMState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false
                    }));
                } else {
                    setAssignCRMState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false,
                        modalErrorMessage:res.__error
                    }));
                }
            }
        );
    };

    return (
        <AssignCRMView
            {...props}
            {...assignCRMState}
            assignCRM={assignCRM}
            onChangeFormData={onChangeFormData}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(AssignCRM);
