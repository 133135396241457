import React from 'react';
import {connect} from "react-redux";
import CRMDashboardView from "./CRMDashboardView";

const CRMDashboard=(props)=> {


    return (
        <CRMDashboardView
            {...props}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isSMView: state.appState.deviceInfo.isSMView,
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(CRMDashboard);