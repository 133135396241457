import React from 'react';
import DLModal from "../../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLText from "../../../../../core/components/DLText/DLText";
import DLModalActions from "../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLButton from "../../../../../core/components/DLButton/DLButton";

const ConfirmationModel = (props) => {
    const {isOpenConfirmationModal, handleConfirmationModal, responseError, history, handleMarkedAsCompleted} = props;
    const renderModalError = () => {
        if (responseError) {
            return (
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            )
        }
    };
    return (
        <DLModal
            history={history}
            maxWidth={"xs"}
            onClose={() => handleConfirmationModal(false)}
            open={isOpenConfirmationModal}
            id={'confirmation-modal'}>
            <DLModalTitle
                id={'confirmation-title'}
                onClose={() => handleConfirmationModal(false)}
                title={'Confirmation'}/>
            <DLModalContainer id={'confirmation-container'}>
                {renderModalError()}
                <div className='d-flex justify-content-center align-items-center' style={{minHeight: '100px'}}>
                    <div>
                        <div className='text-center'>
                            <DLText id={'remove-text'}
                                    text={'Are you sure you want to mark this request as completed?'}/>
                        </div>
                    </div>
                </div>
            </DLModalContainer>

            <DLModalActions id='confirmation-action'>
                <div className='mb-2' style={{paddingTop: '7px', minHeight: '43px'}}>
                    <DLButton
                        id={'confirmation-cancelBtn'}
                        history={history}
                        label={'Cancel'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => handleConfirmationModal(false)}/>
                    <DLButton
                        id={'confirmation-saveBtn'}
                        history={props.history}
                        label={'Mark as Complete'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        sideMargin={"sm"}
                        onClick={() => {
                            handleMarkedAsCompleted()
                        }}/>
                </div>
            </DLModalActions>
        </DLModal>
    );
};

export default ConfirmationModel;