import React, {useEffect, useState} from 'react';
import LeadsView from "./LeadsView";
import {connect} from "react-redux";


const Leads = (props) => {

    const [leadsState, setLeadsState] = useState({
        isManagementUser: false,
        isSetUserType: false
    });

    useEffect(() => {
        const {profile} = props;
        setLeadsState((prevState) => ({
            ...prevState,
            isManagementUser: profile?.isManagement,
            isSetUserType: true
        }))
    }, []);

    return (
        <div>
            <LeadsView
                {...props}
                {...leadsState}
            />
        </div>
    );
};
const mapStateToProps = (state) => ({
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(Leads);