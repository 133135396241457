import React, {useEffect} from 'react';
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Dropdown from "react-bootstrap/Dropdown";
import Paper from "@mui/material/Paper";

import DLText from "../../../core/components/DLText/DLText";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import {isEmpty} from "../../../utils/validations";
import {debounce, getRandomId} from "../../../core/components/DLComponentHelper";
import DLButton from "../../../core/components/DLButton/DLButton";
import appTheme from "../../../assets/appTheme";

const RaiseRequestView = (props) => {

    const {
        isXSView,
        isOptionSelected,
        errors,
        keyword,
        handleSearch,
        clients,
        getAccountType,
        handleClick,
        accountHolders,
        accountType,
        IINNumber,
        category,
        setCategoryValues,
        handleChangeClient,
        handleSubmit,
        capitalizeFirstLetter,
        modalErrorMessage,
        scrollToFieldId,
        onChangeFormData,
        onBlurInputText
    } = props;

    useEffect(() => {
        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);
            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                handleScrollChangeWithDebounce()
            }
        }
        return () => {
            window.scrollTo(0, 0)
        }
    }, [props.scrollToFieldId]);

    const handleScrollChangeWithDebounce = debounce(() => {
        props.onChangeFormData('scrollToFieldId', '');
    }, 500);

    const renderDesktopHeader = () => {
        return (
            <div className='w-100'>
                <div
                    className={isXSView ? 'ps-3 page-header p-0 bg-white' : 'page-header d-flex justify-content-between'}>
                    <div className="d-flex align-items-center">
                        <i className='fa-solid fa-chevron-left cursor fa-2xs'
                           style={{width: 10}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className="ps-2">
                            Raise a Request
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    const renderAccountHolders = (accountHolders) => {
        if (isEmpty(accountHolders)) {
            return '-';
        }
        return (
            <div className='d-flex'>
                {
                    accountHolders.map((item, index) => {
                        return (
                            <div className='d-flex' key={getRandomId()}>
                                <div>
                                    <DLText id={''}
                                            text={capitalizeFirstLetter(item?.fullName)}
                                            fontWeight={"normal"}
                                            isInline={true}
                                            fontSize={"xs"}
                                            fontColor={"grayDark"}
                                    />
                                </div>
                                {index <= (accountHolders?.length - 2) &&
                                    <div className='me-1'>
                                        <DLText id={''}
                                                text={','}
                                                isInline={true}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                                fontColor={"grayDark"}
                                        />
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const getUi = () => {
        if (isEmpty(clients)) {
            return (
                <div className="w-100" style={{height: 'calc(100vh - 555px)'}}>
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <DLText id={""}
                                text={"No match found."}
                                fontColor={"gray"}
                                marginBottom={"none"}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div>
                {!isEmpty(clients) && <div>
                    {clients.map((item, index) => {
                        return (
                            <Dropdown.Item onClick={() => handleClick(item)} key={getRandomId()}>
                                <div>
                                    <div className={index === 0 ? "p-2" : "p-2 border-top"}>
                                        <div>
                                            <DLText id={""}
                                                    text={getAccountType(item?.accountType) + ' - '}
                                                    fontWeight={"normal"}
                                                    fontSize={"xs"}
                                                    isInline={true}
                                                    marginBottom={"none"}
                                            />
                                            <DLText id={""}
                                                    text={item?.IINNumber}
                                                    fontWeight={"normal"}
                                                    fontSize={"xs"}
                                                    isInline={true}
                                                    marginBottom={"none"}
                                            />
                                        </div>
                                        {renderAccountHolders(item?.accountHolders)}
                                    </div>
                                </div>
                            </Dropdown.Item>
                        )
                    })}
                </div>}
            </div>
        );
    }

    const renderSearchBar = () => {
        return (
            <div className='d-flex justify-content-center align-items-center'>
                <div className={"w-100"} style={{ position: 'relative' }}>
                    <div className="resappbar-search-box-container" style={{flex: 1}}>
                        <Dropdown className='w-100 p-0' style={{borderColor: errors.IINNumber !== '' ? appTheme.appColor.danger : ""}}>
                            <Dropdown.Toggle id="" className='py-1 '>
                                <div className="w-100"
                                     style={{color: "var(--gray-light)"}}
                                >
                                    <Paper
                                        className="res-app-paper"
                                        component=""
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            boxShadow: "none",
                                            p: 0
                                        }}
                                    >
                                        <InputBase
                                            sx={{flex: 1, p: 0, fontSize: '14px', fontWeight: 500}}
                                            fullWidth
                                            onChange={(e) => handleSearch(e.target.value)}
                                            autoFocus={true}
                                            placeholder={"Search by Name"}
                                            value={keyword}
                                        />
                                        {
                                            <SearchIcon style={{color: errors.IINNumber !== '' ? appTheme.appColor.danger : ""}}/>
                                        }
                                    </Paper>
                                </div>
                            </Dropdown.Toggle>
                        </Dropdown>
                    </div>
                    {(!isEmpty(keyword) && !isOptionSelected) &&
                        <div className='resappbar-search-box-container-options mt-1' style={{ position: 'absolute', zIndex: 10, width: '100%' }}>
                            <div style={{maxHeight: 'calc(100vh - 555px)', overflowY: "auto"}}>
                                <div>
                                    {getUi()}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    };

    const renderAccounts = () => {
        return (
            <div>
                <div className='row'>
                    <div className='col-12 mb-4' id={'name-field'}>
                        <div className='mb-4'>
                            <div className={'d-flex'}>
                                <div className='section-title-vertical-bar'/>
                                <div className='ps-3'>
                                    <div className='d-flex'>
                                        <DLText id={''}
                                                text={getAccountType(accountType) + '- '}
                                                fontSize={"md"}
                                                fontWeight={"semi-bold"}
                                                isInline={true}
                                        />
                                        <DLText id={''}
                                                text={IINNumber}
                                                fontSize={"md"}
                                                fontWeight={"semi-bold"}
                                                isInline={true}
                                        />
                                        <div className='ps-3 d-flex align-items-center justify-content-center'>
                                            <DLText id={'change-client'}
                                                    isClickable={true}
                                                    text={"Change Client"}
                                                    fontColor={"lightBlue"}
                                                    onClick={() => {
                                                        handleChangeClient()
                                                    }}
                                            />
                                        </div>
                                    </div>
                                    <DLText id={''}
                                            text={setCategoryValues(category)}
                                            fontSize={"sm"}
                                            fontWeight={"semi-bold"}
                                            fontColor={"grayDark"}
                                            marginBottom={"none"}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            accountHolders.map((item, index) => {
                                return (
                                    <div className='mb-2 ps-4 ms-1' key={index}>
                                        <DLText id={''}
                                                text={'Account Holder ' + (index + 1) + ' - '}
                                                fontSize={"sm"}
                                                fontWeight={"semi-bold"}
                                                isInline={true}
                                        />
                                        <DLText id={''}
                                                text={capitalizeFirstLetter(item?.fullName)}
                                                fontSize={"sm"}
                                                isInline={true}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    const renderButton = () => {
        return (
            <div className="sticky-bottom-buttons gap-0">
                <DLButton
                    id={'raise-request-cancelBtn'}
                    history={history}
                    label={'Cancel'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => props.history.push("/client-requests")}/>
                <DLButton
                    id={'create-lead-saveBtn'}
                    history={props.history}
                    label={'Submit'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    sideMargin={"sm"}
                    onClick={() => handleSubmit()}
                />
            </div>
        )
    };

    const renderModalError = () => {
        if (modalErrorMessage) {
            return (
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderRaiseRequestForm = () => {
        return (
            <div className=" "
                 style={{maxHeight: 'calc(100dvh - 154px)', minHeight: 'calc(100dvh - 154px)', overflow: "auto"}}>
                <div className="bg-white row border-rounded p-3 m-0">
                    <div className="col-lg-6">
                        {renderModalError()}
                        <div className='row'
                             id={'name-field'}>
                            {!isOptionSelected && <div className='mb-3'>
                                <div className='col-12' id={'search-field'}>
                                    <DLText id={''}
                                            text={'Client'}
                                            fontColor={errors.IINNumber !== "" ? "danger" : "grayDark"}
                                            fontWeight={"normal"}
                                            fontSize={"xs"}
                                    />
                                    {renderSearchBar()}
                                    {errors.IINNumber !== '' &&
                                        <div className='ps-2'>
                                            <DLText id={''}
                                                    text={'Please Select Client'}
                                                    fontColor={"danger"}
                                                    fontSize={"xs"}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>}
                            {isOptionSelected &&
                                <div>
                                    {renderAccounts()}
                                </div>}
                            <div>
                                <div className='col-sm-12 p-0 pt-2'
                                     id={'remark-field'}>
                                    <DLInputTextField
                                        id={'remark'}
                                        label={'Remarks'}
                                        value={''}
                                        marginBottom={"none"}
                                        inputType={"text"}
                                        isRequired={true}
                                        isMulti={true}
                                        rows={5}
                                        maxRows={5}
                                        disableHelperMessageHeight={true}
                                        error={errors.remark !== ''}
                                        helperMessage={errors.remark !== '' && errors.remark}
                                        hideCharLength={true}
                                        isClearable={false}
                                        maxCharLength={501}
                                        onChangeValue={(value) => {
                                            onChangeFormData('remark', value)
                                        }}
                                        onBlur={() => {
                                            onBlurInputText('remark')
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderDesktopUI = () => {
        return (
            <div className={isXSView ? '' : 'lead-details-container '}>
                {renderDesktopHeader()}
                <div className={isXSView ? 'p-0' : 'lead-page-container page-container bg-light mx-3 '}>
                    {renderRaiseRequestForm()}
                </div>
                {renderButton()}
            </div>
        )
    }

    return (
        <div>
            {renderDesktopUI()}
        </div>
    );
};

export default RaiseRequestView;