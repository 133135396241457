import React, {useEffect, useState} from 'react';
import PhotographView from "./PhotographView";
import {connect} from "react-redux";
import {getDocAssociateData} from "../../../OnboardingActions";
import {isEmpty} from "../../../../core/components/DLComponentHelper";

const Photograph = (props) => {
    const [photographState, setPhotographState] = useState({
        responseErrors: [],
        imageFilePaths: [],
        isLoaderVisible: false,
        totalErrors: 0,
        document: {
            documentType: 'PHOTOGRAPH',
            documentCategory: 'PHOTOGRAPH',
            originalFileName: '',
            uploadedFileName: '',
            filePath: '',
            fileName: '',
            photographError: ''
        }
    });


    useEffect(() => {
        const {selectedScreenObj} = props;
        props.getUplodedDocuments(selectedScreenObj?.screenKey, selectedScreenObj?.personId);
    }, [props.selectedScreenObj]);

    useEffect(() => {
        let docData = mapDocumentData(props.selectedScreenObj);
        if (!isEmpty(docData)) {
            setPhotographState(prevState => ({
                ...prevState,
                document: docData,
            }));
        }
    }, [props.selectedScreenObj.documentTypes]);

    const mapDocumentData = (selectedScreenObj) => {
        let selectedDocumentTypes = selectedScreenObj.documentTypes;
        let {isFromOnboardingFlow} = props;

        let docData = {
            documentType: 'PHOTOGRAPH',
            documentCategory: 'PHOTOGRAPH',
            originalFileName: '',
            uploadedFileName: '',
            filePath: '',
            fileName: '',
            photographError: ''
        };

        if (!isEmpty(selectedDocumentTypes)) {
            //get PAN document using id from documentTypes
            let photographDocument = selectedDocumentTypes.find((document) => document.id === 'PHOTOGRAPH');

            if (!isEmpty(photographDocument.documents)) {
                let documents = photographDocument.documents[0];
                docData.filePath = isEmpty(documents.imageFilePaths) ? '' : documents.imageFilePaths[0];
                docData.fileName = documents.fileName;
                docData.id = documents.id;
                if(isEmpty(documents)){
                    docData.photographError = isFromOnboardingFlow ? '' :'Please upload photograph'
                }
            }
            if(isEmpty(photographDocument.documents[0])){
                docData.photographError = isFromOnboardingFlow ? '' : 'Please upload photograph'
            }
        }
        return docData
    };

    const associateDocument = (type, response) => {
        const {selectedScreenObj, onboardingApplicationId} = props;
        const {document} = photographState;

        if(type === 'ERROR'){
            setPhotographState(prevStep => ({
                ...prevStep,
                document: {
                    ...document,
                    photographError: response
                }
            }));
            return;
        }

        if (type === 'DELETE') {
            setPhotographState(prevStep => ({
                ...prevStep,
                document: {
                    ...document,
                    filePath: '',
                    photographError: ''
                },
                responseErrors: [],
                totalErrors: 0,
            }));
            return;
        }

        setPhotographState(prevStep => ({
            ...prevStep,
            isLoaderVisible: true,
        }));

        const associatePayload = {
            type: document.documentType,
            personId: selectedScreenObj?.personId,
            documentRequirementId: selectedScreenObj?.documentRequirementId,
            originalFileName: response?.originalFileName,
            uploadedFileName: response?.uploadedFileName,
            onboardingApplicationId: onboardingApplicationId
        };

        getDocAssociateData(associatePayload)
            .then((res) => {
                if (res.success) {

                    setPhotographState(prevStep => ({
                        ...prevStep,
                        document: {
                            ...document,
                            id: res.data?.id,
                            filePath: isEmpty(res.data.imageFilePaths) ? '' : res.data.imageFilePaths[0],
                            fileName: res.data?.fileName,
                            photographError: '',
                        },
                        isLoaderVisible: false,
                        responseErrors: [],
                        totalErrors: 0,
                    }));
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                    }
                    setPhotographState(prevStep => ({
                        ...prevStep,
                        document: {
                            ...document,
                            photographError: '',
                            filePath: '',
                            id: ''
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                    }));
                }
            })
    };

    const validatePhoto = () => {
        const {document} = photographState;
        if (isEmpty(document.filePath)) {
            setPhotographState(prevStep => ({
                ...prevStep,
                document: {
                    ...document,
                    photographError: 'Please upload photograph'
                },
                isLoaderVisible: false,
                responseErrors: [],
                totalErrors: 0,
            }));
            return false;
        }
        return true
    };

    const onNextButtonClick = () => {
        const {handleClickNext, isProceedWithErrors} = props;

        setPhotographState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));

        const validateObject = validatePhoto();
        if (validateObject || isProceedWithErrors) {
            handleClickNext();

            setPhotographState(prevState => ({
                ...prevState,
                isLoaderVisible: false
            }));

        }
    };

    const onSaveAndCloseClick = () => {
        const {isProceedWithErrors, selectedScreenObj, handleClickSaveAndClose, saveFailureErrorMessage} = props;
        setPhotographState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));
        handleClickSaveAndClose();
        setPhotographState(prevState => ({
            ...prevState,
            isLoaderVisible: false
        }));

    };

    return (
        <PhotographView {...props}
                        {...photographState}
                        onNextButtonClick={onNextButtonClick}
                        associateDocument={associateDocument}
                        onSaveAndCloseClick={onSaveAndCloseClick}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    accountId: state.onboardingFlowState.accountId,
    onboardingApplicationId: state.onboardingFlowState.onboardingApplicationId,
});

export default connect(mapStateToProps, {})(Photograph);