
import {getAppEnvironmentVariables} from "./helperPlatform";
import {getAuthorization, getAuthorizationToken} from "./httpHelper";
import {showSystemErrorBlock, showToastMessage} from "../AppComponents/AppActions";
import store from "../store"
import {setNotAccessible, userLoggedOut} from "../user/userReducer";
const API_URL = process.env.REACT_APP_API_URL;

export const handleFileError = (uploadFileStatus, fileSize, fileLimit) => {

    if (uploadFileStatus === 'error_file_size') {
        let _size = fileSize;
        let sizeType = ['Bytes', 'KB', 'MB', 'GB'],
            i = 0;
        while (_size > 900) {
            _size /= 1024;
            i++;
        }

        let exactSize = (Math.round(_size * 100) / 100) + ' ' + sizeType[i];

        store.dispatch(showToastMessage('warning', 'You have exceeded maximum size of ' + exactSize))
    } else if (uploadFileStatus === 'rejected_max_files') {
        if (fileLimit < 2) {
            return ('You can upload only ' + fileLimit + ' file at a time.')
        } else {
            return ('You can upload only ' + fileLimit + ' file\'s at a time.')
        }
    } else if (uploadFileStatus === 'rejected_file_type') {
        return ('This file format is not supported. Please check file again.')
    }
};


const performFileUpload = async (binaryFile, API_url) =>  {
    let statusCode;
    let config = getAppEnvironmentVariables();
    let auth = await getAuthorizationToken();
    let url = config.REACT_APP_API_URL + API_url;
    return fetch(url,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + auth.token
            },
            body: binaryFile,
        })
        .then(response =>{
            statusCode=response.status;
            return response.json()
        } )
        .then((res) => {
            if (res) {
                if (statusCode === 200) {
                    return {
                        success:true,
                        ...res
                    }
                }
                if (statusCode >= 300 && statusCode < 500){
                    store.dispatch( showToastMessage('warning',res.__error));
                    return null;
                }
            }
        })
};

export async function uploadData(data, url){
    let res = {};
    let files = data;
    if (files) {
        let newFile = files;
        let formData = new FormData();
        formData.append('file', newFile);
        let filePathVal;
        if (typeof files === 'string') {
            filePathVal = files;
        } else {
            filePathVal = await performFileUpload(formData, url);
        }

        if (filePathVal) {
            res = filePathVal;
        } else {
            res = false;
        }
    }

    return res;
}

export const uploadEmailFiles2 = (b64Data, fileName, url) => {
    console.log('fileName',fileName)
    let newFile = new File([b64Data], fileName, {"type": b64Data.type});
    let formData = new FormData();
    let statusCode;
    formData.append('file',newFile, fileName);
    let URL = API_URL + url;
    return fetch(URL, {
        method: 'POST',
        headers :{
            ...getAuthorization(),
        },
        body: formData
    })
        .then(response => {
            // capture the status code if the response to json conversion call breaks
            statusCode = response.status;
            if (response.status === 204) {
                return response
            } else {
                return response.json()
            }
        })
        .then(data => {
            if (statusCode === 200 || statusCode === 201) {
                return data
            }

            if (statusCode === 204) {
                return ''
            }

            if (statusCode >= 400 && statusCode < 500) {
                if (statusCode === 401) {
                    store.dispatch(userLoggedOut())
                } else {
                    if (statusCode === 403) {
                        store.dispatch(setNotAccessible())
                    } else {
                        store.dispatch(showToastMessage('warning', data.__error))
                    }
                }
                return data
            }

            if (statusCode >= 500) {
                store.dispatch(showSystemErrorBlock());
                return ''
            }
            // return result
            // editor.AssetManager.add('https://poc.unicom.deccanlogic.com/upload/' + result.imagePath);
        })
        .catch(error => {
            console.error('Error:', error);
        });
};

export const downloadS3BucketFile = (url, fileName) => {
    return fetch(API_URL + url,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAuthorizationToken().token
            }
        })
        .then((response) => {
            if (response) {
                return response.blob()
                    .then(blob => {
                        switch (response?.status) {
                            case 200:
                            case 204:
                                if (!blob) {
                                    return {success: false};
                                }
                                return {
                                    blobData: window.URL.createObjectURL(blob),
                                    success: true
                                };
                            case 409:
                            case 403:
                            case 401:
                            case 400:
                                store.dispatch(showToastMessage('danger', response.statusText));
                                break;
                            case 500:
                                store.dispatch(showSystemErrorBlock());
                                break;
                            default:
                                break;
                        }
                    });
            }
        })
};


