import React, {useEffect, useState} from 'react';
import DLDocUploaderView from "./DlDocUploaderView"
import {disAssociateDocument, downloadFile, updateDocument} from "../../../onboarding/OnboardingActions";
import PropTypes from "prop-types";
import DLLoader from "../DLLoader/DLLoader";
import DLDocumentUploader from "../DLDocumentUploader/DLDocumentUploader";
import {isEmpty} from "../../../utils/validations";
import {connect} from "react-redux";

const DLDocUploader = (props) => {

    const [DLDocUploaderState, setDLDocUploaderState] = useState({
        maxFileUploadLimit: '',
        attachment: '',
        fileName: '',
        boxLabel: 'Upload File',
        isShowLoader: false,
        previewImage:''
    });

    useEffect(() => {
        const {filePath} = props;
        if (isEmpty(filePath)) {
            setDLDocUploaderState((prevState) => ({
                ...prevState,
                fileName: '',
                attachment:'',
                previewImage:''
            }));
            return
        }
        downloadDocument(filePath);
    }, [props.filePath]);

    const handleChange = (name, value) => {
        setDLDocUploaderState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };


    const onChangeFile = (value, fileType) => {
        //delete call triggers and gives empty attachment
        if (isEmpty(value?.attachment)) {
            setDLDocUploaderState((prevState) => ({
                ...prevState,
                attachment: '',
                fileName: '',
                previewImage: ''
            }));
            props.onChangeValue('DELETE',{})
        } else {
            // upload attachment
            uploadDocument({
                attachment: value.attachment,
                fileName: value.fileName
            }, fileType)
        }
    };

    const uploadDocument = (updateObj, fileType) => {
        setDLDocUploaderState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        updateDocument(updateObj.attachment, fileType)
            .then((updateResponse) => {
                if (updateResponse.success) {
                    setDLDocUploaderState((prevState) => ({
                        ...prevState,
                        fileName: updateResponse.originalFileName,
                        attachment: updateObj.attachment,
                        isShowLoader: false
                    }));
                    props.onChangeValue('UPLOAD',updateResponse);
                } else {
                    setDLDocUploaderState((prevState) => ({
                        ...prevState,
                        isShowLoader: false
                    }));
                }
            });
    };


    const downloadDocument = () => {
        const {filePath,uploadedFileName} = props;
        const {fileName} = DLDocUploaderState;

        setDLDocUploaderState((prevStep) => ({
            ...prevStep,
            isShowLoader: true
        }));

        downloadFile(filePath)
            .then((res) => {
                if (res.success) {
                    setDLDocUploaderState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                        previewImage: res.blobData,
                        fileName: isEmpty(uploadedFileName) ? fileName : uploadedFileName
                    }));
                } else {
                    //TODO
                    setDLDocUploaderState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                    }));
                }
            });
    };

    const handleChangeStatus = ({meta, remove, xhr, file}, status, prop) => {
        const {handleError} = props;

        if (status === 'done') {
            let object = {
                attachment: file,
                fileName: meta.name,
            };
            onChangeFile(object, file?.type);
        } else if (status === 'error_file_size' || status === 'rejected_max_files' || status === 'rejected_file_type') {
            props.onChangeValue('ERROR',handleError(status));
        }
    };

    const isEmpty = (value) => {
        return (
            value === undefined ||
            value === 'undefined' ||
            value === null || value === ''
        )
    };

    const handleClearDoc = () =>{
        setDLDocUploaderState((prevState) => ({
            ...prevState,
            attachment: '',
            fileName: '',
            previewImage: '',
            isShowLoader: false,
        }));
        props.onChangeValue('DELETE',{});
    };

    const handleRemoveAttachment=()=>{
        const{documentId, onboardingApplicationId}=props;
        setDLDocUploaderState((prevStep) => ({
            ...prevStep,
            isShowLoader: true,
        }));
        if(isEmpty(documentId)){
            handleClearDoc();
            return
        }
        disAssociateDocument(documentId, onboardingApplicationId)
            .then(res=>{
                setDLDocUploaderState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false,
                }));
                if(res.success){
                    handleClearDoc()
                }else{
                    props.onChangeValue('ERROR',[res?.__error]);
                }
            })

    };

    return (
        <DLDocUploaderView
            {...props}
            DLDocUploaderState={DLDocUploaderState}
            handleChange={handleChange}
            handleChangeStatus={handleChangeStatus}
            onChangeFile={onChangeFile}
            isEmpty={isEmpty}
            handleRemoveAttachment={handleRemoveAttachment}
        />
    );
};

DLDocUploader.propTypes = {
    id: PropTypes.string.isRequired,  // for testing purpose
    label: PropTypes.string.isRequired,
    allowedFileTypes: PropTypes.string,
    attachments: PropTypes.array,  // existing attachments OR attachments passed by this component in onChangeValue
    fileNames: PropTypes.array,    // existing filenames
    onChangeValue: PropTypes.func.isRequired, // should be function will pass object as { attachments:[],filenames:[],previewUrl:[] }
    error: PropTypes.bool,
    uploadFileLimit: PropTypes.number,   // number of max upload file
    uploadFileSize: PropTypes.number,
    isRequired: PropTypes.bool,
    handleModalOpen: PropTypes.func,
    handleError: PropTypes.func,
    tooltipValue: PropTypes.string, // should be a trans key
    boxLabel: PropTypes.string, // label on box
    isShowLabel: PropTypes.bool,
    hideDeleteIcon: PropTypes.bool, //to hide delete icon
    marginBottom: PropTypes.oneOf([
        'none',
        'xxs',
        'xs',
        'sm',
        'md',
    ]),
    hidden: PropTypes.bool,
    helperMessage: PropTypes.string,
    iconType: PropTypes.string,
    fontSize: PropTypes.string,
    fontColor: PropTypes.string,
    disableHelperMessageHeight: PropTypes.bool,
    boxStyle: PropTypes.object,
    startIcon: PropTypes.element,
    isDeletable: PropTypes.bool,
    isDisableDelete: PropTypes.bool,
    placeHolder: PropTypes.string,
    imageHeight: PropTypes.string,
    downloadFileName: PropTypes.string
};

DLDocUploader.defaultProps = {
    allowedFileTypes: '.jpg, .png, .jpeg, .tiff, .pdf',
    attachments: [],
    fileNames: [],
    uploadFileLimit: 1,
    uploadFileSize: 62914560,
    isShowLabel: false,
    isRequired: false,
    hidden: false,
    marginBottom: 'sm',
    error: false,
    helperMessage: '',
    boxLabel: 'Upload File',
    disableHelperMessageHeight: false,
    hideDeleteIcon: false,
    fontSize: '14px',
    fontColor: '#000000',
    labelFontSize: 'xs',
    isDeletable: true,
    placeHolder: 'Drag & Drop or Upload JPG, PNG or PDF file',
    isDisableDelete: false,
    imageHeight: 'calc(100dvh - 446px)'
};

const mapStateToProps = (state) => ({
    isXSView: state.appState.deviceInfo.isXSView,
    onboardingApplicationId: state.onboardingFlowState.onboardingApplicationId
});

export default connect(mapStateToProps, {})(DLDocUploader);


